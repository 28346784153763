import { Divider, IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetStudentsQueryFilters,
  GetStudentsQuerySort,
  SORT_DIRECTION,
  STUDENTS_ARRANGE_BY_FILTER_KEYS,
  STUDENTS_QUERY_FILTER_KEYS,
  StudentsArrangeBy,
  StudentSearchResult,
  useGetStudentsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  ChartsCustomGrid,
  StudentsStaffCharts,
  useCharts,
  useChartsToggle,
} from '@schooly/components/charts';
import {
  ArrangedByCollapsableSection,
  getCombinedRowsFromSearchResults,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useArrangeByFromSearchParams,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { useSelectIds } from '@schooly/utils/bulk-actions';
import { format, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useSchool } from '../../hooks/useSchool';
import useUserCounts from '../../hooks/useUserCounts';
import AccessDenied from '../AccessDenied';
import { ArrangedByStudentsGrid, StudentsArrangedByList } from './StudentsArrangedByList';
import { StudentsBulkActions } from './StudentsBulkActions';
import { StudentsFilters } from './StudentsFilters';
import { StudentRow, StudentsHeader } from './StudentsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 8;

type StudentsContentProps = {
  initialFilters?: GetStudentsQueryFilters;
  initialArrangeBy?: StudentsArrangeBy | null;
};

const StudentsContent: React.FC<StudentsContentProps> = ({ initialFilters, initialArrangeBy }) => {
  const { permissions, schoolId } = useAuth();
  const navigate = useNavigate();
  const { onToggleChartsOpened, isChartsOpened, showZeroValues } = useCharts();
  const { lastAppliedFilter, lastAppliedArrangeBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.Students,
    filterKeys: STUDENTS_QUERY_FILTER_KEYS,
    arrangeByKeys: STUDENTS_ARRANGE_BY_FILTER_KEYS,
    schoolId: schoolId || '',
  });
  const arrangeByFromSearchParams = useArrangeByFromSearchParams(STUDENTS_ARRANGE_BY_FILTER_KEYS);
  let arrangeBy = initialArrangeBy;
  if (lastAppliedArrangeBy !== undefined) {
    arrangeBy = lastAppliedArrangeBy;
  } else if (arrangeByFromSearchParams !== undefined) {
    arrangeBy = arrangeByFromSearchParams;
  }

  const { hasHouses } = useSchool();
  const { selectedIds, toggleSelectAll, toggleSelectId, clearSelectedIds, toggleSelectedIds } =
    useSelectIds();
  const [arrangeByWithCustomGrid, setArrangeByCustomGrid] = useState<{
    arrangeBy: StudentsArrangeBy;
    totalCount?: number;
    grid: ChartsCustomGrid<GetStudentsQueryFilters> | null;
  } | null>(arrangeBy ? { arrangeBy, grid: null } : null);
  const { userCounts } = useUserCounts();
  const { formatMessage } = useIntl();
  const studentFiltersRef = useRef<StudentsFilters | null>(null);

  useChartsToggle({
    hasArrangeBy: !!arrangeByWithCustomGrid,
    isChartsOpened,
    onToggleChartsOpened,
  });

  const handleSetArrangeBy = useCallback((arrangeBy: StudentsArrangeBy | null) => {
    setArrangeByCustomGrid(arrangeBy ? { arrangeBy, grid: null } : null);
  }, []);

  const handleSetArrangeByTotalCount = useCallback((totalCount?: number) => {
    setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, totalCount } : null));
  }, []);

  const handleSetCustomGrid = useCallback(
    (grid: ChartsCustomGrid<GetStudentsQueryFilters> | null) => {
      setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, grid } : null));
    },
    [],
  );

  const handleAddApplicationClick = useCallback(() => {
    navigate('/applications');
  }, [navigate]);

  const defaultFilters: GetStudentsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [format(startOfDay(new Date()), DEFAULT_DATE_FORMAT_FNS)],
    }),
    [],
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: STUDENTS_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    params,
    error,
    setParams,
    fetchNextPage,
  } = useGetStudentsQuery(
    {
      query: '',
      schoolId: schoolId || '',
      filters: lastAppliedFilter ?? initialFiltersState,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always', enabled: arrangeByWithCustomGrid === null },
  );

  useEffect(clearSelectedIds, [
    params.filters,
    params.query,
    params.sort,
    arrangeByWithCustomGrid,
    clearSelectedIds,
  ]);
  useSyncFiltersStateWithSearchParams({
    pathname: '/students',
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    charts: isChartsOpened,
    zeroes: showZeroValues,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Students,
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    schoolId: schoolId || '',
  });

  const handleChangeSort = useCallback(
    (sort: GetStudentsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetStudentsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () =>
      getCombinedRowsFromSearchResults(
        data?.pages.reduce(
          (prev, curr) => [...prev, ...curr.results],
          [] as StudentSearchResult[],
        ) ?? [],
      ),
    [data?.pages],
  );

  const handleSelectRow = useCallback(
    (id: string) => {
      if (selectedIds === 'all') {
        toggleSelectedIds(
          entries.reduce<string[]>((acc, next) => (next.id === id ? acc : [...acc, next.id]), []),
        );
      } else {
        toggleSelectId(id);
      }
    },
    [entries, selectedIds, toggleSelectId, toggleSelectedIds],
  );
  const skeletonColumnsCount = SKELETON_COLS - (hasHouses ? 0 : 1); // hide column if no houses
  const total = arrangeByWithCustomGrid
    ? // If we have arrange by
      arrangeByWithCustomGrid.grid
      ? // and have custom grid then show custom grid count
        arrangeByWithCustomGrid.grid.count
      : //or arrange by totalCount
        arrangeByWithCustomGrid.totalCount
    : // if no arrange by then just data.count
      data?.pages[0]?.relation_count;

  const noResults = !isLoading && !entries.length;
  const showCharts = isChartsOpened && !noResults;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  const studentsBulkActions = (
    <StudentsBulkActions
      filters={params.filters}
      query={params.query}
      schoolId={schoolId}
      onClear={clearSelectedIds}
      selectedIds={selectedIds}
    />
  );

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="applications-New"
          pageTitleTextId="section-Students"
          showActionButton={permissions.includes('application_viewer')}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleAddApplicationClick}
          buttonCounter={userCounts?.application || undefined}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage(
              { id: 'people-SearchAmongType' },
              {
                userTypePlural: formatMessage({
                  id: 'userType-student-plural',
                }).toLowerCase(),
              },
            )}
          />
        </PageHeader>
        <StudentsFilters
          ref={studentFiltersRef}
          arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
          onSetArrangeBy={handleSetArrangeBy}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          defaultUserFilters={defaultUserFilters}
          defaultUserArrangeBy={initialArrangeBy ?? null}
        />
      </Stack>

      {showCharts && (
        <Stack mt={showCharts || (!showCharts && arrangeByWithCustomGrid) ? 3 : 0}>
          <StudentsStaffCharts
            entityType="student"
            breakDownByOptions={[
              FilterKeys.Status,
              // Not implemented on BE
              // FilterKeys.LeavingReason,
              FilterKeys.AgeGroup,
              ...(hasHouses ? [FilterKeys.House] : []),
              FilterKeys.Gender,
              FilterKeys.Nationality,
              FilterKeys.TutorGroup,
            ]}
            arrangeBy={arrangeByWithCustomGrid?.arrangeBy}
            query={params.query}
            filters={params.filters}
            onOpenCustomGrid={handleSetCustomGrid}
            onOpenArrangeByDropdown={studentFiltersRef.current?.openArrangeBy}
          />
        </Stack>
      )}

      {!arrangeByWithCustomGrid ? (
        <>
          <MainPageGrid mt={2.4} bottomElement={studentsBulkActions}>
            <StudentsHeader
              schoolHasHouses={hasHouses}
              sort={params.sort}
              onChangeSort={handleChangeSort}
              rightIcon={
                !showCharts ? (
                  <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                    <ChartIcon />
                  </IconButton>
                ) : undefined
              }
              onSelectAll={!!total ? toggleSelectAll : undefined}
              isSelectedAll={selectedIds === 'all'}
            />
            <GridBody>
              {entries?.map((entry) => (
                <StudentRow
                  schoolHasHouses={hasHouses}
                  schoolId={schoolId}
                  combinedStudentRow={entry}
                  key={entry.key}
                  onSelect={handleSelectRow}
                  isSelected={
                    selectedIds ? selectedIds === 'all' || selectedIds.has(entry.id) : false
                  }
                />
              ))}
              {isLoading && <SkeletonRows columnsCount={skeletonColumnsCount} amount={PAGE_SIZE} />}
              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={skeletonColumnsCount}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </MainPageGrid>
          {!isLoading && !entries.length && (
            <MainGridNoResultsStub textId="students-NoResults-title" />
          )}
        </>
      ) : (
        <>
          {!showCharts && (
            <>
              <Stack flexDirection="row" justifyContent="flex-end" mb={1.5} mt={3.5} pr={1}>
                <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                  <ChartIcon />
                </IconButton>
              </Stack>
              <Divider />
            </>
          )}
          {arrangeByWithCustomGrid.grid ? (
            <>
              <ArrangedByCollapsableSection
                isExpanded
                count={arrangeByWithCustomGrid.grid.count}
                title={arrangeByWithCustomGrid.grid.title}
              >
                <ArrangedByStudentsGrid
                  schoolHasHouses={hasHouses}
                  selectedIds={selectedIds}
                  onToggleSelectAll={toggleSelectAll}
                  onToggleSelectId={toggleSelectId}
                  count={arrangeByWithCustomGrid.grid.count}
                  sort={params.sort}
                  query={params.query}
                  filters={arrangeByWithCustomGrid.grid.filters}
                  schoolId={schoolId}
                  isSelectedAll={selectedIds === 'all'}
                  onChangeSort={handleChangeSort}
                />
              </ArrangedByCollapsableSection>
              {studentsBulkActions}
            </>
          ) : (
            <StudentsArrangedByList
              schoolHasHouses={hasHouses}
              schoolId={schoolId}
              filters={params.filters}
              showZeroCounts={showCharts ? showZeroValues : undefined}
              sort={params.sort}
              query={params.query}
              arrangeBy={arrangeByWithCustomGrid.arrangeBy}
              onChangeSort={handleChangeSort}
              onSetTotalCount={handleSetArrangeByTotalCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default StudentsContent;
