import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { AnnualPlanRecordTypes } from '@schooly/api';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  ANNUAL_PLANNER_VIEW_MODE_SELECT_WIDTH,
  AnnualPlannerViewModeSelect,
} from './AnnualPlannerViewModeSelect';
import { useAnnualPlannerFilter } from './useAnnualPlannerFilter';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageFilter: FC = () => {
  const { formatMessage } = useIntl();
  const { hasEventsPermissions, hasAssessmentsPermissions, recordTypesMap, toggleRecordType } =
    useAnnualPlanner();
  const { getFilterItemProps } = useAnnualPlannerFilter();

  return (
    <Stack direction="row">
      <Stack
        direction="row"
        mt={0.5}
        mb={1.75}
        flexWrap="wrap"
        sx={(theme) => ({ gap: theme.spacing(1, 2.5) })}
      >
        <FormControlLabel
          label={formatMessage({ id: 'annualPlanner-Records-SchoolPeriods-Title' })}
          control={
            <Checkbox
              checked={recordTypesMap[AnnualPlanRecordTypes.SCHOOL_PERIOD]}
              onChange={toggleRecordType(AnnualPlanRecordTypes.SCHOOL_PERIOD)}
            />
          }
          {...getFilterItemProps(AnnualPlanRecordTypes.SCHOOL_PERIOD)}
        />

        {hasEventsPermissions && (
          <>
            <FormControlLabel
              label={formatMessage({ id: 'annualPlanner-Records-Holidays-Title' })}
              control={
                <Checkbox
                  sx={(theme) => ({
                    '&.Mui-checked .svg-icon circle': {
                      fill: theme.palette.common.green1,
                    },
                  })}
                  checked={recordTypesMap[AnnualPlanRecordTypes.HOLIDAY]}
                  onChange={toggleRecordType(AnnualPlanRecordTypes.HOLIDAY)}
                />
              }
              {...getFilterItemProps(AnnualPlanRecordTypes.HOLIDAY)}
            />

            <FormControlLabel
              label={formatMessage({ id: 'annualPlanner-Records-Events-Title' })}
              control={
                <Checkbox
                  sx={(theme) => ({
                    '&.Mui-checked .svg-icon circle': {
                      fill: theme.palette.common.moss1,
                    },
                  })}
                  checked={recordTypesMap[AnnualPlanRecordTypes.EVENT]}
                  onChange={toggleRecordType(AnnualPlanRecordTypes.EVENT)}
                />
              }
              {...getFilterItemProps(AnnualPlanRecordTypes.EVENT)}
            />
          </>
        )}

        {hasAssessmentsPermissions && (
          <>
            <FormControlLabel
              label={formatMessage({ id: 'annualPlanner-Records-Assessments-Title' })}
              control={
                <Checkbox
                  sx={(theme) => ({
                    '&.Mui-checked .svg-icon circle': {
                      fill: theme.palette.common.dijon1,
                    },
                  })}
                  checked={recordTypesMap[AnnualPlanRecordTypes.ASSESSMENT]}
                  onChange={toggleRecordType(AnnualPlanRecordTypes.ASSESSMENT)}
                />
              }
              {...getFilterItemProps(AnnualPlanRecordTypes.ASSESSMENT)}
            />

            <FormControlLabel
              label={formatMessage({ id: 'annualPlanner-Records-Reports-Title' })}
              control={
                <Checkbox
                  sx={(theme) => ({
                    '&.Mui-checked .svg-icon circle': {
                      fill: theme.palette.common.wine1,
                    },
                  })}
                  checked={recordTypesMap[AnnualPlanRecordTypes.REPORT]}
                  onChange={toggleRecordType(AnnualPlanRecordTypes.REPORT)}
                />
              }
              {...getFilterItemProps(AnnualPlanRecordTypes.REPORT)}
            />
          </>
        )}
      </Stack>
      <Stack flex={`1 1 ${ANNUAL_PLANNER_VIEW_MODE_SELECT_WIDTH}px`} alignItems="flex-end">
        <AnnualPlannerViewModeSelect />
      </Stack>
    </Stack>
  );
};
