import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { SessionReplayOptions } from '@amplitude/plugin-session-replay-browser/lib/esm/typings/session-replay';

export const DEFAULT_SESSION_REPLAY_SAMPLE_RATE = 0.1;
export const INCREASED_SESSION_REPLAY_SAMPLE_RATE = 0.5;

export enum AnalyticsEventName {
  // annual planner events
  DrillDownAnnuallySelect = 'DrillDown_annually_select',
  DrillDownTermlySelect = 'DrillDown_termly_select',
  DrillDownMonthlySelect = 'DrillDown_monthly_select',
  DrillDownAnnuallyShown = 'DrillDown_annually',
  DrillDownTermlyShown = 'DrillDown_termly',
  DrillDownMonthlyShown = 'DrillDown_monthly',

  //leaving reason events
  RLOpen = 'RL_open',
  RLAddReason = 'RL_add_reason',
  RLArchiveReason = 'RL_archive_reason',
}

type AnalyticsEvent<T extends AnalyticsEventName, P = undefined> = {
  name: T;
  payload?: P;
};

type AnnualPlannerViewAnalyticsEvent = AnalyticsEvent<
  | AnalyticsEventName.DrillDownAnnuallySelect
  | AnalyticsEventName.DrillDownTermlySelect
  | AnalyticsEventName.DrillDownMonthlySelect
  | AnalyticsEventName.DrillDownAnnuallyShown
  | AnalyticsEventName.DrillDownTermlyShown
  | AnalyticsEventName.DrillDownMonthlyShown
>;
type LeavingReasonAnalyticsEvent = AnalyticsEvent<
  AnalyticsEventName.RLOpen | AnalyticsEventName.RLAddReason | AnalyticsEventName.RLArchiveReason,
  { user_role: string[] }
>;

type AnalyticsTypedEvent = AnnualPlannerViewAnalyticsEvent | LeavingReasonAnalyticsEvent;

export const trackAmplitudeEvent = ({ name, payload }: AnalyticsTypedEvent) => {
  amplitude.track(name, payload);
};

export const updateAmplitudeSessionReplayOptions = async (options: SessionReplayOptions) => {
  const sessionReplayTracking = sessionReplayPlugin(options);

  if (sessionReplayTracking.name) {
    try {
      await amplitude.remove(sessionReplayTracking.name).promise;
    } catch (error) {
      console.error('Failed to remove Amplitude Session Replay Plugin:', error);
    }
  }

  try {
    await amplitude.add(sessionReplayTracking).promise;
  } catch (error) {
    console.error('Failed to add Amplitude Session Replay Plugin:', error);
  }
};

export const startAmplitudeNewSession = () => {
  const sessionId = Date.now();
  amplitude.setSessionId(sessionId);
};
