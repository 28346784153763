import {
  AnnualPlanRecord,
  AnnualPlanRecordTypes,
  DEFAULT_DATE_FORMAT_FNS,
  ReportStatuses,
  SchoolYear,
} from '@schooly/api';
import { AssessmentStatuses } from '@schooly/constants';
import {
  addHours,
  compareAsc,
  compareDesc,
  differenceInMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDate,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  parse,
  startOfMonth,
  startOfToday,
} from 'date-fns';

import { usePeriodValidation } from '../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { useAnnualPlanner } from '../WithAnnualPlanner';
import {
  ANNUAL_PLANNER_DAY_CELL_WIDTH,
  AnnualPlannerCalendarWithDates,
  AnnualPlannerCreateForm,
  AnnualPlannerRecordMeta,
} from './scheme';
import { UseAnnualPlannerCellItem } from './useAnnualPlannerCell';

export const getAnnualPlannerCell = ({
  month,
  day,
  start,
  end,
  date,
}: {
  month: Date;
  start: Date;
  end: Date;
  day?: number;
  date?: Date;
}) => {
  const lastDayOfMonth = endOfMonth(month).getDate();
  const currentDate = date ?? new Date(month);
  let hasDate = true;

  //checks date validity and changes to last day of month if day is passed
  if (day) {
    hasDate = day <= lastDayOfMonth;
    currentDate.setDate(Math.min(day, lastDayOfMonth));
  }

  hasDate = hasDate && !isBefore(currentDate, start) && !isAfter(currentDate, end);

  const isFirstDate = isSameDay(currentDate, start);
  const isLastMonth = isSameMonth(currentDate, end);
  const isWeekEnd = isWeekend(currentDate);
  const isSunday = currentDate.getDay() === 0;
  const isMonday = currentDate.getDay() === 1;
  const isThisMonth = month ? isSameMonth(currentDate, month) : false;
  const dayOfMonth = getDate(currentDate);

  return {
    date: currentDate,
    hasDate,
    isFirstDate,
    isLastMonth,
    isWeekEnd,
    isSunday,
    isThisMonth,
    dayOfMonth,
    day: dayOfMonth,
    isMonday,
  };
};

export interface GetCellPositionProps extends AnnualPlannerCalendarWithDates {
  date: Date;
}

export const getCellPosition = (props: GetCellPositionProps) => {
  const start =
    typeof props.start === 'string'
      ? parse(props.start, DEFAULT_DATE_FORMAT_FNS, new Date())
      : props.start;

  const end =
    typeof props.end === 'string'
      ? parse(props.end, DEFAULT_DATE_FORMAT_FNS, new Date())
      : props.end;

  const months = differenceInMonths(end, start) + 1;

  const topDateNum = getDate(props.date) - 1;
  const bottomDateNum = topDateNum + 1;
  const monthNum = differenceInMonths(addHours(props.date, 1), startOfMonth(start));

  const topValue = (topDateNum * 100) / 31;
  const top = topValue === 0 ? '1px' : `calc(${topValue}% + 1px)`;

  const rightValue = ((monthNum + 1) * 100) / months;
  const right = `${100 - rightValue}%`;

  const bottomValue = (bottomDateNum * 100) / 31;
  const bottom = `${100 - bottomValue}%`;

  const leftValue = (monthNum * 100) / months;
  const left =
    leftValue === 0
      ? `${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px`
      : `calc(${leftValue}% + ${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px)`;

  const widthValue = rightValue - leftValue;
  const width = `calc(${widthValue}% - ${ANNUAL_PLANNER_DAY_CELL_WIDTH + 1}px)`;

  return {
    top,
    topValue,
    right,
    rightValue,
    bottom,
    bottomValue,
    left,
    leftValue,
    width,
    widthValue,
    topDateNum,
    bottomDateNum,
  };
};

export const getCellStyleParams = (
  date: Date,
  group: UseAnnualPlannerCellItem,
  index: number,
  groups: UseAnnualPlannerCellItem[],
) => {
  const padding: string[] = groups.map((group, i) =>
    group.size === 'wide' ||
    (isSameDay(group.start, date) && (i === groups.length - 1 || groups[i + 1].size === 'wide'))
      ? '1fr'
      : `${i > 0 ? (group.narrowIndex - groups[i - 1].narrowIndex) * 3 : 3}px`,
  );
  const gridColumnStart = index + 1;

  const titleColumnStart =
    group.size === 'narrow' ? padding.findIndex((p) => p === '1fr') + 1 : gridColumnStart;

  return { padding, gridColumnStart, titleColumnStart };
};

export const getRecordLink = (record: AnnualPlannerRecordMeta) => {
  switch (record.type) {
    case AnnualPlanRecordTypes.EVENT:
    case AnnualPlanRecordTypes.HOLIDAY:
      return `/events/${record.id}`;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return `/assessments/${record.id}`;
    case AnnualPlanRecordTypes.REPORT:
      return `/reports/${record.id}`;
  }
};

export const useGetRecordLockHint = () => {
  const { schoolYear } = useAnnualPlanner();
  const { validatePastDate } = usePeriodValidation();

  const getRecordLockHint = (record: AnnualPlannerRecordMeta) => {
    switch (record.type) {
      case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        if (isBefore(record.start, startOfToday())) {
          return 'annualPlanner-Records-SchoolPeriods-LockHint';
        }

        const group = schoolYear?.period_groups?.find(
          (group) => group.id === record.details.period_group_id,
        );
        const hasFrequencyConnection = !!group?.frequency;
        const isStarted = group?.periods.some((period) => validatePastDate(period.date_from));
        return hasFrequencyConnection && isStarted
          ? 'school-schoolPeriods-EditBlockedByFrequencyConnection'
          : undefined;
      case AnnualPlanRecordTypes.HOLIDAY:
        return record.details.can_be_edited === false || isBefore(record.start, startOfToday())
          ? 'annualPlanner-Records-Holidays-LockHint'
          : undefined;
      case AnnualPlanRecordTypes.EVENT:
        return record.details.can_be_edited === false || isBefore(record.start, startOfToday())
          ? 'annualPlanner-Records-Events-LockHint'
          : undefined;
      case AnnualPlanRecordTypes.ASSESSMENT:
        return record.details.status === AssessmentStatuses.Published
          ? 'annualPlanner-Records-Assessments-LockHint'
          : undefined;
      case AnnualPlanRecordTypes.REPORT:
        return record.details.report_status === ReportStatuses.Published
          ? 'annualPlanner-Records-Reports-LockHint'
          : undefined;
    }
  };

  return getRecordLockHint;
};

export const getRecordFormattedDate = (record: AnnualPlannerRecordMeta) => {
  const dates: Date[] = [record.start];

  if (!isSameDay(record.end, record.start)) {
    dates.push(record.end);
  }

  return dates.map((date) => format(date, 'd MMM yyyy')).join(' - ');
};

export const getRecordTitleByType = (type: AnnualPlanRecordTypes) => {
  switch (type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return 'annualPlanner-Records-SchoolPeriods-Type';
    case AnnualPlanRecordTypes.EVENT:
      return 'annualPlanner-Records-Events-Type';
    case AnnualPlanRecordTypes.HOLIDAY:
      return 'annualPlanner-Records-Holidays-Type';
    case AnnualPlanRecordTypes.ASSESSMENT:
      return 'annualPlanner-Records-Assessments-Type';
    case AnnualPlanRecordTypes.REPORT:
      return 'annualPlanner-Records-Reports-Type';
  }
};

export const getRecordFormData = (
  record: AnnualPlannerRecordMeta,
  schoolYear: SchoolYear,
): Partial<AnnualPlannerCreateForm> => {
  const originId = record.details.id;
  const type = record.type;
  const date: AnnualPlannerCreateForm['date'] = [
    record.start ? format(record.start, DEFAULT_DATE_FORMAT_FNS) : '',
    record.end ? format(record.end, DEFAULT_DATE_FORMAT_FNS) : '',
  ];

  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD: {
      const group = schoolYear.period_groups?.find(
        (group) => group.id === record.details.period_group_id,
      );

      return {
        originId,
        type,
        date,
        schoolPeriod: {
          groupId: record.details.period_group_id,
          groupName: group?.name,
        },
      };
    }
    case AnnualPlanRecordTypes.HOLIDAY:
    case AnnualPlanRecordTypes.EVENT:
      return {
        originId,
        type,
        date,
        event: {
          name: record.details.title,
          isHoliday: type === AnnualPlanRecordTypes.HOLIDAY,
        },
      };
    case AnnualPlanRecordTypes.ASSESSMENT:
      return {
        originId,
        type,
        date,
        assessment: {
          name: record.details.name,
        },
      };
    case AnnualPlanRecordTypes.REPORT:
      return {
        originId,
        type,
        date,
        report: {
          name: record.details.name,
        },
      };
    default:
      return {
        originId,
        type,
        date,
      };
  }
};

export const getRecordIncompleteFields = (record: AnnualPlannerRecordMeta) => {
  const fields: string[] = [];

  switch (record.type) {
    case AnnualPlanRecordTypes.EVENT:
      if (!record.details.description) {
        fields.push('description');
      }

      if (!record.details.invitee_type) {
        fields.push('invitee_type');
      }

      break;

    case AnnualPlanRecordTypes.ASSESSMENT:
      if (record.details.status === AssessmentStatuses.Published) {
        // The published assessment can not be incomplete. But the assessment might occur published
        // without some mandatory fields (like built-in assessment or so), in this case have to
        // ignore incomplete fields.
        // TODO: double-check if this is do a valid case
        break;
      }

      if (!record.details.method_ids?.length) {
        fields.push('methods');
      }

      if (!record.details.group_ids?.length) {
        fields.push('groups');
      }

      break;

    case AnnualPlanRecordTypes.REPORT:
      if (!record.details.assessment_ids?.length) {
        fields.push('assessments');
      }

      break;
  }

  return fields;
};

export const isAnnualPlannerRecordMeta = (
  value: AnnualPlannerRecordMeta | null,
): value is AnnualPlannerRecordMeta => {
  return value !== null;
};

/** Retrieves record's minimal metadata in a common format for the calendar grid */
export const getAnnualPlannerRecordMeta = (
  record: AnnualPlanRecord,
  getSchoolYearById: (id: string) => SchoolYear | undefined,
): AnnualPlannerRecordMeta | undefined => {
  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD: {
      const period = getSchoolYearById(record.year_id)
        ?.period_groups?.find((group) => group.id === record.period_group_id)
        ?.periods.find((period) => period.id === record.id);

      if (!period) {
        return;
      }

      return {
        id: period.id,
        type: record.type,
        title: period.name,
        start: parse(period.date_from, DEFAULT_DATE_FORMAT_FNS, new Date()),
        end: parse(period.date_to, DEFAULT_DATE_FORMAT_FNS, new Date()),
        details: record,
      };
    }
    case AnnualPlanRecordTypes.EVENT:
    case AnnualPlanRecordTypes.HOLIDAY:
      return {
        id: record.id,
        type: record.type,
        title: record.title,
        start: parse(record.start, DEFAULT_DATE_FORMAT_FNS, new Date()),
        end: parse(record.end, DEFAULT_DATE_FORMAT_FNS, new Date()),
        details: record,
      } as AnnualPlannerRecordMeta;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return {
        id: record.id,
        type: record.type,
        title: record.name,
        start: parse(record.assessment_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
        end: parse(record.assessment_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
        details: record,
      };
    case AnnualPlanRecordTypes.REPORT: {
      if (!record.scheduled_publish_date) {
        return undefined;
      }

      return {
        id: record.id,
        type: record.type,
        title: record.name,
        start: parse(record.scheduled_publish_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
        end: parse(record.scheduled_publish_date, DEFAULT_DATE_FORMAT_FNS, new Date()),
        details: record,
      };
    }
  }
};

export const getAnnualPlannerRecordWeight = <T extends Pick<AnnualPlanRecord, 'type'>>(
  record: T,
) => {
  switch (record.type) {
    case AnnualPlanRecordTypes.SCHOOL_PERIOD:
      return 0;
    case AnnualPlanRecordTypes.EVENT:
      return 1;
    case AnnualPlanRecordTypes.HOLIDAY:
      return 2;
    case AnnualPlanRecordTypes.ASSESSMENT:
      return 3;
    case AnnualPlanRecordTypes.REPORT:
      return 4;
    default:
      return 5;
  }
};

export const getSortedAnnualPlannerRecords = (
  records: AnnualPlanRecord[],
  getSchoolYearById: (id: string) => SchoolYear | undefined,
) => {
  return records
    .sort((a, b) => getAnnualPlannerRecordWeight(a) - getAnnualPlannerRecordWeight(b))
    .sort((a, b) => {
      const metaA = getAnnualPlannerRecordMeta(a, getSchoolYearById);
      const metaB = getAnnualPlannerRecordMeta(b, getSchoolYearById);

      if (!metaA) {
        return -1;
      }

      if (!metaB) {
        return 1;
      }

      const n = compareAsc(metaA.start, metaB.start);

      if (n !== 0) {
        return n;
      }

      return compareDesc(metaA.end, metaB.end);
    });
};

export const getAnnualPlannerRecordsByDate = (
  records: AnnualPlanRecord[],
  getSchoolYearById: (id: string) => SchoolYear | undefined,
) => {
  // the records should always be in the same order
  const sortedRecords = getSortedAnnualPlannerRecords(records, getSchoolYearById);

  return sortedRecords.reduce<Record<string, AnnualPlannerRecordMeta[]>>((prev, record) => {
    const meta = getAnnualPlannerRecordMeta(record, getSchoolYearById);

    if (!meta) {
      return prev;
    }

    eachDayOfInterval(meta).forEach((date) => {
      const dateString = format(date, DEFAULT_DATE_FORMAT_FNS);

      if (!prev[dateString]) {
        prev[dateString] = [];
      }

      prev[dateString].push(meta);
    });

    return prev;
  }, {});
};

//in month view we need to show gaps between records
export const getAnnualPlannerRecordsWithGapsByDate = (
  records: AnnualPlanRecord[],
  getSchoolYearById: (id: string) => SchoolYear | undefined,
) => {
  // the records should always be in the same order
  const sortedRecords = getSortedAnnualPlannerRecords(records, getSchoolYearById);

  return sortedRecords.reduce<Record<string, (AnnualPlannerRecordMeta | null)[]>>(
    (prev, record) => {
      const meta = getAnnualPlannerRecordMeta(record, getSchoolYearById);

      if (!meta) {
        return prev;
      }

      eachDayOfInterval(meta).forEach((date, index, dates) => {
        const dateString = format(date, DEFAULT_DATE_FORMAT_FNS);
        const prevDay = index > 0 ? dates[index - 1] : null;
        const prevIndex = prevDay
          ? prev[format(prevDay, DEFAULT_DATE_FORMAT_FNS)].findIndex((r) => r && r.id === meta.id)
          : -1;

        if (!prev[dateString]) {
          prev[dateString] = [];
        }

        //keeps the same position of the record in calendar adding gaps
        if (prevIndex >= 0) {
          if (prevIndex - prev[dateString].length > 0) {
            prev[dateString].push(...Array(prevIndex - prev[dateString].length).fill(null));
            prev[dateString].push(meta);
            return;
          }
        }

        //moves following records to gaps in calendar
        const gapIndex = prev[dateString].findIndex((r) => r === null);
        const canMove = gapIndex >= 0 && (prevIndex === -1 || prevIndex === gapIndex);

        if (canMove) {
          prev[dateString][gapIndex] = meta;
        } else {
          prev[dateString].push(meta);
        }
      });

      return prev;
    },
    {},
  );
};
