import {
  Box,
  ClickAwayListener,
  ClickAwayListenerProps,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { SignUpRespondent, useGetMembership } from '@schooly/api';
import { SimpleButton, StarIcon } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import { useSchool } from '../../../../hooks/useSchool';

export interface SignUpPreviewRespondentsRecordPopupProps {
  respondent?: SignUpRespondent;
  onClose: ClickAwayListenerProps['onClickAway'];
}

export const SignUpPreviewRespondentsRecordPopup: FC<SignUpPreviewRespondentsRecordPopupProps> = ({
  respondent,
  onClose,
}) => {
  const { schoolId = '' } = useSchool();
  const { data, isLoading } = useGetMembership(
    'student',
    { schoolId, id: respondent?.student.relation_id ?? '' },
    { enabled: !!respondent?.student.relation_id },
  );

  if (!respondent) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box minWidth={250}>
        <Box px={0.5} sx={{ '& .PersonCard .card-body:hover': { bgcolor: 'transparent' } }}>
          <PersonCardBasic
            user={respondent.student}
            userType="student"
            isListItem
            isUsernameClickable
          />
        </Box>
        <Stack px={1} pb={1} gap={1}>
          <Typography variant="caption" color="text.secondary" textTransform="uppercase">
            <FormattedMessage id="userType-parentsGuardians" />
          </Typography>
          {isLoading ? (
            <Typography>
              <Skeleton />
            </Typography>
          ) : (
            <>
              {data?.guardian_relations.map((relation) => (
                <Link key={relation.id} to={`/parents/${relation.guardian.relation_id}`}>
                  <SimpleButton
                    startIcon={relation.primary ? <StarIcon /> : undefined}
                    sx={(theme) => ({
                      fontSize: theme.typography.body1.fontSize,
                      lineHeight: theme.typography.body1.lineHeight,
                      gap: 0.5,

                      '& .MuiButton-startIcon .svg-icon': {
                        fontSize: theme.spacing(2),
                      },
                    })}
                  >
                    {getUserFullName(relation.guardian)}
                  </SimpleButton>
                </Link>
              ))}
            </>
          )}
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};
