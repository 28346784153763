import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6 6.40005V11.9144L4.08326 2.39764C5.09307 1.40931 6.47538 0.800049 7.99998 0.800049C11.0928 0.800049 13.6 3.30725 13.6 6.40005Z"
      fill="#B3BBCB"
    />
    <path
      d="M3.10753 3.67328C2.65683 4.48021 2.39998 5.41013 2.39998 6.40005V12.8H12.2343L14.1171 14.6829L14.6828 14.1172L1.88282 1.31721L1.31714 1.88289L3.10753 3.67328Z"
      fill="#B3BBCB"
    />
    <path
      d="M5.22808 13.6C5.78138 14.5565 6.81553 15.2 7.99998 15.2C9.18443 15.2 10.2186 14.5565 10.7719 13.6H5.22808Z"
      fill="#B3BBCB"
    />
  </svg>
);
