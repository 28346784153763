import { Divider, IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetGroupsQueryFilters,
  GetGroupsQuerySort,
  Group,
  GroupArrangeBy,
  GROUPS_ARRANGE_BY_FILTER_KEYS,
  GROUPS_QUERY_FILTER_KEYS,
  SORT_DIRECTION,
  useGetGroupsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  ChartsCustomGrid,
  GroupCharts,
  useCharts,
  useChartsToggle,
} from '@schooly/components/charts';
import {
  ArrangedByCollapsableSection,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useArrangeByFromSearchParams,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  usePeriodsLabel,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import AccessDenied from '../AccessDenied';
import { ArrangedByGroupsGrid, GroupsArrangedByList } from './GroupsArrangedByList';
import { GroupFilters, GroupsFilters } from './GroupsFilters';
import { GroupRow, GroupsHeader } from './GroupsGrid';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 7;

type GroupsContentProps = {
  initialFilters?: GetGroupsQueryFilters;
  initialArrangeBy?: GroupArrangeBy | null;
};

const GroupsContent: React.FC<GroupsContentProps> = ({ initialFilters, initialArrangeBy }) => {
  const location = useAppLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { schoolId, permissions } = useAuth();
  const { onToggleChartsOpened, isChartsOpened } = useCharts();

  const { lastAppliedFilter, lastAppliedArrangeBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.Groups,
    filterKeys: GROUPS_QUERY_FILTER_KEYS,
    arrangeByKeys: GROUPS_ARRANGE_BY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const arrangeByFromSearchParams = useArrangeByFromSearchParams(GROUPS_ARRANGE_BY_FILTER_KEYS);
  let arrangeBy = initialArrangeBy;
  if (lastAppliedArrangeBy !== undefined) {
    arrangeBy = lastAppliedArrangeBy;
  } else if (arrangeByFromSearchParams !== undefined) {
    arrangeBy = arrangeByFromSearchParams;
  }

  const groupFiltersRef = useRef<GroupFilters | null>(null);

  const [arrangeByWithCustomGrid, setArrangeByCustomGrid] = useState<{
    arrangeBy: GroupArrangeBy;
    totalCount?: number;
    grid: ChartsCustomGrid<GetGroupsQueryFilters> | null;
  } | null>(arrangeBy ? { arrangeBy, grid: null } : null);

  useChartsToggle({
    hasArrangeBy: !!arrangeByWithCustomGrid,
    isChartsOpened,
    onToggleChartsOpened,
  });

  const handleAddGroupClick = useCallback(() => {
    navigate('/groups/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  const handleSetArrangeBy = useCallback((arrangeBy: GroupArrangeBy | null) => {
    setArrangeByCustomGrid(arrangeBy ? { arrangeBy, grid: null } : null);
  }, []);

  const handleSetCustomGrid = useCallback(
    (grid: ChartsCustomGrid<GetGroupsQueryFilters> | null) => {
      setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, grid } : null));
    },
    [],
  );

  const handleSetArrangeByTotalCount = useCallback((totalCount?: number) => {
    setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, totalCount } : null));
  }, []);

  const { defaultValidity, schoolYears } = useSchoolYears();

  const { getPeriodLabel } = usePeriodsLabel({ schoolYears });

  const defaultFilters: GetGroupsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: GROUPS_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetGroupsQuery(
    {
      schoolId: schoolId || '',
      query: '',
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      filters: lastAppliedFilter ?? initialFiltersState,
    },
    { refetchOnMount: 'always', enabled: arrangeByWithCustomGrid === null },
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  useSyncFiltersStateWithSearchParams({
    pathname: '/groups',
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    charts: isChartsOpened,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Groups,
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    schoolId: schoolId || '',
  });

  const handleChangeSort = useCallback(
    (sort: GetGroupsQuerySort) => {
      setParams((p) => ({
        ...p,
        sort,
      }));
    },
    [setParams],
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetGroupsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () => data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Group[]) ?? [],
    [data?.pages],
  );

  const total = arrangeByWithCustomGrid
    ? // If we have arrange by
      arrangeByWithCustomGrid.grid
      ? // and have custom grid then show custom grid count
        arrangeByWithCustomGrid.grid.count
      : //or arrange by totalCount
        arrangeByWithCustomGrid.totalCount
    : // if no arrange by then just data.count
      data?.pages[0]?.count;

  const noResults = !isLoading && !entries.length;
  const showCharts = isChartsOpened && !noResults;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="groups-NewGroup"
          pageTitleTextId="section-Groups"
          showActionButton={permissions.includes('group_manager')}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleAddGroupClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage({ id: 'groups-SearchGroups' })}
          />
        </PageHeader>
        <GroupsFilters
          notActualInitialDate={notActualInitialDate}
          arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
          onSetArrangeBy={handleSetArrangeBy}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          ref={groupFiltersRef}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
          defaultUserArrangeBy={initialArrangeBy ?? null}
        />
      </Stack>
      {showCharts && (
        <Stack mt={showCharts || (!showCharts && arrangeByWithCustomGrid) ? 3 : 0}>
          <GroupCharts
            arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
            filters={params.filters}
            query={params.query}
            onOpenCustomGrid={handleSetCustomGrid}
            onOpenArrangeByDropdown={groupFiltersRef.current?.openArrangeBy}
          />
        </Stack>
      )}
      {!arrangeByWithCustomGrid ? (
        <>
          <MainPageGrid mt={2.4}>
            <GroupsHeader
              sort={params.sort}
              onChangeSort={handleChangeSort}
              rightIcon={
                !showCharts ? (
                  <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                    <ChartIcon />
                  </IconButton>
                ) : undefined
              }
            />
            <GridBody>
              {entries?.map((entry) => {
                const { date_from, date_to } = entry.validity;
                const periodLabel =
                  date_from && date_to
                    ? getPeriodLabel({
                        startDate: date_from,
                        endDate: date_to,
                      })
                    : undefined;

                return <GroupRow group={entry} key={entry.id} periodLabel={periodLabel} />;
              })}
              {isLoading && <SkeletonRows columnsCount={6} amount={PAGE_SIZE} />}
              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={SKELETON_COLS}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </MainPageGrid>
          {!isLoading && !entries.length && (
            <MainGridNoResultsStub textId="groups-NoResults-title" />
          )}
        </>
      ) : (
        <>
          {!showCharts && (
            <>
              <Stack flexDirection="row" justifyContent="flex-end" pb={1.5} pt={3.5} pr={1}>
                <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                  <ChartIcon />
                </IconButton>
              </Stack>
              <Divider />
            </>
          )}
          {arrangeByWithCustomGrid.grid ? (
            <ArrangedByCollapsableSection
              isExpanded
              count={arrangeByWithCustomGrid.grid.count}
              title={arrangeByWithCustomGrid.grid.title}
            >
              <ArrangedByGroupsGrid
                count={arrangeByWithCustomGrid.grid.count}
                sort={params.sort}
                filters={arrangeByWithCustomGrid.grid.filters}
                schoolId={schoolId}
                query={params.query}
                onChangeSort={handleChangeSort}
                getPeriodLabel={getPeriodLabel}
              />
            </ArrangedByCollapsableSection>
          ) : (
            <GroupsArrangedByList
              schoolId={schoolId}
              filters={params.filters}
              sort={params.sort}
              query={params.query}
              arrangeByKey={arrangeByWithCustomGrid.arrangeBy}
              onChangeSort={handleChangeSort}
              onSetTotalCount={handleSetArrangeByTotalCount}
              getPeriodLabel={getPeriodLabel}
            />
          )}
        </>
      )}
    </>
  );
};

export default GroupsContent;
