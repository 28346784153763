import { Box, IconButton, Stack } from '@mui/material';
import {
  AssessmentGroup,
  FilterKeys,
  GetGroupsQueryFilters,
  Group,
  useGetGroupsQuery,
} from '@schooly/api';
import { FilterButton, SearchInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { CrossIcon, FilterIcon, SkeletonRowsComponent } from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ModalPanel } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { AssessmentGroupFiltersPopup } from '../../AssessmentsPreviewModal/AssessmentGroupFiltersPopup';
import { InlineFiltersTags } from '../InlineFiltersTags';
import AssessmentsCreateModalGroupsItem from './AssessmentsCreateModalGroupsItem';

const PAGE_SIZE = 30;

const groupFilters: GetGroupsQueryFilters = {
  [FilterKeys.StaffHouse]: [],
  [FilterKeys.StudentHouse]: [],
  [FilterKeys.AgeGroup]: [],
  [FilterKeys.StaffStatus]: [],
  [FilterKeys.StudentStatus]: [],
  [FilterKeys.Subject]: [],
  [FilterKeys.OnlyTutorGroups]: [],
  [FilterKeys.Date]: [],
  [FilterKeys.SingleDate]: [],
};

type AssessmentsCreateModalGroupsRightSidebarProps = {
  selectedGroups: AssessmentGroup[];
  onCardClick: (group: Group) => void;
  onSetGroups: (groups: Group[]) => void;
  relationIds: string[];
  schoolId: string;
  dateParams: GetGroupsQueryFilters;
};

export const AssessmentsCreateModalGroupsRightSidebar: FC<AssessmentsCreateModalGroupsRightSidebarProps> =
  ({ selectedGroups, onCardClick, relationIds, schoolId, dateParams }) => {
    const { formatMessage } = useIntl();

    const [filtersModalVisible, openFilterModal, closeFiltersModal] = useFlag(false);
    const initialFiltersState = { ...groupFilters, ...dateParams };

    const {
      data,
      hasNextPage,
      isFetchingNextPage,
      params,
      setParams,
      fetchNextPage,
      isLoading,
      isFetching,
    } = useGetGroupsQuery({
      schoolId: schoolId,
      pageSize: PAGE_SIZE,
      filters: initialFiltersState,
      relationIds,
      query: '',
    });

    const total = data?.pages[0]?.count;

    const hasFiltersApplied = Object.keys(initialFiltersState).some(
      (key) => !!params.filters[key as keyof typeof initialFiltersState]?.length,
    );

    const handleSetFiltersQuery = useCallback(
      (query: string) => {
        setParams((p) => ({ ...p, query }));
      },
      [setParams],
    );

    const handleSetFilters = useCallback(
      (filters: GetGroupsQueryFilters) => {
        setParams((p) => ({
          ...p,
          filters: {
            ...filters,
            ...dateParams,
          },
        }));
      },
      [dateParams, setParams],
    );

    const handleResetFilters = useCallback(() => {
      setParams((p) => ({ ...p, filters: {} }));
    }, [setParams]);

    const loaderRef = useInfiniteScroll(isFetching, fetchNextPage, hasNextPage);

    const groups = useMemo(() => {
      const selectedGroupIds = selectedGroups.map(({ id }) => id);
      return data?.pages.reduce<Group[]>(
        (prev, curr) => [...prev, ...curr.results.filter((g) => !selectedGroupIds.includes(g.id))],
        [],
      );
    }, [data?.pages, selectedGroups]);

    const renderList = () => {
      return (
        <>
          {!isLoading && !groups?.length && <NoSearchResultsFound />}

          {groups?.length && (
            <Stack>
              {groups?.map((g) => {
                return (
                  <AssessmentsCreateModalGroupsItem
                    key={g.id}
                    onClick={() => onCardClick(g)}
                    group={g}
                    generateHref={(id) => `/groups/${id}`}
                  />
                );
              })}
            </Stack>
          )}

          {isLoading && <SkeletonRowsComponent rowCount={PAGE_SIZE} />}

          {hasNextPage && (
            <>
              <div ref={loaderRef} />
              {isFetchingNextPage && (
                <SkeletonRowsComponent
                  rowCount={Math.min(
                    PAGE_SIZE,
                    total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                  )}
                />
              )}
            </>
          )}
        </>
      );
    };

    return (
      <Stack sx={{ height: '100%' }}>
        <ModalHeader title={formatMessage({ id: 'assessments-Groups' })} active />
        <Box p={2.5}>
          <Stack gap={1}>
            <Stack justifyContent="center" position="relative">
              <SearchInput
                placeholder={formatMessage({ id: 'people-Search' })}
                onChangeText={handleSetFiltersQuery}
                value={params.query || ''}
                canClear={false}
                InputProps={{
                  endAdornment: (
                    <Stack pl={0.75} gap={0.5} pr={1} flexDirection="row">
                      {!!params.query && (
                        <IconButton
                          sx={(theme) => ({ '&:hover': { color: theme.palette.text.primary } })}
                          inverse
                          onClick={() => handleSetFiltersQuery('')}
                        >
                          <CrossIcon />
                        </IconButton>
                      )}
                      <FilterButton onClick={openFilterModal} hasFiltersApplied={hasFiltersApplied}>
                        <FilterIcon />
                      </FilterButton>
                    </Stack>
                  ),
                }}
              />
            </Stack>
            <InlineFiltersTags
              onResetFilters={handleResetFilters}
              onOpenFilters={openFilterModal}
              filters={params.filters}
            />
          </Stack>
        </Box>
        <ModalPanel sx={{ pt: 0, flex: '1 1 auto', overflowY: 'auto' }} active>
          {renderList()}
        </ModalPanel>
        {filtersModalVisible && (
          <AssessmentGroupFiltersPopup
            onClose={closeFiltersModal}
            defaultFilters={initialFiltersState}
            onSetFilters={handleSetFilters}
            filters={params.filters}
          />
        )}
      </Stack>
    );
  };
