import { useQuery } from '@tanstack/react-query';

import { AnnualPlan, GetAnnualPlanRequest } from './apiTypes/endpoints/annualPlan';
import { ApiError } from './apiTypes/misc';
import { RQUseQueryOptions } from './apiTypes/query';
import { UserFilter } from './apiTypes/users';
import * as api from './requests';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const ANNUAL_PLANNER_URL = '/plan/';

const getAnnualPlanBaseQueryParams = (filters: Partial<UserFilter>) => {
  return removeObjectUndefinedNullValues({
    intersect_date_from: filters?.intersect_date?.[0],
    intersect_date_to: filters?.intersect_date?.[1],
    school_days_from: filters?.school_days_date?.[0],
    school_days_to: filters?.school_days_date?.[1],
  });
};

export const getAnnualPlan = ({ schoolId, filters }: GetAnnualPlanRequest): Promise<AnnualPlan> => {
  const { school_year, ...restFilters } = filters;
  const baseQueryParams = restFilters ? getAnnualPlanBaseQueryParams(restFilters) : {};

  return api.get(`/school_details/${schoolId}${ANNUAL_PLANNER_URL}${school_year}`, {
    params: baseQueryParams,
  });
};

export const GET_ANNUAL_PLAN_QUERY = `${ANNUAL_PLANNER_URL}/GET_ANNUAL_PLAN_QUERY`;

export const useGetAnnualPlanQuery = (
  params: GetAnnualPlanRequest,
  options?: RQUseQueryOptions<AnnualPlan>,
) => {
  const filters = removeObjectEmptyArrayValues(params.filters);

  const query = useQuery<AnnualPlan, ApiError>(
    [GET_ANNUAL_PLAN_QUERY, { ...params, filters }],
    () => getAnnualPlan({ ...params, filters }),
    {
      ...options,
    },
  );

  return query;
};
