import {
  Card,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { InformationIcon, Spin } from '@schooly/style';
import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useNotificationSettings } from '../../../../hooks/useNotificationSettings';
import { SchoolNotificationsEmail } from './SchoolNotificationsEmail';

export type SchoolNotificationsInternalEmailType = 'admissionsEmail' | 'invoicingEmail';

export const SchoolNotificationsInternal: FC = () => {
  const { $t } = useIntl();
  const {
    loadingUpdateNotificationSettings,
    loadingNotificationSettings,
    updateInternalEmailsNotifications,
    notificationSettings,
  } = useNotificationSettings();

  const onSubmit = useCallback(
    async (email: string, type: SchoolNotificationsInternalEmailType) => {
      return await updateInternalEmailsNotifications({
        admissions_email: type === 'admissionsEmail' ? email : undefined,
        invoicing_email: type === 'invoicingEmail' ? email : undefined,
      });
    },
    [updateInternalEmailsNotifications],
  );

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <FormattedMessage id="school-sections-Notifications-Internal" />
              <Tooltip title={$t({ id: 'school-sections-Notifications-Internal-Hint' })}>
                <IconButton inverse>
                  <InformationIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          }
          sx={{
            cursor: 'pointer',
          }}
        />
        {loadingNotificationSettings ? (
          <Stack justifyContent="center" alignItems="center" width="100%">
            <Icon fontSize="medium">
              <Spin />
            </Icon>
          </Stack>
        ) : (
          <>
            <Grid container spacing={3} p={2.5} alignItems="baseline">
              <Grid item xs={3}>
                <Stack height="100%" justifyContent="center">
                  <Typography variant="h3">
                    <FormattedMessage id="school-sections-Notifications-Internal-AdmissionsEmail" />
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={9}>
                <SchoolNotificationsEmail
                  email={notificationSettings?.internal_emails?.admissions_email}
                  key="admissionsEmail"
                  loading={loadingUpdateNotificationSettings}
                  onSubmit={(email) => onSubmit(email, 'admissionsEmail')}
                />
              </Grid>

              <Grid item xs={3}>
                <Stack height="100%" justifyContent="center">
                  <Typography variant="h3">
                    <FormattedMessage id="school-sections-Notifications-Internal-InvoicingEmail" />
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={9}>
                <SchoolNotificationsEmail
                  email={notificationSettings?.internal_emails?.invoicing_email}
                  key="invoicingEmail"
                  loading={loadingUpdateNotificationSettings}
                  onSubmit={(email) => onSubmit(email, 'invoicingEmail')}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
    </Card>
  );
};
