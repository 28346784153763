import { Box } from '@mui/material';
import { ChartBarData, ChartBarSeries } from '@schooly/api';
import { theme } from '@schooly/style';
import type {
  ECElementEvent,
  EChartsOption,
  TooltipComponentFormatterCallbackParams,
} from 'echarts';
import ReactECharts from 'echarts-for-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { getHSBColors, HSBColor, HSBToRGB } from './utils';

const BAR_TYPE = 'bar';

interface ItemStyle {
  color?: string;
  opacity?: number;
  decal?: {
    color?: string;
    backgroundColor?: string;
    dashArrayX?: number[];
    dashArrayY?: number[];
    rotation?: number;
  };
  borderColor?: string;
  borderWidth?: number;
}
interface BarData {
  value: number;
  itemStyle?: ItemStyle;
}

interface DataItem {
  name?: string;
  itemStyle?: ItemStyle;
}

export interface BarClickData {
  dataValue: number | string | null;
  dataKey: ChartBarSeries['dataKey'];
  name: ChartBarSeries['name'];
}
export interface BarSelectProps {
  data?: Omit<BarClickData, 'name'>[];
  bar: ECElementEvent;
}

export interface ChartBarProps {
  barPosition?: 'vertical' | 'horizontal';
  chartData: ChartBarData;
  renderTooltip?: (params: TooltipComponentFormatterCallbackParams) => string;
  onBarSelect?: ({ data, bar }: BarSelectProps) => void;
  emptyBarOptions?: EChartsOption;
  getDataName: (data: BarClickData) => string;
  selectedBar?: ECElementEvent;
  legendData?: Array<string | DataItem>;
  showZeroValues?: boolean;
  minHeight?: number;
  withoutBorder?: boolean;
  gridBottom?: number;
  customColorPalette?: Array<HSBColor>;
  customAxisValueFormatter?: (value: number) => string | number;
  customSeriesLabelFormatter?: (params: any) => string;
}

export const ChartBar: FC<ChartBarProps> = ({
  barPosition,
  chartData,
  renderTooltip,
  onBarSelect,
  getDataName,
  selectedBar,
  legendData,
  minHeight = 460,
  withoutBorder,
  gridBottom,
  customColorPalette,
  customAxisValueFormatter,
  customSeriesLabelFormatter,
}) => {
  const echartRef = useRef<echarts.ECharts | null>(null);
  const [width, setWidth] = useState(0);

  const { ref } = useResizeDetector<HTMLDivElement>({
    onResize: () => {
      setWidth(ref.current?.clientWidth ?? 0);
    },
  });

  const columnCount = chartData.series.length;
  const colorPalette = getHSBColors(columnCount);

  const getLabelTransform = useCallback(
    (width?: number) => {
      if (barPosition === 'vertical') {
        return 90;
      }
      if (chartData.arrange_by.data.length >= 30 || (width && width < 450)) {
        return 90;
      } else if (chartData.arrange_by.data.length >= 20) {
        return 35;
      }

      return 0;
    },
    [barPosition, chartData.arrange_by.data.length],
  );

  const getAxisData = useCallback(
    (width?: number, extra?: { inverse?: boolean }) => ({
      type: 'category' as const,
      data: chartData.arrange_by.data.map(({ dataValue, name }) =>
        getDataName({ dataKey: chartData.arrange_by.dataKey, dataValue, name }),
      ),
      triggerEvent: true,
      axisLabel: {
        interval: 0,
        rotate: getLabelTransform(width),
        width: chartData.arrange_by.data.length > 10 ? 70 : 90,
        overflow: 'break' as const,
      },

      axisTick: {
        inside: false,
        alignWithLabel: true,
      },
      splitLine: {
        show: false,
      },
      splitArea: {
        show: false,
      },
      verticalAlign: 'bottom',
      inverse: extra?.inverse || false,
    }),
    [chartData.arrange_by.data, chartData.arrange_by.dataKey, getLabelTransform, getDataName],
  );

  // TODO create container for legend https://github.com/apache/echarts/issues/4871
  const gridBottomSpace = useMemo(() => {
    if (chartData.series.length < 15 || !width) {
      return 50;
    }
    if (width <= 500) {
      return 200;
    }
    if (width <= 850) {
      return 130;
    }

    return 100;
  }, [chartData.series.length, width]);

  useEffect(() => {
    if (width) {
      echartRef.current?.resize();
    }
  }, [gridBottomSpace, width]);

  const showZoom = useMemo(() => {
    const totalBarCount = chartData.series.reduce<number[]>((acc, next) => {
      return acc.length
        ? next.data.map((v, i) => {
            const count = acc[i];
            return count ? count + v : v;
          })
        : next.data;
    }, []);

    // Getting 5 percents value from max bar count
    const percentsOfMaxCount = (Math.max(...totalBarCount) / 100) * 5;
    const min = Math.min(...totalBarCount);

    return percentsOfMaxCount > min;
  }, [chartData.series]);

  const echartsWidth = useMemo(() => {
    if (!showZoom) {
      return barPosition === 'vertical' ? width - 12 : width;
    }

    return width - 20;
  }, [barPosition, showZoom, width]);

  const gridOptions = useMemo(
    () => ({
      show: true,
      containLabel: true,
      borderWidth: withoutBorder ? 0 : undefined,
      width: echartsWidth,
      left: barPosition === 'vertical' ? 1 : 0,
      top: 7,
      bottom: gridBottom ?? gridBottomSpace,
      borderColor: theme.palette.common.light3,
    }),
    [barPosition, echartsWidth, gridBottom, gridBottomSpace, withoutBorder],
  );

  const axisValue = useMemo(
    () => ({
      type: 'value' as const,
      axisLabel: {
        formatter: customAxisValueFormatter
          ? customAxisValueFormatter
          : (a: number): any => (a >= 1 ? Math.ceil(a) : 0),
      },
    }),
    [],
  );

  const options: EChartsOption = useMemo(
    () => ({
      grid: gridOptions,
      ...(showZoom &&
        barPosition === 'horizontal' && {
          dataZoom: [
            {
              type: 'slider',
              show: true,
              yAxisIndex: [0],
              width: 14,
              showDetail: false,
              showDataShadow: false,
              brushSelect: false,
              borderColor: theme.palette.common.light3,
              fillerColor: theme.palette.common.light2,
              borderRadius: 0,
            },
          ],
        }),
      yAxis: barPosition === 'vertical' ? getAxisData(undefined, { inverse: true }) : axisValue,
      xAxis: barPosition === 'horizontal' ? getAxisData() : axisValue,
      tooltip: [
        {
          trigger: 'item',
          enterable: true,
          showContent: true,
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
          textStyle: {
            ...(theme.typography.caption as any),
            color: theme.palette.primary.main,
          },
          padding: [2, 4, 2, 4],
          borderWidth: 0,
          formatter: renderTooltip,
        },
      ],
      legend: {
        orient: 'horizontal',
        top: 'bottom',
        icon: 'circle',
        data: legendData,
        textStyle: {
          color: theme.palette.common.grey2,
          ...(theme.typography.body1 as any),
          // Accept only number
          lineHeight: 18,
        },
      },
      series: chartData.series.map(({ data, dataKey, dataValue, name, itemStyle, label }, i) => {
        const hsb =
          customColorPalette?.length === chartData.series.length
            ? customColorPalette[i]
            : colorPalette[i];
        const color = hsb ? HSBToRGB(hsb) : undefined;
        const categoryName = getDataName({ dataKey, dataValue, name });

        return {
          color,
          data: data.map((d, ii) => {
            const total = chartData.series.reduce((prev, curr) => prev + (curr.data[ii] ?? 0), 0);

            // https://schooly.atlassian.net/browse/TR-4853
            // Should display label using the following criteria
            //  - value > 0 - display inside a bar
            //  - value === 0:
            //    - One series item (no break down enabled) - display 0 on top of a bar
            //    - Several series (break down enabled) - display a single 0 on top of a bar
            const value = total ? d || undefined : i === 0 ? 0 : undefined;

            return {
              value,
              itemStyle: itemStyle ? itemStyle : { color, opacity: 1 },
              label:
                total === 0
                  ? {
                      position: barPosition === 'vertical' ? 'right' : 'top',
                      color: theme.palette.primary.main,
                    }
                  : undefined,
            };
          }),
          hsb,
          type: BAR_TYPE,
          stack: 'total',
          name: categoryName,
          groupPadding: 0,
          label: {
            show: true,
            position: 'inside',
            color:
              (hsb as HSBColor).h > 20 && (hsb as HSBColor).h < 100
                ? theme.palette.primary.main
                : theme.palette.background.paper,
            ...(label && label),
            formatter: customSeriesLabelFormatter,
          },
        };
      }),
    }),
    [
      axisValue,
      barPosition,
      colorPalette,
      getAxisData,
      getDataName,
      gridOptions,
      legendData,
      chartData.series,
      renderTooltip,
      showZoom,
    ],
  );

  useEffect(() => {
    if (!width || !echartRef) {
      return;
    }
    if (barPosition === 'horizontal') {
      echartRef.current?.setOption({
        xAxis: getAxisData(width),
        grid: gridOptions,
      });
    } else {
      echartRef.current?.setOption({
        grid: gridOptions,
      });
    }
  }, [barPosition, getAxisData, gridBottomSpace, gridOptions, width]);

  const isolateOtherBars = useCallback(
    (
      getCondition: ({
        seriesIndex,
        dataIndex,
        seriesName,
      }: {
        seriesIndex: number;
        dataIndex: number;
        seriesName?: string;
      }) => boolean,
    ) => {
      const options = echartRef.current?.getOption();
      const series = options?.series as EChartsOption['series'];
      if (!Array.isArray(series)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const newSeries = series.map(({ emphasis, ...s }, seriesIndex) => {
        const data = s.data as BarData[];

        return {
          ...s,
          data: data.map((d, dataIndex) => {
            const condition = getCondition({
              seriesIndex,
              dataIndex,
              seriesName: s.name as string,
            });

            return {
              ...d,
              itemStyle: {
                ...d.itemStyle,
                opacity: condition ? 1 : 0.3,
              },
            };
          }),
        };
      });

      echartRef.current?.setOption({
        series: newSeries,
      });
    },
    [],
  );

  const clearIsolation = useCallback(() => {
    const { series } = options;

    if (!Array.isArray(series)) {
      return;
    }

    echartRef.current?.setOption({ series });
  }, [options]);

  useEffect(() => {
    if (selectedBar) {
      isolateOtherBars(
        ({ dataIndex, seriesIndex }) =>
          selectedBar.dataIndex === dataIndex && selectedBar.seriesIndex === seriesIndex,
      );
    }
  }, [isolateOtherBars, selectedBar]);

  const onClick = useCallback(
    (e: ECElementEvent) => {
      if (
        e.componentType === 'axis' ||
        typeof e.seriesIndex !== 'number' ||
        typeof e.dataIndex !== 'number'
      ) {
        return;
      }
      const currentSeries = chartData.series[e.seriesIndex];

      if (!currentSeries) {
        return;
      }

      const series = {
        dataValue: currentSeries.dataValue,
        dataKey: currentSeries.dataKey,
      };

      const arrangeByData = chartData.arrange_by.data[e.dataIndex];

      const axis = {
        dataValue: arrangeByData?.dataValue ?? null,
        dataKey: chartData.arrange_by.dataKey,
      };

      onBarSelect?.({ data: [series, axis], bar: e });
    },
    [chartData.series, chartData.arrange_by.data, chartData.arrange_by.dataKey, onBarSelect],
  );

  const onMouseOver = useCallback(
    (e: ECElementEvent) => {
      if (e.targetType === 'axisLabel') {
        isolateOtherBars(({ dataIndex }) => e.dataIndex === dataIndex);
        return;
      }

      isolateOtherBars(
        ({ dataIndex, seriesIndex }) => e.dataIndex === dataIndex && e.seriesIndex === seriesIndex,
      );
    },
    [isolateOtherBars],
  );

  const onMouseOut = useCallback(() => {
    clearIsolation();

    if (selectedBar) {
      isolateOtherBars(
        ({ dataIndex, seriesIndex }) =>
          selectedBar.dataIndex === dataIndex && selectedBar.seriesIndex === seriesIndex,
      );
    }
  }, [clearIsolation, isolateOtherBars, selectedBar]);

  const onHighlight = useCallback(
    (name: string) => {
      isolateOtherBars(({ seriesName }) => seriesName === name);
    },
    [isolateOtherBars],
  );

  const onDownplay = useCallback(() => {
    clearIsolation();

    if (selectedBar) {
      isolateOtherBars(
        ({ dataIndex, seriesIndex }) =>
          selectedBar.dataIndex === dataIndex && selectedBar.seriesIndex === seriesIndex,
      );
    }
  }, [clearIsolation, isolateOtherBars, selectedBar]);

  const onLegendSelectChange = useCallback(
    (params: { name: string; selected: Record<string, boolean> }) => {
      // If user unselect last legend
      const isLastLegendUnSelected = Object.values(params.selected).every((v) => !v);

      if (isLastLegendUnSelected) {
        echartRef.current?.setOption({ animation: false });

        // Re-select what the user unselected
        echartRef.current?.dispatchAction({
          type: 'legendSelect',
          name: params.name,
        });

        echartRef.current?.setOption({ animation: true });
      }
    },
    [],
  );

  useEffect(() => {
    if (echartRef.current) {
      echartRef.current.on('mousemove', onMouseOver);
      echartRef.current.on('click', onClick);
      echartRef.current.on('mouseout', onMouseOut);
      // In type definition params: unknown
      echartRef.current.on('highlight', (params: any) => {
        onHighlight(params.seriesName);
      });

      echartRef.current.on('downplay', onDownplay);
      // In type definition params: unknown
      echartRef.current.on('legendselectchanged', (params: any) => {
        onLegendSelectChange(params);
      });

      return () => {
        echartRef.current?.off('mousemove', onMouseOver);
        echartRef.current?.off('click', onClick);
        echartRef.current?.off('mouseout', onMouseOut);
        echartRef.current?.off('highlight', (params: any) => {
          onHighlight(params);
        });
        echartRef.current?.off('downplay', onDownplay);
        echartRef.current?.off('legendselectchanged', (params: any) => {
          onLegendSelectChange(params);
        });
      };
    }
  }, [
    clearIsolation,
    isolateOtherBars,
    onClick,
    onDownplay,
    onHighlight,
    onLegendSelectChange,
    onMouseOut,
    onMouseOver,
    selectedBar,
  ]);

  const onChartReady = (ref: echarts.ECharts) => {
    echartRef.current = ref;
  };

  return (
    <Box position="relative" ref={ref}>
      <ReactECharts
        option={options}
        style={{
          minHeight,
          width,
        }}
        notMerge={true}
        onChartReady={onChartReady}
      />
    </Box>
  );
};
