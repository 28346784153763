import { ChipProps, Icon, Stack, Tooltip } from '@mui/material';
import { IconProps } from '@mui/material/Icon/Icon';
import { LeavingReasonType, SchoolProperty } from '@schooly/api';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ArchiveIcon, StaffIcon } from '../../assets/assets';
import { TagSelect } from './TagSelect';

export interface TagSelectPropertyProps extends Omit<ChipProps, 'property' | 'defaultValue'> {
  userRole?: SchoolUserRole;
  property?: {
    id?: SchoolProperty['id'];
    name?: SchoolProperty['name'];
    type?: SchoolProperty['type'] | LeavingReasonType;
    archived?: SchoolProperty['archived'];
  };
  defaultValue?: React.ReactNode;
  testId?: string;
  outlined?: boolean;
  tooltip?: ReactNode;
}

const smallIconProps: IconProps = {
  className: 'MuiChip-avatar MuiChip-avatarSmall',
  sx: {
    '&&&': { m: 0 },
    '& .svg-icon': {
      width: 'var(--icon-size, 1em)',
      height: 'var(--icon-size, 1em)',
    },
  },
};

export const TagSelectProperty: FC<TagSelectPropertyProps> = ({
  userRole,
  property,
  defaultValue,
  testId,
  tooltip,
  ...props
}) => {
  const { $t } = useIntl();

  const showStaffIcon =
    userRole === SchoolUserRole.Staff && property?.type !== SchoolPropertyType.Department;

  const hasTooltip = Boolean(property?.archived ?? tooltip);

  const labelText = property ? property.name : defaultValue;

  const avatar = useMemo(() => {
    if (property?.archived) {
      return (
        <Icon
          sx={{
            '&& .svg-icon': {
              width: 'var(--icon-size, 1em)',
              height: 'var(--icon-size, 1em)',
            },
          }}
        >
          <ArchiveIcon />
        </Icon>
      );
    }
    if (showStaffIcon) {
      return (
        <Icon>
          <StaffIcon />
        </Icon>
      );
    }
  }, [property?.archived, showStaffIcon]);

  const tag = (
    <TagSelect
      label={
        property?.archived ? (
          <Stack direction="row" alignItems="center" gap={0.5}>
            {showStaffIcon && (
              <Icon {...(props.size === 'small' ? smallIconProps : {})}>
                <StaffIcon />
              </Icon>
            )}
            {labelText}
          </Stack>
        ) : (
          labelText
        )
      }
      data-test-id={testId}
      avatar={avatar}
      {...props}
    />
  );

  return hasTooltip ? (
    <Tooltip
      title={property?.archived ? $t({ id: `schoolProperty-Archived-${property?.type}` }) : tooltip}
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 200,
          },
        },
      }}
      data-test-id={testId}
    >
      {tag}
    </Tooltip>
  ) : (
    tag
  );
};
