import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import {
  Application,
  ApplicationsByChildrenIds,
  ApplicationsByChildrenIdsRequest,
  ApplicationShort,
  ApplicationStatus,
} from './apiTypes/applications';
import {
  ApplicationConvertRequest,
  ApplicationCreateRequest,
  ApplicationCreateResponse,
  ApplicationRejectRequest,
  ApplicationUpdateNotesRequest,
  ApplicationUpdateRequest,
  ParentApplicationCreateRequest,
  ParentApplicationCreateResponse,
} from './apiTypes/endpoints/applications';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { School } from './apiTypes/schools';
import { UserFilter } from './apiTypes/users';
import * as api from './requests';

const APPLICATIONS_URL = '/application';

type GetApplicationsRequest = {
  school_id: School['id'];
  status: ApplicationStatus;
  filters?: Partial<UserFilter>;
  page_number?: number;
  page_size?: number;
  query?: string;
};

export const GET_APPLICATIONS_QUERY = `${APPLICATIONS_URL}/GET_APPLICATIONS_QUERY`;

export function getApplications({
  school_id,
  status,
  filters,
  page_number,
  page_size,
  query,
}: GetApplicationsRequest): Promise<PagedResponse<ApplicationShort>> {
  const params = removeObjectUndefinedOrNullValues({
    page_number,
    page_size,
    search_query: query || undefined,
    filters,
  });

  return api.get(`${APPLICATIONS_URL}/list?school_id=${school_id}&status=${status}`, {
    params,
  });
}

export const useGetApplicationsQuery = (
  initialParams: Omit<GetApplicationsRequest, 'filters' | 'sort'>,
  options?: RQUseInfiniteQueryOptions<PagedResponse<ApplicationShort>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ApplicationShort>, ApiError>(
    [GET_APPLICATIONS_QUERY, { ...params }],
    ({ pageParam }) =>
      getApplications({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function getApplication(id: Application['id']): Promise<Application> {
  return api.get(`${APPLICATIONS_URL}/${id}`);
}

export function getApplicationsByStudentIds({
  children_ids,
  school_user_relation_id,
}: ApplicationsByChildrenIdsRequest): Promise<ApplicationsByChildrenIds[]> {
  return api.get(`${APPLICATIONS_URL}/for-children`, {
    params: {
      children_ids,
      school_user_relation_id,
    },
  });
}

export const GET_APPLICATIONS_BY_STUDENT_IDS_QUERY = `${APPLICATIONS_URL}/GET_APPLICATIONS_BY_STUDENT_IDS_QUERY`;

export const useGetApplicationsByStudentIdsQuery = (
  data: ApplicationsByChildrenIdsRequest,
  options?: RQUseQueryOptions<ApplicationsByChildrenIds[]>,
) => {
  return useQuery<ApplicationsByChildrenIds[], ApiError>(
    [GET_APPLICATIONS_BY_STUDENT_IDS_QUERY, data],
    () => getApplicationsByStudentIds(data),
    options,
  );
};

export function getApplicationStatuses(): Promise<Array<ApplicationStatus>> {
  return api.get(`${APPLICATIONS_URL}/statuses`);
}

export function createApplication({
  schoolId,
  ...params
}: ApplicationCreateRequest): Promise<ApplicationCreateResponse> {
  return api.post(`${APPLICATIONS_URL}/create/${schoolId}`, params);
}

export function createParentApplication({
  schoolId,
  ...params
}: ParentApplicationCreateRequest): Promise<ParentApplicationCreateResponse> {
  return api.post(`${APPLICATIONS_URL}/create/${schoolId}/public`, params);
}

export const CREATE_PARENT_APPLICATION_MUTATION = `${APPLICATIONS_URL}/CREATE_PARENT_APPLICATION_MUTATION`;

export const useCreateParentApplicationMutation = (
  options?: RQUseMutationOptions<ParentApplicationCreateResponse, ParentApplicationCreateRequest>,
): RQUseMutationResult<ParentApplicationCreateResponse, ParentApplicationCreateRequest> => {
  return useMutation([CREATE_PARENT_APPLICATION_MUTATION], createParentApplication, {
    ...options,
  });
};

export function updateApplication({
  id,
  ...params
}: ApplicationUpdateRequest): Promise<Omit<ApplicationCreateResponse, 'application_id'>> {
  return api.patch(`${APPLICATIONS_URL}/${id}`, params);
}

export function updateApplicationNotes({ id, ...params }: ApplicationUpdateNotesRequest): Promise<{
  success: 'string';
}> {
  return api.patch(`${APPLICATIONS_URL}/${id}/notes`, params);
}

export function convertApplication({
  id,
  ...params
}: ApplicationConvertRequest): Promise<{ status: 'success' }> {
  return api.patch(`${APPLICATIONS_URL}/${id}/convert`, params);
}

export const useConvertApplication = (
  options?: RQUseMutationOptions<{ status: 'success' }, ApplicationConvertRequest>,
): RQUseMutationResult<{ status: 'success' }, ApplicationConvertRequest> => {
  return useMutation(convertApplication, options);
};

export function rejectApplication({
  id,
  ...data
}: ApplicationRejectRequest): Promise<{ status: 'success' }> {
  return api.patch(`${APPLICATIONS_URL}/${id}/reject`, data);
}
