import { CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { getAxiosInstance } from '@schooly/api';
import { useUserContext, WithAuth, WithUser } from '@schooly/components/authentication';
import { ConfirmationDialogProvider } from '@schooly/components/confirmation-dialog';
import { WithDnd } from '@schooly/components/drag-and-drop';
import { WithNotifications } from '@schooly/components/notifications';
import { Loading, theme } from '@schooly/style';
import { FC, useCallback, useEffect, useMemo } from 'react';

import CookieNotification from './components/common/CookieNotification';
import { FormbricksSurvey } from './components/common/FormbricksSurvey';
import MaintenanceModal from './components/common/MaintenanceModal';
import { MUI_PRO_LICENSE_KEY } from './config';
import { WithCollapsiblePanel } from './context/collapsiblePanel/WithCollapsiblePanel';
import { LastRefreshTimeContextProvider } from './context/LastRefreshTimeContext';
import useMaintenance from './context/maintenance/useMaintenance';
import { WithRouter } from './context/router/WithRouter';
import { WithRouterState } from './context/router/WithRouterState';
import { WithSchool } from './context/SchoolContext';
import useAppLocation from './hooks/useAppLocation';
import usePrevious from './hooks/usePrevious';
import { DemoSchoolCtaBanner } from './pages/DemoSchool/DemoSchoolCtaBanner';
import MaintenancePage from './pages/Maintenance';
import InboxModal from './pages/Messages/InboxModal';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { actions as messagesActions } from './redux/slices/messagesSlice';
import { AppRoutes } from './routes/AppRoutes';
import setupPendingMiddleware from './utils/pendingMiddleware';

function App() {
  const dispatch = useAppDispatch();
  const { pathname, state: locationState } = useAppLocation();

  useMemo(() => {
    setupPendingMiddleware(getAxiosInstance(), dispatch);
  }, [dispatch]);

  const prevLocationState = usePrevious(locationState);
  const { init: maintenanceInit, enabled: maintenanceEnabled } = useMaintenance();

  const init = maintenanceInit && !maintenanceEnabled;

  // Reset scroll on every page transition
  useEffect(() => {
    if (!init) {
      return;
    }

    // Do not involve this logic if we open/close modals with URLs
    if (locationState?.backgroundLocation || prevLocationState?.backgroundLocation) {
      return;
    }

    window.scrollTo(0, 0);
  }, [init, pathname, locationState]); // eslint-disable-line react-hooks/exhaustive-deps

  // display loader while maintenance is checking
  if (!maintenanceInit && !maintenanceEnabled) {
    return <Loading />;
  }

  const content = <AppContent maintenanceEnabled={maintenanceEnabled} />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <WithCollapsiblePanel>
        <WithDnd>
          <WithNotifications>
            <ConfirmationDialogProvider>
              <LastRefreshTimeContextProvider>
                {maintenanceEnabled ? content : <WithUser>{content}</WithUser>}
              </LastRefreshTimeContextProvider>
            </ConfirmationDialogProvider>
          </WithNotifications>
        </WithDnd>
      </WithCollapsiblePanel>
    </ThemeProvider>
  );
}

const AppContent: FC<{ maintenanceEnabled?: boolean }> = ({ maintenanceEnabled }) => {
  const dispatch = useAppDispatch();
  const { user } = useUserContext();
  const {
    messages: { isInboxModalOpen },
  } = useAppSelector((state) => state);

  useEffect(() => {
    LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY);
  }, []);

  const handleInboxModalClose = useCallback(() => {
    dispatch(messagesActions.toggleInboxModal(false));
  }, [dispatch]);

  if (user === undefined && !maintenanceEnabled) {
    return <Loading />;
  }

  return (
    <WithAuth>
      <WithSchool>
        <WithRouterState>
          <WithRouter>
            {maintenanceEnabled ? <MaintenancePage /> : <AppRoutes />}
            {!maintenanceEnabled && <MaintenanceModal />}
            <InboxModal isOpen={isInboxModalOpen} onClose={handleInboxModalClose} />
            <CookieNotification />
            <DemoSchoolCtaBanner />
            <FormbricksSurvey />
          </WithRouter>
        </WithRouterState>
      </WithSchool>
    </WithAuth>
  );
};

export default App;
