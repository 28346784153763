import { Icon, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ControlTextField, FormTextField } from '@schooly/components/form-text-field';
import { DeleteIcon, LockIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FieldPath, FieldPathValue } from 'react-hook-form-lts/dist/types/path';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { PeriodRow, PeriodRowProps } from './PeriodRow';
import { SchoolPeriodForm, SchoolPeriodGroupsForm } from './SchoolPeriodUpdateContent';
import { usePeriodValidation } from './usePeriodValidation';

export interface PeriodGroupProps {
  groupIdx: number;
  schoolYear: PeriodRowProps['schoolYear'];
  autoFocus?: boolean;
}

const getEmptyPeriod = (name: string) => ({
  date_from: '',
  date_to: '',
  id: uuidv4(),
  name,
});

export const PeriodGroup: FC<PeriodGroupProps> = ({ groupIdx, schoolYear, autoFocus }) => {
  const { formatMessage } = useIntl();
  const { control, watch, clearErrors, formState, trigger, setError } =
    useFormContext<SchoolPeriodGroupsForm>();
  const path = useMemo(() => `period_groups.${groupIdx}` as const, [groupIdx]);
  const currentGroup = watch(path);
  const periodsArr = watch(`${path}.periods`);
  const nameError = formState.errors.period_groups?.[groupIdx]?.name;
  const { update, remove } = useFieldArray({
    control: control,
    name: 'period_groups',
  });
  const isMainGroup = currentGroup.main;

  const { validatePeriodsOverlap } = usePeriodValidation();

  const handleUpdateNames = useCallback(
    (idx?: number) => {
      const periods = periodsArr.reduce<SchoolPeriodForm[]>(
        (acc, p, i) =>
          idx === i ? acc : [...acc, { ...p, name: `${currentGroup.name} ${acc.length + 1}` }],
        [],
      );

      update(groupIdx, { ...currentGroup, periods });

      if (!periods.length) {
        clearErrors(`${path}.name`);
      }
    },
    [clearErrors, currentGroup, groupIdx, path, periodsArr, update],
  );
  const handlePeriodsSort = useCallback(
    (periods?: SchoolPeriodForm[]) => {
      const currentPeriods = periods ? periods : [...periodsArr];
      const sortedPeriods = currentPeriods
        // TODO: why do we need this sort? The same happens in the next sort as well
        .sort((a) => (a.date_from && a.date_to ? -1 : 1))
        .sort((a, b) =>
          // TODO: why not to use date-fns built-ins compareAsc/compareDesc
          a.date_to && b.date_from
            ? new Date(a.date_to).getTime() - new Date(b.date_from).getTime()
            : 1,
        )
        .map((p, i) => ({ ...p, name: `${currentGroup.name} ${i + 1}` }));

      update(groupIdx, {
        ...currentGroup,
        periods: sortedPeriods,
      });

      return sortedPeriods;
    },
    [currentGroup, groupIdx, periodsArr, update],
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name === `${path}.name`) {
        handleUpdateNames();
        trigger(`period_groups`);
      }
    });

    return () => subscription.unsubscribe();
  }, [handleUpdateNames, path, trigger, watch]);

  const handleCreatePeriod = useCallback(() => {
    const nextPeriodCount = currentGroup.periods.length + 1;
    update(groupIdx, {
      ...currentGroup,
      periods: [...currentGroup.periods, getEmptyPeriod(`${currentGroup.name} ${nextPeriodCount}`)],
    });
  }, [currentGroup, groupIdx, update]);

  const handleDeleteGroup = useCallback(() => {
    remove(groupIdx);
  }, [groupIdx, remove]);

  const isStarted = useMemo(() => {
    if (!periodsArr) return false;
    return periodsArr.some(({ isStarted }) => Boolean(isStarted));
  }, [periodsArr]);
  const hasFrequencyConnected = Boolean(currentGroup.frequency);
  const canDelete = !isStarted && !hasFrequencyConnected && !isMainGroup;

  const getLockedDeleteTooltip = () => {
    if (hasFrequencyConnected) {
      return formatMessage({ id: 'school-schoolPeriods-EditBlockedByFrequencyConnection' });
    }
    if (isMainGroup) {
      return formatMessage({ id: 'school-schoolPeriods-DeletionBlockedForMainPeriod' });
    }
  };
  const hasLockedDeleteTooltip = Boolean(getLockedDeleteTooltip());

  const handlePeriodDelete = useCallback(
    (idx: number) => {
      const periods = periodsArr.filter((_, index) => index !== idx);
      const updatedPeriods = handlePeriodsSort(periods);

      if (!canDelete) return;

      if (!schoolYear) return;

      clearErrors(`period_groups.${groupIdx}.periods`);

      for (const [index, { date_from, date_to, id }] of updatedPeriods.entries()) {
        if (!date_from || !date_to) continue;

        const isPeriodOverlaps = validatePeriodsOverlap(
          date_from,
          date_to,
          updatedPeriods.filter((p) => p.id !== id && !!p.date_from && !!p.date_to),
        );

        if (!isPeriodOverlaps) continue;

        const startDatePath = `period_groups.${groupIdx}.periods.${index}.date_from` as const;
        const endDatePath = `period_groups.${groupIdx}.periods.${index}.date_to` as const;

        [startDatePath, endDatePath].forEach((path) =>
          setError(path, {
            type: 'validate',
            message: formatMessage({ id: 'school-schoolPeriods-ErrorPeriodOverlapsPrevPeriod' }),
          }),
        );
      }
    },
    [
      formatMessage,
      canDelete,
      clearErrors,
      groupIdx,
      handlePeriodsSort,
      periodsArr,
      schoolYear,
      setError,
      validatePeriodsOverlap,
    ],
  );

  const validateGroupName = useCallback(
    (
      value: FieldPathValue<FieldValues, FieldPath<FieldValues>>,
      formValues: SchoolPeriodGroupsForm,
    ) => {
      // TR-5569: By any reason the react-hook-forms doesn't store values of disabled fields in
      // PROD build. As temporary solution, will be checking for undefined. (In the TR-5182 there
      // was just a !!value check below, which is not fully correct).
      // TODO: figure out with disabled fields in PROD build, probably the certain form
      //  implementation is wrong and might be improved

      if (
        value !== undefined &&
        formValues.period_groups[groupIdx].periods.length > 0 &&
        !value.toString().trim()
      ) {
        return formatMessage({ id: 'input-ErrorRequired' });
      }

      const error =
        value !== undefined &&
        formValues.period_groups
          .slice(0, groupIdx)
          .some((g, i) => i !== groupIdx && g.name === value.toString().trim());

      return error ? formatMessage({ id: 'school-schoolPeriods-UniqueGroupName' }) : true;
    },
    [formatMessage, groupIdx],
  );

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: '6px',
        padding: 2,
        '.MuiFormHelperText-root': {
          backgroundColor: theme.palette.background.default,
        },
        '.MuiInputBase-input': {
          color: nameError ? theme.palette.error.main : undefined,
        },
      })}
    >
      <Stack width="100%">
        {isMainGroup && (
          <>
            <Typography variant="h2" mb={1}>
              {formatMessage({ id: 'school-schoolPeriods-MainSchoolPeriods' })}
            </Typography>
            <Typography variant="h3" mb={2}>
              {formatMessage({ id: 'school-schoolPeriods-MainSchoolPeriods-Description' })}
            </Typography>
          </>
        )}

        <Stack direction="row">
          <Stack
            flex="1 1 50%"
            position="relative"
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '8px',
            }}
          >
            {isStarted ? (
              <>
                <FormTextField
                  value={currentGroup.name}
                  label={formatMessage({ id: 'school-schoolPeriods-GroupName' })}
                  fullWidth
                  hideLabel
                  disabled
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) =>
                        !nameError ? `${theme.palette.common.light3} !important` : undefined,
                    },
                  }}
                />
              </>
            ) : (
              <ControlTextField
                name={`period_groups.${groupIdx}.name`}
                control={control}
                label={formatMessage({ id: 'school-schoolPeriods-GroupName' })}
                fullWidth
                hideLabel
                rules={{
                  validate: validateGroupName,
                }}
                autoFocus={autoFocus}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) =>
                      !nameError ? `${theme.palette.common.light3} !important` : undefined,
                  },
                }}
              />
            )}
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                position: 'absolute',
                right: theme.spacing(1.5),
                top: theme.spacing(1.5),
                gap: 1,
              })}
            >
              <Typography
                color={!currentGroup.should_publish_events ? 'common.grey' : 'primary.main'}
              >
                {formatMessage({ id: 'school-schoolPeriods-PeriodGroupPublishAsEvents' })}
              </Typography>
              <Switch
                checked={currentGroup.should_publish_events}
                disabled={isStarted}
                onChange={(e) => {
                  update(groupIdx, { ...currentGroup, should_publish_events: e.target.checked });
                }}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              pl: 1,
              alignSelf: 'flex-start',
              pt: 1.5,
            }}
          >
            {canDelete ? (
              <IconButton inverse onClick={handleDeleteGroup}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <Tooltip
                disableHoverListener={!hasLockedDeleteTooltip}
                title={getLockedDeleteTooltip()}
              >
                <Icon
                  sx={{
                    color: 'common.grey',
                    '&:hover': { color: hasLockedDeleteTooltip ? 'primary.main' : 'common.grey' },
                  }}
                >
                  <LockIcon />
                </Icon>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Stack gap={1} pt={2}>
          {!!periodsArr &&
            periodsArr.map((p, periodIdx) => {
              return (
                <PeriodRow
                  key={p.id}
                  groupIdx={groupIdx}
                  periodIdx={periodIdx}
                  schoolYear={schoolYear}
                  isStarted={!!p.isStarted}
                  onPeriodsSort={handlePeriodsSort}
                  onDelete={handlePeriodDelete}
                  canEdit={!p.isStarted && !hasFrequencyConnected}
                />
              );
            })}
        </Stack>
        <SimpleButton
          disabled={!currentGroup.name || hasFrequencyConnected}
          sx={{
            mt: !!periodsArr?.length ? 2 : 0,
            alignSelf: 'flex-start',
            '&.Mui-disabled': {
              backgroundColor: (theme) => theme.palette.background.default,
            },
          }}
          onClick={handleCreatePeriod}
          startIcon={<PlusIcon />}
        >
          <FormattedMessage id="school-schoolPeriods-AddPeriod" />
        </SimpleButton>
      </Stack>
    </Stack>
  );
};
