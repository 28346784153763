import LinesFillBgPng from './images/lines-fill-bg-orange.png';

export * from './icons';
export { ApplicationLargeSvg } from './images/application-large.svg';
export { ArrowSelectSvg } from './images/arrow-select.svg';
export { BasketSvg } from './images/BasketSvg.svg';
export { BigArrowSvg } from './images/BigArrow.svg';
export { EmptyFolderSvg } from './images/EmptyFolder.svg';
export { EmptyListSvg } from './images/EmptyList.svg';
export { EmptySchoolSvg } from './images/EmptySchool.svg';
export { ErrorSvg } from './images/Error.svg';
export { IncompleteSvg } from './images/Incomplete.svg';
export { MailBoxSvg } from './images/mail-box.svg';
export { RichLinkInner } from './images/RichLinkInner.svg';
export { default as SchoolyLogoSvg } from './images/schooly-logo.svg';
export { SuccessLargeSvg } from './images/success-large.svg';
export const LinesFillBg = LinesFillBgPng;
