import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 3.5C10 3.77614 9.77614 4 9.5 4C9.22386 4 9 3.77614 9 3.5C9 3.22386 9.22386 3 9.5 3C9.77614 3 10 3.22386 10 3.5Z"
      fill="#24275B"
    />
    <path
      d="M6 2.5H12.7562V8.46856C13.1016 8.46856 13.1953 8.46856 13.7562 8.46856V2.5C13.7562 1.94772 13.3084 1.5 12.7562 1.5H6C5.44772 1.5 5 1.94771 5 2.5V16.3864C5 16.9387 5.44771 17.3864 6 17.3864H8.89242L8.89242 16.3864H6L6 2.5Z"
      fill="#24275B"
    />
    <path
      d="M10.258 10.1778C10.3852 10.0669 10.5499 10 10.7297 10H15.1081C15.5111 10 15.8378 10.3358 15.8378 10.75V12.25H15.1081V10.75H12.2323L14.003 11.761C14.2347 11.8933 14.3784 12.1443 14.3784 12.4167V13.25L17.7543 13.25L16.4312 11.8902C16.2887 11.7437 16.2887 11.5063 16.4312 11.3598C16.5737 11.2134 16.8047 11.2134 16.9472 11.3598L18.8931 13.3598C19.0356 13.5063 19.0356 13.7437 18.8931 13.8902L16.9472 15.8902C16.8047 16.0366 16.5737 16.0366 16.4312 15.8902C16.2887 15.7437 16.2887 15.5063 16.4312 15.3598L17.7543 14L14.3784 14V16.25H15.1081V14.75H15.8378V16.25C15.8378 16.6642 15.5111 17 15.1081 17H14.3784V18.25C14.3784 18.5157 14.2416 18.7616 14.0187 18.8964C13.7959 19.0312 13.5203 19.0347 13.2943 18.9056L10.3753 17.239C10.1437 17.1067 10 16.8557 10 16.5833V10.75C10 10.5624 10.067 10.391 10.1777 10.2594C10.2023 10.2303 10.2291 10.2029 10.258 10.1778Z"
      fill="#24275B"
    />
  </svg>
);
