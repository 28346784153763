import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import {
  ATTENDANCE_CODE_HAS_NOTIFICATIONS_OPTIONS,
  ATTENDANCE_CODE_IN_SCHOOl_OPTIONS,
  ATTENDANCE_CODE_IS_PRESENT_OPTIONS,
  AttendanceCodeHasNotifications,
  AttendanceCodeInSchool,
  AttendanceCodeIsPresent,
} from '@schooly/constants';
import { CrossIcon, InformationIcon } from '@schooly/style';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { FormRadioGroup } from '../../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import { ControlModalHeaderInput } from '../../../../components/uikit-components/Modal/ControlModalHeaderInput';
import {
  ModalContent,
  ModalMain,
  ModalSmall,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { AttendanceCodeForm } from '../../../../hooks/useAttendanceCodes';
import { useNotificationSettings } from '../../../../hooks/useNotificationSettings';

export type AddAttendanceCodeModalContentProps = {
  code: Partial<AttendanceCode>;
  isEditing: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  onDelete?: () => void;
  onClose: () => void;
  onSubmit: (v: AttendanceCodeForm) => void;
  validateCode: (code: AttendanceCodeForm['code'], id?: AttendanceCodeForm['id']) => string | true;
  canEditNotification?: boolean;
};

export const AddAttendanceCodeModalContent: FC<AddAttendanceCodeModalContentProps> = ({
  code,
  isDeleting,
  isEditing,
  isLoading,
  onDelete,
  onSubmit,
  onClose,
  validateCode,
  canEditNotification,
}) => {
  const { $t, formatMessage } = useIntl();
  const { attendanceNotificationsEnabled } = useNotificationSettings();

  const form = useForm<AttendanceCodeForm>({
    defaultValues: {
      ...code,
      in_school: code.in_school
        ? AttendanceCodeInSchool.InSchool
        : AttendanceCodeInSchool.OutOfSchool,
      is_present: code.is_present
        ? AttendanceCodeIsPresent.Present
        : AttendanceCodeIsPresent.Absent,
      has_push_notifications: code.has_push_notifications
        ? AttendanceCodeHasNotifications.Yes
        : AttendanceCodeHasNotifications.No,
    },
    mode: 'onChange',
  });

  const codeValue = form.watch('code');
  const nameValue = form.watch('name');

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="name"
                rules={{
                  required: true,
                  onChange: (e) => {
                    if (!codeValue && !nameValue && `${e.target.value}`) {
                      form.setValue('code', `${e.target.value}`.charAt(0));
                      form.trigger('code');
                    }
                  },
                }}
                placeholder={$t({ id: 'attendance-CodeName' })}
                autoFocus
              />
            }
          >
            {(!isLoading || !isDeleting) && (
              <IconButton onClick={onClose}>
                <CrossIcon />
              </IconButton>
            )}
          </ModalHeader>
          <ModalMain>
            <ModalContent active>
              <Stack gap={3} sx={{ height: '100%' }}>
                <Stack gap={1}>
                  <Typography variant="h2">
                    {formatMessage({ id: 'attendance-CountAsPresent' })}
                  </Typography>
                  <Typography variant="h3" color="text.primary" mb={1}>
                    {formatMessage({ id: 'attendance-CountAsPresent-Description' })}
                  </Typography>
                  <FormRadioGroup options={ATTENDANCE_CODE_IS_PRESENT_OPTIONS} name="is_present" />
                </Stack>

                <Stack gap={1}>
                  <Typography variant="h2">
                    {formatMessage({ id: 'attendance-CountAsOnPremises' })}
                  </Typography>
                  <Typography variant="h3" color="text.primary" mb={1}>
                    {formatMessage({ id: 'attendance-CountAsOnPremises-Description' })}
                  </Typography>
                  <FormRadioGroup options={ATTENDANCE_CODE_IN_SCHOOl_OPTIONS} name="in_school" />
                </Stack>

                {canEditNotification && (
                  <Stack gap={2}>
                    <Typography variant="h2">
                      {formatMessage({ id: 'attendance-ShouldNotifyParents' })}
                      {!attendanceNotificationsEnabled && (
                        <Tooltip
                          componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                          title={
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>
                              {formatMessage({ id: 'attendance-NotificationDisabledHint' })}
                            </Box>
                          }
                        >
                          <IconButton inverse sx={{ ml: 0.5, mb: 0.5 }}>
                            <InformationIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>

                    <FormRadioGroup
                      disabled={!attendanceNotificationsEnabled}
                      options={ATTENDANCE_CODE_HAS_NOTIFICATIONS_OPTIONS}
                      name="has_push_notifications"
                    />
                  </Stack>
                )}

                <Stack gap={2}>
                  <Typography variant="h2">
                    {formatMessage({ id: 'attendance-AddCode' })}
                  </Typography>
                  <ControlTextField
                    name="code"
                    control={form.control}
                    label={$t({ id: 'attendance-Code' })}
                    fullWidth
                    canClear
                    rules={{
                      required: true,
                      validate: (v) => validateCode(`${v}`, code.id),
                    }}
                    hideLabel
                    sx={{ width: 100, '& .Mui-error': { textWrap: 'nowrap' } }}
                  />

                  <ControlTextField
                    name="description"
                    control={form.control}
                    label={$t({ id: 'attendance-CodeDescription' })}
                    fullWidth
                    multiline
                    rows={2}
                    canClear
                  />
                </Stack>
              </Stack>
            </ModalContent>
          </ModalMain>
          <ModalFooterWithActions
            isNewItem={!isEditing}
            saving={isLoading}
            showDeleteButton={isEditing}
            deleting={isDeleting}
            disabled={isDeleting || isLoading}
            onDeleteClick={onDelete}
            active
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
