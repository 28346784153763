import { SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { exportAttendanceRegister } from '@schooly/api';
import { AttendanceRegisterForSchool } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { FireIcon, Spin } from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';

import { AttendanceExportButton, AttendanceExportButtonProps } from './AttendanceExport.styled';

export interface AttendanceRegisterExportProps extends AttendanceExportButtonProps {
  register: AttendanceRegisterForSchool;
  sx?: SxProps<Theme>;
}

export const AttendanceRegisterExport: FC<AttendanceRegisterExportProps> = ({
  register,
  sx,
  ...props
}) => {
  const { showError } = useNotifications();
  const [fetching, setFetching] = useState(false);

  const handleClick = useCallback<React.MouseEventHandler>(
    async (event) => {
      event.stopPropagation();

      if (fetching) {
        return;
      }

      setFetching(true);

      try {
        const data = await exportAttendanceRegister(register.id);

        let filename = `${register.name ?? ''}.${register.register_date}.pdf`;
        filename = decodeURI(filename);

        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        showError(error as ApiError);
      }

      setFetching(false);
    },
    [fetching, register.id, register.name, register.register_date, showError],
  );

  return (
    <AttendanceExportButton
      onClick={handleClick}
      sx={(theme) => ({
        ...(fetching && { '&.MuiIconButton-root': { visibility: 'visible' } }),
        ...((typeof sx === 'function' ? sx(theme) : sx ?? {}) as SystemStyleObject),
      })}
      {...props}
    >
      {fetching ? <Spin /> : <FireIcon />}
    </AttendanceExportButton>
  );
};
