import { useGetDuplicatesQuery } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { Loading } from '@schooly/style';
import { isWithinInterval } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { useNavigate } from 'react-router-dom';

import { ModalSmall } from '../../../components/uikit-components/Modal/Modal.styled';
import { ApplyStudentsForm } from '../../../context/applications/ApplicationsContext';
import { useApplication } from '../../../context/applications/useApplication';
import { useSchool } from '../../../hooks/useSchool';
import useSchoolYears from '../../../hooks/useSchoolYears';
import useUserCounts from '../../../hooks/useUserCounts';
import { ApplicationAccessLayer } from '../ApplicationAccessLayer';
import { ApplicationAddStudentForm, StudentFormDefaultYears } from './ApplicationAddStudentForm';

export const ApplicationAddStudentsModal: FC = () => {
  const {
    application,
    fetching,
    handleApplyStudents,
    applicationId,
    getProductAssignments,
    converting,
  } = useApplication();
  const { schoolId } = useSchool();
  const { schoolYears } = useSchoolYears();
  const navigate = useNavigate();
  const { updateCounts } = useUserCounts();
  const handleSubmit = useCallback<SubmitHandler<ApplyStudentsForm>>(
    async (data) => {
      const id = await handleApplyStudents(data);
      if (id) {
        updateCounts();
        navigate('/students');
      }
    },
    [handleApplyStudents, updateCounts, navigate],
  );

  const childrenForDuplicatesCheck = application?.children.map(
    ({ id, given_name, known_as, last_name, date_of_birth }) => {
      return {
        id,
        given_name,
        known_as: known_as ?? null,
        last_name,
        date_of_birth,
        role: SchoolUserRole.Student,
      };
    },
  );

  const defaultYears = useMemo(() => {
    if (!application || !schoolYears.length) return {};

    return application.children.reduce<StudentFormDefaultYears>(
      (acc, { preferred_start_date, id }) => {
        const schoolYear = schoolYears.find(({ start, end }) =>
          isWithinInterval(new Date(preferred_start_date), {
            start: new Date(start),
            end: new Date(end),
          }),
        );

        return schoolYear ? { ...acc, [id]: schoolYear } : acc;
      },
      {},
    );
  }, [application, schoolYears]);

  const { data: duplicateRecords, isLoading } = useGetDuplicatesQuery(
    { schoolId: schoolId || '', data: childrenForDuplicatesCheck || [] },
    { enabled: !!schoolId && !!childrenForDuplicatesCheck?.length },
  );

  const content = useMemo(() => {
    if (fetching || !application || isLoading) {
      return <Loading />;
    }

    return (
      <ApplicationAddStudentForm
        onClose={() => navigate(`/applications/${applicationId}`, { state: { replace: true } })}
        application={application}
        handleSubmit={handleSubmit}
        duplicateRecords={duplicateRecords}
        defaultYears={defaultYears}
        getProductAssignments={getProductAssignments}
        saving={converting}
      />
    );
  }, [
    application,
    applicationId,
    converting,
    defaultYears,
    duplicateRecords,
    fetching,
    getProductAssignments,
    handleSubmit,
    isLoading,
    navigate,
  ]);

  return (
    <ModalSmall open>
      <ApplicationAccessLayer checkEditPermissions>{content}</ApplicationAccessLayer>
    </ModalSmall>
  );
};
