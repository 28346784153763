import { AnnualPlanRecordTypes, SelectOption } from '@schooly/api';
import { WithRecurringConfirmDialog } from '@schooly/components/recurring';
import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useAnnualPlanner } from '../../WithAnnualPlanner';
import { AnnualPlannerCreateForm, AnnualPlannerRecordMeta } from '../scheme';
import { WithAnnualPlannerCreateAssessment } from './forms/WithAnnualPlannerCreateAssessment';
import { WithAnnualPlannerCreateEvent } from './forms/WithAnnualPlannerCreateEvent';
import { WithAnnualPlannerCreateReport } from './forms/WithAnnualPlannerCreateReport';
import { WithAnnualPlannerCreateSchoolPeriod } from './forms/WithAnnualPlannerCreateSchoolPeriod';
import {
  AnnualPlannerCreateRecordGeneralContext,
  WithAnnualPlannerCreateGeneral,
} from './WithAnnualPlannerCreateGeneral';
import { WithAnnualPlannerCreateObserver } from './WithAnnualPlannerCreateObserver';

export const defaultAnnualPlannerCreateFormValues: AnnualPlannerCreateForm = {
  date: ['', ''],
  schoolPeriod: {},
  event: {},
  assessment: {},
  report: {},
};

export interface AnnualPlannerCreateRecordContextProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<AnnualPlannerCreateRecordContextProps['anchorEl']>>;
  date?: Date[];
  setDate: Dispatch<SetStateAction<AnnualPlannerCreateRecordContextProps['date']>>;
  type?: AnnualPlanRecordTypes;
  setType: Dispatch<SetStateAction<AnnualPlannerCreateRecordContextProps['type']>>;
  record?: AnnualPlannerRecordMeta;
  setRecord: Dispatch<SetStateAction<AnnualPlannerCreateRecordContextProps['record']>>;
  expandedData?: AnnualPlannerCreateForm;
  setExpandedData: Dispatch<SetStateAction<AnnualPlannerCreateRecordContextProps['expandedData']>>;
  isOpen: boolean;
  open: (
    anchorEl: AnnualPlannerCreateRecordContextProps['anchorEl'],
    date?: AnnualPlannerCreateRecordContextProps['date'],
    record?: AnnualPlannerRecordMeta,
  ) => void;
  openParamsRef: MutableRefObject<
    Parameters<AnnualPlannerCreateRecordContextProps['open']> | undefined
  >;
  openParams: Parameters<AnnualPlannerCreateRecordContextProps['open']> | undefined;
  /*
   * Can not use SetStateAction here as the implementation does not suppose
   * a func argument in this particular case
   */
  setOpenParams: (value: AnnualPlannerCreateRecordContextProps['openParams']) => void;

  close: () => void;
  typeOptions: SelectOption<AnnualPlanRecordTypes>[];
}

export const AnnualPlannerCreateRecordContext =
  createContext<AnnualPlannerCreateRecordContextProps>({
    anchorEl: null,
    setAnchorEl: () => {},
    date: undefined,
    setDate: () => {},
    type: undefined,
    setType: () => {},
    record: undefined,
    setRecord: () => {},
    expandedData: undefined,
    setExpandedData: () => {},
    isOpen: false,
    open: () => {},
    openParamsRef: { current: undefined },
    openParams: undefined,
    setOpenParams: () => {},
    close: () => {},
    typeOptions: [],
  });

export const WithAnnualPlannerCreate: FC<PropsWithChildren> = ({ children }) => {
  const { $t } = useIntl();
  const { hasSchoolAdminPermissions, hasEventsPermissions, hasAssessmentsPermissions } =
    useAnnualPlanner();
  const [anchorEl, setAnchorEl] = useState<AnnualPlannerCreateRecordContextProps['anchorEl']>(null);
  const [date, setDate] = useState<AnnualPlannerCreateRecordContextProps['date']>();
  const [type, setType] = useState<AnnualPlannerCreateRecordContextProps['type']>();
  const [record, setRecord] = useState<AnnualPlannerCreateRecordContextProps['record']>();
  const [expandedData, setExpandedData] =
    useState<AnnualPlannerCreateRecordContextProps['expandedData']>();

  const openParamsRef = useRef<Parameters<AnnualPlannerCreateRecordContextProps['open']>>();
  const [openParams, _setOpenParams] =
    useState<Parameters<AnnualPlannerCreateRecordContextProps['open']>>();

  const setOpenParams = useCallback<AnnualPlannerCreateRecordContextProps['setOpenParams']>(
    (value) => {
      openParamsRef.current = value;
      _setOpenParams(value);
    },
    [],
  );

  const isOpen = Boolean(anchorEl);

  const open = useCallback<AnnualPlannerCreateRecordContextProps['open']>(
    (anchorEl, date, record) => {
      if (!isOpen) {
        setAnchorEl(anchorEl);
        setDate(date);
        setType(undefined);
        setRecord(record);
      }
    },
    [isOpen],
  );

  const close = useCallback<AnnualPlannerCreateRecordContextProps['close']>(() => {
    setAnchorEl(null);
    setExpandedData(undefined);
    setDate(undefined);
    setType(undefined);
    setRecord(undefined);
  }, []);

  const typeOptions = useMemo(() => {
    const options: SelectOption<AnnualPlanRecordTypes>[] = [];

    if (hasSchoolAdminPermissions) {
      options.push({
        value: AnnualPlanRecordTypes.SCHOOL_PERIOD,
        label: $t({ id: 'annualPlanner-Records-SchoolPeriods-Type' }),
      });
    }

    if (hasEventsPermissions) {
      options.push({
        value: AnnualPlanRecordTypes.HOLIDAY,
        label: $t({ id: 'annualPlanner-Records-Holidays-Type' }),
      });

      options.push({
        value: AnnualPlanRecordTypes.EVENT,
        label: $t({ id: 'annualPlanner-Records-Events-Type' }),
      });
    }

    if (hasAssessmentsPermissions) {
      options.push({
        value: AnnualPlanRecordTypes.ASSESSMENT,
        label: $t({ id: 'annualPlanner-Records-Assessments-Type' }),
      });

      options.push({
        value: AnnualPlanRecordTypes.REPORT,
        label: $t({ id: 'annualPlanner-Records-Reports-Type' }),
      });
    }

    return options;
  }, [$t, hasAssessmentsPermissions, hasEventsPermissions, hasSchoolAdminPermissions]);

  const value = useMemo<AnnualPlannerCreateRecordContextProps>(
    () => ({
      anchorEl,
      setAnchorEl,
      date,
      setDate,
      type,
      setType,
      record,
      setRecord,
      expandedData,
      setExpandedData,
      isOpen,
      open,
      openParamsRef,
      openParams,
      setOpenParams,
      close,
      typeOptions,
    }),
    [
      anchorEl,
      close,
      date,
      expandedData,
      isOpen,
      open,
      openParams,
      record,
      setOpenParams,
      type,
      typeOptions,
    ],
  );

  return (
    <WithRecurringConfirmDialog>
      <AnnualPlannerCreateRecordContext.Provider value={value}>
        <WithAnnualPlannerCreateSchoolPeriod>
          <WithAnnualPlannerCreateEvent>
            <WithAnnualPlannerCreateAssessment>
              <WithAnnualPlannerCreateReport>
                <WithAnnualPlannerCreateObserver>
                  <WithAnnualPlannerCreateGeneral>{children}</WithAnnualPlannerCreateGeneral>
                </WithAnnualPlannerCreateObserver>
              </WithAnnualPlannerCreateReport>
            </WithAnnualPlannerCreateAssessment>
          </WithAnnualPlannerCreateEvent>
        </WithAnnualPlannerCreateSchoolPeriod>
      </AnnualPlannerCreateRecordContext.Provider>
    </WithRecurringConfirmDialog>
  );
};

export const useAnnualPlannerCreateRecord = () => {
  return {
    ...useContext(AnnualPlannerCreateRecordContext),
    ...useContext(AnnualPlannerCreateRecordGeneralContext),
  };
};
