import { Typography } from '@mui/material';
import { LeavingReasonSet, LeavingReasonType, useGetSchoolLeavingReasons } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
  SelectContentSkeleton,
} from '@schooly/components/filters';
import { FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

type RegistrationStatusLeavingReasonSelectProps = {
  schoolId: string;
  selectedReason?: LeavingReasonSet;
  onSelect: (v: LeavingReasonSet) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const RegistrationStatusLeavingReasonSelect: FC<RegistrationStatusLeavingReasonSelectProps> =
  ({ schoolId, selectedReason, onSelect, ...dropdownProps }) => {
    const { formatMessage } = useIntl();
    const { data } = useGetSchoolLeavingReasons({
      schoolId,
    });
    const dropdown = useRef<DropdownSelect | null>(null);

    const renderContent = useCallback(() => {
      if (!data) return <SelectContentSkeleton />;

      return (
        <>
          {data.leaving_reasons
            .filter((r) => !r.archived)
            .map((r) => (
              <DropdownSelectRow
                key={r.id}
                isSelected={
                  selectedReason?.type === LeavingReasonType.Predefined &&
                  selectedReason.id === r.id
                }
                onClick={() => {
                  onSelect(r);
                  dropdown.current?.close();
                }}
              >
                {r.title}
              </DropdownSelectRow>
            ))}
          <DropdownSelectRow
            isSelected={selectedReason?.type === LeavingReasonType.Other}
            onClick={() => {
              onSelect({
                type: LeavingReasonType.Other,
                title: selectedReason?.type === LeavingReasonType.Other ? selectedReason.title : '',
              });
              dropdown.current?.close();
            }}
          >
            {formatMessage({ id: 'school-Settings-LeavingReason-Other' })}
          </DropdownSelectRow>
        </>
      );
    }, [data, formatMessage, onSelect, selectedReason]);

    return (
      <DropdownSelect
        ref={dropdown}
        hasValues={!!selectedReason}
        renderContent={renderContent}
        requiredLabel="required"
        {...dropdownProps}
      >
        {() => {
          if (!selectedReason) return null;

          return (
            <Typography variant="h3" noWrap>
              {selectedReason.type === LeavingReasonType.Predefined
                ? data?.leaving_reasons.find((r) => r.id === selectedReason.id)?.title
                : formatMessage({ id: 'school-Settings-LeavingReason-Other' })}
            </Typography>
          );
        }}
      </DropdownSelect>
    );
  };
