import { IconButton } from '@mui/material';
import {
  DEFAULT_SESSION_REPLAY_SAMPLE_RATE,
  INCREASED_SESSION_REPLAY_SAMPLE_RATE,
  startAmplitudeNewSession,
  updateAmplitudeSessionReplayOptions,
} from '@schooly/analytics';
import { CrossIcon, Loading, ModalLarge } from '@schooly/style';
import React, { FC, useEffect, useRef } from 'react';

import AccessDenied from '../../../components/common/AccessDenied';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../context/router/useRouter';
import { WithDataGridHover } from '../../../context/table/dataGridHover/WithDataGridHover';
import { useAssessmentsMarkbook } from '../../../hooks/useAssessmentsMarkbook';
import { AssessmentMarkbookExport } from './AssessmentMarkbookExport';
import { AssessmentMarkbookGrid, MarkbookRef } from './AssessmentMarkbookGrid';

export const AssessmentMarkbookModal: FC = () => {
  const { goBack } = useRouter();
  const markbookRef = useRef<MarkbookRef>(null);
  const { group, assessments, students, lists, entries, dateFrom, dateTo, fetching, error } =
    useAssessmentsMarkbook({ onUpdateEntries: markbookRef.current?.updateEntries });

  useEffect(() => {
    const startAmplitudeCustomSession = async (sampleRate: number) => {
      startAmplitudeNewSession();
      await updateAmplitudeSessionReplayOptions({ sampleRate });
    };

    startAmplitudeCustomSession(INCREASED_SESSION_REPLAY_SAMPLE_RATE);

    return () => {
      startAmplitudeCustomSession(DEFAULT_SESSION_REPLAY_SAMPLE_RATE);
    };
  }, []);

  return (
    <ModalLarge onClose={goBack} open>
      {error && !fetching ? (
        <AccessDenied />
      ) : (
        <>
          {!students || !group || fetching ? (
            <Loading />
          ) : (
            <>
              <ModalHeader title={group.name} active>
                <AssessmentMarkbookExport />

                <IconButton onClick={goBack}>
                  <CrossIcon />
                </IconButton>
              </ModalHeader>
              <WithDataGridHover>
                <AssessmentMarkbookGrid
                  ref={markbookRef}
                  groupId={group.id}
                  assessments={assessments}
                  students={students}
                  lists={lists}
                  entries={entries}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </WithDataGridHover>
            </>
          )}
        </>
      )}
    </ModalLarge>
  );
};
