import { Button, IconButton, Stack } from '@mui/material';
import { SchoolYear, SchoolYearPeriod, SchoolYearPeriodGroup } from '@schooly/api';
import { getSchoolPeriodLabel } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  CheckIcon,
  CrossIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  PlusIcon,
  Spin,
} from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import AccessDenied from '../../../../components/common/AccessDenied';
import { ModalHeaderV2 } from '../../../../components/uikit-components/Modal/ModalHeaderV2';
import { PeriodGroup, PeriodGroupProps } from './PeriodGroup';
import { usePeriodValidation } from './usePeriodValidation';
import { useSchoolPeriodsUpdate } from './useSchoolPeriodsUpdate';

export interface SchoolPeriodsUpdateContentProps {
  schoolYear: PeriodGroupProps['schoolYear'];
  autoFocusGroup?: SchoolYearPeriodGroup['id'];
  onClose: () => void;
}

export type SchoolPeriodForm = SchoolYearPeriod & { originId?: string; isStarted?: boolean };
export interface SchoolPeriodGroupsForm {
  period_groups: Array<
    Omit<SchoolYearPeriodGroup, 'periods'> & {
      originId?: string;
      periods: Array<SchoolPeriodForm>;
    }
  >;
}

export const defaultValues: SchoolPeriodGroupsForm = {
  period_groups: [
    {
      id: uuidv4(),
      name: '',
      periods: [],
      should_publish_events: false,
      frequency: null,
      main: true,
    },
  ],
};

export const SchoolPeriodsUpdateContent: FC<SchoolPeriodsUpdateContentProps> = ({
  schoolYear,
  autoFocusGroup,
  onClose,
}) => {
  const { canEdit, isUpdating, updatePeriods, currentSchoolYear } = useSchoolPeriodsUpdate(
    schoolYear as SchoolYear,
  );
  const { validatePastDate, validateMainPeriod } = usePeriodValidation();
  const { $t } = useIntl();
  const { showNotification } = useNotifications();

  //main period group should be shown first
  const periodGroups = useMemo(() => {
    const groups = schoolYear?.period_groups ?? [];
    return [...groups].sort((a, b) => (b.main ? 1 : 0) - (a.main ? 1 : 0));
  }, [schoolYear?.period_groups]);

  const form = useForm<SchoolPeriodGroupsForm>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: !!periodGroups.length
      ? {
          period_groups: periodGroups.map((g) => ({
            ...g,
            originId: g.id,
            periods: g.periods.map((p) => ({
              ...p,
              originId: p.id,
              isStarted: p.isStarted !== undefined ? p.isStarted : validatePastDate(p.date_from),
            })),
          })),
        }
      : defaultValues,
  });

  const groups = form.watch('period_groups');
  const mainGroup = useMemo(() => groups.find((g) => g.main), [groups]);

  const canAddGroup = !!mainGroup?.periods[0]?.date_from && !!mainGroup?.periods[0]?.date_to;

  const { append } = useFieldArray({
    control: form.control,
    name: 'period_groups',
  });

  const onSubmit = useCallback<SubmitHandler<SchoolPeriodGroupsForm>>(
    async (d) => {
      let error = false;

      d.period_groups.forEach((g, gIdx) =>
        g.periods.forEach((p, pIdx) => {
          if ((!p.date_from || !p.date_to) && !error) {
            error = true;
          }

          if (!p.date_from) {
            form.setError(`period_groups.${gIdx}.periods.${pIdx}.date_from`, {
              type: 'validate',
              message: $t({ id: 'school-schoolPeriods-ErrorDates' }),
            });
          } else if (!p.date_to) {
            form.setError(`period_groups.${gIdx}.periods.${pIdx}.date_to`, {
              type: 'validate',
              message: $t({ id: 'school-schoolPeriods-ErrorDates' }),
            });
          }
        }),
      );

      if (!validateMainPeriod(d)) {
        showNotification({ textId: 'school-schoolPeriods-MainPeriodRequiredError', type: 'error' });
        error = true;
      }

      if (error) return;

      const res = await updatePeriods(d);

      if (res) {
        showNotification({
          textId: 'school-schoolPeriods-UpdateNotification',
          values: { yearName: currentSchoolYear?.name ?? '' },
          type: 'success',
        });

        onClose();
      }
    },
    [
      $t,
      currentSchoolYear?.name,
      form,
      onClose,
      showNotification,
      updatePeriods,
      validateMainPeriod,
    ],
  );

  const handleGroupCreate = useCallback(() => {
    append({
      id: '',
      name: '',
      periods: [],
      should_publish_events: false,
      frequency: null,
      main: false,
    });
  }, [append]);

  if (!canEdit || !schoolYear)
    return (
      <>
        <ModalHeader
          withBorderBottom={false}
          active
          sx={(theme) => ({ borderRadius: theme.spacing(1) })}
        >
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <AccessDenied />
      </>
    );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalHeaderV2
          active
          title={schoolYear.name}
          subHeader={getSchoolPeriodLabel({
            startDate: schoolYear.start,
            endDate: schoolYear.end,
          })}
          borderRadius="8px 8px 0 0"
        >
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>
        <ModalMain>
          <ModalContent
            sx={{
              paddingTop: 1.75,
            }}
            active
          >
            <Stack gap={2.75}>
              <Stack gap={3}>
                {groups.map((g, i) => {
                  return (
                    <PeriodGroup
                      key={g.id}
                      groupIdx={i}
                      schoolYear={currentSchoolYear}
                      autoFocus={autoFocusGroup === g.id}
                    />
                  );
                })}
              </Stack>
              {canAddGroup && (
                <Button
                  onClick={handleGroupCreate}
                  variant="outlined"
                  sx={{ alignSelf: 'flex-start' }}
                  startIcon={<PlusIcon />}
                >
                  <FormattedMessage id="school-schoolPeriods-AddGroup" />
                </Button>
              )}
            </Stack>
          </ModalContent>
        </ModalMain>

        <ModalFooter active borderRadius="0 0 8px 8px">
          <Button
            type="submit"
            endIcon={isUpdating ? <Spin /> : <CheckIcon />}
            disabled={isUpdating}
            data-test-id="modal-submit"
          >
            <FormattedMessage id="action-Save" />
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
