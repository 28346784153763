import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import * as api from './requests';
import { GetStudentsQueryFilters } from './school';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

export type StudentInconsistencyType =
  | 'rollover_in_process'
  | 'same_age_group'
  | 'have_registration'
  | 'left_or_leaving'
  | 'not_enrolled'
  | 'no_next_age_group'
  | 'no_current_age_group';

const STUDENTS_ROLLOVER_URL = 'registration/students/rollover';
const DEFAULT_PAGE_SIZE = 50;

export interface StudentRolloverRequestParams {
  student_ids: 'all' | string[];
  year_from_id: string;
  year_to_id: string;
  filters?: GetStudentsQueryFilters;
  query?: string;
}

export type StudentInconsistenciesResponse = {
  // If null, than there is no conflicts for selected students
  inconsistencies: Partial<Record<StudentInconsistencyType, number>> | null;
  // If false, then all selected students cannot roll over.
  can_rollover: boolean;
};

function getRolloverFilterParams(filters: StudentRolloverRequestParams['filters']) {
  return removeObjectUndefinedNullValues({
    date: filters?.date?.[0],
    status_ids: filters?.status?.join(','),
    age_group: filters?.age_group?.join(','),
    house: filters?.house?.join(','),
    gender: filters?.gender?.join(','),
    nationality: filters?.nationality?.join(','),
    group: filters?.group?.join(','),
    tutor_group: filters?.tutor_group?.join(','),
    expired: filters?.expired?.join(','),
  });
}

export function getStudentsInconsistencies(
  params: StudentRolloverRequestParams | undefined,
): Promise<StudentInconsistenciesResponse> {
  if (!params) return Promise.resolve({ inconsistencies: null, can_rollover: true });

  const { filters, query, ...bodyParams } = params;
  const filterQueryParams = getRolloverFilterParams(filters);
  return api.post(`${STUDENTS_ROLLOVER_URL}/inconsistencies`, bodyParams, {
    params: { ...filterQueryParams, query: query || undefined },
  });
}

export const GET_STUDENTS_INCONSISTENCIES_QUERY = `${STUDENTS_ROLLOVER_URL}/GET_STUDENTS_INCONSISTENCIES_QUERY`;

export const useGetStudentsInconsistenciesQuery = (
  params: StudentRolloverRequestParams | undefined,
  options?: RQUseQueryOptions<StudentInconsistenciesResponse>,
) => {
  return useQuery<StudentInconsistenciesResponse, ApiError>(
    [GET_STUDENTS_INCONSISTENCIES_QUERY, params],
    () => getStudentsInconsistencies(params),
    options,
  );
};

export const GET_STUDENTS_INCONSISTENCY_LIST_QUERY = `${STUDENTS_ROLLOVER_URL}GET_STUDENTS_INCONSISTENCY_LIST_QUERY`;

export type StudentInconsistency = {
  student_id: string;
  last_name: string;
  given_name: string;
  profile_picture: string | null;
  house_id: string | null;
  age_group_id: string | null;
  parents_invite_accepted?: boolean;
  status: {
    id: string;
    archived: boolean;
    name: string;
    applies_from: string;
    applies_to: string;
    same_age_group: boolean;
  };
};

export type StudentsInconsistencyListRequestParams = {
  inconsistency_type: StudentInconsistencyType;
  page_number?: number;
  page_size?: number;
} & StudentRolloverRequestParams;

export function getStudentsInconsistencyList({
  page_size = DEFAULT_PAGE_SIZE,
  page_number = 1,
  filters,
  query,
  ...bodyParams
}: StudentsInconsistencyListRequestParams): Promise<PagedResponse<StudentInconsistency>> {
  const filterQueryParams = getRolloverFilterParams(filters);
  return api.post(
    `${STUDENTS_ROLLOVER_URL}/inconsistencies/list`,
    {
      page_size,
      page_number,
      ...bodyParams,
    },
    { params: { ...filterQueryParams, query: query || undefined } },
  );
}

export const useGetStudentsInconsistencyList = (
  params: StudentsInconsistencyListRequestParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentInconsistency>>,
) => {
  return useInfiniteQuery<PagedResponse<StudentInconsistency>, ApiError>(
    [GET_STUDENTS_INCONSISTENCY_LIST_QUERY, params],
    ({ pageParam }) => getStudentsInconsistencyList({ page_number: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );
};

type StartRolloverRequestParams = {
  applies_from?: string;
  status_id?: string;
} & StudentRolloverRequestParams;

type StartRolloverResponse = {
  id: string;
};

export const START_ROLLOVER_MUTATION = `${STUDENTS_ROLLOVER_URL}START_ROLLOVER_MUTATION`;

export function startRollover({
  filters,
  query,
  ...bodyParams
}: StudentRolloverRequestParams): Promise<StartRolloverResponse> {
  const filterQueryParams = getRolloverFilterParams(filters);
  return api.post(STUDENTS_ROLLOVER_URL, bodyParams, {
    params: { ...filterQueryParams, query: query || undefined },
  });
}

export const useStartRolloverMutation = (
  options?: RQUseMutationOptions<StartRolloverResponse, StartRolloverRequestParams>,
): RQUseMutationResult<StartRolloverResponse, StartRolloverRequestParams> => {
  return useMutation(
    [START_ROLLOVER_MUTATION],
    (params: StartRolloverRequestParams) => startRollover(params),
    {
      ...options,
    },
  );
};

export type RolloverAggregatedKey = 'enrolled' | 'will_enrolled' | 'pending_parent_response';

export type RolloverAggregatedDataResponse = {
  total: number;
  rollover_count: number;
  result: Array<{
    key: RolloverAggregatedKey;
    rows: Array<{
      total: number;
      name: string;
      value: string;
    }>;
  }>;
};

export const GET_ROLLOVER_AGGREGATED_DATA = `${STUDENTS_ROLLOVER_URL}GET_ROLLOVER_AGGREGATED_DATA`;

export function getRolloverAggregatedData({
  filters,
  query,
  ...params
}: StudentRolloverRequestParams): Promise<RolloverAggregatedDataResponse> {
  const filterQueryParams = getRolloverFilterParams(filters);
  return api.post(`${STUDENTS_ROLLOVER_URL}/aggregate`, params, {
    params: { ...filterQueryParams, query: query || undefined },
  });
}

export const useGetRolloverAggregatedData = (
  params: StudentRolloverRequestParams,
  options?: RQUseQueryOptions<RolloverAggregatedDataResponse>,
) => {
  return useQuery<RolloverAggregatedDataResponse, ApiError>(
    [GET_ROLLOVER_AGGREGATED_DATA, params],
    () => getRolloverAggregatedData(params),
    options,
  );
};
