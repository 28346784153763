export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_FNS = 'yyyy-MM-dd';
export const DEFAULT_YEAR_MONTH_FORMAT_FNS = 'yyyy-MM';
export const DEFAULT_FORMATTED_DATE_FORMAT_FNS = 'dd MMMM yyyy';
export const SHORT_FORMATTED_DATE_FORMAT_FNS = 'dd MMM yyyy';
export const DATE_FORMAT_FULL_MONTH_FNS = 'LLLL';
export const DATE_FORMAT_RANGE_FNS = 'd MMM yyyy';
export const DATE_FORMAT_RANGE_SHORT_FNS = 'd MMM yyyy';
export const DATE_FORMAT_SHORT_FNS = 'dd MMM';
export const DATE_FORMAT_MONTH_SHORT_FNS = 'MMM yyyy';
export const DATE_NA = 'na';
export const DATE_FORMAT_ORDINAL_MONTH_DAY_FNS = 'do';
export const DATE_FORMAT_MONTH_YEAR = "MMM ''yy";
export const DATE_FORMAT_DAY = 'd';
export const DATE_FORMAT_WEEK_DAY_FNS = 'EEE';
