import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ApiError,
  FilterKeys,
  GET_SCHOOL_LEAVE_STATUS,
  GET_SCHOOL_LEAVING_REASONS,
  GET_SCHOOL_PROPERTIES_QUERY,
  GET_SCHOOL_RE_ENROLLMENT_STATUS,
  LeavingReasonType,
  useGetSchoolLeavingReasons,
  useGetSchoolLeavingStatus,
  useGetSchoolReEnrollmentStatus,
  useUpdateLeavingReasonsMutation,
  useUpdateSchoolLeavingStatusMutation,
  useUpdateSchoolReEnrollmentStatusMutation,
} from '@schooly/api';
import { clearLastAppliedFilter, StoredFilterSections } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  EditIcon,
  GridContainer,
  GridRowItem,
  GridRowStyled,
  InformationIcon,
  NewTabIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback } from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useNotificationSettings } from '../../../hooks/useNotificationSettings';
import { useSchool } from '../../../hooks/useSchool';
import useSchoolYears from '../../../hooks/useSchoolYears';
import AccessDeniedPage from '../../AccessDenied';
import { ManageGroupModalTooltip } from '../../Groups/ManageGroupModal/ManageGroupModalTooltip';
import { SchoolLeavingReasonsForm, SchoolLeavingReasonsModal } from './SchoolLeavingReasonsModal';
import { SchoolLeavingReasonsContent } from './SchoolReEnrollment/SchoolLeavingReasonsContent';
import {
  SchoolReEnrollmentContent,
  ViewStudentProps,
} from './SchoolReEnrollment/SchoolReEnrollmentContent';
import { SchoolWithdrawalStatusContent } from './SchoolReEnrollment/SchoolWithdrawalStatusContent';

const CARD_LABEL_WIDTH = 300;

export const SchoolAnnualRollover: FC = () => {
  const navigate = useNavigate();
  const { showError } = useNotifications();
  const { schoolYears } = useSchoolYears();
  const { schoolId, isSchoolAdmin } = useSchool();
  const [reasonsModalOpened, showReasonsModal, hideReasonsModal] = useFlag();
  const { notificationSettings } = useNotificationSettings();

  const queryClient = useQueryClient();

  const { data: reasons, isFetching: loadingReasons } = useGetSchoolLeavingReasons(
    {
      schoolId: schoolId ?? '',
    },
    {
      enabled: Boolean(schoolId),
      refetchOnMount: 'always',
    },
  );

  const { data: reEnrollmentStatus } = useGetSchoolReEnrollmentStatus(schoolId ?? '', {
    enabled: Boolean(schoolId && isSchoolAdmin),
    refetchOnMount: 'always',
  });

  const { data: currentLeavingStatus } = useGetSchoolLeavingStatus(schoolId ?? '', {
    enabled: Boolean(schoolId),
    refetchOnMount: 'always',
  });

  const leavingStatusId = currentLeavingStatus?.leaving_status_id;

  const { mutate: updateLeavingStatus, isLoading: loadingUpdateLeavingStatus } =
    useUpdateSchoolLeavingStatusMutation();

  const {
    mutate: updateSchoolReEnrollmentStatus,
    isLoading: loadingUpdateSchoolReEnrollmentStatus,
  } = useUpdateSchoolReEnrollmentStatusMutation();

  const { mutate: updateReasons, isLoading: loadingUpdateReasons } =
    useUpdateLeavingReasonsMutation();

  const handleReEnrollmentSubmit = useCallback(
    async (enabled: boolean) => {
      if (!schoolId || !leavingStatusId) return;
      updateSchoolReEnrollmentStatus(
        {
          enabled,
          schoolId,
          status_ids: [leavingStatusId],
        },
        {
          onSuccess: (result) => {
            queryClient.setQueryData([GET_SCHOOL_RE_ENROLLMENT_STATUS, schoolId], result);
            queryClient.removeQueries([
              GET_SCHOOL_PROPERTIES_QUERY,
              {
                schoolId,
                userType: SchoolUserRole.Student,
                showReEnrollmentProperties: true,
              },
            ]);
          },
        },
      );
    },
    [schoolId, updateSchoolReEnrollmentStatus, queryClient, leavingStatusId],
  );

  const handleSelectWithdrawalStatus = useCallback(
    (statusId: string) => {
      if (!reEnrollmentStatus || !schoolId) return;

      updateLeavingStatus(
        {
          schoolId,
          status_id: statusId,
        },
        {
          onError: (err) => {
            console.error(err);
            showError(err as ApiError);
          },
          onSuccess: () => {
            queryClient.invalidateQueries([GET_SCHOOL_LEAVE_STATUS]);
          },
        },
      );

      updateSchoolReEnrollmentStatus(
        {
          enabled: reEnrollmentStatus.enabled,
          schoolId,
          status_ids: [statusId],
        },
        {
          onSuccess: (result) => {
            queryClient.setQueryData([GET_SCHOOL_RE_ENROLLMENT_STATUS, schoolId], result);
            queryClient.removeQueries([
              GET_SCHOOL_PROPERTIES_QUERY,
              {
                schoolId,
                userType: SchoolUserRole.Student,
                showReEnrollmentProperties: true,
              },
            ]);
          },
        },
      );
    },
    [
      queryClient,
      reEnrollmentStatus,
      schoolId,
      showError,
      updateLeavingStatus,
      updateSchoolReEnrollmentStatus,
    ],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolLeavingReasonsForm>>(
    async (data) => {
      if (!schoolId || !data.reasons) {
        return;
      }

      updateReasons(
        {
          schoolId,
          leaving_reasons: data.reasons.map((reason, index) => ({
            id: reason.id || undefined,
            title: reason.title ?? '',
            order: index + 1,
            archived: reason.archived ?? false,
            type: LeavingReasonType.Predefined,
          })),
        },
        {
          onError: (err) => {
            console.error(err);
            showError(err as ApiError);
          },
          onSuccess: () => {
            hideReasonsModal();
            queryClient.invalidateQueries([GET_SCHOOL_LEAVING_REASONS]);
          },
        },
      );
    },
    [schoolId, queryClient, hideReasonsModal, updateReasons, showError],
  );

  const handleViewStudents = useCallback(
    ({ statusId, yearId }: ViewStudentProps) => {
      const year = schoolYears.find((y) => y.id === yearId);
      if (!year) return;

      clearLastAppliedFilter(StoredFilterSections.Students);

      navigate(
        `/students?${`${FilterKeys.Date}=${JSON.stringify([year.start])}&${
          FilterKeys.Status
        }=${JSON.stringify([statusId])}`}`,
      );
    },
    [navigate, schoolYears],
  );

  if (!isSchoolAdmin || !schoolId) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-RolloverAndLeaving" />
      </Box>

      <Stack direction="column" gap={4}>
        <Card>
          <Stack direction="row" alignItems="center" py={1.5} px={2.5} gap={5}>
            <Stack direction="row" alignItems="center" gap={1} width="300px">
              <Typography variant="h2">
                <FormattedMessage id="section-WithdrawalStatus" />
              </Typography>

              <ManageGroupModalTooltip
                text={
                  <Typography color="primary.main">
                    <FormattedMessage id="school-leavingStatus-LeavingStatusTooltip" />
                  </Typography>
                }
                arrow
              >
                <IconButton>
                  <InformationIcon />
                </IconButton>
              </ManageGroupModalTooltip>
            </Stack>

            {!currentLeavingStatus || !reEnrollmentStatus ? (
              <Skeleton
                variant="rectangular"
                sx={(theme) => ({ maxWidth: 500, height: theme.spacing(3), my: 1.2 })}
              />
            ) : (
              <SchoolWithdrawalStatusContent
                schoolId={schoolId ?? ''}
                selectedStatusId={leavingStatusId}
                loading={loadingUpdateLeavingStatus}
                onSelectId={handleSelectWithdrawalStatus}
              />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" py={1.5} px={2.5} gap={5}>
            <Typography variant="h3" color="common.grey2" width="300px">
              <FormattedMessage id="school-leavingNotice-NotificationEmail" />
            </Typography>

            <TextField
              disabled
              value={
                notificationSettings?.internal_emails?.admissions_email ||
                'admissions.school@school.com'
              }
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={<FormattedMessage id="school-leavingNotice-NotificationEmailTooltip" />}
                  >
                    <Link to="/settings/notifications" style={{ zIndex: 1 }}>
                      <Box sx={{ paddingX: 1, display: 'flex', alignItems: 'center' }}>
                        <IconButton size="small">
                          <NewTabIcon />
                        </IconButton>
                      </Box>
                    </Link>
                  </Tooltip>
                ),
              }}
              sx={{ maxWidth: 500, width: '100%' }}
            />
          </Stack>
        </Card>

        <Card>
          <CardHeader
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="h2">
                  <FormattedMessage id="section-ReasonsForLeaving" />
                </Typography>

                <ManageGroupModalTooltip
                  text={
                    <Typography color="primary.main">
                      <FormattedMessage id="school-leavingStatus-LeavingReasonsTooltip" />
                    </Typography>
                  }
                  arrow
                >
                  <IconButton>
                    <InformationIcon />
                  </IconButton>
                </ManageGroupModalTooltip>
              </Stack>
            }
            action={
              <SimpleButton startIcon={<EditIcon />} onClick={showReasonsModal}>
                <FormattedMessage id="school-leavingNotice-EditReasonsTooltip" />
              </SimpleButton>
            }
          />

          <CardContent sx={{ padding: reasons?.leaving_reasons.length ? 2 : 0 }}>
            <Stack px={2.5} width={'100%'}>
              <Stack flexGrow={1} alignItems="center" gap={2}>
                {loadingReasons ? (
                  <GridContainer>
                    {new Array(4).fill(true).map(() => {
                      return (
                        <GridRowStyled>
                          <GridRowItem>
                            <Skeleton
                              variant="text"
                              sx={(theme) => ({
                                width: 300,
                                height: theme.spacing(3.5),
                              })}
                            />
                          </GridRowItem>
                        </GridRowStyled>
                      );
                    })}
                  </GridContainer>
                ) : (
                  <SchoolLeavingReasonsContent reasons={reasons?.leaving_reasons ?? []} />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <Box
            height={20}
            pt={2.5}
            pl={2.5}
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                height: 50,
              },
            })}
          >
            <Typography variant="h2" sx={{ width: CARD_LABEL_WIDTH }}>
              <FormattedMessage id="section-AnnualRolloverReEnrollment" />
            </Typography>
          </Box>
          {!!leavingStatusId && (
            <Box pt={0.5} pb={2.5}>
              {!reEnrollmentStatus ? (
                <Stack justifyContent="center" alignItems="center" width="100%">
                  <Icon fontSize="medium">
                    <Spin />
                  </Icon>
                </Stack>
              ) : (
                <SchoolReEnrollmentContent
                  data={reEnrollmentStatus}
                  statusId={leavingStatusId}
                  schoolId={schoolId}
                  onSubmit={handleReEnrollmentSubmit}
                  isUpdating={loadingUpdateSchoolReEnrollmentStatus}
                  onViewStudents={handleViewStudents}
                />
              )}
            </Box>
          )}
        </Card>
      </Stack>

      <Outlet />

      {reasonsModalOpened && (
        <SchoolLeavingReasonsModal
          reasons={reasons?.leaving_reasons ?? []}
          opened={reasonsModalOpened}
          loading={loadingReasons || loadingUpdateReasons}
          onClose={hideReasonsModal}
          onSubmit={handleSubmit}
        />
      )}
    </MainLayout>
  );
};
