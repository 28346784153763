import { Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import {
  ATTENDANCE_CODE_IN_SCHOOl_OPTIONS,
  ATTENDANCE_CODE_IS_PRESENT_OPTIONS,
} from '@schooly/constants';
import {
  DisabledNotificationIcon,
  DragIcon,
  EditIcon,
  NotificationIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { FC } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';

interface AttendanceCodeProps {
  code: AttendanceCode;
  editable?: boolean;
  onEditClick?: () => void;
  draggable?: boolean;
  provided?: DraggableProvided;
  notificationsEnabled?: boolean;
}

export function normalizeInSchoolValue(inSchoolValue: AttendanceCode['in_school']) {
  if ('boolean' === typeof inSchoolValue) {
    return inSchoolValue ? 1 : 0;
  }

  return undefined;
}

export function normalizeIsPresentValue(isPresentValue: AttendanceCode['is_present']) {
  if ('boolean' === typeof isPresentValue) {
    return isPresentValue ? 1 : 0;
  }

  return undefined;
}

export const AttendanceCodeRow: FC<AttendanceCodeProps> = ({
  code,
  onEditClick,
  draggable,
  provided,
  editable,
  notificationsEnabled,
}) => {
  const { formatMessage } = useIntl();
  const inSchoolLabel = ATTENDANCE_CODE_IN_SCHOOl_OPTIONS.find(
    ({ value }) => normalizeInSchoolValue(code.in_school) === value,
  )?.labelTextId;
  const isPresentLabel = ATTENDANCE_CODE_IS_PRESENT_OPTIONS.find(
    ({ value }) => normalizeIsPresentValue(code.is_present) === value,
  )?.labelTextId;

  return (
    <GridRowStyled
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      style={provided?.draggableProps.style}
      noBorderRadius
      sx={{
        '&:hover': {
          '.editIconCell': {
            visibility: 'visible',
          },
          '.notificationIcon': {
            color: notificationsEnabled ? undefined : 'common.grey2',
          },
        },
      }}
    >
      <GridRowItem sx={{ py: 1.25, minHeight: 44 }}>
        {draggable && (
          <IconButton sx={{ flex: '1 1 auto' }} {...provided?.dragHandleProps} inverse>
            <DragIcon />
          </IconButton>
        )}
        <GridRowName sx={{ flex: '1 1 20%' }}>
          <Stack flexDirection="row" gap={1.25} pr={0.75} alignItems="center">
            <TypographyWithOverflowHint variant="h3">{code.name}</TypographyWithOverflowHint>
            {code.has_push_notifications && (
              <Tooltip
                componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                title={formatMessage({
                  id: notificationsEnabled
                    ? 'attendance-ParentNotification'
                    : 'attendance-ParentNotification-Disabled',
                })}
              >
                <Icon
                  fontSize="small"
                  className="notificationIcon"
                  sx={{ color: notificationsEnabled ? undefined : 'common.grey' }}
                >
                  {notificationsEnabled ? <NotificationIcon /> : <DisabledNotificationIcon />}
                </Icon>
              </Tooltip>
            )}
          </Stack>
        </GridRowName>
        <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
          {isPresentLabel && <FormattedMessage id={isPresentLabel} />}
        </GridRowName>
        <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
          {inSchoolLabel && <FormattedMessage id={inSchoolLabel} />}
        </GridRowName>
        <GridRowName sx={{ flex: '1 1 5%' }} variant="body1">
          {code.code}
        </GridRowName>
        <GridRowName sx={{ flex: '1 1 40%', minWidth: 0 }} variant="body1">
          <TypographyWithOverflowHint>{code.description}</TypographyWithOverflowHint>
        </GridRowName>

        {editable && (
          <GridRowCell
            sx={{
              flex: '1 1 auto',
              visibility: 'hidden',
            }}
            className="editIconCell"
            align="right"
          >
            <Icon
              color="primary"
              sx={{ cursor: 'pointer', display: 'inline' }}
              onClick={onEditClick}
            >
              <EditIcon />
            </Icon>
          </GridRowCell>
        )}
      </GridRowItem>
    </GridRowStyled>
  );
};
