import { Box, Stack, Typography } from '@mui/material';
import { FilterKeys } from '@schooly/api';
import { buildClassName } from '@schooly/utils/build-classname';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFilters } from '../../../../context/filters/useFilters';
import FilterControl, { IFilterControlProps } from '../FilterControl';

export const SubjectControl: FC<IFilterControlProps> = (props) => {
  const { getDraftValue } = useFilters();

  const isOnlyTutorGroups = (getDraftValue(FilterKeys.OnlyTutorGroups) ?? []).join('') === '1';

  return (
    <Stack justifyContent="center" sx={{ height: (theme) => theme.spacing(5) }}>
      {isOnlyTutorGroups ? (
        <Box className={buildClassName('filter-control', props.className)}>
          <Box className="filter-control-name">
            <FormattedMessage id="groups-GroupSubject" />
          </Box>
          <Typography color="text.secondary" px={2} py={1}>
            <FormattedMessage id="groups-GroupSubject-disabledFilter-Hint" />
          </Typography>
        </Box>
      ) : (
        <FilterControl {...props} />
      )}
    </Stack>
  );
};
