import { Button, IconButton } from '@mui/material';
import { ArrowRightIcon, CrossIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ModalFooter, ModalLarge } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { useSchool } from '../../../hooks/useSchool';
import { SchoolSettingsLayout } from '../SchoolSettingsLayout';
import { SchoolTuneContent } from './SchoolTuneContent';

export const SchoolTune: FC = () => {
  const navigate = useNavigate();
  const { currentSchool, isCompleted } = useSchool();

  const handleClose = useCallback(() => {
    // TODO: do we need `goBack` here?
    navigate('/settings');
  }, [navigate]);

  const handleComplete = useCallback(() => {
    if (!isCompleted) {
      return;
    }

    navigate('notifications');
  }, [isCompleted, navigate]);

  return (
    <SchoolSettingsLayout>
      <ModalLarge open hideBackdrop onClose={handleClose}>
        <ModalHeader title={currentSchool?.name} active>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <SchoolTuneContent />
        <ModalFooter active>
          <Button onClick={handleComplete} disabled={!isCompleted} endIcon={<ArrowRightIcon />}>
            <FormattedMessage id="action-Next" />
          </Button>
        </ModalFooter>
      </ModalLarge>
    </SchoolSettingsLayout>
  );
};
