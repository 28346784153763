import { Icon, Popper, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { DATE_FORMAT } from '@schooly/constants';
import { CalendarIcon, Spin } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';

import { ExtendedFieldError } from '../../../components/ui/Input/utils';
import { AssessmentForm, AssessmentFormValidate } from './AssessmentCreateContent';

type AssessmentsDateSelectProps = {
  loading: boolean;
  onValidate: AssessmentFormValidate;
  forceOpened?: boolean;
  onOpen: () => void;
};

export const AssessmentsDateSelect: FC<AssessmentsDateSelectProps> = ({
  loading,
  onValidate,
  forceOpened,
  onOpen,
}) => {
  const { ref, width } = useResizeDetector<HTMLDivElement>();

  const { control, formState, watch } = useFormContext<AssessmentForm>();
  const dateString = watch('assessment_date');
  const error: ExtendedFieldError = get(formState.errors, 'assessment_date');
  const date = useMemo(() => (dateString ? newDateTimezoneOffset(dateString) : null), [dateString]);
  const value = date ? format(date, DATE_FORMAT) : null;
  const { $t } = useIntl();

  return (
    <Stack
      ref={ref}
      height={44}
      sx={{
        flex: '1 1 100%',
      }}
    >
      <Popper
        anchorEl={ref.current}
        sx={(theme) => ({
          zIndex: theme.zIndex.tooltip + 1,
          minWidth: width ?? 0,
        })}
        open={!!ref.current}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ]}
        placement="auto"
        disablePortal
      >
        <Controller
          name={'assessment_date'}
          control={control}
          rules={{
            required: true,
            validate: onValidate,
          }}
          render={({ field }) => {
            return (
              <DateSelect
                date={field.value ?? undefined}
                onSetDate={(d) => {
                  field.onChange(format(d, DEFAULT_DATE_FORMAT_FNS));
                }}
                onOpen={onOpen}
                opened={forceOpened || undefined}
                hasValues={!!value}
                placeholder={$t({ id: 'assessments-AssessmentDate' })}
                error={error}
                startIcon={
                  <Icon sx={{ color: 'common.grey' }}>{loading ? <Spin /> : <CalendarIcon />}</Icon>
                }
                disabled={loading}
              />
            );
          }}
        />
      </Popper>
    </Stack>
  );
};
