import { Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SchoolYear, SchoolYearPeriod } from '@schooly/api';
import { getSchoolPeriodLabel } from '@schooly/components/filters';
import {
  ArrowAngleIcon,
  CalendarIcon,
  ChevronUpSmallIcon,
  EditIcon,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  LockIcon,
  PlusIcon,
  SimpleButton,
  StarIcon,
} from '@schooly/style';
import { isBefore, startOfToday } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from 'react-router';

interface SchoolYearRowProps {
  schoolYear: SchoolYear;
}

export const SchoolYearRow: FC<SchoolYearRowProps> = ({ schoolYear }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { ref } = useResizeDetector<HTMLDivElement>();

  const canEdit = useMemo(() => {
    const today = startOfToday();
    const isDateInPast = isBefore(new Date(schoolYear.end), today);

    return !isDateInPast;
  }, [schoolYear.end]);

  const [open, setOpen] = useState(false);
  const hasGroupPeriod = Boolean(schoolYear.period_groups?.length);

  return (
    <GridRowStyled
      sx={{
        '&:hover': {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
          '.svg-icon': {
            color: 'text.primary',
          },
          '.editPeriodIcon, .mainPeriodGroupIcon': {
            visibility: 'visible',
            color: 'primary.main',
          },
        },
      }}
      onClick={() => setOpen((o) => !o)}
    >
      <GridRowItem>
        <GridRowName ref={ref} sx={{ flex: `0 0 52%`, minWidth: 0 }}>
          {schoolYear.name}
        </GridRowName>
        <GridRowName>
          <IconButton inverse>
            <CalendarIcon />
          </IconButton>
          <Typography component="span" variant="h3" pl={1.25}>
            {getSchoolPeriodLabel({ startDate: schoolYear.start, endDate: schoolYear.end })}
          </Typography>
        </GridRowName>
        <GridRowName sx={{ flex: 0 }}>
          <Stack direction="row" gap={2}>
            {!hasGroupPeriod && canEdit && (
              <SimpleButton
                data-test-id="update-school-periods-modal"
                onClick={() => navigate(schoolYear.id)}
                startIcon={<PlusIcon />}
              >
                <FormattedMessage id="school-schoolPeriods-AddSchoolPeriods" />
              </SimpleButton>
            )}

            {hasGroupPeriod && (
              <IconButton
                sx={(theme) => ({
                  fontSize: theme.spacing(1),
                  cursor: 'pointer',
                  color: theme.palette.common.grey,
                  transform: !open ? 'rotate(180deg)' : undefined,
                  '&:hover': {
                    color: theme.palette.text.primary,
                  },
                })}
              >
                <ChevronUpSmallIcon />
              </IconButton>
            )}

            {canEdit && hasGroupPeriod && (
              <IconButton
                sx={(theme) => ({
                  color: theme.palette.common.grey,
                  visibility: 'hidden',
                  '&:hover': {
                    '.svg-icon': {
                      color: theme.palette.text.primary,
                    },
                  },
                })}
                onClick={() => navigate(schoolYear.id)}
                inverse
              >
                <EditIcon className="editPeriodIcon" />
              </IconButton>
            )}

            {!canEdit && (
              <Icon sx={{ color: 'text.secondary' }}>
                <LockIcon />
              </Icon>
            )}
          </Stack>
        </GridRowName>
      </GridRowItem>

      {open &&
        schoolYear.period_groups?.map((group, groupIdx) => (
          <>
            <GridRowItem noVerticalPadding gap={1.75}>
              <Icon
                inverse
                sx={(theme) => ({
                  color: theme.palette.common.grey,
                })}
              >
                <ArrowAngleIcon />
              </Icon>
              <GridRowName py={1.5} sx={(theme) => ({ borderBottom: theme.mixins.borderValue() })}>
                <Stack direction="row">
                  <Typography component="span" variant="h3">
                    {group.name}
                  </Typography>
                  {group.main && (
                    <Tooltip title={formatMessage({ id: 'school-schoolPeriods-MainPeriodHint' })}>
                      <Icon sx={{ ml: 1 }}>
                        <StarIcon className="mainPeriodGroupIcon" />
                      </Icon>
                    </Tooltip>
                  )}
                </Stack>
              </GridRowName>
            </GridRowItem>

            {group.periods.map((p, periodIndex) => {
              const isLast =
                !!schoolYear.period_groups?.length &&
                groupIdx === schoolYear.period_groups.length - 1 &&
                periodIndex === group.periods.length - 1;
              return (
                <PeriodRow
                  parentWidth={ref.current?.clientWidth}
                  key={p.id}
                  withBorder={!isLast}
                  period={p}
                />
              );
            })}
          </>
        ))}
    </GridRowStyled>
  );
};

interface PeriodRowProps {
  withBorder: boolean;
  period: SchoolYearPeriod;
  parentWidth?: number;
}

const PeriodRow: FC<PeriodRowProps> = ({ withBorder, period, parentWidth }) => {
  return (
    <GridRowItem noVerticalPadding ml={3.25}>
      <Stack direction="row" alignItems="center">
        <Icon
          inverse
          sx={(theme) => ({
            color: theme.palette.common.grey,
          })}
        >
          <ArrowAngleIcon />
        </Icon>
        <GridRowName
          paddingBottom={1.25}
          paddingTop={1.25}
          pl={2}
          sx={(theme) => ({
            borderBottom: withBorder ? theme.mixins.borderValue() : undefined,
            width: parentWidth ? parentWidth - 46 : undefined,
          })}
        >
          <Typography component="span" variant="h3">
            {period.name}
          </Typography>
        </GridRowName>
      </Stack>
      <GridRowName
        paddingBottom={1.25}
        paddingTop={1.25}
        sx={(theme) => ({
          borderBottom: withBorder ? theme.mixins.borderValue() : undefined,
        })}
      >
        <IconButton inverse>
          <CalendarIcon />
        </IconButton>
        <Typography component="span" variant="h3" pl={1.25}>
          {getSchoolPeriodLabel({
            startDate: period.date_from,
            endDate: period.date_to,
          })}
        </Typography>
      </GridRowName>
    </GridRowItem>
  );
};
