import { Stack, Typography } from '@mui/material';
import { EditIcon, MedicalIcon } from '@schooly/style';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useProfile } from '../../../context/profile/useProfile';
import useFlag from '../../../hooks/useFlag';
import buildClassName from '../../../utils/buildClassName';
import AddButton from '../AddButton';
import MedicalModal from './MedicalModal';

interface MedicalPropertyParams {
  size?: 'lg' | 'md' | 'sm';
  color?: string;
  iconColor?: string;
}

const MedicalProperty = ({
  size = 'sm',
  color = 'gray',
  iconColor = 'light-gray',
}: MedicalPropertyParams) => {
  const { formatMessage } = useIntl();
  const [isDialogOpen, showDialog, hideDialog] = useFlag();

  const { user, hasEditingPermission, schoolMembership } = useProfile();

  const { details = {} } = schoolMembership ?? {};

  if (!user) {
    return null;
  }

  if (!details?.allergy && hasEditingPermission) {
    return (
      <>
        <AddButton
          className={buildClassName(
            `profile-property add size--${size} text--primary icon--${iconColor}`,
            hasEditingPermission && 'can-edit',
          )}
          onClick={showDialog}
          labelTextId="peopleDetail-AddMedicalNeeds"
          testId="student-medical-needs-add-button"
        />
        <MedicalModal
          user={user}
          studentMembership={schoolMembership}
          isOpen={isDialogOpen}
          onClose={hideDialog}
          canEdit={true}
        />
      </>
    );
  }

  return (
    <>
      {details?.allergy ? (
        <button
          type="button"
          aria-label={formatMessage({ id: 'ariaLabel-Edit' })}
          data-test-id="student-medical-needs-edit-button"
          onClick={hasEditingPermission ? showDialog : undefined}
          className={buildClassName(
            `profile-property not-editable-property medical-property size--${size} text--${color} icon--${iconColor}`,
            hasEditingPermission && 'can-edit',
          )}
        >
          <div className="property-icon">
            <MedicalIcon />
          </div>

          <Stack direction="row" justifyContent="space-between" width="100%" pr={3} gap={2}>
            <Typography
              data-test-id="student-medical-needs-label"
              variant="h3"
              color={details?.severe ? 'error.main' : 'text.primary'}
              textAlign="left"
            >
              {details?.allergy}
            </Typography>

            <Typography variant="h3" color="text.secondary" minWidth={125} textAlign="left">
              <FormattedMessage
                id={
                  details?.reviewed_by_nurse
                    ? 'peopleDetail-ReviewedByNurse'
                    : 'peopleDetail-NotReviewedByNurse'
                }
              />
            </Typography>
          </Stack>
          {hasEditingPermission && <EditIcon className="edit-icon" />}
        </button>
      ) : (
        <div
          className={buildClassName(
            `profile-property not-editable-property medical-property size--${size} text--${color} icon--${iconColor}`,
          )}
        >
          <div className="property-icon">
            <MedicalIcon />
          </div>

          <Typography variant="h3" color="text.secondary">
            <FormattedMessage id="peopleDetail-MedicalNeeds" />
          </Typography>
        </div>
      )}
      <MedicalModal
        user={user}
        studentMembership={schoolMembership}
        isOpen={isDialogOpen}
        onClose={hideDialog}
        canEdit={true}
      />
    </>
  );
};

export default MedicalProperty;
