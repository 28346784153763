import { BoxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { forwardRef } from 'react';

import { GridRowItem, GridRowStyled } from './Grid';

export const MIN_ROW_HEIGHT = 44;

export const RowSimple = forwardRef<HTMLDivElement, BoxProps>(({ children, sx, ...props }, ref) => {
  return (
    <GridRowStyled
      ref={ref}
      sx={(theme) => ({
        ':hover': {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
          '.hoverText': {
            color: 'common.grey2',
          },
        },
        ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
      })}
      {...props}
    >
      <GridRowItem sx={{ minHeight: MIN_ROW_HEIGHT }}>{children}</GridRowItem>
    </GridRowStyled>
  );
});
