import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import { format, isAfter, isBefore } from 'date-fns';
import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../../scheme';

export const AnnualPlannerCreateReport: FC = () => {
  const { $t } = useIntl();
  const { control } = useFormContext<AnnualPlannerCreateForm>();
  const { yearStart, yearEnd } = useAnnualPlanner();

  const isDateNotWithinSchoolYear = useCallback(
    (date: Date) =>
      Boolean((yearStart && isBefore(date, yearStart)) || (yearEnd && isAfter(date, yearEnd))),
    [yearEnd, yearStart],
  );

  return (
    <>
      <Controller
        control={control}
        name="date.0"
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          return (
            <DateSelect
              ref={field.ref}
              onSetDate={(date) => {
                if (isDateNotWithinSchoolYear(date)) return;
                field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
              }}
              date={field.value}
              requiredLabel="required"
              error={fieldState.error}
              CalendarProps={{ shouldDisableDate: isDateNotWithinSchoolYear }}
            />
          );
        }}
      />

      <ControlTextField
        name="report.name"
        control={control}
        label={$t({
          id: 'annualPlanner-Popover-Report-Name',
        })}
        rules={{
          required: true,
        }}
        fullWidth
      />
    </>
  );
};
