import { useGetAssessmentQuery } from '@schooly/api';
import { WithRecurringConfirmDialog } from '@schooly/components/recurring';
import { Loading, ModalSmall } from '@schooly/style';
import React, { FC, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AssessmentCreateContent, AssessmentForCreateForm } from './AssessmentCreateContent';
import { WithAssessmentCreate, WithAssessmentCreateProps } from './useAssessmentCreateModal';

export interface AssessmentsCreateModalProps
  extends Pick<WithAssessmentCreateProps, 'onClose' | 'onPreview'> {
  id?: string;
  assessment?: AssessmentForCreateForm;
  isFetching?: boolean;
}

export const AssessmentsCreateModal: FC<AssessmentsCreateModalProps> = ({
  id: propId,
  assessment: propAssessment,
  isFetching: propIsFetching,
  onClose,
  onPreview,
}) => {
  const paperRef = useRef<HTMLDivElement>(null);
  const { id: paramId } = useParams<'id'>();
  const navigate = useNavigate();

  const assessmentId = propId ?? paramId;

  const { data, isFetching: dataIsFetching } = useGetAssessmentQuery(
    { id: assessmentId ?? '', modifyInfo: true },
    {
      enabled: Boolean(assessmentId),
      refetchOnMount: 'always',
    },
  );

  const assessment = propAssessment ?? data;
  const isFetching = propAssessment ? propIsFetching : dataIsFetching;

  return (
    <WithRecurringConfirmDialog>
      <ModalSmall
        PaperProps={{ ref: paperRef }}
        open
        onClose={
          onClose
            ? onClose
            : () => {
                navigate('/assessments');
              }
        }
      >
        {isFetching ? (
          <Loading />
        ) : (
          <WithAssessmentCreate id={assessmentId} onClose={onClose} onPreview={onPreview}>
            <AssessmentCreateContent assessment={assessment} />
          </WithAssessmentCreate>
        )}
      </ModalSmall>
    </WithRecurringConfirmDialog>
  );
};
