import { DEFAULT_DATE_FORMAT_FNS, SchoolYear } from '@schooly/api';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  isFirstDayOfMonth,
  parse,
} from 'date-fns';
import { useMemo } from 'react';

import { AnnualPlannerCalendarWithDates } from './scheme';

export const useAnnualPlannerYearGrid = (params: AnnualPlannerCalendarWithDates) => {
  return useMemo(() => {
    const start =
      typeof params.start === 'string'
        ? parse(params.start, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.start;

    const end =
      typeof params.end === 'string'
        ? parse(params.end, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.end;

    const rows = 31;

    const months = eachMonthOfInterval({ start, end });
    const days = new Array(rows).fill(true).map((_, index) => index + 1);

    return {
      start,
      end,
      cols: months.length,
      rows,
      months,
      days,
    };
  }, [params.start, params.end]);
};

interface UseAnnualPlannerMonthGridProps extends AnnualPlannerCalendarWithDates {
  yearStart: SchoolYear['start'];
  yearEnd: SchoolYear['end'];
}

export const useAnnualPlannerMonthGrid = (params: UseAnnualPlannerMonthGridProps) => {
  return useMemo(() => {
    const start =
      typeof params.start === 'string'
        ? parse(params.start, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.start;

    const end =
      typeof params.end === 'string'
        ? parse(params.end, DEFAULT_DATE_FORMAT_FNS, new Date())
        : params.end;

    const yearStart = parse(params.yearStart, DEFAULT_DATE_FORMAT_FNS, new Date());
    const yearEnd = parse(params.yearEnd, DEFAULT_DATE_FORMAT_FNS, new Date());

    const dates = eachDayOfInterval({ start, end });
    const weeks = eachWeekOfInterval({ start, end });
    const month = dates.find((date) => isFirstDayOfMonth(date)) ?? start;
    const firstWeek = dates.slice(0, 7);

    return {
      dates,
      month,
      firstWeek,
      rows: weeks.length,
      start,
      end,
      yearStart,
      yearEnd,
    };
  }, [params.start, params.end, params.yearStart, params.yearEnd]);
};
