import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { AreaOfLearning, AssessmentMethod, AssessmentMethodType } from '@schooly/api';
import { CommentIcon, LinesFillBg, TypographyWithOverflowHint } from '@schooly/style';
import {
  FC,
  forwardRef,
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useAssessmentsGradesForSchool } from '../../../hooks/useAssessmentsGradesForSchool';

export const SUBJECTS_COLUMN_WIDTH = 140;
export const HEADER_HEIGHT = 115;
export const PADDING_LEFT = 20;
export const AGE_GROUP_TITLE_HEIGHT = 46;
export const CELL_HEIGHT = 34;

export type HoveredCell = (EventTarget & HTMLDivElement) | null;

export const AreaHeader: FC<{
  areas: AreaOfLearning[];
}> = ({ areas }) => {
  const maxMethods = Math.max(...areas.map((a) => a.methods.length));

  return (
    <Stack flexDirection="row">
      {areas.map((a, i) => {
        return (
          <Stack
            key={a.name}
            sx={(theme) => ({
              position: 'relative',
              '&:not(:last-child)': {
                borderRight: `1px solid ${theme.palette.common.light3}`,
              },
              minWidth: theme.spacing(6 * maxMethods),
              flex: 1,
            })}
          >
            <Stack
              sx={(theme) => ({
                height: CELL_HEIGHT,
                padding: theme.spacing(0.5, 1),
                borderBottom: `1px solid ${theme.palette.common.light2}`,
              })}
              justifyContent="center"
            >
              <TypographyWithOverflowHint textAlign="center">{a.name}</TypographyWithOverflowHint>
            </Stack>
            <Stack flexDirection="row">
              {a.methods.map((m, i) => {
                return (
                  <Stack
                    key={m.method_type}
                    sx={(theme) => ({
                      height: CELL_HEIGHT,
                      minWidth: theme.spacing(6),
                      justifyContent: 'center',
                      flex: 1,
                      flexDirection: 'row',
                      px: theme.spacing(0.5),
                      alignItems: 'center',
                      '&:not(:last-child)': {
                        borderRight: `1px solid ${theme.palette.common.light2}`,
                      },
                    })}
                  >
                    <AssessmentMethodTitle method={m} />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

type MatrixHighlightProps = {
  containerRef: RefObject<HTMLElement>;
};

export type HighlightRef = {
  show: (v: HoveredCell) => void;
};

type OverlayState =
  | {
      left: number;
      top: number;
      width: number;
      height: number;
    }[]
  | null;

export const MatrixHighlight = forwardRef<HighlightRef, MatrixHighlightProps>(
  ({ containerRef }, ref) => {
    const [overlay, setOverlay] = useState<OverlayState>(null);
    const [hoveredCell, setHoveredCell] = useState<HoveredCell>(null);

    const containerElement = containerRef?.current;

    useImperativeHandle(
      ref,
      () => ({
        show: setHoveredCell,
      }),
      [],
    );

    const calcLinesPosition = useCallback(() => {
      if (!hoveredCell || !containerElement) {
        setOverlay(null);
        return;
      }

      const crect = containerElement.getBoundingClientRect();
      const { height, width, x, y } = hoveredCell.getBoundingClientRect();

      const calcMinLeft = (v: number) => Math.max(v, SUBJECTS_COLUMN_WIDTH + 20);
      const calcDiffWidth = (left: number, width: number) =>
        Math.min(width, width + left - (SUBJECTS_COLUMN_WIDTH + 20));

      const areaElement = hoveredCell.parentNode as unknown as HTMLDivElement | null;

      if (!areaElement) return null;
      const groupElement = (areaElement.parentNode as unknown as HTMLDivElement | null)
        ?.parentNode as unknown as HTMLDivElement | null;

      if (!groupElement) return null;
      const parentRect = areaElement.getBoundingClientRect();
      const groupRect = groupElement.getBoundingClientRect();

      const overlayState: OverlayState = [
        {
          top: y - crect.top,
          left: PADDING_LEFT,
          width: Math.max(x - PADDING_LEFT - crect.x + width, SUBJECTS_COLUMN_WIDTH),
          height,
        },
        {
          top: HEADER_HEIGHT - CELL_HEIGHT,
          left: calcMinLeft(x - crect.x),
          width: calcDiffWidth(x - crect.x, width),
          height: y + height - crect.y - HEADER_HEIGHT + CELL_HEIGHT,
        },
        {
          top: AGE_GROUP_TITLE_HEIGHT,
          left: calcMinLeft(parentRect.left - crect.left),
          width: calcDiffWidth(parentRect.left - crect.left, parentRect.width),
          height: parentRect.height,
        },
        {
          top: 0,
          left: calcMinLeft(groupRect.left - crect.left),
          width: calcDiffWidth(groupRect.left - crect.left, groupRect.width),
          height: AGE_GROUP_TITLE_HEIGHT,
        },
      ];

      setOverlay(overlayState);
    }, [containerElement, hoveredCell]);

    useEffect(() => {
      containerElement?.addEventListener('scroll', calcLinesPosition);
      calcLinesPosition();

      return () => containerElement?.removeEventListener('scroll', calcLinesPosition);
    }, [containerElement, calcLinesPosition]);

    if (!overlay) return null;

    return (
      <>
        {overlay.map((o, i) => (
          <Box
            key={i}
            sx={(theme) => ({
              backgroundColor: theme.palette.background.default,
              pointerEvents: 'none',
              position: 'absolute',
              mixBlendMode: 'multiply',
              left: o.left,
              zIndex: 4,
              width: o.width,
              top: o.top,
              height: o.height,
            })}
          />
        ))}
      </>
    );
  },
);

export const AssessmentMethodTitle: FC<{ method: AssessmentMethod }> = ({ method }) => {
  const { $t } = useIntl();
  const { grades } = useAssessmentsGradesForSchool();

  switch (method.method_type) {
    case AssessmentMethodType.Comment:
      return (
        <Icon sx={{ pointerEvents: 'none' }}>
          <CommentIcon />
        </Icon>
      );

    case AssessmentMethodType.Grade:
      return (
        <Typography sx={{ pointerEvents: 'none' }} noWrap>
          {grades.find((g) => g.id === method.select_list_id)?.name}
        </Typography>
      );

    case AssessmentMethodType.Score:
      return (
        <Typography sx={{ pointerEvents: 'none' }} noWrap>
          {method.score_out_of ? `0/${method.score_out_of}` : $t({ id: 'assessments-Type-0' })}
        </Typography>
      );

    default:
      return null;
  }
};

export const ColumnCell: FC<{
  areas: AreaOfLearning[];
  hasValue: boolean;
  isTutor?: boolean;
  setHoveredCell: (v: HoveredCell) => void;
}> = ({ isTutor, areas, hasValue, setHoveredCell }) => {
  const maxMethods = Math.max(...areas.map((a) => a.methods.length));

  const { $t } = useIntl();

  return (
    <Stack
      position="relative"
      sx={(theme) => ({
        height: CELL_HEIGHT,
        '&:not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      })}
    >
      {hasValue ? (
        <>
          {isTutor ? (
            <Stack>
              <Stack
                onMouseOver={(e) => {
                  setHoveredCell(e.currentTarget);
                }}
                onMouseOut={() => setHoveredCell(null)}
                flexDirection="row"
                sx={{
                  height: CELL_HEIGHT,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon>
                  <CommentIcon />
                </Icon>
              </Stack>
            </Stack>
          ) : (
            <Stack flexDirection="row">
              {areas.map((a, ai) => (
                <Stack
                  key={a.name}
                  sx={(theme) => ({
                    position: 'relative',
                    '&:not(:last-child)': {
                      borderRight: `1px solid ${theme.palette.common.light3}`,
                    },
                    minWidth: theme.spacing(6 * maxMethods),
                    flex: 1,
                  })}
                >
                  <Stack flexDirection="row">
                    {a.methods.map((m, i) => (
                      <Stack
                        onMouseOver={(e) => {
                          setHoveredCell(e.currentTarget);
                        }}
                        onMouseOut={() => setHoveredCell(null)}
                        key={m.method_type}
                        sx={(theme) => ({
                          '&:hover': {
                            '.cell-overlay': {
                              display: 'none',
                            },
                            '.MuiTypography-root, .MuiIcon-root': {
                              color: theme.palette.primary.main,
                            },
                          },
                          height: CELL_HEIGHT,
                          minWidth: theme.spacing(6),
                          px: theme.spacing(0.5),
                          justifyContent: 'center',
                          flexDirection: 'row',
                          flex: 1,
                          position: 'relative',
                          alignItems: 'center',
                          '&:not(:last-child)': {
                            borderRight: `1px solid ${theme.palette.common.light2}`,
                          },
                        })}
                      >
                        <AssessmentMethodTitle method={m} />
                        <DotCell />
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </>
      ) : (
        <Stack sx={{ minHeight: '100%' }}>
          <Tooltip
            followCursor
            arrow={false}
            TransitionProps={{
              timeout: {
                enter: 0,
                exit: 0,
              },
            }}
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  maxWidth: 400,
                  fontSize: theme.spacing(1.25),
                  borderRadius: theme.spacing(0.5),
                  margin: `${theme.spacing(2, 0, 0)} !important`,
                  padding: theme.spacing(0.25, 0.5),
                }),
              },
            }}
            placement="bottom-start"
            title={$t({ id: 'reports-NoGroupsForSubject' })}
          >
            <Stack
              onMouseOver={(e) => {
                setHoveredCell(e.currentTarget);
              }}
              onMouseOut={() => setHoveredCell(null)}
              sx={(theme) => ({
                flex: 1,
                minWidth: theme.spacing(6 * maxMethods),
                backgroundImage: `url(${LinesFillBg})`,
                backgroundColor: theme.palette.common.orange5,
              })}
            />
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export const DotCell: FC = () => (
  <Stack
    className="cell-overlay"
    position="absolute"
    left={0}
    top={0}
    bottom={1}
    right={0}
    bgcolor="white"
    justifyContent="center"
    alignItems="center"
    sx={{ pointerEvents: 'none' }}
  >
    <Box
      sx={(theme) => ({
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.common.light3,
      })}
    />
  </Stack>
);

export const MatrixOverlay: FC = () => (
  <>
    <Box
      sx={(theme) => ({
        position: 'absolute',
        backgroundColor: 'white',
        top: HEADER_HEIGHT,
        bottom: 0,
        width: theme.spacing(2.5),
      })}
    />
    <Box
      sx={(theme) => ({
        top: 0,
        left: 0,
        borderRight: `1px solid ${theme.palette.common.light3}`,
        borderBottom: `1px solid ${theme.palette.common.light3}`,
        backgroundColor: 'white',
        position: 'absolute',
        height: HEADER_HEIGHT,
        width: 160,
        zIndex: 3,
      })}
    />
  </>
);

export const SubjectCell: FC<PropsWithChildren> = ({ children }) => (
  <Stack
    sx={(theme) => ({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: SUBJECTS_COLUMN_WIDTH,
      pr: theme.spacing(1),
      borderRight: `1px solid ${theme.palette.common.light3}`,
      height: CELL_HEIGHT,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    })}
  >
    {children}
  </Stack>
);

type HeadCellProps = PropsWithChildren & {
  name: string;
  hasBottomBorder: boolean;
};

export const AgeGroupHeader: FC<HeadCellProps> = ({ children, hasBottomBorder, name }) => (
  <Stack
    className="head-cell"
    sx={(theme) => ({
      borderRight: `1px solid ${theme.palette.common.light3}`,
      flex: 1,
    })}
  >
    <Stack
      sx={(theme) => ({
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        minHeight: theme.spacing(5),
        minWidth: theme.spacing(12),
        position: 'relative',
        borderBottom: hasBottomBorder ? `1px solid ${theme.palette.common.light3}` : undefined,
      })}
    >
      <Stack position="absolute" left={10} right={10}>
        <TypographyWithOverflowHint textAlign="center" variant="h3" color="text.primary">
          {name}
        </TypographyWithOverflowHint>
      </Stack>
    </Stack>
    {children}
  </Stack>
);
