import { Icon, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { ProductVariantPrice, useGetSchoolPaymentFrequencies } from '@schooly/api';
import {
  CellDropdownSelect,
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
  SelectContentSkeleton,
} from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { Attention2Icon } from '@schooly/style';
import { FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

type VariantPriceSelectProps = {
  options: ProductVariantPrice[];
  shownInHead?: boolean;
  priceFrequencyId?: string;
  schoolId: string;
  showMissingFrequencyId?: string;
  showWarning: boolean;
  onSelect: (v: ProductVariantPrice) => void;
  yearId: string;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const VariantPriceSelect: FC<VariantPriceSelectProps> = ({
  priceFrequencyId,
  showMissingFrequencyId,
  showWarning,
  options,
  schoolId,
  shownInHead,
  onSelect,
  placeholder,
  yearId,
  ...rest
}) => {
  const { $t } = useIntl();
  const dropdown = useRef<DropdownSelect | null>(null);
  const { data } = useGetSchoolPaymentFrequencies({ school_id: schoolId, year_id: yearId });
  const [opened, open, close] = useFlag();

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    return (
      <>
        {options.map((p) => (
          <DropdownSelectRow
            key={p.frequency_id}
            isSelected={p.frequency_id === priceFrequencyId}
            onClick={() => {
              onSelect(p);
              dropdown.current?.close();
            }}
          >
            {$t({
              id: `frequencies-${data.frequencies.find((f) => f.id === p.frequency_id)?.type}`,
            })}
          </DropdownSelectRow>
        ))}
      </>
    );
  }, [$t, data, priceFrequencyId, onSelect, options]);

  const selectedFrequencyType = data?.frequencies.find((f) => f.id === priceFrequencyId)?.type;

  return (
    <CellDropdownSelect
      ref={dropdown}
      sx={(theme) => ({
        borderTopLeftRadius: shownInHead ? undefined : 0,
        borderTopRightRadius: shownInHead ? undefined : 0,
        margin: shownInHead ? theme.spacing(-1, -1, '-9px', -1) : theme.spacing('-1px', 0),
      })}
      hasValues
      renderContent={renderContent}
      onOpen={open}
      onClose={close}
      placeholder={selectedFrequencyType ? placeholder : undefined}
      {...rest}
    >
      {() => {
        if (!data) return <Skeleton />;

        return (
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between" flex={1}>
            <Typography color={selectedFrequencyType ? 'text.primary' : 'common.grey'} noWrap>
              {selectedFrequencyType
                ? $t({
                    id: `frequencies-${selectedFrequencyType}`,
                  })
                : placeholder}
            </Typography>
            {!opened && showWarning && (
              <Tooltip
                title={
                  showMissingFrequencyId
                    ? $t(
                        { id: 'profile-ThereIsNoFrequency' },
                        {
                          name: $t({
                            id: `frequencies-${
                              data.frequencies.find((f) => f.id === showMissingFrequencyId)?.type
                            }`,
                          }),
                        },
                      )
                    : undefined
                }
              >
                <Icon
                  sx={{
                    zIndex: 1,
                    color: 'warning.main',
                    '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                  }}
                >
                  <Attention2Icon />
                </Icon>
              </Tooltip>
            )}
          </Stack>
        );
      }}
    </CellDropdownSelect>
  );
};
