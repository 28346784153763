import { Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  FilterSection,
  GetStudentsQueryFilters,
  STUDENTS_ARRANGE_BY_FILTER_KEYS,
  STUDENTS_QUERY_FILTER_KEYS,
  StudentsArrangeBy,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  ArrangeByDropdown,
  DateDropdown,
  FilterDropdown,
  filterExistingFilterOptions,
  FiltersContainer,
  GenderExpandedSelect,
  GenderTagSelect,
  getSelectedItemsWithGrouping,
  MoreButton,
  MoreButtonOption,
  NationalityExpandedSelect,
  NationalityTagSelect,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  PropertyTypeExpandedSelect,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import {
  Genders,
  Nationalities,
  PROPERTIES_TEXT_IDS,
  SchoolPropertyType,
  SchoolUserRole,
} from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { Spin, TagSelectProperty } from '@schooly/style';
import { format } from 'date-fns';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../hooks/useSchool';

type StudentsFiltersProps = {
  arrangeBy: StudentsArrangeBy | null;
  onSetArrangeBy: (v: StudentsArrangeBy | null) => void;
  schoolId: string;
  filters: GetStudentsQueryFilters;
  defaultFilters: GetStudentsQueryFilters;
  onSetFilters: (v: GetStudentsQueryFilters) => void;
  defaultUserFilters: GetStudentsQueryFilters;
  defaultUserArrangeBy: StudentsArrangeBy | null;
};

export type StudentsFilters = {
  openArrangeBy: () => void;
};

const StudentsFiltersComponent = (
  {
    arrangeBy,
    defaultFilters,
    filters: actualFilters,
    schoolId,
    onSetFilters,
    onSetArrangeBy,
    defaultUserFilters,
    defaultUserArrangeBy,
  }: StudentsFiltersProps,
  ref: React.ForwardedRef<StudentsFilters>,
) => {
  const { $t } = useIntl();
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const { currentStaff } = useAuth();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const arrangeByDropdown = useRef<ArrangeByDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetStudentsQueryFilters>(actualFilters);
  const [draftArrangeBy, setDraftArrangeBy] =
    useState<StudentsArrangeBy | null | undefined>(arrangeBy);
  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);
  const { expired, ...filtersCanBeSaved } = draftFilters;
  const { hasHouses } = useSchool();

  useImperativeHandle(
    ref,
    () => ({
      openArrangeBy: () => {
        setDraftArrangeBy((arrangeBy) => (arrangeBy === null ? undefined : arrangeBy));
        setTimeout(() => arrangeByDropdown.current?.open(), 300);
      },
    }),
    [],
  );

  useEffect(() => {
    setDraftArrangeBy(arrangeBy);
    setDraftFilters(actualFilters);
  }, [actualFilters, arrangeBy]);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const handleSetDate = useCallback((v: Date) => {
    setDraftFilters((filters) => ({
      ...filters,
      [FilterKeys.Date]: [format(v, DEFAULT_DATE_FORMAT_FNS)],
    }));
  }, []);

  const {
    onClearGender,
    onClearNationality,
    onSelectGender,
    onSelectNationality,
    onClearAgeGroup,
    onClearHouse,
    onClearStatus,
    onClearNoStatus,
    onSelectNoStatus,
    onSelectAgeGroup,
    onSelectHouseId,
    onSelectStatusId,
    // onSelectLeavingReasonId,
    // onClearLeavingReason,
  } = useMemo(() => {
    const updateFilter = (key: FilterKeys.House | FilterKeys.LeavingReason) => (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    const onSelectNationality = (v: Nationalities) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Nationality]: filters[FilterKeys.Nationality]?.includes(v)
          ? filters[FilterKeys.Nationality]?.filter((ct) => ct !== v)
          : [...(filters[FilterKeys.Nationality] || []), v],
      }));
    };
    const onSelectGender = (v: Genders) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Gender]: filters[FilterKeys.Gender]?.includes(v)
          ? filters[FilterKeys.Gender]?.filter((ct) => ct !== v)
          : [...(filters[FilterKeys.Gender] || []), v],
      }));
    };

    const onSelectStatusId = (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Status]: filters[FilterKeys.Status]?.includes(id)
          ? filters[FilterKeys.Status]?.filter((ct) => ct !== id)
          : [...(filters[FilterKeys.Status] || []), id],
        [FilterKeys.Expired]: [],
      }));
    };

    const onSelectNoStatus = () => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.Expired]: filters[FilterKeys.Expired]?.length ? [] : [1],
        [FilterKeys.Status]: [],
      }));
    };

    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };

    return {
      onSelectGender,
      onSelectNationality,
      onSelectHouseId: updateFilter(FilterKeys.House),
      onClearHouse: clearFilter(FilterKeys.House),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectStatusId,
      onSelectNoStatus,
      onSelectLeavingReasonId: updateFilter(FilterKeys.LeavingReason),
      onClearLeavingReason: clearFilter(FilterKeys.LeavingReason),
      onClearStatus: clearFilter(FilterKeys.Status),
      onClearNoStatus: clearFilter(FilterKeys.Expired),
      onClearNationality: clearFilter(FilterKeys.Nationality),
      onClearGender: clearFilter(FilterKeys.Gender),
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDraftArrangeBy(null);
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleResetToDefault = useCallback(() => {
    setDraftArrangeBy(defaultUserArrangeBy);
    setDraftFilters(defaultUserFilters);
  }, [defaultUserArrangeBy, defaultUserFilters]);

  const handleToggleArrangeBy = useCallback(() => {
    setDraftArrangeBy((v) => (v === null ? undefined : null));
  }, []);

  const filtersDate = draftFilters.date?.[0];
  const filtersStatus = draftFilters.status;
  // const filtersLeavingReason = draftFilters.leaving_reason;
  const filtersNoStatus = draftFilters.expired;
  const filtersNationality = draftFilters.nationality;
  const filtersGender = draftFilters.gender;
  const filtersHouse = draftFilters.house;
  const filtersAgeGroup = draftFilters.age_group;

  const noStatusFilterSelected = Boolean(filtersNoStatus?.length);
  const noStatusOption = {
    id: FilterKeys.Expired,
    name: $t({ id: PROPERTIES_TEXT_IDS[FilterKeys.Expired] }),
    type: SchoolPropertyType.Status,
  };

  const dateLabel = $t({ id: 'schoolProperty-Date' });
  const statusLabel = $t({ id: 'schoolProperty-Status' });
  // const leavingReasonLabel = $t({ id: 'schoolProperty-ReasonsForLeaving' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });
  const houseLabel = `${$t({ id: 'schoolProperty-House' })}`;
  const genderLabel = `${$t({ id: 'peopleDetail-Gender' })}`;
  const nationalityLabel = `${$t({ id: 'peopleDetail-Nationality' })}`;

  const filterOptions: MoreButtonOption<keyof GetStudentsQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.Status, label: statusLabel },
    // { value: FilterKeys.LeavingReason, label: leavingReasonLabel },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.House, label: houseLabel },
    { value: FilterKeys.Gender, label: genderLabel },
    { value: FilterKeys.Nationality, label: nationalityLabel },
  ];

  const arrangeByDropdownOptions = hasHouses
    ? STUDENTS_ARRANGE_BY_FILTER_KEYS
    : STUDENTS_ARRANGE_BY_FILTER_KEYS.filter((k) => k !== FilterKeys.House);

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const handleApply = useMemo(() => {
    // Operator != is used on purpose to properly compare against undefined and null
    // eslint-disable-next-line eqeqeq
    const arrangeByChanged = draftArrangeBy != arrangeBy;
    const filtersChanged = STUDENTS_QUERY_FILTER_KEYS.some((key) => {
      const draftFiltersForKey = [...(draftFilters[key] || [])];
      const actualFiltersForKey = [...(actualFilters[key] || [])];

      return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
    });

    if (!filtersChanged && !arrangeByChanged) return undefined;

    if (arrangeByChanged && draftArrangeBy && noStatusFilterSelected) {
      return () => {
        onSelectNoStatus();
        onSetFilters({ ...draftFilters, [FilterKeys.Expired]: [] });
        onSetArrangeBy(draftArrangeBy || null);
      };
    }

    return () => {
      filtersChanged && onSetFilters(draftFilters);
      arrangeByChanged && onSetArrangeBy(draftArrangeBy || null);
    };
  }, [
    draftArrangeBy,
    arrangeBy,
    noStatusFilterSelected,
    draftFilters,
    actualFilters,
    onSelectNoStatus,
    onSetFilters,
    onSetArrangeBy,
  ]);

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        onSaveFilter={handleSaveFilter}
        schoolId={schoolId}
        section={FilterSection.Student}
        filters={{ ...filtersCanBeSaved, arrange_by: draftArrangeBy || undefined }}
        onSetFilters={(v) => {
          const appliedFilters = pickOnlyParamsFromFilterKeys(STUDENTS_QUERY_FILTER_KEYS, v);

          onSetFilters({
            ...appliedFilters,
            [FilterKeys.Date]: appliedFilters.date || defaultFilters.date,
          });

          if (!v.arrange_by) {
            onSetArrangeBy(null);
            return;
          }
          for (const key of STUDENTS_ARRANGE_BY_FILTER_KEYS) {
            key === v.arrange_by && onSetArrangeBy(key);
          }
        }}
      />
      {filtersDate && (
        <DateDropdown date={filtersDate} label={dateLabel} onSetDate={handleSetDate} />
      )}

      {filtersStatus && (
        <FilterDropdown
          onClear={() => {
            toggleFiltersVisible(FilterKeys.Status);
            toggleFiltersVisible(FilterKeys.Expired);
          }}
          label={statusLabel}
          tags={(open) => {
            if (noStatusFilterSelected) {
              return [noStatusOption.id].map((id) => (
                <TagSelectProperty
                  userRole={SchoolUserRole.Student}
                  property={noStatusOption}
                  defaultValue={<Spin />}
                  sx={{ maxWidth: 200 }}
                  key={id}
                  id={id}
                  onClick={open}
                />
              ));
            }

            return filtersStatus.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                schoolId={schoolId}
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
              />
            ));
          }}
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.Status}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersStatus}
              onSelectId={onSelectStatusId}
              onClose={onClose}
              onClear={() => {
                onClearStatus();
                onClearNoStatus();
              }}
              noneOption={!arrangeBy ? noStatusOption : undefined}
              onNoneOptionSelect={onSelectNoStatus}
              isNoneOptionSelected={noStatusFilterSelected}
            />
          )}
        </FilterDropdown>
      )}
      {/* {filtersLeavingReason && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.LeavingReason)}
          label={leavingReasonLabel}
          tags={(open) =>
            renderLeavingReasonTags({
              schoolId,
              ids: filtersLeavingReason,
            })
          }
        >
          {(onClose) => (
            <LeavingReasonsExpandedSelect
              schoolId={schoolId}
              selectedIds={filtersLeavingReason}
              onSelect={onSelectLeavingReasonId}
              onClose={onClose}
              onClear={onClearLeavingReason}
            />
          )}
        </FilterDropdown>
      )} */}
      {filtersAgeGroup && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {filtersHouse && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.House)}
          label={houseLabel}
          tags={(open) =>
            filtersHouse.map((id) => (
              <PropertyTypeTagSelect
                userRole={SchoolUserRole.Student}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <PropertyTypeExpandedSelect
              propertyType={SchoolPropertyType.House}
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersHouse}
              onSelectId={onSelectHouseId}
              onClose={onClose}
              onClear={onClearHouse}
            />
          )}
        </FilterDropdown>
      )}
      {filtersNationality && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Nationality)}
          label={nationalityLabel}
          tags={(open) =>
            filtersNationality.map((v) => (
              <NationalityTagSelect sx={{ maxWidth: 200 }} key={v} nationality={v} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <NationalityExpandedSelect
              selectedNationalities={filtersNationality}
              onSelectNationality={onSelectNationality}
              onClose={onClose}
              onClear={onClearNationality}
            />
          )}
        </FilterDropdown>
      )}
      {filtersGender && (
        <FilterDropdown
          onClear={() => toggleFiltersVisible(FilterKeys.Gender)}
          label={genderLabel}
          tags={(open) =>
            filtersGender.map((v) => (
              <GenderTagSelect sx={{ maxWidth: 200 }} key={v} gender={v} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <GenderExpandedSelect
              selectedGenders={filtersGender}
              onSelectGender={onSelectGender}
              onClose={onClose}
              onClear={onClearGender}
            />
          )}
        </FilterDropdown>
      )}
      {draftArrangeBy !== null && (
        <ArrangeByDropdown
          ref={(r: any) => (arrangeByDropdown.current = r as ArrangeByDropdown)}
          onSelectOption={setDraftArrangeBy}
          selectedOption={draftArrangeBy}
          options={arrangeByDropdownOptions}
          onClear={() => setDraftArrangeBy(undefined)}
          onRemove={() => setDraftArrangeBy(null)}
        />
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({
          filterOptions,
          hasHouses,
        })}
        selectedOptions={STUDENTS_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        isSelectedArrangeBy={draftArrangeBy !== null}
        onToggleArrangeBy={handleToggleArrangeBy}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StudentsFilters = forwardRef(StudentsFiltersComponent);
