import { Box } from '@mui/material';
import { AnnualPlannerViewMode, AnnualPlanRecord, SchoolYear } from '@schooly/api';
import { Loading } from '@schooly/style';
import { FC } from 'react';

import { AnnualPlannerCalendarBackdrop } from './AnnualPlannerCalendar.styled';
import { AnnualPlannerCreateExpanded } from './AnnualPlannerCreatePopover/AnnualPlannerCreateExpanded';
import { AnnualPlannerCreatePopover } from './AnnualPlannerCreatePopover/AnnualPlannerCreatePopover';
import { useAnnualPlannerCreateRecord } from './AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { AnnualPlannerGridLayoutMonth } from './layouts/calendar/AnnualPlannerGridLayoutMonth';
import { AnnualPlannerGridLayoutYear } from './layouts/calendar/AnnualPlannerGridLayoutYear';
import { AnnualPlannerDropLayoutMonth } from './layouts/drop/AnnualPlannerDropLayoutMonth';
import { AnnualPlannerDropLayoutYear } from './layouts/drop/AnnualPlannerDropLayoutYear';
import { AnnualPlannerRecordsLayoutYear } from './layouts/records/AnnualPlannerRecordsLayoutYear';
import { AnnualPlannerCalendarWithDates } from './scheme';

export interface AnnualPlannerCalendarProps extends AnnualPlannerCalendarWithDates {
  loading: boolean;
  viewMode?: AnnualPlannerViewMode;
  schoolYear: SchoolYear;
  records?: AnnualPlanRecord[];
}

export const AnnualPlannerCalendar: FC<AnnualPlannerCalendarProps> = ({
  start,
  end,
  records,
  loading,
  viewMode,
  schoolYear,
}) => {
  const { isOpen } = useAnnualPlannerCreateRecord();
  const isMonthView = viewMode === AnnualPlannerViewMode.Month;

  const renderCalendar = () => {
    if (isMonthView) {
      return (
        <>
          <AnnualPlannerGridLayoutMonth
            records={records}
            start={start}
            end={end}
            yearStart={schoolYear.start}
            yearEnd={schoolYear.end}
          />
          <AnnualPlannerDropLayoutMonth
            start={start}
            end={end}
            yearStart={schoolYear.start}
            yearEnd={schoolYear.end}
          />
        </>
      );
    }

    return (
      <>
        <AnnualPlannerGridLayoutYear start={start} end={end} />
        <AnnualPlannerRecordsLayoutYear start={start} end={end} records={records} />
        <AnnualPlannerDropLayoutYear start={start} end={end} />
      </>
    );
  };

  return (
    <Box className="AnnualPlannerCalendar-root" sx={{ position: 'relative', flex: '1 1 100%' }}>
      <AnnualPlannerCalendarBackdrop open={loading}>
        <Loading />
      </AnnualPlannerCalendarBackdrop>

      {renderCalendar()}

      {isOpen && (
        <>
          <AnnualPlannerCreatePopover viewMode={viewMode} />
          <AnnualPlannerCreateExpanded />
        </>
      )}
    </Box>
  );
};
