import { AnalyticsEventName, trackAmplitudeEvent } from '@schooly/analytics';
import {
  AnnualPlannerViewMode,
  DATE_FORMAT_FULL_MONTH_FNS,
  DEFAULT_DATE_FORMAT_FNS,
  SchoolYear,
} from '@schooly/api';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import {
  addWeeks,
  eachDayOfInterval,
  eachMonthOfInterval,
  format,
  isFirstDayOfMonth,
  isSameMonth,
  isWithinInterval,
  lastDayOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';

import { AnnualPlannerViewPeriod } from './AnnualPlannerCalendar/scheme';

export const getPlannerMonthPeriods = (start?: Date, end?: Date): AnnualPlannerViewPeriod[] => {
  if (!start || !end) return [];

  const months = eachMonthOfInterval({ start, end });

  return months.map((monthStart) => {
    const firstWeekStart = startOfWeek(monthStart);
    const lastWeekEnd = addWeeks(subDays(firstWeekStart, 1), 6);
    return {
      name: format(monthStart, DATE_FORMAT_FULL_MONTH_FNS),
      start: format(firstWeekStart, DEFAULT_DATE_FORMAT_FNS),
      end: format(lastWeekEnd, DEFAULT_DATE_FORMAT_FNS),
    };
  });
};

export const getPlannerDefaultViewPeriodIndex = (
  mode: AnnualPlannerViewMode,
  periods: AnnualPlannerViewPeriod[],
): number => {
  let currentPeriodIndex = 0;

  if (periods.length < 2) return currentPeriodIndex;

  switch (mode) {
    case AnnualPlannerViewMode.Month: {
      currentPeriodIndex = periods.findIndex(({ start, end }) => {
        const startDate = newDateTimezoneOffset(start);
        const endDate = newDateTimezoneOffset(end);
        const month =
          eachDayOfInterval({ start: startDate, end: endDate }).find(isFirstDayOfMonth) ??
          startDate;
        return isSameMonth(new Date(), month);
      });

      break;
    }
    case AnnualPlannerViewMode.Year:
    case AnnualPlannerViewMode.Term: {
      currentPeriodIndex = periods.findIndex(({ start, end }) =>
        isWithinInterval(new Date(), {
          start: newDateTimezoneOffset(start),
          end: newDateTimezoneOffset(end),
        }),
      );
    }
  }

  return currentPeriodIndex >= 0 ? currentPeriodIndex : 0;
};

export const getPlannerViewPeriods = (
  mode: AnnualPlannerViewMode,
  schoolYear?: SchoolYear,
): AnnualPlannerViewPeriod[] => {
  switch (mode) {
    case AnnualPlannerViewMode.Year: {
      return schoolYear
        ? [
            {
              name: schoolYear.name,
              start: schoolYear.start,
              end: schoolYear.end,
            },
          ]
        : [];
    }
    case AnnualPlannerViewMode.Term: {
      const mainPeriodGroup = schoolYear?.period_groups?.find((g) => g.main);
      return mainPeriodGroup
        ? mainPeriodGroup.periods.map((p) => ({
            name: p.name,
            start: p.date_from,
            end: p.date_to,
          }))
        : [];
    }
    case AnnualPlannerViewMode.Month: {
      return schoolYear
        ? getPlannerMonthPeriods(
            newDateTimezoneOffset(schoolYear.start),
            newDateTimezoneOffset(schoolYear.end),
          )
        : [];
    }
  }
};

export const trackPlannerViewModeEvents = (
  viewMode: AnnualPlannerViewMode,
  action: 'show' | 'select',
) => {
  const eventNameMap = {
    show: {
      [AnnualPlannerViewMode.Year]: AnalyticsEventName.DrillDownAnnuallyShown,
      [AnnualPlannerViewMode.Term]: AnalyticsEventName.DrillDownTermlyShown,
      [AnnualPlannerViewMode.Month]: AnalyticsEventName.DrillDownMonthlyShown,
    },
    select: {
      [AnnualPlannerViewMode.Year]: AnalyticsEventName.DrillDownAnnuallySelect,
      [AnnualPlannerViewMode.Term]: AnalyticsEventName.DrillDownTermlySelect,
      [AnnualPlannerViewMode.Month]: AnalyticsEventName.DrillDownMonthlySelect,
    },
  };

  trackAmplitudeEvent({ name: eventNameMap[action][viewMode] });
};

export const getPlannerIntersectDateFilter = (
  mode: AnnualPlannerViewMode,
  period?: AnnualPlannerViewPeriod,
) => {
  if (mode === AnnualPlannerViewMode.Year || !period) return [];
  const { start, end } = period;

  return [start, end];
};

export const getPlannerSchoolDaysDateFilter = (
  mode: AnnualPlannerViewMode,
  period?: AnnualPlannerViewPeriod,
) => {
  if (!period) return [];

  const { start, end } = period;

  switch (mode) {
    case AnnualPlannerViewMode.Year: {
      return [];
    }
    case AnnualPlannerViewMode.Term: {
      return [start, end];
    }
    case AnnualPlannerViewMode.Month: {
      const startDate = newDateTimezoneOffset(start);
      const endDate = newDateTimezoneOffset(end);

      const startOfMonth =
        eachDayOfInterval({
          start: startDate,
          end: endDate,
        }).find(isFirstDayOfMonth) ?? startDate;

      return [
        format(startOfMonth, DEFAULT_DATE_FORMAT_FNS),
        format(lastDayOfMonth(startOfMonth), DEFAULT_DATE_FORMAT_FNS),
      ];
    }
  }
};
