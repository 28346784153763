import { Box } from '@mui/material';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  ModalContent,
  ModalMain,
} from '../../../../../components/uikit-components/Modal/Modal.styled';
import { SchoolTuneStatusesArchivable, SchoolTuneStatusModalMode } from './scheme';
import { SchoolTuneStatusesModalArrow } from './SchoolTuneStatusesModalArrow';
import { SchoolTuneStatusesModalCategory } from './SchoolTuneStatusesModalCategory';

export interface SchoolTuneStatusesModalMainPanelProps extends SchoolTuneStatusesArchivable {
  type: SchoolTuneStatusModalMode;
  leavingStatusId?: string;
}

export const SchoolTuneStatusesModalMainPanel: FC<SchoolTuneStatusesModalMainPanelProps> = ({
  type,
  showArchived,
  setShowArchived,
  autoSwitchedArchivedOn,
  leavingStatusId,
}) => {
  const { $t } = useIntl();

  return (
    <ModalMain>
      <ModalContent sx={{ pt: 0 }} active>
        <Box
          display="grid"
          gridTemplateColumns="1fr 29px 1fr 29px 1fr"
          gridTemplateRows="repeat(2, 1fr)"
          height="100%"
          gap={1}
          rowGap={2}
        >
          <Box gridColumn="span 1" gridRow="span 2" overflow="hidden">
            <SchoolTuneStatusesModalCategory
              type={type}
              category="prospective"
              title={$t({ id: 'school-tabs-Statuses-Status-Prospective' })}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              autoSwitchedArchivedOn={autoSwitchedArchivedOn}
            />
          </Box>
          <Box gridColumn="span 1">
            <SchoolTuneStatusesModalArrow />
          </Box>
          <Box gridColumn="span 1" overflow="hidden">
            <SchoolTuneStatusesModalCategory
              type={type}
              category="current"
              title={$t({ id: 'school-tabs-Statuses-Status-Current' })}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              autoSwitchedArchivedOn={autoSwitchedArchivedOn}
            />
          </Box>
          <Box gridColumn="span 1">
            <SchoolTuneStatusesModalArrow />
          </Box>
          <Box gridColumn="span 1" overflow="hidden">
            <SchoolTuneStatusesModalCategory
              type={type}
              category="former"
              title={$t({ id: 'school-tabs-Statuses-Status-Former' })}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              autoSwitchedArchivedOn={autoSwitchedArchivedOn}
              leavingStatusId={leavingStatusId}
            />
          </Box>
          <Box gridColumn="span 3" overflow="hidden">
            <SchoolTuneStatusesModalArrow />
          </Box>
          <Box gridColumn="span 1" overflow="hidden">
            <SchoolTuneStatusesModalCategory
              type={type}
              category="unsuccessful"
              title={$t({ id: 'school-tabs-Statuses-Status-Unsuccessful' })}
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              autoSwitchedArchivedOn={autoSwitchedArchivedOn}
            />
          </Box>
        </Box>
      </ModalContent>
    </ModalMain>
  );
};
