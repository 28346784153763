import { Icon, IconButton, Stack, Tooltip } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import {
  ATTENDANCE_CODE_IN_SCHOOl_OPTIONS,
  ATTENDANCE_CODE_IS_PRESENT_OPTIONS,
} from '@schooly/constants';
import {
  CrossIcon,
  DisabledNotificationIcon,
  DragIcon,
  NotificationIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import {
  ModalContent,
  ModalLarge,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import { useNotificationSettings } from '../../../../hooks/useNotificationSettings';
import { normalizeInSchoolValue, normalizeIsPresentValue } from './AttendanceCodeRow';

export interface AttendanceCodeOrderForm {
  attendanceCodes: AttendanceCode[];
}

export const AttendanceCodeModal: FC = () => {
  const { attendanceCodes, changeAttendanceCodeOrder, isLoading, onClose } = useAttendanceCodes();
  const { attendanceNotificationsEnabled: notificationsEnabled } = useNotificationSettings();

  const { $t, formatMessage } = useIntl();

  const form = useForm<AttendanceCodeOrderForm>({
    defaultValues: { attendanceCodes },
  });

  const { fields, move } = useFieldArray({
    control: form.control,
    name: 'attendanceCodes',
  });

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const onChangeOrder = useCallback<SubmitHandler<AttendanceCodeOrderForm>>(
    async ({ attendanceCodes }) => {
      const codes = attendanceCodes.map((c, i) => ({ id: c.id, order: i + 1 }));

      await changeAttendanceCodeOrder(codes);
    },
    [changeAttendanceCodeOrder],
  );

  return (
    <ModalLarge open>
      <ModalHeader title={$t({ id: 'attendance-Codes' })} active>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <GridContainer sx={{ height: '100%' }}>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="AttendanceCodesDroppable">
                {(provided) => (
                  <DndListWrapper {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((code, i) => (
                      <Draggable key={code.id} draggableId={code.id} index={i}>
                        {(provided) => {
                          const inSchoolLabel = ATTENDANCE_CODE_IN_SCHOOl_OPTIONS.find(
                            ({ value }) => normalizeInSchoolValue(code.in_school) === value,
                          )?.labelTextId;
                          const isPresentLabel = ATTENDANCE_CODE_IS_PRESENT_OPTIONS.find(
                            ({ value }) => normalizeIsPresentValue(code.is_present) === value,
                          )?.labelTextId;

                          return (
                            <GridRowStyled
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={provided.draggableProps.style}
                              noBorderRadius
                              key={code.id}
                              sx={{
                                '&:hover': {
                                  '.notificationIcon': {
                                    color: notificationsEnabled ? undefined : 'common.grey2',
                                  },
                                },
                              }}
                            >
                              <GridRowItem gap={2} sx={{ overflow: 'hidden', minHeight: 44 }}>
                                {fields.length > 1 && (
                                  <IconButton {...provided.dragHandleProps} inverse>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <GridRowName sx={{ flex: '1 1 30%' }}>
                                  <Stack flexDirection="row" gap={1.25} alignItems="center">
                                    <TypographyWithOverflowHint variant="h3">
                                      {code.name}
                                    </TypographyWithOverflowHint>
                                    {code.has_push_notifications && (
                                      <Tooltip
                                        componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                                        title={formatMessage({
                                          id: notificationsEnabled
                                            ? 'attendance-ParentNotification'
                                            : 'attendance-ParentNotification-Disabled',
                                        })}
                                      >
                                        <Icon
                                          fontSize="small"
                                          className="notificationIcon"
                                          sx={{
                                            color: notificationsEnabled ? undefined : 'common.grey',
                                          }}
                                        >
                                          {notificationsEnabled ? (
                                            <NotificationIcon />
                                          ) : (
                                            <DisabledNotificationIcon />
                                          )}
                                        </Icon>
                                      </Tooltip>
                                    )}
                                  </Stack>
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 15%' }} variant="body1">
                                  {isPresentLabel && <FormattedMessage id={isPresentLabel} />}
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 15%' }} variant="body1">
                                  {inSchoolLabel && <FormattedMessage id={inSchoolLabel} />}
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 10%' }} variant="body1">
                                  {code.code}
                                </GridRowName>
                                <GridRowName sx={{ flex: '1 1 40%' }} variant="body1">
                                  <TypographyWithOverflowHint>
                                    {code.description}
                                  </TypographyWithOverflowHint>
                                </GridRowName>
                              </GridRowItem>
                            </GridRowStyled>
                          );
                        }}
                      </Draggable>
                    ))}
                  </DndListWrapper>
                )}
              </Droppable>
            </DragDropContext>
          </GridContainer>
        </ModalContent>
      </ModalMain>

      <ModalFooterWithActions
        saving={isLoading}
        onSaveClick={form.handleSubmit(onChangeOrder)}
        active
      />
    </ModalLarge>
  );
};
