import {
  ANNUAL_PLAN_FILTER_KEYS,
  AnnualPlan,
  AnnualPlanFilters,
  AnnualPlannerViewMode,
  AnnualPlanRecordTypes,
  FilterKeys,
  isAnnualPlanFilters,
  SchoolYear,
  SchoolYearPeriodGroup,
  useGetAnnualPlanQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import useSchoolYears from '../../hooks/useSchoolYears';
import { AnnualPlannerViewPeriod } from './AnnualPlannerCalendar/scheme';
import {
  getPlannerDefaultViewPeriodIndex,
  getPlannerIntersectDateFilter,
  getPlannerSchoolDaysDateFilter,
  getPlannerViewPeriods,
  trackPlannerViewModeEvents,
} from './utils';

export interface AnnualPlannerContextProps {
  hasSchoolAdminPermissions: boolean;
  hasEventsPermissions: boolean;
  hasAssessmentsPermissions: boolean;
  schoolYear?: SchoolYear;
  yearStart?: Date;
  yearEnd?: Date;
  filters: AnnualPlanFilters;
  handleSchoolYearSelect: (yearId: SchoolYear['id']) => void;
  recordTypesMap: Partial<Record<AnnualPlanRecordTypes, boolean>>;
  toggleRecordType: (type: AnnualPlanRecordTypes) => () => void;
  data?: AnnualPlan;
  isLoading: boolean;
  isFetching: boolean;
  mainPeriodGroup?: SchoolYearPeriodGroup;
  viewMode?: AnnualPlannerViewMode;
  handleViewModeSelect: (viewMode: AnnualPlannerViewMode) => void;
  viewPeriods: AnnualPlannerViewPeriod[];
  currentViewPeriodIndex?: number;
  handleViewPeriodChange: (index: number) => void;
}

export const AnnualPlannerContext = createContext<AnnualPlannerContextProps>({
  hasSchoolAdminPermissions: false,
  hasEventsPermissions: false,
  hasAssessmentsPermissions: false,
  schoolYear: undefined,
  yearStart: undefined,
  yearEnd: undefined,
  filters: { school_year: [], record_type: [], planner_view: [] },
  recordTypesMap: {},
  toggleRecordType: () => () => {},
  data: undefined,
  isLoading: false,
  isFetching: false,
  mainPeriodGroup: undefined,
  viewMode: undefined,
  handleViewModeSelect: () => {},
  handleSchoolYearSelect: () => {},
  viewPeriods: [],
  currentViewPeriodIndex: undefined,
  handleViewPeriodChange: () => {},
});

export const WithAnnualPlanner: FC<PropsWithChildren> = ({ children }) => {
  const hasMounted = useRef(false);
  const { defaultValidity, getSchoolYearById } = useSchoolYears();

  const { schoolId = '', permissions } = useAuth();

  const hasSchoolAdminPermissions = permissions.includes('school_admin');
  const hasEventsPermissions = permissions.includes('event_viewer');
  const hasAssessmentsPermissions = permissions.includes('assessment_viewer');

  // define filters by default
  const defaultFilters = useMemo<AnnualPlanFilters>(() => {
    const defaultRecordTypes = [
      AnnualPlanRecordTypes.SCHOOL_PERIOD,
      ...(hasEventsPermissions ? [AnnualPlanRecordTypes.EVENT, AnnualPlanRecordTypes.HOLIDAY] : []),
      ...(hasAssessmentsPermissions
        ? [AnnualPlanRecordTypes.ASSESSMENT, AnnualPlanRecordTypes.REPORT]
        : []),
    ];

    return {
      [FilterKeys.SchoolYear]: [defaultValidity?.id ?? ''],
      [FilterKeys.RecordType]: defaultRecordTypes,
      [FilterKeys.PlannerView]: [AnnualPlannerViewMode.Year],
    };
  }, [defaultValidity?.id, hasAssessmentsPermissions, hasEventsPermissions]);

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.AnnualPlanner,
    filterKeys: ANNUAL_PLAN_FILTER_KEYS,
    schoolId: schoolId,
  });

  const filtersFromParams = useFiltersStateFromSearchParams({
    filterKeys: ANNUAL_PLAN_FILTER_KEYS,
    defaultFilters,
  }) as AnnualPlanFilters;

  const initialFilters = useMemo(() => {
    const savedFilters =
      lastAppliedFilter && isAnnualPlanFilters(lastAppliedFilter) && lastAppliedFilter;
    const paramsFilters = isAnnualPlanFilters(filtersFromParams) && filtersFromParams;

    const filters = savedFilters || paramsFilters || defaultFilters;
    const { record_type: recordTypes, ...initialFilters } = filters;

    // additionally check permissions, as the users could manually set irrelevant record types
    // or the ones they have no access for
    const initialRecordTypes = recordTypes
      ? recordTypes.filter((recordType) => {
          switch (recordType) {
            case AnnualPlanRecordTypes.EVENT:
            case AnnualPlanRecordTypes.HOLIDAY:
              return hasEventsPermissions;
            case AnnualPlanRecordTypes.ASSESSMENT:
            case AnnualPlanRecordTypes.REPORT:
              return hasAssessmentsPermissions;
            case AnnualPlanRecordTypes.SCHOOL_PERIOD:
              return true;
            default:
              return false;
          }
        })
      : [];

    const initialViewMode = filters[FilterKeys.PlannerView][0];
    const schoolYear = getSchoolYearById(filters[FilterKeys.SchoolYear][0]);

    const initialViewPeriods = getPlannerViewPeriods(initialViewMode, schoolYear);
    const initialPeriodIndex = getPlannerDefaultViewPeriodIndex(
      initialViewMode,
      initialViewPeriods,
    );
    const initialPeriod = initialViewPeriods[initialPeriodIndex];

    const intersectDateFilter = getPlannerIntersectDateFilter(initialViewMode, initialPeriod);
    const schoolDaysDateFilter = getPlannerSchoolDaysDateFilter(initialViewMode, initialPeriod);

    return {
      [FilterKeys.RecordType]: initialRecordTypes,
      [FilterKeys.IntersectDate]: intersectDateFilter,
      [FilterKeys.SchoolDaysDate]: schoolDaysDateFilter,
      ...initialFilters,
    };
  }, [
    defaultFilters,
    filtersFromParams,
    getSchoolYearById,
    hasAssessmentsPermissions,
    hasEventsPermissions,
    lastAppliedFilter,
  ]);

  const [filters, setFilters] = useState<AnnualPlanFilters>(initialFilters);

  const viewMode = filters[FilterKeys.PlannerView][0];
  const recordTypes = filters[FilterKeys.RecordType];
  const { intersect_date, school_days_date, ...savedFilters } = filters;

  const schoolYear = getSchoolYearById(filters[FilterKeys.SchoolYear][0]);
  const viewPeriods = useMemo(
    () => getPlannerViewPeriods(viewMode, schoolYear),
    [schoolYear, viewMode],
  );
  const mainPeriodGroup = schoolYear?.period_groups?.find((g) => g.main);

  const [currentViewPeriodIndex, setCurrentViewPeriodIndex] = useState(
    getPlannerDefaultViewPeriodIndex(viewMode, viewPeriods),
  );

  const { yearStart, yearEnd } = useMemo(() => {
    const yearStart = schoolYear?.start ? newDateTimezoneOffset(schoolYear.start) : undefined;
    const yearEnd = schoolYear?.end ? newDateTimezoneOffset(schoolYear.end) : undefined;
    return { yearStart, yearEnd };
  }, [schoolYear?.end, schoolYear?.start]);

  const {
    data: requestData,
    isFetching,
    isLoading,
  } = useGetAnnualPlanQuery(
    {
      schoolId,
      filters: {
        [FilterKeys.SchoolYear]: filters[FilterKeys.SchoolYear],
        [FilterKeys.IntersectDate]: filters[FilterKeys.IntersectDate],
        [FilterKeys.SchoolDaysDate]: filters[FilterKeys.SchoolDaysDate],
      },
    },
    { refetchOnMount: 'always' },
  );

  const data = useMemo(() => {
    if (!requestData) return;

    let records = requestData.records;

    //to remove main period group from planner on term view
    if (viewMode === AnnualPlannerViewMode.Term) {
      records = requestData.records.filter(
        (record) =>
          !(
            record.type === AnnualPlanRecordTypes.SCHOOL_PERIOD &&
            record.period_group_id === mainPeriodGroup?.id
          ),
      );
    }

    /**
     * Filters records by type.
     * Can not rely on useGetAnnualPlanQuery.select option as it's being called on each render and the
     * calculations are pretty heavy
     */
    return {
      ...requestData,
      records: records.filter((record) => recordTypes?.includes(record.type)),
    };
  }, [mainPeriodGroup?.id, recordTypes, requestData, viewMode]);

  useSyncFiltersStateWithSearchParams({ pathname: '/planner', filters: savedFilters });
  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.AnnualPlanner,
    filters: savedFilters,
    schoolId,
  });

  /* Record types state */
  const recordTypesMap = useMemo(() => {
    return (
      recordTypes?.reduce<AnnualPlannerContextProps['recordTypesMap']>(
        (prev, type) => ({ ...prev, [type]: true }),
        {},
      ) ?? {}
    );
  }, [recordTypes]);

  const toggleRecordType = useCallback<AnnualPlannerContextProps['toggleRecordType']>(
    (type) => () =>
      setFilters({
        ...filters,
        [FilterKeys.RecordType]:
          (recordTypesMap[type]
            ? recordTypes?.filter((item) => item !== type)
            : [...(recordTypes ?? []), type]) ?? [],
      }),
    [filters, recordTypes, recordTypesMap],
  );

  const handleViewModeSelect = useCallback<AnnualPlannerContextProps['handleViewModeSelect']>(
    (mode) => {
      const periods = getPlannerViewPeriods(mode, schoolYear);
      if (!periods.length) return;

      const defaultViewPeriodIndex = getPlannerDefaultViewPeriodIndex(mode, periods);
      const period = periods[defaultViewPeriodIndex];
      const isYearView = mode === AnnualPlannerViewMode.Year;

      setCurrentViewPeriodIndex(defaultViewPeriodIndex);

      setFilters({
        ...filters,
        [FilterKeys.PlannerView]: [mode],
        [FilterKeys.IntersectDate]: isYearView ? [] : getPlannerIntersectDateFilter(mode, period),
        [FilterKeys.SchoolDaysDate]: isYearView ? [] : getPlannerSchoolDaysDateFilter(mode, period),
      });
    },
    [filters, schoolYear],
  );

  const handleViewPeriodChange = useCallback<AnnualPlannerContextProps['handleViewPeriodChange']>(
    (index) => {
      setCurrentViewPeriodIndex(index);
      const period = viewPeriods[index];

      if (viewMode !== AnnualPlannerViewMode.Year) {
        setFilters({
          ...filters,
          [FilterKeys.IntersectDate]: getPlannerIntersectDateFilter(viewMode, period),
          [FilterKeys.SchoolDaysDate]: getPlannerSchoolDaysDateFilter(viewMode, period),
        });
      }
    },
    [filters, viewMode, viewPeriods],
  );

  const handleSchoolYearSelect = useCallback<AnnualPlannerContextProps['handleSchoolYearSelect']>(
    (yearId) => {
      const schoolYear = getSchoolYearById(yearId);
      if (!schoolYear) return;

      let mode = viewMode;
      let periods = getPlannerViewPeriods(mode, schoolYear);

      //in case there is no main period group in year
      if (!periods.length && viewMode === AnnualPlannerViewMode.Term) {
        mode = AnnualPlannerViewMode.Year;
        periods = getPlannerViewPeriods(mode, schoolYear);
      }

      const defaultViewPeriodIndex = getPlannerDefaultViewPeriodIndex(mode, periods);
      const isYearView = mode === AnnualPlannerViewMode.Year;
      const period = periods[defaultViewPeriodIndex];

      setCurrentViewPeriodIndex(defaultViewPeriodIndex);

      setFilters({
        ...filters,
        [FilterKeys.SchoolYear]: [yearId],
        [FilterKeys.PlannerView]: [mode],
        [FilterKeys.IntersectDate]: isYearView ? [] : getPlannerIntersectDateFilter(mode, period),
        [FilterKeys.SchoolDaysDate]: isYearView ? [] : getPlannerSchoolDaysDateFilter(mode, period),
      });
    },
    [filters, getSchoolYearById, viewMode],
  );

  useEffect(() => {
    if (!schoolYear && defaultValidity) {
      setFilters(filters);
    }
  }, [defaultValidity, filters, schoolYear]);

  //in case there is no main period group in year
  useEffect(() => {
    if (viewMode === AnnualPlannerViewMode.Term && !mainPeriodGroup) {
      handleViewModeSelect(AnnualPlannerViewMode.Year);
    }
  }, [handleViewModeSelect, mainPeriodGroup, viewMode]);

  useEffect(() => {
    if (!hasMounted.current) {
      trackPlannerViewModeEvents(initialFilters[FilterKeys.PlannerView][0], 'show');
      hasMounted.current = true;
    }
  }, [initialFilters]);

  const value = useMemo(
    () => ({
      hasSchoolAdminPermissions,
      hasEventsPermissions,
      hasAssessmentsPermissions,
      schoolYear,
      yearStart,
      yearEnd,
      filters,
      recordTypesMap,
      toggleRecordType,
      data,
      isLoading,
      isFetching,
      mainPeriodGroup,
      viewMode,
      handleViewModeSelect,
      viewPeriods,
      currentViewPeriodIndex,
      setCurrentViewPeriodIndex,
      handleViewPeriodChange,
      handleSchoolYearSelect,
    }),
    [
      hasSchoolAdminPermissions,
      hasEventsPermissions,
      hasAssessmentsPermissions,
      schoolYear,
      yearStart,
      yearEnd,
      filters,
      recordTypesMap,
      toggleRecordType,
      data,
      isLoading,
      isFetching,
      mainPeriodGroup,
      viewMode,
      handleViewModeSelect,
      viewPeriods,
      currentViewPeriodIndex,
      handleViewPeriodChange,
      handleSchoolYearSelect,
    ],
  );

  return <AnnualPlannerContext.Provider value={value}>{children}</AnnualPlannerContext.Provider>;
};

export const useAnnualPlanner = () => {
  return useContext(AnnualPlannerContext);
};
