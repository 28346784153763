import { AnnualPlanRecord } from '@schooly/api';
import { getDaysInMonth } from 'date-fns';
import { FC, useMemo } from 'react';

import useSchoolYears from '../../../../../hooks/useSchoolYears';
import { AnnualPlannerCalendarRecords } from '../../AnnualPlannerCalendar.styled';
import { AnnualPlannerCalendarWithDates } from '../../scheme';
import { useAnnualPlannerYearGrid } from '../../useAnnualPlannerGrid';
import { getAnnualPlannerRecordsByDate } from '../../utils';
import { AnnualPlannerRecordsLayoutCell } from './AnnualPlannerRecordsLayoutCell';

export interface AnnualPlannerRecordsLayoutYearProps extends AnnualPlannerCalendarWithDates {
  records?: AnnualPlanRecord[];
}

export const AnnualPlannerRecordsLayoutYear: FC<AnnualPlannerRecordsLayoutYearProps> = ({
  records = [],
  ...props
}) => {
  const { getSchoolYearById } = useSchoolYears();
  const { months } = useAnnualPlannerYearGrid(props);

  const data = useMemo(
    () => getAnnualPlannerRecordsByDate(records, getSchoolYearById),
    [getSchoolYearById, records],
  );

  return (
    <AnnualPlannerCalendarRecords>
      {months.map((month) =>
        new Array(getDaysInMonth(month)).fill(true).map((_, index) => {
          const day = index + 1;
          const date = new Date(month);
          date.setDate(day);

          return (
            <AnnualPlannerRecordsLayoutCell
              key={`cell-${month.getFullYear()}-${month.getMonth()}-${day}`}
              start={props.start}
              end={props.end}
              date={date}
              records={data}
            />
          );
        }),
      )}
    </AnnualPlannerCalendarRecords>
  );
};
