import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { eachDayOfInterval, format, isAfter, isBefore, isValid, max, min, parse } from 'date-fns';
import { RefObject, useCallback, useEffect } from 'react';

import { AnnualPlannerCreateRecordContextProps } from './AnnualPlannerCreatePopover/WithAnnualPlannerCreate';

export const useAnnualPlannerGridEvents = ({
  gridRef,
  openParamsRef,
  setOpenParams,
  open,
  validityStart,
  validityEnd,
}: {
  gridRef: RefObject<HTMLDivElement | null>;
  openParamsRef: AnnualPlannerCreateRecordContextProps['openParamsRef'];
  setOpenParams: AnnualPlannerCreateRecordContextProps['setOpenParams'];
  open: AnnualPlannerCreateRecordContextProps['open'];
  validityStart: Date;
  validityEnd: Date;
}) => {
  const resetHighlighters = useCallback(() => {
    gridRef.current
      ?.querySelectorAll<HTMLDivElement>('.AnnualPlannerCalendar-MainGrid-highlight')
      .forEach((highlighter) => {
        highlighter.classList.remove(
          'AnnualPlannerCalendar-MainGrid-highlight-noTitle',
          'AnnualPlannerCalendar-MainGrid-highlight-start',
          'AnnualPlannerCalendar-MainGrid-highlight-end',
          'AnnualPlannerCalendar-MainGrid-highlight-visible',
        );
        highlighter.style.display = 'none';
      });
  }, [gridRef]);

  const onCellMouseDown = (event: React.MouseEvent<HTMLDivElement>, date: Date) => {
    if (!openParamsRef) {
      return;
    }

    const cell = (event.target as HTMLElement).closest<HTMLDivElement>(
      '.AnnualPlannerCalendar-MainGrid-cell',
    );

    setOpenParams([cell, [date, date]]);

    resetHighlighters();

    const highlighter = cell?.querySelector<HTMLDivElement>(
      '.AnnualPlannerCalendar-MainGrid-highlight',
    );

    if (highlighter) {
      highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-start');
      highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-end');
      highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-visible');
      highlighter.style.display = 'block';
    }
  };

  useEffect(() => {
    const onMouseOver = (event: MouseEvent) => {
      if (!gridRef.current || !openParamsRef.current?.[1]?.[0]) {
        return;
      }

      // the grid cell might be behind other element
      const cell = document
        .elementsFromPoint(event.x, event.y)
        .find((element) => element.classList.contains('AnnualPlannerCalendar-MainGrid-cell'));

      if (!cell) {
        return;
      }

      let date = parse(cell.getAttribute('data-date') ?? '', DEFAULT_DATE_FORMAT_FNS, new Date());

      if (!isValid(date)) {
        return;
      }

      if (isBefore(date, validityStart)) {
        date = validityStart;
      } else if (isAfter(date, validityEnd)) {
        date = validityEnd;
      }

      const start = min([openParamsRef.current[1][0], date]);
      const end = max([openParamsRef.current[1][0], date]);

      resetHighlighters();

      eachDayOfInterval({ start, end }).forEach((date, index, arr) => {
        const highlighter = gridRef.current?.querySelector<HTMLDivElement>(
          `.AnnualPlannerCalendar-MainGrid-cell[data-date="${format(
            date,
            DEFAULT_DATE_FORMAT_FNS,
          )}"] .AnnualPlannerCalendar-MainGrid-highlight`,
        );

        if (highlighter) {
          if (index > 0 && index < arr.length - 1) {
            // excluding first and last cells which should stay with their title
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-noTitle');
          }

          if (index === 0) {
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-start');
          }

          if (index === arr.length - 1) {
            highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-end');
          }
          highlighter.classList.add('AnnualPlannerCalendar-MainGrid-highlight-visible');
          highlighter.style.display = 'block';
        }
      });
    };

    const onMouseUp = (event: MouseEvent) => {
      if (!openParamsRef.current) {
        return;
      }

      // the grid cell might be behind other element
      const cell = document
        .elementsFromPoint(event.x, event.y)
        .find((element) => element.classList.contains('AnnualPlannerCalendar-MainGrid-cell'));

      if (!cell || !openParamsRef.current[1]) {
        setOpenParams(undefined);
        resetHighlighters();
        return;
      }

      let date = parse(cell.getAttribute('data-date') ?? '', DEFAULT_DATE_FORMAT_FNS, new Date());

      if (!isValid(date)) {
        setOpenParams(undefined);
        resetHighlighters();
        return;
      }

      let dateIsValid = true;

      if (isBefore(date, validityStart)) {
        date = validityStart;
        dateIsValid = false;
      } else if (isAfter(date, validityEnd)) {
        date = validityEnd;
        dateIsValid = false;
      }

      const paramsDate = openParamsRef.current[1][0];
      // should open a popover under the earliest date, instead of from what
      // the selection has started
      const openForCurrentCell = isBefore(date, paramsDate);
      const selectionRange = openForCurrentCell ? [date, paramsDate] : [paramsDate, date];

      const anchorCell = openForCurrentCell ? cell : openParamsRef.current[0];

      if (dateIsValid || !openForCurrentCell) {
        open(anchorCell as HTMLDivElement, selectionRange);
      } else {
        const validDateCell = gridRef.current?.querySelector<HTMLDivElement>(
          `.AnnualPlannerCalendar-MainGrid-cell[data-date="${format(
            date,
            DEFAULT_DATE_FORMAT_FNS,
          )}"]`,
        );

        if (!validDateCell) {
          setOpenParams(undefined);
          resetHighlighters();
          return;
        }

        open(validDateCell as HTMLDivElement, selectionRange);
      }

      setOpenParams(undefined);
      resetHighlighters();
    };

    document.addEventListener('mouseover', onMouseOver);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseover', onMouseOver);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [gridRef, open, openParamsRef, resetHighlighters, setOpenParams, validityEnd, validityStart]);

  return { onCellMouseDown };
};
