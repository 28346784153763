export enum LeavingReasonType {
  Predefined = 'predefined',
  Other = 'other',
}

export type LeavingReason = {
  id: string;
  type: LeavingReasonType.Predefined;
  title: string;
  order: number;
  archived: boolean;
  is_used: boolean;
};

export type OtherLeavingReason = {
  type: LeavingReasonType.Other;
  title: string;
};

export type LeavingReasonSet =
  | {
      type: LeavingReasonType.Other;
      title: string;
    }
  | {
      type: LeavingReasonType.Predefined;
      id: string;
    };
