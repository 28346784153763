import { Stack } from '@mui/material';
import { PayableFeeStatus } from '@schooly/api';
import { FC, useCallback, useRef } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderFeeStatusTags } from './FeeStatusExpandedSelect';

type FeeStatusSelectProps = {
  selectedStatuses: PayableFeeStatus[];
  onSelectStatus: (v: PayableFeeStatus) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const PAYABLE_FEE_STATUSES: PayableFeeStatus[] = [
  'upcoming',
  'paid',
  'partially_paid',
  'overdue',
  'due',
  // Hidden from select to avoid BE inconsistency while filtering fees
  // 'voided',
  'cancelled',
];

export const FeeStatusSelectMultiple: FC<FeeStatusSelectProps> = ({
  selectedStatuses,
  onSelectStatus,
  placeholder,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderFeeStatusTags({
          statuses: PAYABLE_FEE_STATUSES,
          tagProps: (status) => ({
            variant: selectedStatuses.includes(status) ? 'filled' : undefined,
          }),
          onClick: onSelectStatus,
        })}
      </Stack>
    );
  }, [onSelectStatus, selectedStatuses]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      placeholder={placeholder}
      hasValues={selectedStatuses.length > 0}
      renderContent={renderContent}
    >
      {() =>
        renderFeeStatusTags({
          statuses: selectedStatuses,
          tagProps: { sx: { maxWidth: 200 }, size: 'small' },
          onDelete: onSelectStatus,
        })
      }
    </DropdownSelect>
  );
};
