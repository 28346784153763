/* eslint-disable simple-import-sort/imports */
// The general stylesheets must be imported before the ./App to ensure correct stylesheets order

import '@schooly/style/src/css/custom_bootstrap.scss';
import '@schooly/style/src/css/index.scss';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import * as Sentry from '@sentry/react';

import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { init } from '@schooly/api';

import packageInfo from '@schooly/package-info';
import App from './App';
import {
  AWS_CONFIG,
  BUILD_DATE,
  DEBUG,
  COMMIT_SHA,
  LANGUAGE,
  SERVER,
  SENTRY_DSN,
  IS_DEV,
  SENTRY_ENVIRONMENT_NAME,
  AMPLITUDE_API_KEY,
} from './config';
import { WithMaintenance } from './context/maintenance/WithMaintenance';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { IntlProviderWithMessages } from '@schooly/utils/translations';
import { queryClient, persistOptions } from './queryClient';
import { initDateDefaultOptions } from '@schooly/utils/date';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { DEFAULT_SESSION_REPLAY_SAMPLE_RATE } from '@schooly/analytics';
init({ baseURL: SERVER, verbose: DEBUG });

// AWS cognito and S3 configuration
Amplify.configure(AWS_CONFIG);

declare global {
  interface Window {
    INIT_URL: URL;
  }
}

window.INIT_URL = new URL(window.location.href);

/* eslint-disable no-console */
console.log('');
console.group('Schooly Web App');
console.log(
  `App version: ${packageInfo.version}${
    BUILD_DATE ? ` (built at ${moment(BUILD_DATE).format()})` : ''
  }`,
);
if (COMMIT_SHA) {
  console.log('Latest commit from the build:');
  console.log(`https://github.com/myTreehive/treehive-web/commit/${COMMIT_SHA}`);
}
console.groupEnd();
console.log('');
/* eslint-enable no-console */

if (!IS_DEV) {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: DEFAULT_SESSION_REPLAY_SAMPLE_RATE,
  });
  amplitude.add(sessionReplayTracking);
  amplitude.init(AMPLITUDE_API_KEY, { fetchRemoteConfig: true, autocapture: true });
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT_NAME,
    debug: IS_DEV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
    tracesSampleRate: 0.25,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [SERVER],
    release: packageInfo.version,

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.5,
  });
}

initDateDefaultOptions(LANGUAGE);

ReactDOM.render(
  <React.StrictMode>
    <PersistQueryClientProvider persistOptions={persistOptions} client={queryClient}>
      <Provider store={store}>
        <IntlProviderWithMessages overrideLanguage={LANGUAGE}>
          <BrowserRouter>
            <WithMaintenance>
              <App />
            </WithMaintenance>
          </BrowserRouter>
        </IntlProviderWithMessages>
      </Provider>
      {/* <ReactQueryDevtools
        toggleButtonProps={{ style: { transform: 'scale(0.5)' } }}
        initialIsOpen={false}
      /> */}
    </PersistQueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
