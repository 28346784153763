import { ATTENDANCE_CODE_MAX_LENGTH } from '@schooly/constants';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import {
  AddAttendanceCodeModalContent,
  AddAttendanceCodeModalContentProps,
} from './AddAttendanceCodeModalContent';

const getEmptyCode = () => ({
  id: undefined,
  name: '',
  description: '',
  code: '',
  in_school: true,
  is_present: true,
  has_push_notifications: false,
});

export const AddAttendanceCodeModal: FC = () => {
  const { formatMessage } = useIntl();

  const {
    codeId,
    isEditing,
    attendanceCodes,
    saveAttendanceCode,
    deleteAttendanceCode,
    isLoading,
    isDeleting,
    onClose,
  } = useAttendanceCodes();

  const code = useMemo(() => {
    if (!codeId) {
      return getEmptyCode();
    }

    return attendanceCodes.find((c) => c.id === codeId) || getEmptyCode();
  }, [codeId, attendanceCodes]);

  const validateCode = useCallback<AddAttendanceCodeModalContentProps['validateCode']>(
    (code, id) => {
      if (code.length > ATTENDANCE_CODE_MAX_LENGTH) {
        return formatMessage({ id: 'attendance-CodeError-MaxLength' });
      }

      const isDuplicate = attendanceCodes.some((c) => c.code === code && c.id !== id);
      if (isDuplicate) {
        return formatMessage({ id: 'attendance-CodeError-AlreadyUsed' });
      }

      return true;
    },
    [attendanceCodes, formatMessage],
  );

  return (
    <AddAttendanceCodeModalContent
      code={code}
      onClose={onClose}
      isLoading={isLoading}
      isDeleting={isDeleting}
      isEditing={isEditing}
      onSubmit={saveAttendanceCode}
      onDelete={() => deleteAttendanceCode(code)}
      validateCode={validateCode}
      canEditNotification
    />
  );
};
