import { Stack } from '@mui/material';
import { PageHeader } from '@schooly/components/filters';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { AnnualPlannerCalendar } from './AnnualPlannerCalendar/AnnualPlannerCalendar';
import { AnnualPlannerHeader } from './AnnualPlannerHeader';
import { AnnualPlannerPageFilter } from './AnnualPlannerPageFilter';
import { AnnualPlannerPageSchoolDays } from './AnnualPlannerPageSchoolDays';
import { AnnualPlannerPageSchoolYear } from './AnnualPlannerPageSchoolYear';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageContent: FC = () => {
  const {
    isLoading,
    data,
    currentViewPeriodIndex = 0,
    viewPeriods,
    viewMode,
    schoolYear,
  } = useAnnualPlanner();
  const currentPeriod = viewPeriods[currentViewPeriodIndex];

  return (
    <MainLayout>
      <PageHeader
        pageTitle={
          <Stack direction="row" gap={1}>
            <FormattedMessage id="annualPlanner-PageTitle" />
            <AnnualPlannerPageSchoolYear />
          </Stack>
        }
        actionsContent={<AnnualPlannerPageSchoolDays />}
      />

      <AnnualPlannerPageFilter />

      {schoolYear && currentPeriod && (
        <>
          <AnnualPlannerHeader />
          <AnnualPlannerCalendar
            start={currentPeriod.start}
            end={currentPeriod.end}
            records={data?.records}
            loading={isLoading}
            viewMode={viewMode}
            schoolYear={schoolYear}
          />
        </>
      )}
    </MainLayout>
  );
};
