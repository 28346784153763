import { Box, IconButton, Stack, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import {
  ATTENDANCE_CODE_IN_SCHOOl_OPTIONS,
  ATTENDANCE_CODE_IS_PRESENT_OPTIONS,
  ATTENDANCE_CODE_MAX_LENGTH,
} from '@schooly/constants';
import {
  Counter,
  CrossIcon,
  DragIcon,
  EditIcon,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  ModalContent,
  ModalLarge,
  PlusIcon,
  SimpleButton,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { AttendanceCodeForm } from '../../../hooks/useAttendanceCodes';
import {
  AddAttendanceCodeModalContent,
  AddAttendanceCodeModalContentProps,
} from '../SchoolAttendance/SchoolAttendanceCodes/AddAttendanceCodeModalContent';
import {
  normalizeInSchoolValue,
  normalizeIsPresentValue,
} from '../SchoolAttendance/SchoolAttendanceCodes/AttendanceCodeRow';

type AttendanceCodeCreate = Omit<AttendanceCode, 'id' | 'order'>;

type SchoolCreateAttendanceCodesModalProps = {
  title?: string;
  codes: AttendanceCodeCreate[];
  onClose: () => void;
  onSubmit: (form: SchoolCreateAttendanceCodesModalForm) => void;
};

export type SchoolCreateAttendanceCodesModalForm = {
  codes: AttendanceCodeCreate[];
};

export const SchoolCreateAttendanceCodesModal: FC<SchoolCreateAttendanceCodesModalProps> = ({
  title,
  codes: initialCodes,
  onClose,
  onSubmit,
}) => {
  const { $t, formatMessage } = useIntl();
  const form = useForm<SchoolCreateAttendanceCodesModalForm>({
    defaultValues: {
      codes: initialCodes,
    },
  });
  const [editingCodeIndex, setEditingCodeIndex] = useState<number | null>(
    initialCodes.length ? null : -1,
  );

  const {
    fields: codes,
    append,
    remove,
    move,
    update,
  } = useFieldArray({
    control: form.control,
    name: 'codes',
  });

  useEffect(() => {
    onSubmit({ codes });
  }, [codes, onSubmit]);

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const editCodeWithActions = useMemo(() => {
    if (editingCodeIndex === null) return;

    const onClose = () => setEditingCodeIndex(null);

    const validateCode: AddAttendanceCodeModalContentProps['validateCode'] = (code, id) => {
      if (code.length > ATTENDANCE_CODE_MAX_LENGTH) {
        return formatMessage({ id: 'attendance-CodeError-MaxLength' });
      }

      const isDuplicate = codes.some((c) => c.code === code && c.id !== id);
      if (isDuplicate) {
        return formatMessage({ id: 'attendance-CodeError-AlreadyUsed' });
      }

      return true;
    };

    if (editingCodeIndex === -1) {
      return {
        code: generateEmptyAttendanceCode(),
        onSubmit: (v: AttendanceCodeForm) => {
          append({
            ...v,
            in_school: !!v.in_school,
            is_present: !!v.is_present,
            has_push_notifications: !!v.has_push_notifications,
          });
          onClose();
        },
        onClose,
        isEditing: false,
        validateCode,
      };
    }

    const relatedCode = codes[editingCodeIndex];

    if (!relatedCode) return;

    return {
      code: relatedCode,
      onSubmit: (v: AttendanceCodeForm) => {
        update(editingCodeIndex, {
          ...v,
          in_school: !!v.in_school,
          is_present: !!v.is_present,
          has_push_notifications: !!v.has_push_notifications,
        });
        onClose();
      },
      onDelete: () => {
        remove(editingCodeIndex);
        onClose();
      },
      onClose,
      isEditing: true,
      validateCode,
    };
  }, [append, codes, editingCodeIndex, formatMessage, remove, update]);

  useEffect(() => {
    if (!!editCodeWithActions || codes.length) return;
    onClose();
  }, [editCodeWithActions, onClose, codes.length]);

  return (
    <>
      <ModalLarge open={!editCodeWithActions} onClose={onClose}>
        <ModalHeader active title={title}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Typography variant="h2">{$t({ id: 'attendance-Codes' })}</Typography>
              <Counter sx={{ minWidth: 20, textAlign: 'center', fontWeight: 600 }}>
                {codes.length}
              </Counter>
            </Stack>
            <SimpleButton startIcon={<PlusIcon />} onClick={() => setEditingCodeIndex(-1)}>
              {$t({ id: 'attendance-AddCodes' })}
            </SimpleButton>
          </Stack>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <Stack mt={3} {...provided.droppableProps} ref={provided.innerRef}>
                  {codes?.map((code, i) => {
                    const inSchoolLabel = ATTENDANCE_CODE_IN_SCHOOl_OPTIONS.find(
                      ({ value }) => normalizeInSchoolValue(code.in_school) === value,
                    )?.labelTextId;
                    const isPresentLabel = ATTENDANCE_CODE_IS_PRESENT_OPTIONS.find(
                      ({ value }) => normalizeIsPresentValue(code.is_present) === value,
                    )?.labelTextId;

                    return (
                      <Draggable key={code.id} draggableId={code.id} index={i}>
                        {(provided) => (
                          <GridRowStyled
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={provided.draggableProps.style}
                            key={code.id}
                            sx={{
                              '& :hover': {
                                '.editRoleIcon': {
                                  visibility: 'visible',
                                },
                              },
                            }}
                          >
                            <GridRowItem
                              gap={2}
                              sx={{
                                minHeight: 44,
                                '& > .MuiTypography-root': { overflow: 'hidden' },
                              }}
                            >
                              {codes.length > 1 && (
                                <IconButton {...provided.dragHandleProps} inverse>
                                  <DragIcon />
                                </IconButton>
                              )}

                              <GridRowName sx={{ flex: '1 1 30%' }}>
                                <Stack flexDirection="row" gap={1.25} alignItems="center">
                                  <TypographyWithOverflowHint variant="h3">
                                    {code.name}
                                  </TypographyWithOverflowHint>
                                </Stack>
                              </GridRowName>
                              <GridRowName sx={{ flex: '1 1 15%' }}>
                                <Typography>
                                  {isPresentLabel && <FormattedMessage id={isPresentLabel} />}
                                </Typography>
                              </GridRowName>
                              <GridRowName sx={{ flex: '1 1 15%' }}>
                                <Typography>
                                  {inSchoolLabel && <FormattedMessage id={inSchoolLabel} />}
                                </Typography>
                              </GridRowName>
                              <GridRowName sx={{ flex: '1 1 10%' }}>
                                <Typography>{code.code}</Typography>
                              </GridRowName>
                              <GridRowName sx={{ flex: '1 1 40%' }}>
                                <Box>
                                  <TypographyWithOverflowHint>
                                    {code.description}
                                  </TypographyWithOverflowHint>
                                </Box>
                              </GridRowName>
                              <IconButton
                                className="editRoleIcon"
                                sx={{ visibility: 'hidden' }}
                                inverse
                                onClick={() => setEditingCodeIndex(i)}
                              >
                                <EditIcon />
                              </IconButton>
                            </GridRowItem>
                          </GridRowStyled>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </ModalContent>
      </ModalLarge>
      {!!editCodeWithActions && (
        <AddAttendanceCodeModalContent
          {...editCodeWithActions}
          isLoading={false}
          isDeleting={false}
          canEditNotification={false}
        />
      )}
    </>
  );
};

export const generateEmptyAttendanceCode = (): AttendanceCodeCreate => ({
  name: '',
  description: '',
  code: '',
  in_school: true,
  is_present: true,
  has_push_notifications: false,
});
