import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.98 3.84374e-05C10.98 3.84374e-05 9.72085 2.12187 10.8118 4.29278C11.9035 6.46372 12.8606 8.17439 12.6417 9.99963C12.6417 9.99963 13.1958 7.03921 16 6.2657C16 6.2657 14.959 8.18981 15.5296 10.9042C16.1002 13.6179 14.8074 18.6839 9.501 20C9.501 20 14.2029 17.5493 10.8275 13.5686C10.8275 13.5686 10.7438 15.5264 9.0056 16.1097C9.0056 16.1097 11.7424 11.5454 7.71263 7.30192C7.71263 7.30192 8.63613 10.2463 5.54635 12.763C2.45658 15.2798 3.98497 19.3256 5.76476 19.8844C5.76476 19.8844 -3.94134 15.6409 1.8352 6.89119C1.8352 6.89119 2.03704 8.88126 3.51465 9.65462C3.51465 9.65462 3.29624 1.11837 10.9792 0L10.98 3.84374e-05Z"
      fill="#24275B"
    />
  </svg>
);
