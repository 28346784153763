import { Icon, Typography } from '@mui/material';
import { AssignedProductAvailableVariant } from '@schooly/api';
import {
  CellDropdownSelect,
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
} from '@schooly/components/filters';
import { ArrowDownV2Icon } from '@schooly/style';
import { FC, useCallback, useRef } from 'react';

type ProductTypeSelectProps = {
  selectedTypeName: string;
  options: AssignedProductAvailableVariant[];
  onSelect: (v: AssignedProductAvailableVariant) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ProductTypeSelect: FC<ProductTypeSelectProps> = ({
  selectedTypeName,
  options,
  onSelect,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const renderContent = useCallback(() => {
    return (
      <>
        {options.map((o) => (
          <DropdownSelectRow
            key={o.type_id}
            isSelected={selectedTypeName === o.type_name}
            onClick={() => {
              onSelect(o);
              dropdown.current?.close();
            }}
          >
            {o.type_name}
          </DropdownSelectRow>
        ))}
      </>
    );
  }, [onSelect, options, selectedTypeName]);

  return (
    <CellDropdownSelect
      ref={dropdown}
      hasValues
      renderContent={renderContent}
      disabled={options.length === 1}
      renderRightIcon={
        options.length === 1
          ? () => <></>
          : () => (
              <Icon
                sx={(theme) => ({
                  width: theme.spacing(1),
                  transform: 'rotate(180deg)',
                  path: { stroke: theme.palette.common.grey },
                })}
              >
                <ArrowDownV2Icon />
              </Icon>
            )
      }
      {...dropdownProps}
    >
      {() => {
        return (
          <Typography color="text.primary" noWrap>
            {selectedTypeName}
          </Typography>
        );
      }}
    </CellDropdownSelect>
  );
};
