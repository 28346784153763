import { Stack } from '@mui/material';
import { AssessmentMethodType } from '@schooly/api';
import { PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useState } from 'react';
import { Controller, get, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { AssessmentForm, getAssessmentFormErrorTextId } from '../AssessmentCreateContent';
import { getDefaultAssessmentMethod } from '../useAssessmentCreateModal';
import { useWithAssessmentFormValidation } from '../useAssessmentFormValidation';
import { AssessmentsCreateModalSettingsComment } from './AssessmentsCreateModalSettingsComment';
import { AssessmentsCreateModalSettingsDefault } from './AssessmentsCreateModalSettingsDefault';
import { AssessmentsCreateModalSettingsMethodType } from './AssessmentsCreateModalSettingsMethodType';
import { AssessmentsGradeControl } from './AssessmentsGradeControl';
import { AssessmentsScoreControl } from './AssessmentsScoreControl';

type AssessmentsCreateModalSettingsMethodsProps = {
  opened: boolean;
};

export const AssessmentsCreateModalSettingsMethods: FC<AssessmentsCreateModalSettingsMethodsProps> =
  ({ opened }) => {
    const [autoFocus, setAutoFocus] = useState<AssessmentMethodType | null>();
    const { $t } = useIntl();
    const { control, formState, watch, clearErrors } = useFormContext<AssessmentForm>();
    const formMethods = watch('methods');

    const {
      fields: methods,
      append: appendMethod,
      remove: removeMethod,
      update,
    } = useFieldArray({
      control: control,
      name: 'methods',
    });

    const filteredMethods = formMethods?.filter((method) => method.method_type != null) ?? [];
    const blankMethod = formMethods?.find((method) => method.method_type == null);

    const { onValidateMethod } = useWithAssessmentFormValidation();

    const handleAddMethod = useCallback(() => {
      appendMethod({ method_type: null });
      setAutoFocus(null);
    }, [appendMethod]);

    const handleMethodChange = (index: number, methodType?: AssessmentMethodType) => {
      if (methodType != null) {
        update(index, getDefaultAssessmentMethod(methodType));
      }

      if (formState.errors.methods?.[index]) {
        clearErrors(`methods.${index}`);
      }

      if (methodType === AssessmentMethodType.Score || methodType === AssessmentMethodType.Grade) {
        setAutoFocus(methodType);
      }
    };

    const handleMethodDelete = (index: number) => {
      removeMethod(index);
    };

    return (
      <Stack
        alignItems="flex-start"
        gap={2}
        width="100%"
        height="100%"
        sx={{
          '&&& .MuiAutocomplete-endAdornment': {
            top: (theme) => theme.spacing(2.75),
          },
        }}
      >
        <Controller
          control={control}
          name={'methods'}
          rules={{
            required: $t({ id: getAssessmentFormErrorTextId('methods') }),
          }}
          render={({ field }) => {
            const error = get(formState.errors, field.name)?.root;

            if (!methods?.length) {
              return (
                <AssessmentsCreateModalSettingsMethodType
                  onChange={(methodType) => {
                    if (methodType != null) {
                      appendMethod(getDefaultAssessmentMethod(methodType));
                    }

                    if (methodType !== AssessmentMethodType.Comment) {
                      setAutoFocus(methodType);
                    }
                  }}
                  error={error}
                  autoFocus={autoFocus === null || opened}
                />
              );
            }

            return (
              <>
                {methods.map((method, index) => {
                  const isAutoFocus = autoFocus === method.method_type;

                  switch (method.method_type) {
                    case AssessmentMethodType.Score:
                      return (
                        <AssessmentsScoreControl
                          key={method.id}
                          index={index}
                          autoFocus={isAutoFocus}
                          onMethodChange={(methodType) => handleMethodChange(index, methodType)}
                          onDelete={() => handleMethodDelete(index)}
                        />
                      );
                    case AssessmentMethodType.Grade:
                      return (
                        <AssessmentsGradeControl
                          key={method.id}
                          index={index}
                          autoFocus={isAutoFocus}
                          onMethodChange={(methodType) => handleMethodChange(index, methodType)}
                          onDelete={() => handleMethodDelete(index)}
                          onValidate={onValidateMethod}
                        />
                      );
                    case AssessmentMethodType.Comment:
                      return (
                        <AssessmentsCreateModalSettingsComment
                          key={method.id}
                          method={method}
                          autoFocus={isAutoFocus}
                          onMethodChange={(methodType) => handleMethodChange(index, methodType)}
                          onDelete={() => handleMethodDelete(index)}
                        />
                      );
                    default:
                      return (
                        <AssessmentsCreateModalSettingsDefault
                          key={`blank-${index}`}
                          onMethodChange={(methodType) => handleMethodChange(index, methodType)}
                          onDelete={() => handleMethodDelete(index)}
                          autoFocus={isAutoFocus}
                        />
                      );
                  }
                })}
              </>
            );
          }}
        />
        {!blankMethod && filteredMethods.length < 3 && !!methods?.length && (
          <SimpleButton startIcon={<PlusIcon />} onClick={handleAddMethod}>
            <FormattedMessage id="assessments-AssessmentAddType" />
          </SimpleButton>
        )}
      </Stack>
    );
  };
