import {
  RegistrationStatus,
  RegistrationStatusUpdate,
  SchoolProperty,
  SchoolYear,
  StaffRegistration,
  StudentRegistration,
  UserType,
} from '@schooly/api';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { format, isAfter, isSameDay } from 'date-fns';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../config';
import formatDate from '../utils/formatDate';

export function getActualRegistrationStatus(registration: StudentRegistration | StaffRegistration) {
  const now = Date.now();

  const relevantDateStatuses = registration.statuses.filter(
    ({ applies_from, applies_to }) =>
      moment(applies_from).isSameOrBefore(now) && moment(applies_to).isSameOrAfter(now),
  );

  if (!relevantDateStatuses) {
    return undefined;
  }
  if (relevantDateStatuses.length === 1) {
    return relevantDateStatuses[0];
  }

  // If multiple statuses are found, return the latest one
  return relevantDateStatuses[relevantDateStatuses.length - 1];
}

export function getActualRegistrations(
  registrations: (StudentRegistration | StaffRegistration)[] | undefined,
) {
  if (!registrations) {
    return [];
  }

  const validRegistrations = registrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration);

    return actualStatus && actualStatus.school_property.category;
  });

  const applyingOrCurrentRegistrations = validRegistrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration)!;

    const category = actualStatus.school_property.category!;

    return (
      (category.current === false && category.final === false) ||
      category.current === true ||
      category.final === false
    );
  });

  if (applyingOrCurrentRegistrations.length) {
    return applyingOrCurrentRegistrations;
  }

  // If user has no applying or current registrations, check for final registrations
  const finalRegistrations = validRegistrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration)!;

    const category = actualStatus.school_property.category!;
    return category.current === false && category.final === true;
  });

  if (finalRegistrations.length) {
    return finalRegistrations;
  }

  // If user has no final registrations, check for the remaining invalid/no status registrations
  return registrations.filter((registration) => {
    const actualStatus = getActualRegistrationStatus(registration);

    return !actualStatus || !actualStatus.school_property.category;
  });
}

export function getSortedStatuses(statuses: RegistrationStatus[]): RegistrationStatus[] {
  return statuses.slice(0).sort((statusA, statusB) => {
    const statusAMoment = moment(statusA.applies_from, DEFAULT_DATE_FORMAT);
    const statusBMoment = moment(statusB.applies_from, DEFAULT_DATE_FORMAT);

    // If two statuses have the same date, respect the initial array order
    if (statusAMoment.isSame(statusBMoment)) {
      const statusAIndex = statuses.findIndex(({ id }) => id === statusA.id);
      const statusBIndex = statuses.findIndex(({ id }) => id === statusB.id);

      return statusAIndex - statusBIndex;
    }

    return statusAMoment.diff(statusBMoment, 'days');
  });
}

export function getDisplayStatus(
  statuses: RegistrationStatus[],
  filterDate?: string,
): RegistrationStatus {
  let sortedStatuses = getSortedStatuses(statuses);

  if (filterDate) {
    sortedStatuses = sortedStatuses.filter(({ applies_from }) =>
      moment(applies_from, DEFAULT_DATE_FORMAT).isSameOrBefore(
        moment(filterDate, DEFAULT_DATE_FORMAT),
      ),
    );
  }

  return sortedStatuses[sortedStatuses.length - 1];
}

export function getLeavingNoticeStatus(statuses: RegistrationStatus[]) {
  const leavingStatus = statuses.find((status) => status.created_by?.user_id);
  // we need status only if it was created py parent
  return leavingStatus;
  // return leavingStatus?.created_by.role === SchoolUserRole.Parent ? leavingStatus : undefined;
}

export function getSortedStudentRegistrations(registrations: StudentRegistration[] | undefined) {
  if (!registrations) {
    return [];
  }

  return registrations.slice(0).sort((registrationA, registrationB) => {
    const schoolYearAMoment = moment(registrationA.school_year.start, DEFAULT_DATE_FORMAT);
    const schoolYearBMoment = moment(registrationB.school_year.start, DEFAULT_DATE_FORMAT);

    const statusAMoment = moment(
      getDisplayStatus(registrationA.statuses)?.applies_from,
      DEFAULT_DATE_FORMAT,
    );
    const statusBMoment = moment(
      getDisplayStatus(registrationB.statuses)?.applies_from,
      DEFAULT_DATE_FORMAT,
    );

    if (schoolYearAMoment.isAfter(schoolYearBMoment)) {
      return -1;
    }

    if (schoolYearAMoment.isBefore(schoolYearBMoment)) {
      return 1;
    }

    if (statusAMoment.isAfter(statusBMoment)) {
      return -1;
    }

    if (statusAMoment.isBefore(statusBMoment)) {
      return 1;
    }

    return 0;
  });
}
export type RegistrationTuple<R> = [R[] | undefined, R[] | undefined, R[] | undefined] | [];

export function getRegistrationsByTimePeriods<R extends StudentRegistration | StaffRegistration>(
  registrations: R[] | undefined,
  userType: UserType,
): RegistrationTuple<R> {
  if (!registrations) {
    return [];
  }

  const now = Date.now();

  if (userType === 'student') {
    const current = (registrations as StudentRegistration[]).filter(
      ({ school_year }) =>
        moment(school_year?.start, DEFAULT_DATE_FORMAT).isSameOrBefore(now) &&
        moment(school_year?.end, DEFAULT_DATE_FORMAT).isSameOrAfter(now),
    );

    const future = (registrations as StudentRegistration[]).filter(
      ({ id, school_year }) =>
        moment(school_year?.start, DEFAULT_DATE_FORMAT).isAfter(now) &&
        !current.map((c) => c.id).includes(id),
    );

    const previous = (registrations as StudentRegistration[]).filter(
      ({ id, school_year }) =>
        moment(school_year?.end, DEFAULT_DATE_FORMAT).isBefore(now) &&
        !current.map((c) => c.id).includes(id),
    );

    return [
      getSortedStudentRegistrations(current),
      getSortedStudentRegistrations(future),
      getSortedStudentRegistrations(previous),
    ] as RegistrationTuple<R>;
  }

  const current = (registrations as StaffRegistration[]).filter(({ statuses }) => {
    const currentStatus = statuses.find(
      ({ school_property }) =>
        school_property.category?.current && !school_property.category?.final,
    );
    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    return (
      currentStatus &&
      moment(currentStatus.applies_from, DEFAULT_DATE_FORMAT).isBefore(now) &&
      (!finalStatus || moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now))
    );
  });

  const future = (registrations as StaffRegistration[]).filter(({ id, statuses }) => {
    if (current.map((c) => c.id).includes(id)) {
      return false;
    }

    const currentStatus = statuses.find(
      ({ school_property }) =>
        school_property.category?.current && !school_property.category?.final,
    );
    const applyingStatus = statuses.find(
      ({ school_property }) =>
        !school_property.category?.current && !school_property.category?.final,
    );

    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    if (currentStatus) {
      return moment(currentStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now);
    }

    if (finalStatus) {
      return moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isAfter(now);
    }

    return !!applyingStatus;
  });

  const previous = (registrations as StaffRegistration[]).filter(({ id, statuses }) => {
    const finalStatus = statuses.find(({ school_property }) => school_property.category?.final);

    return (
      (!finalStatus || moment(finalStatus.applies_from, DEFAULT_DATE_FORMAT).isBefore(now)) &&
      !current.map((c) => c.id).includes(id) &&
      !future.map((c) => c.id).includes(id)
    );
  });

  return [current, future, previous] as RegistrationTuple<R>;
}

export function validateStatuses(
  statuses: RegistrationStatusUpdate[],
  userType: SchoolUserRole,
  schoolProperties?: SchoolProperty[],
  selectedSchoolYear?: SchoolYear,
) {
  const errors: Record<string, any>[] = [];

  if (!statuses) return errors;

  statuses.forEach(({ school_property_id, applies_from }, index) => {
    const statusProperty = schoolProperties?.find(({ id }) => id === school_property_id);

    const appliesFromDateMoment = moment(applies_from, DEFAULT_DATE_FORMAT);

    if (selectedSchoolYear && userType === SchoolUserRole.Student) {
      if (appliesFromDateMoment.isAfter(moment(selectedSchoolYear.end, DEFAULT_DATE_FORMAT))) {
        errors[index] = {
          ...errors[index],
          applies_from: {
            messageTextId: 'error-StatusDateBoundsEnd',
            messageValues: {
              date: formatDate(selectedSchoolYear.end),
            },
          },
        };
        return;
      }

      if (
        statusProperty?.category?.current &&
        appliesFromDateMoment.isBefore(moment(selectedSchoolYear.start, DEFAULT_DATE_FORMAT))
      ) {
        errors[index] = {
          ...errors[index],
          applies_from: {
            messageTextId: 'error-StatusDateBoundsStart',
            messageValues: {
              date: formatDate(selectedSchoolYear.start),
            },
          },
        };
        return;
      }
    }

    if (index === 0) {
      return;
    }

    const previousDate = statuses[index - 1].applies_from;
    if (appliesFromDateMoment.isBefore(previousDate)) {
      errors[index] = {
        ...errors[index],
        applies_from: {
          messageTextId: 'error-StatusDateOrder',
        },
      };
    }
  });

  return errors;
}

export function isStudentRegistration(
  registration?: StudentRegistration | StaffRegistration,
): registration is StudentRegistration {
  return !!registration && 'school_year' in registration;
}

export const getRegistrationStatusesByType = (registration: StudentRegistration) => {
  return [
    registration.statuses.filter(
      (status) =>
        status.school_property.category?.name === 'Former' ||
        status.school_property.category?.name === 'Unsuccessful',
    ),
    registration.statuses.filter((status) => status.school_property.category?.name === 'Current'),
    registration.statuses.filter(
      (status) => status.school_property.category?.name === 'Prospective',
    ),
  ];
};

interface RegistrationActualStatus {
  status?: RegistrationStatus;
  registration: StudentRegistration;
}

const APPLIES_DATE_FORMAT = 'dd MMM yyyy';

const getActualRegistrationsStatuses = (registrations: StudentRegistration[]) => {
  return registrations
    .map<RegistrationActualStatus>((registration) => {
      const [formerStatuses, currentStatuses, prospectiveStatuses] =
        getRegistrationStatusesByType(registration);

      const lastRegistrationStatus = registration.statuses[registration.statuses.length - 1];
      if (
        formerStatuses.length &&
        lastRegistrationStatus.school_property.category?.final &&
        (isAfter(new Date(), new Date(lastRegistrationStatus.applies_from)) ||
          isSameDay(new Date(), new Date(lastRegistrationStatus.applies_from)))
      ) {
        return {
          status: lastRegistrationStatus,
          registration,
        };
      } else if (currentStatuses.length) {
        return {
          status: currentStatuses[currentStatuses.length - 1],
          registration,
        };
      }

      return {
        status: prospectiveStatuses[prospectiveStatuses.length - 1],
        registration,
      };
    })
    .sort(
      (a, b) =>
        new Date(a.status?.applies_from || '').getTime() -
        new Date(b.status?.applies_from || '').getTime(),
    );
};

const checkRegistrationStatuses = (registrations: StudentRegistration[], startingDate?: string) => {
  const actualRegistationsStatuses = getActualRegistrationsStatuses(registrations);

  const lastStatus = actualRegistationsStatuses[actualRegistationsStatuses.length - 1];
  if (lastStatus.status?.school_property.category?.final) {
    return {
      finalStatus: lastStatus.status.school_property.name ?? '',
      halfDay: lastStatus.registration.half_day,
      ageGroup:
        lastStatus.registration.school_properties.find(
          ({ type }) => type === SchoolPropertyType.AgeGroup,
        )?.name ?? '',
    };
  }

  const prospectiveStatuses = actualRegistationsStatuses.filter(
    (r) => r.status?.school_property.category?.name === 'Prospective',
  );

  const currentStatuses = actualRegistationsStatuses.filter(
    (r) => r.status?.school_property.category?.current,
  );

  if (prospectiveStatuses.length && !currentStatuses.length) {
    const lastProspectiveStatus = prospectiveStatuses[prospectiveStatuses.length - 1];
    return {
      ageGroup:
        lastProspectiveStatus.registration.school_properties.find(
          ({ type }) => type === SchoolPropertyType.AgeGroup,
        )?.name ?? '',
      halfDay: lastProspectiveStatus.registration.half_day,
      finalStatus: lastProspectiveStatus.status?.school_property.name ?? '',
    };
  }

  if (currentStatuses.length) {
    const lastCurrentStatus = currentStatuses[currentStatuses.length - 1];

    return {
      ageGroup:
        lastCurrentStatus.registration.school_properties.find(
          ({ type }) => type === SchoolPropertyType.AgeGroup,
        )?.name ?? '',
      halfDay: lastCurrentStatus.registration.half_day,
      startDate:
        startingDate ??
        format(new Date(lastCurrentStatus.status?.applies_from || ''), APPLIES_DATE_FORMAT),
    };
  }
};

const getFirstStartingDate = (registrations: StudentRegistration[]) => {
  const startingDates = registrations
    .map((registration) => {
      return registration.statuses
        .filter((status) => status.school_property.category?.current)
        .map((status) => status.applies_from);
    })
    .flat()
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return startingDates.length ? format(new Date(startingDates[0]), APPLIES_DATE_FORMAT) : undefined;
};

export const getRegistrationInfo = (registrations: StudentRegistration[]) => {
  const [currentRegistrations, futureRegistrations, previousRegistrations] =
    getRegistrationsByTimePeriods(registrations, 'student');

  const currentRegistrationsOnlyFormer = !getActualRegistrationsStatuses(
    currentRegistrations ?? [],
  ).filter(
    (status) =>
      status.status?.school_property.category?.name === 'Prospective' ||
      status.status?.school_property.category?.current,
  ).length;

  const firstStartingDate = getFirstStartingDate(registrations);

  if (currentRegistrationsOnlyFormer && futureRegistrations?.length) {
    return checkRegistrationStatuses(futureRegistrations, firstStartingDate);
  } else if (currentRegistrations?.length) {
    return checkRegistrationStatuses(currentRegistrations, firstStartingDate);
  } else if (futureRegistrations?.length) {
    return checkRegistrationStatuses(futureRegistrations, firstStartingDate);
  } else if (previousRegistrations?.length) {
    const formerRegistrations = previousRegistrations
      .map<RegistrationActualStatus | undefined>((registration) => {
        const formerStatuses = registration.statuses.filter(
          (status) => status.school_property.category?.final,
        );
        if (formerStatuses.length) {
          return {
            status: formerStatuses[formerStatuses.length - 1],
            registration,
          };
        }
        return undefined;
      })
      .filter((r) => !!r);

    if (formerRegistrations.length) {
      const lastFormerRegistration = formerRegistrations[formerRegistrations.length - 1];
      return {
        ageGroup:
          lastFormerRegistration?.registration.school_properties.find(
            ({ type }) => type === SchoolPropertyType.AgeGroup,
          )?.name ?? '',
        finalStatus: lastFormerRegistration?.status?.school_property.name || '',
        halfDay: false,
        startDate: '',
      };
    }
  }

  return null;
};
