export enum AttendanceCodeInSchool {
  OutOfSchool,
  InSchool,
}

export enum AttendanceCodeIsPresent {
  Absent,
  Present,
}

export enum AttendanceCodeHasNotifications {
  No,
  Yes,
}

export const ATTENDANCE_CODE_MAX_LENGTH = 1;
