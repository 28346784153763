import {
  AnnualPlanRecordTypes,
  DEFAULT_DATE_FORMAT_FNS,
  Group,
  listGroups,
  MAX_PAGE_SIZE,
} from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { DateSelect } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { format, isAfter, isBefore } from 'date-fns';
import { FC, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';

import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCreateForm } from '../../scheme';
import { useAnnualPlannerCreateRecord } from '../WithAnnualPlannerCreate';
import { useAnnualPlannerCreateAssessment } from './WithAnnualPlannerCreateAssessment';

export const AnnualPlannerCreateAssessmentDate: FC = () => {
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { yearStart, yearEnd } = useAnnualPlanner();
  const { record, setExpandedData } = useAnnualPlannerCreateRecord();
  const { disableSaveAssessment, setDisableSaveAssessment } = useAnnualPlannerCreateAssessment();
  const { watch, getValues, setValue, control } = useFormContext<AnnualPlannerCreateForm>();
  const { schoolId = '', relationId = '', permissions } = useAuth();

  const date = watch('date.0');

  const checkIrrelevantGroups = useCallback(async () => {
    if (!record || record.type !== AnnualPlanRecordTypes.ASSESSMENT) {
      return;
    }

    const { assessment_date, group_ids } = record.details;

    setDisableSaveAssessment(true);

    const relationIds = permissions.includes('assessment_manager') ? [] : [relationId];

    try {
      const res = await listGroups({
        schoolId,
        pageSize: MAX_PAGE_SIZE,
        singleDate: date,
        relationIds,
      });

      const groups = res?.results;

      const relevantGroups = group_ids?.reduce<Group[]>((prev, group_id) => {
        const group = groups.find((group) => group.id === group_id);

        if (group) {
          prev.push(group);
        }

        return prev;
      }, []);

      if (relevantGroups?.length !== group_ids?.length) {
        const confirmed = await getConfirmation({ textId: 'assessments-ChangingAssessmentDate' });

        if (confirmed) {
          const formValues = getValues();

          setExpandedData({
            ...formValues,
            assessment: { ...formValues.assessment, groups: relevantGroups },
          });
        } else {
          setValue('date.0', assessment_date);
        }
      }
    } catch (err) {
      console.error(err);
      showError(err as ApiError);
    }

    setDisableSaveAssessment(false);
  }, [
    date,
    getConfirmation,
    getValues,
    permissions,
    record,
    relationId,
    schoolId,
    setDisableSaveAssessment,
    setExpandedData,
    setValue,
    showError,
  ]);

  const isDateNotWithinSchoolYear = useCallback(
    (date: Date) =>
      Boolean((yearStart && isBefore(date, yearStart)) || (yearEnd && isAfter(date, yearEnd))),
    [yearEnd, yearStart],
  );

  useEffect(() => {
    if (!record || record.type !== AnnualPlanRecordTypes.ASSESSMENT) {
      return;
    }

    const { assessment_date } = record.details;

    if (date !== assessment_date) {
      checkIrrelevantGroups();
    }
  }, [checkIrrelevantGroups, date, record]);

  return (
    <Controller
      control={control}
      name="date.0"
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        return (
          <DateSelect
            ref={field.ref}
            onSetDate={(date) => {
              if (isDateNotWithinSchoolYear(date)) return;
              field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
            }}
            date={field.value}
            requiredLabel="required"
            error={fieldState.error}
            disabled={disableSaveAssessment}
            isLoading={disableSaveAssessment}
            CalendarProps={{ shouldDisableDate: isDateNotWithinSchoolYear }}
          />
        );
      }}
    />
  );
};
