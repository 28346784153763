import { Box, Stack } from '@mui/material';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { useSchool } from '../../../hooks/useSchool';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolNotificationsInternal } from './SchoolNotificarionsInternal/SchoolNotificationsInternal';
import { SchoolNotificationsAttendance } from './SchoolNotificationsAttendance/SchoolNotificationsAttendance';

export const SchoolNotifications: FC = () => {
  const { isSchoolAdmin } = useSchool();

  if (!isSchoolAdmin) {
    return <AccessDeniedPage />;
  }

  return (
    <MainLayout>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-Notifications" />
      </Box>

      <Stack gap={2}>
        <SchoolNotificationsInternal />

        <SchoolNotificationsAttendance />
      </Stack>

      <Outlet />
    </MainLayout>
  );
};
