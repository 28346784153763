import { Button } from '@mui/material';
import {
  ConductEntriesRequest,
  GetConductEntriesQueryFilters,
  GetConductEntriesQuerySort,
  useExportConductForPage,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ExportStatus, PageExport } from '@schooly/components/export';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { ContextMenuAction } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { downloadFile } from '../../utils/downloadFile';

export type ConductExportProps = {
  params: Omit<ConductEntriesRequest, 'filters' | 'sort'> & {
    filters: GetConductEntriesQueryFilters;
    sort?: GetConductEntriesQuerySort;
  };
  onSuccess?: () => void;
  onError?: () => void;
  conductLogsCount: number;
};

export const ConductExport: FC<ConductExportProps> = ({
  params,
  onSuccess,
  onError,
  conductLogsCount,
}) => {
  const { formatMessage } = useIntl();
  const { schoolId } = useAuth();

  const [exportStatus, setExportStatus] = useState<ExportStatus | null>(null);

  const { mutateAsync: exportConductForPage, isLoading } = useExportConductForPage();

  const resetExportStatus = useCallback(() => {
    setTimeout(() => setExportStatus(null), DELAY_BEFORE_HIDE_ICON_DONE);
  }, []);

  const handleError = useCallback(() => {
    onError?.();
    setExportStatus('error');
    resetExportStatus();
  }, [onError, resetExportStatus]);

  const handleSuccess = useCallback(() => {
    onSuccess?.();
    setExportStatus('success');
    resetExportStatus();
  }, [onSuccess, resetExportStatus]);

  const handleExportClick = useCallback(async () => {
    if (!schoolId) {
      return;
    }

    await exportConductForPage(params, {
      onSuccess: (data) => {
        try {
          const timestamp = format(new Date(), 'dd-MM-yy_HH-mm');
          downloadFile(data, `conducts_${timestamp}.csv`);

          handleSuccess();
        } catch (err) {
          handleError();
        }
      },
      onError: handleError,
    });
  }, [schoolId, exportConductForPage, params, handleError, handleSuccess]);

  const renderMenuItem = useCallback(
    ({ labelTextId: label, onClick }: ContextMenuAction, close: () => void) => {
      return (
        <Button
          variant="outlined"
          onClick={() => {
            onClick();
            close();
          }}
          disabled={!schoolId}
          fullWidth
        >
          {label}
        </Button>
      );
    },
    [schoolId],
  );

  const actions = [
    {
      labelTextId: formatMessage({ id: 'conduct-Export' }, { count: conductLogsCount }),
      onClick: handleExportClick,
    },
  ];

  return (
    <PageExport
      isLoading={isLoading}
      exportStatus={exportStatus}
      actions={actions}
      renderMenuItem={renderMenuItem}
    />
  );
};
