import {
  Box,
  Divider,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { FilterKeys, GetPayableFeesArrangeBy, InvoicesReportingAggregatedRow } from '@schooly/api';
import { Currencies, CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  ChevronLeftIcon,
  Counter,
  DiscountIcon,
  DoneCircleIcon,
  DueIcon,
  OverdueIcon,
  Price,
  ProjectedIcon,
  StudentIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { PAYABLE_FEES_COL_WIDTH } from './PayableFeesGrid';

type PayableFeesCollapsableSectionProps = PropsWithChildren<{
  currency: Currencies;
  isExpanded?: boolean;
  arrangeByKey: GetPayableFeesArrangeBy;
  row: InvoicesReportingAggregatedRow;
  canExpand?: boolean;
  totalCountOverride?: number;
}> &
  StackProps;

export const PayableFeesCollapsableSection: FC<PayableFeesCollapsableSectionProps> = ({
  row,
  currency,
  children,
  arrangeByKey,
  totalCountOverride,
  isExpanded: isInitiallyExpanded,
  canExpand: isInitiallyCanExpand,
  ...props
}) => {
  const canExpand = Boolean(row.count);
  const { formatMessage } = useIntl();
  const [expanded, setExpanded] = useState(canExpand ? isInitiallyExpanded : false);
  const [rowHovered, setRowHovered] = useState(false);

  const color = rowHovered ? 'primary.main' : 'text.primary';

  const isExpanded = canExpand ? expanded : false;

  const toggleExpanded = useCallback(() => {
    if (isInitiallyCanExpand) return;
    setExpanded((e) => !e);
  }, [isInitiallyCanExpand]);

  const name = (() => {
    switch (arrangeByKey) {
      case FilterKeys.Month:
        return format(newDateTimezoneOffset(row.value + ''), "MMM''yy");

      // case FilterKeys.ProductType:
      case FilterKeys.Payer:
      case FilterKeys.Product:
      case FilterKeys.InvoicesAgeGroup:
        return row.name;
      case FilterKeys.DayPastDue:
        return formatMessage({ id: `payableFees-${row.name}` });
      default:
        const _never: never = arrangeByKey;
        return _never;
    }
  })();

  const renderCounter = (v?: number) => {
    if (!v) return null;
    return <Counter>{v}</Counter>;
  };

  return (
    <Stack position="relative">
      <Stack
        onClick={toggleExpanded}
        onMouseEnter={() => setRowHovered(true)}
        onMouseLeave={() => setRowHovered(false)}
        sx={{
          zIndex: 4,
          transition: 'all .3s',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: expanded ? 'white' : undefined,
          mb: 0,
          py: 3,
          position: isExpanded ? 'sticky' : undefined,
          top: isExpanded ? 0 : undefined,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'background.default',
          },
        }}
        {...props}
      >
        <Stack
          minWidth={
            PAYABLE_FEES_COL_WIDTH.payer_1 +
            PAYABLE_FEES_COL_WIDTH.invoiceNum_2 +
            PAYABLE_FEES_COL_WIDTH.invoiceDate_3 +
            PAYABLE_FEES_COL_WIDTH.dueDate_4
          }
        >
          <Typography
            variant="h2"
            sx={{
              pl: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {name}{' '}
            {renderCounter(totalCountOverride !== undefined ? totalCountOverride : row.count)}
          </Typography>
        </Stack>
        <Cell
          title={formatMessage({ id: 'payableFees-students' })}
          text={row.students}
          icon={
            <Icon fontSize="small" sx={{ color }}>
              <StudentIcon />
            </Icon>
          }
          minWidth={PAYABLE_FEES_COL_WIDTH.student_5}
          color={color}
        />
        <Cell
          title={formatMessage({ id: 'payableFees-discount-amount' })}
          text={
            <Price price={row.discount} currency={CURRENCY_SYMBOLS[currency]} variant="body1" />
          }
          icon={
            <Icon fontSize="small" sx={{ color }}>
              <DiscountIcon />
            </Icon>
          }
          minWidth={PAYABLE_FEES_COL_WIDTH.discount_6}
          color={color}
        />
        <Cell
          title={formatMessage({ id: 'payableFees-paid' })}
          text={
            row.paid ? (
              <Price
                price={row.paid}
                color="success.main"
                currency={CURRENCY_SYMBOLS[currency]}
                variant="body1"
              />
            ) : undefined
          }
          icon={
            <Icon sx={{ color: 'success.main' }}>
              <DoneCircleIcon />
            </Icon>
          }
          color="success.main"
          minWidth={PAYABLE_FEES_COL_WIDTH.paid_7}
        />
        <Cell
          title={formatMessage({ id: 'payableFees-due' })}
          text={
            row.due ? (
              <Price
                price={row.due}
                color="warning.main"
                currency={CURRENCY_SYMBOLS[currency]}
                variant="body1"
              />
            ) : undefined
          }
          icon={
            <Icon sx={{ color: 'warning.main' }}>
              <DueIcon />
            </Icon>
          }
          minWidth={PAYABLE_FEES_COL_WIDTH.due_8}
        />
        <Cell
          title={formatMessage({ id: 'payableFees-status-overdue' })}
          text={
            row.overdue ? (
              <Price
                price={row.overdue}
                color="error.main"
                currency={CURRENCY_SYMBOLS[currency]}
                variant="body1"
              />
            ) : undefined
          }
          icon={
            <Icon sx={{ color: 'error.main' }}>
              <OverdueIcon />
            </Icon>
          }
          minWidth={PAYABLE_FEES_COL_WIDTH.total_9}
        />

        <Stack flex={1} flexDirection="row" alignItems="center" px={0.75} gap={2}>
          <Stack flex={1}>
            {!!row.projected && (
              <Tooltip
                followCursor
                placement="bottom-start"
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      px: 1,
                      py: 0.5,
                    },
                  },
                }}
                disableInteractive
                arrow={false}
                title={formatMessage({ id: 'payableFees-status-projected' })}
              >
                <Stack flexDirection="row" alignItems="center">
                  <Icon sx={{ color: 'primary.main', mr: 0.5 }}>
                    <ProjectedIcon />
                  </Icon>
                  <Price
                    price={row.projected}
                    color="primary.main"
                    currency={CURRENCY_SYMBOLS[currency]}
                    variant="body1"
                  />
                </Stack>
              </Tooltip>
            )}
          </Stack>
          <Stack flex={1} pt={0.25}>
            {!!row.total && (
              <Tooltip
                followCursor
                placement="bottom-start"
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      px: 1,
                      py: 0.5,
                    },
                  },
                }}
                disableInteractive
                arrow={false}
                title={formatMessage({ id: 'payableFees-total' })}
              >
                <Stack>
                  <TypographyWithOverflowHint>
                    <Price
                      price={row.total}
                      currency={CURRENCY_SYMBOLS[currency]}
                      color={rowHovered ? 'primary.main' : 'text.primary'}
                    />
                  </TypographyWithOverflowHint>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Stack>
        <Stack
          minWidth={PAYABLE_FEES_COL_WIDTH.expand_11}
          position="relative"
          justifyContent="center"
        >
          {Boolean(row.count && !isInitiallyCanExpand) && (
            <Box sx={(theme) => ({ position: 'absolute', right: theme.spacing(1) })}>
              <IconButton
                size="medium"
                disabled={!isExpanded}
                sx={{
                  transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)',
                  path: {
                    color: rowHovered ? 'primary.main' : undefined,
                  },
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Stack>
      {isExpanded && children}
      <Divider sx={{ mt: expanded ? 1 : 0, borderColor: isExpanded ? 'transparent' : undefined }} />
    </Stack>
  );
};

const Cell: FC<
  {
    text: ReactNode;
    icon: ReactNode;
    color?: string;
    minWidth: number;
  } & StackProps
> = ({ text, icon, color, minWidth, title, ...rest }) => {
  if (!text) return <Stack minWidth={minWidth} {...rest} />;

  const content = (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      width={minWidth}
      minWidth={minWidth}
      px={0.75}
      {...rest}
    >
      {icon}
      <TypographyWithOverflowHint color={color} pt={0.25}>
        {text}
      </TypographyWithOverflowHint>
    </Stack>
  );

  return title ? (
    <Tooltip
      followCursor
      placement="bottom-start"
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            px: 1,
            py: 0.5,
          },
        },
      }}
      disableInteractive
      arrow={false}
      title={title}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};
