import {
  Avatar,
  Box,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GetStudentsQuerySort,
  SchoolRelation,
  SORT_DIRECTION,
  StudentSearchResult,
  WithAvatar,
  WithTelephone,
} from '@schooly/api';
import { InviteNotAcceptedWarning } from '@schooly/components/annual-roll-over';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { CombinedRow } from '@schooly/components/filters';
import { RenderSchoolProperty } from '@schooly/components/render-school-property';
import {
  Genders,
  getLabelIdForOption,
  SchoolPropertyType,
  SchoolUserRole,
} from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  CheckboxSquareIconButton,
  ChevronUpIcon,
  GridCell,
  GridHead,
  SortableCell,
  TypographyWithOverflowHint,
  UpdateIcon,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { formatPhoneNumber } from '@schooly/utils/phone-number';
import { FC, MouseEventHandler, ReactNode, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { ReEnrollmentTag } from '../../components/common/StudentRegistration/useReEnrollmentStatuses';
import useAppLocation from '../../hooks/useAppLocation';

type StudentsHeaderProps = {
  sort?: GetStudentsQuerySort;
  onChangeSort: (v: GetStudentsQuerySort) => void;
  rightIcon?: ReactNode;
  isSelectedAll?: boolean;
  onSelectAll?: () => void;
  schoolHasHouses: boolean;
} & TableHeadProps;

export const StudentsHeader: FC<StudentsHeaderProps> = ({
  onChangeSort,
  rightIcon,
  sort,
  onSelectAll,
  isSelectedAll,
  schoolHasHouses,
  ...rest
}) => {
  const { $t } = useIntl();
  const handleSort = (columnTextId: GetStudentsQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="50px" right>
        <CheckboxSquareIconButton
          isActive={isSelectedAll}
          disabled={!onSelectAll}
          onClick={onSelectAll}
        />
      </GridCell>
      <SortableCell
        label={$t({ id: 'people-Name' })}
        sort={sort}
        columnTextId="last_name"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={$t({ id: 'schoolProperty-AgeGroup' })}
        sort={sort}
        columnTextId="age_group"
        onChangeSort={handleSort}
        width="130px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      />
      <SortableCell
        label={$t({ id: 'peopleDetail-Gender' })}
        sort={sort}
        columnTextId="gender"
        onChangeSort={handleSort}
        width="130px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      />
      {schoolHasHouses && (
        <SortableCell
          label={$t({ id: 'schoolProperty-House' })}
          sort={sort}
          columnTextId="house"
          onChangeSort={handleSort}
          width="130px"
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              width: 100,
            },
          })}
        />
      )}
      <GridCell
        width="130px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'people-PrimaryContact' })}
      </GridCell>
      <SortableCell
        label={$t({ id: 'peopleDetail-Status' })}
        sort={sort}
        columnTextId="status"
        onChangeSort={handleSort}
        width="220px"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      />
      <GridCell width="40px" pr={1} right>
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

type StudentRowProps = {
  combinedStudentRow: CombinedRow<StudentSearchResult>;
  schoolId: string;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  schoolHasHouses: boolean;
};

export const StudentRow: FC<StudentRowProps> = ({
  combinedStudentRow,
  schoolHasHouses,
  schoolId,
  isSelected,
  onSelect,
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useAppLocation();

  const { propertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
    showReEnrollmentProperties: true,
  });

  const [isExpanded, setExpanded] = useState(false);
  const { registration, id, leaving_reason } = combinedStudentRow.item;

  const user = registration.school_user_relation;

  const name = getUserFullName(user);

  const ageGroup = registration.age_group
    ? propertiesMap.age_group.find((p) => p.id === registration.age_group)
    : undefined;

  const isReEnrollmentRegistration = registration.type === 're_enrollment';
  const isHalfDay = !!registration.half_day;

  const house = registration.house
    ? propertiesMap.house.find((p) => p.id === registration.house)
    : undefined;

  const status = propertiesMap.status.find((p) => p.id === id);

  const isParentsInviteAccepted = registration.school_user_relation.parents_invite_accepted;
  const isReEnrollmentStatus = status?.source?.type === 're_enrollment';
  const isFinalCategory = status?.category?.final;

  const hasExpiredStatus = combinedStudentRow.item.is_expired;
  const noStatus = {
    name: formatMessage({ id: 'schoolProperty-NoStatus' }),
    type: SchoolPropertyType.Status,
  };

  const additionalRowsLength = combinedStudentRow.additionalRows.length;

  const handleSelect: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onSelect?.(combinedStudentRow.id);

      const node = ref.current;
      if (!node) return;

      const scrollIfRowHidden = () => {
        const { y, height } = node.getBoundingClientRect();
        const yOffset = window.innerHeight - y;
        const isHiddenByBulkActionBar = !isSelected && yOffset <= height * 1.5;
        isHiddenByBulkActionBar && window.scrollBy({ behavior: 'smooth', top: height });
      };

      setTimeout(scrollIfRowHidden, 300);
    },
    [combinedStudentRow.id, onSelect, isSelected],
  );

  const handleExpandClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded((e) => !e);
  };

  const handleParentClick: MouseEventHandler = (e) => {
    if (!user.primary_contact) return;

    e.preventDefault();
  };

  const hasSuggestedChanges = !!registration.school_user_relation.suggested_changes;

  const isCanceledRegistration =
    isReEnrollmentRegistration && !isReEnrollmentStatus && isFinalCategory;

  const gender = Number.isInteger(user.gender)
    ? formatMessage({
        id: getLabelIdForOption({
          key: Genders[user.gender!],
          optionsKey: 'gender',
        }),
      })
    : '-';

  return (
    <>
      <TableRow
        ref={ref}
        component={Link}
        to={`/students/${combinedStudentRow.id}`}
        state={{ backgroundLocation: location }}
        sx={(theme) => ({
          '.checkbox': {
            transition: 'opacity .2s',
            position: 'absolute',
          },
          ...(!isSelected && {
            '&:not(:hover) .checkbox': {
              opacity: 0,
            },
            '&:hover .checkbox': {
              opacity: 1,
            },
          }),
          td: {
            backgroundColor: isExpanded
              ? `${theme.palette.background.default} !important`
              : undefined,
          },
          '&:hover td': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <GridCell>
          <Stack sx={{ justifyContent: 'center', alignItems: 'flex-end' }} onClick={handleSelect}>
            <CheckboxSquareIconButton isActive={isSelected} className="checkbox" />
          </Stack>
        </GridCell>
        <GridCell>
          <Stack direction="row" gap={2}>
            <Stack
              sx={(theme) => ({
                justifyContent: 'center',
                alignItems: 'center',
                margin: theme.spacing(-0.5, hasSuggestedChanges ? 0.75 : 0),
              })}
            >
              <AvatarAuth
                withAvatarPreview
                user={user}
                sx={
                  hasSuggestedChanges
                    ? (theme: Theme) => ({
                        fontSize: theme.spacing(1.25),
                        width: theme.spacing(2.25),
                        height: theme.spacing(2.25),
                      })
                    : undefined
                }
              />
              {hasSuggestedChanges && (
                <Box
                  sx={(theme) => ({
                    position: 'absolute',
                    pointerEvents: 'none',
                    '.svg-icon': {
                      width: theme.spacing(4),
                      height: theme.spacing(4),
                    },
                  })}
                >
                  <UpdateIcon className="reset-svg-currentColor" />
                </Box>
              )}
            </Stack>
            <TypographyWithOverflowHint variant="h3" color="text.primary" noWrap>
              {name}
            </TypographyWithOverflowHint>
          </Stack>
        </GridCell>
        <GridCell>
          <RenderSchoolProperty
            property={ageGroup}
            defaultValue="-"
            additionalLabel={
              isHalfDay ? ` • ${formatMessage({ id: 'students-HalfDay' })}` : undefined
            }
            tooltipTitle={formatMessage({
              id: `schoolProperty-Archived-${SchoolPropertyType.AgeGroup}`,
            })}
          />
        </GridCell>
        <GridCell>
          <Typography color="text.primary">{gender}</Typography>
        </GridCell>
        {schoolHasHouses && (
          <GridCell>
            <RenderSchoolProperty
              property={house}
              defaultValue="-"
              tooltipTitle={formatMessage({
                id: `schoolProperty-Archived-${SchoolPropertyType.House}`,
              })}
            />
          </GridCell>
        )}
        <GridCell onClick={handleParentClick}>
          <PrimaryContactCellContent contact={user.primary_contact} />
        </GridCell>
        <GridCell>
          <RenderSchoolProperty
            property={hasExpiredStatus ? noStatus : status}
            defaultValue="-"
            sx={{
              color: (theme) => {
                if (!isReEnrollmentStatus) return;
                return isFinalCategory ? theme.palette.error.main : theme.palette.common.orange;
              },
            }}
            tooltipTitle={formatMessage({
              id: `schoolProperty-Archived-${SchoolPropertyType.Status}`,
            })}
          >
            {isReEnrollmentStatus && !isFinalCategory && !isParentsInviteAccepted && (
              <InviteNotAcceptedWarning
                onShowFamily={() => {
                  navigate(`students/${user.relation_id}#family`);
                }}
              />
            )}
            {isCanceledRegistration && (
              <ReEnrollmentTag>
                <FormattedMessage id="students-ReEnrollment-NotReEnrolled" />
              </ReEnrollmentTag>
            )}
          </RenderSchoolProperty>

          {!hasExpiredStatus &&
            !isReEnrollmentRegistration &&
            combinedStudentRow.additionalPermanentRows.map(({ item }) => {
              const status = propertiesMap.status.find((p) => p.id === item.id);
              return (
                <RenderSchoolProperty
                  key={item.id}
                  property={status}
                  defaultValue="-"
                  tooltipTitle={formatMessage({
                    id: `schoolProperty-Archived-${SchoolPropertyType.Status}`,
                  })}
                />
              );
            })}

          {!!leaving_reason?.title && (
            <Tooltip
              title={
                <Stack>
                  <Typography variant="caption">
                    {formatMessage({ id: 'profile-registrationReason' })}:
                  </Typography>
                  {leaving_reason?.title}
                </Stack>
              }
              placement="bottom-start"
              disableInteractive
              arrow={true}
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    zIndex: 2000,
                    maxWidth: 200,
                    margin: `${theme.spacing(1, 0, 0)} !important`,
                    padding: 1.25,
                  }),
                },
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'flex',
                  maxWidth: 100,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                moving to another country. moving to another country. moving to another country
              </Typography>
            </Tooltip>
          )}
        </GridCell>
        <GridCell onClick={handleExpandClick}>
          {!!additionalRowsLength &&
            !hasExpiredStatus &&
            (isExpanded ? (
              <IconButton size="small" sx={{ ml: 0.75 }}>
                <ChevronUpIcon />
              </IconButton>
            ) : (
              <Avatar
                sx={(theme) => ({
                  my: -1,
                  backgroundColor: 'white',
                  fontSize: theme.spacing(1.5),
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                })}
              >
                +{additionalRowsLength}
              </Avatar>
            ))}
        </GridCell>
      </TableRow>
      <>
        {isExpanded &&
          combinedStudentRow.additionalRows.map(
            ({ item: { registration, id }, additionalPermanentRows }) => {
              const ageGroup = registration.age_group
                ? propertiesMap.age_group.find((p) => p.id === registration.age_group)
                : undefined;

              const house = registration.house
                ? propertiesMap.house.find((p) => p.id === registration.house)
                : undefined;

              const status = propertiesMap.status.find((p) => p.id === id);

              return (
                <TableRow
                  key={registration.id}
                  sx={(theme) => ({
                    td: {
                      backgroundColor: `${theme.palette.background.default} !important`,
                    },
                  })}
                >
                  <GridCell />
                  <GridCell />
                  <GridCell>
                    <RenderSchoolProperty
                      property={ageGroup}
                      defaultValue="-"
                      tooltipTitle={formatMessage({
                        id: `schoolProperty-Archived-${SchoolPropertyType.AgeGroup}`,
                      })}
                    />
                  </GridCell>
                  <GridCell />
                  {schoolHasHouses && (
                    <GridCell>
                      <RenderSchoolProperty
                        property={house}
                        defaultValue="-"
                        tooltipTitle={formatMessage({
                          id: `schoolProperty-Archived-${SchoolPropertyType.House}`,
                        })}
                      />
                    </GridCell>
                  )}
                  <GridCell />
                  <GridCell>
                    <RenderSchoolProperty
                      property={status}
                      defaultValue="-"
                      tooltipTitle={formatMessage({
                        id: `schoolProperty-Archived-${SchoolPropertyType.Status}`,
                      })}
                    />

                    {additionalPermanentRows.map(({ id, item }) => {
                      const status = propertiesMap.status.find((p) => p.id === item.id);
                      return (
                        <RenderSchoolProperty
                          property={status}
                          defaultValue="-"
                          tooltipTitle={formatMessage({
                            id: `schoolProperty-Archived-${SchoolPropertyType.Status}`,
                          })}
                        />
                      );
                    })}
                  </GridCell>
                  <GridCell />
                </TableRow>
              );
            },
          )}
      </>
    </>
  );
};

type PrimaryContactCellContentProps = {
  contact?: SchoolRelation & WithTelephone & WithAvatar;
};

const handlePressAtPopupPhone = (event: React.MouseEvent<HTMLAnchorElement>) => {
  event.stopPropagation();
};

const PrimaryContactCellContent: FC<PrimaryContactCellContentProps> = ({ contact }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  if (!contact) return <Typography color="text.primary">—</Typography>;
  const name = getUserFullName(contact);

  const handleParentClick: MouseEventHandler = (e) => {
    e.preventDefault();
    navigate(`/parents/${contact.relation_id}`);
  };

  return (
    <Tooltip
      arrow
      title={
        <Stack
          sx={(theme) => ({
            alignItems: 'center',
            gap: theme.spacing(1),
          })}
        >
          <Typography textAlign="center">{name}</Typography>
          {!!contact.telephone && (
            <Typography
              textAlign="center"
              variant="h3"
              sx={(theme) => ({
                a: {
                  color: 'text.primary',
                  display: 'inline-block',
                  padding: theme.spacing(0, 0.5),
                  borderRadius: theme.spacing(0.5),
                  '&:hover': {
                    backgroundColor: 'background.default',
                  },
                },
              })}
            >
              <a href={`tel:${contact.telephone}`} onClick={handlePressAtPopupPhone}>
                {formatPhoneNumber(contact.telephone)}
              </a>
            </Typography>
          )}
        </Stack>
      }
    >
      <Box width={30}>
        <AvatarAuth
          user={contact}
          ref={ref}
          alt={name}
          sx={{ width: 30, height: 30, my: -1 }}
          onClick={handleParentClick}
        />
      </Box>
    </Tooltip>
  );
};
