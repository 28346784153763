import {
  Backdrop,
  ClickAwayListener,
  Popper,
  Stack,
  styled,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { FC, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { SimpleButton } from './SimpleButton/SimpleButton';

export type ContextMenuAction = {
  labelTextId: string;
  onClick: (close?: () => void) => void;
  icon?: ReactNode;
};

export type ContextMenuProps = {
  children: (open: () => void, opened: boolean) => ReactElement<any, any>;
  actions: ContextMenuAction[];
  renderMenuItem?: (action: ContextMenuAction, handleClose: () => void) => ReactNode;
} & Partial<Omit<TooltipProps, 'children'>>;

const noop = () => {};

export const ContextMenu: FC<ContextMenuProps> = ({
  children,
  actions,
  renderMenuItem,
  ...tooltipProps
}) => {
  const [opened, open, close] = useFlag();
  const { $t } = useIntl();

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={opened ? close : noop}>
        <div>
          <Tooltip
            PopperProps={{ disablePortal: true }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Stack
                sx={{
                  gap: 0.5,
                  alignItems: 'start',
                  flexWrap: 'wrap',
                }}
                className="container"
              >
                {actions.map((a) =>
                  renderMenuItem ? (
                    renderMenuItem(a, close)
                  ) : (
                    <SimpleButton
                      onClick={() => a.onClick(close)}
                      key={a.labelTextId}
                      startIcon={a.icon}
                    >
                      {$t({ id: a.labelTextId })}
                    </SimpleButton>
                  ),
                )}
              </Stack>
            }
            {...tooltipProps}
          >
            {children(open, opened)}
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export const MenuItemArrow = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  width: theme.spacing(),

  '& > .MuiPaper-root': {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: 2, // non-standard shape
    transform: 'rotate(-45deg)',
    transformOrigin: 'right bottom',
    boxShadow: 'none',
  },
}));
