import { Icon, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { CheckIcon, Spin } from '@schooly/style';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { useDebounce } from '../../../../hooks/useDebounce';
import { DELAY_BEFORE_CHANGE_STATUS } from '../SchoolNotificationsAttendance/SchoolNotificationsAttendanceContent';

interface SchoolNotificationsEmailProps {
  loading: boolean;
  email?: string;
  onSubmit: (value: string) => Promise<unknown>;
}

interface SchoolNotificationsEmailForm {
  email: string;
}

export const SchoolNotificationsEmail: FC<SchoolNotificationsEmailProps> = ({
  email: defaultEmail,
  loading,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();

  const form = useForm<SchoolNotificationsEmailForm>({
    defaultValues: {
      email: defaultEmail || '',
    },
  });

  const email = form.watch('email');
  const debouncedEmail = useDebounce(email, 500);
  const lastSubmittedEmail = useRef<string | null>(defaultEmail || null);

  const [showCompleted, setShowCompleted] = useState<boolean>();

  useEffect(() => {
    const handleEmailSave = async (email: string) => {
      lastSubmittedEmail.current = email;

      try {
        await onSubmit(email);
        setShowCompleted(true);

        setTimeout(() => {
          setShowCompleted(false);
        }, DELAY_BEFORE_CHANGE_STATUS);
      } catch (er) {
        form.resetField('email');
      }
    };

    const validateForm = async () => {
      const isValid = await form.trigger();

      if (!isValid || loading || lastSubmittedEmail.current === debouncedEmail) return;

      handleEmailSave(debouncedEmail);
    };

    validateForm();
  }, [form, debouncedEmail, loading, onSubmit]);

  const selectedIcon = useMemo(() => {
    if (loading) {
      return <Spin />;
    }

    return showCompleted ? <CheckIcon /> : null;
  }, [loading, showCompleted]);

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth={500}
        gap={2}
      >
        <ControlTextField
          name="email"
          control={form.control}
          fullWidth
          hideLabel
          error={false}
          rules={{
            required: true,
            pattern: {
              value: VALID_EMAIL_REGEXP,
              message: formatMessage({
                id: 'input-ErrorInvalidEmail',
              }),
            },
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: form.formState.errors.email ? undefined : 'none',
            },
            '.MuiOutlinedInput-root': {
              border: form.formState.errors.email ? undefined : 'none',
            },
            '.MuiOutlinedInput-input': { height: '12px !important' },
            maxWidth: 500,
            width: '100%',
          }}
        />

        <Icon>{selectedIcon}</Icon>
      </Stack>
    </FormProvider>
  );
};
