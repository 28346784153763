import {
  ApiError,
  FilterValue,
  GET_ATTENDANCE_CODES_QUERY,
  GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY,
  NotificationAttendanceSettings,
  NotificationInternalEmails,
  NotificationSettings,
  RQUseQueryOptions,
  useGetNotificationAttendanceSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useNotificationSettings = (options?: RQUseQueryOptions<NotificationSettings>) => {
  const { schoolId = '' } = useAuth();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();

  const [lastAppliedEmails, setLastAppliedEmails] = useState<NotificationInternalEmails>({
    admissions_email: '',
    invoicing_email: '',
  });
  const [selectedCodes, setSelectedCodes] = useState<FilterValue[]>([]);

  const { mutateAsync: updateNotificationSettings, isLoading: loadingUpdateNotificationSettings } =
    useUpdateNotificationSettingsMutation();

  const { data: notificationSettings, isLoading: loadingNotificationSettings } =
    useGetNotificationAttendanceSettingsQuery(
      { schoolId: schoolId ?? '' },
      {
        enabled: !!schoolId,
        refetchOnMount: 'always',
      },
    );

  const attendanceCodeIds = useMemo(
    () =>
      notificationSettings?.attendance.notifications.reduce<string[]>(
        (acc, { id, has_push_notifications }) => (has_push_notifications ? [...acc, id] : acc),
        [],
      ),
    [notificationSettings],
  );

  useEffect(() => {
    if (!attendanceCodeIds) return;

    setSelectedCodes(attendanceCodeIds);
  }, [attendanceCodeIds]);

  useEffect(() => {
    if (!notificationSettings?.internal_emails) return;

    setLastAppliedEmails(notificationSettings.internal_emails);
  }, [notificationSettings?.internal_emails]);

  const attendanceEnabled = useMemo(
    () => notificationSettings?.attendance.enabled,
    [notificationSettings],
  );

  const changeAttendanceNotifications = useCallback(
    async ({ notifications, enabled }: NotificationAttendanceSettings) => {
      if (!notificationSettings) return;

      const { internal_emails } = notificationSettings;

      const admissionsEmail = internal_emails?.admissions_email;
      const invoicingEmail = internal_emails?.invoicing_email;

      if (!admissionsEmail || !invoicingEmail) return;

      const { attendance } = await updateNotificationSettings(
        {
          schoolId,
          internalEmails: { admissionsEmail, invoicingEmail },
          attendance: {
            notifications,
            enabled: Boolean(notifications.length) ? enabled : false,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY]);
            queryClient.invalidateQueries([GET_ATTENDANCE_CODES_QUERY]);
          },
          onError: (err) => {
            showError(err as ApiError);
            setSelectedCodes(attendanceCodeIds ?? []);
          },
        },
      );

      return attendance;
    },
    [
      notificationSettings,
      updateNotificationSettings,
      schoolId,
      queryClient,
      showError,
      attendanceCodeIds,
    ],
  );

  const updateInternalEmailsNotifications = useCallback(
    async (internalEmailsPartial: NotificationInternalEmails) => {
      if (!schoolId || !notificationSettings) {
        return;
      }

      setLastAppliedEmails((s) => ({ ...s, ...internalEmailsPartial }));

      const { attendance } = notificationSettings;

      const admissionsEmail =
        internalEmailsPartial.admissions_email || lastAppliedEmails?.admissions_email;
      const invoicingEmail =
        internalEmailsPartial.invoicing_email || lastAppliedEmails?.invoicing_email;

      if (!admissionsEmail || !invoicingEmail) return;

      return await updateNotificationSettings(
        {
          schoolId,
          internalEmails: {
            admissionsEmail,
            invoicingEmail,
          },
          attendance: {
            ...attendance,
            notifications: attendance.notifications.filter(
              (n) => n.has_push_notifications === true,
            ),
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY]);
          },
          onError: showError,
        },
      );
    },
    [
      notificationSettings,
      queryClient,
      schoolId,
      showError,
      updateNotificationSettings,
      lastAppliedEmails,
    ],
  );

  const onCodesSubmit = useCallback(
    async (enabled: boolean) => {
      if (selectedCodes === undefined || isEqual(attendanceCodeIds, selectedCodes)) {
        return;
      }

      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes?.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data ?? [],
      });

      if (response) {
        return response;
      }
    },
    [attendanceCodeIds, selectedCodes, changeAttendanceNotifications],
  );

  const onToggleSwitch = useCallback(
    async (enabled: boolean) => {
      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes?.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data,
      });

      if (response) {
        return response;
      }
    },
    [selectedCodes, attendanceCodeIds, changeAttendanceNotifications],
  );

  return {
    loadingNotificationSettings,
    loadingUpdateNotificationSettings,
    attendanceNotificationsEnabled: !!attendanceEnabled,
    selectedCodes,
    setSelectedCodes,
    onCodesSubmit,
    onToggleSwitch,
    updateInternalEmailsNotifications,
    attendanceCodeIds,
    notificationSettings,
  };
};
