import { Box, ClickAwayListener, Divider, Stack, Tooltip } from '@mui/material';
import { FC, Fragment, MutableRefObject } from 'react';
import { useIntl } from 'react-intl';

import { AnnualPlannerCalendarRecordTitleTypography } from '../../AnnualPlannerCalendar.styled';
import { AnnualPlannerRecordMeta } from '../../scheme';
import { AnnualPlannerRecordsLayoutRecordDetails } from '../records/AnnualPlannerRecordsLayoutRecordDetails';

interface AnnualPlannerGridLayoutMoreRecordsButtonProps {
  isOpen: boolean;
  records: Array<AnnualPlannerRecordMeta>;
  anchorTitles: MutableRefObject<Record<string, HTMLElement | null> | undefined>;
  onClick: () => void;
  onClose: () => void;
}

export const AnnualPlannerGridLayoutMoreRecordsButton: FC<AnnualPlannerGridLayoutMoreRecordsButtonProps> =
  ({ onClose, isOpen, onClick, records, anchorTitles }) => {
    const { formatMessage } = useIntl();

    return (
      <ClickAwayListener onClickAway={onClose} mouseEvent={isOpen ? undefined : false}>
        <Stack
          onClick={onClick}
          onMouseDown={(e) => e.stopPropagation()}
          sx={{ justifyContent: 'end', mt: 'auto' }}
        >
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  p: 0,
                  maxWidth: 'unset',
                  width: 330,
                },
              },
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -7],
                    },
                  },
                ],
              },
            }}
            title={
              <Stack sx={{ p: 1, maxHeight: 450, overflow: 'auto' }}>
                {records.map((record, index) => (
                  <Fragment key={record.id}>
                    {index > 0 && (
                      <Box p={1}>
                        <Divider />
                      </Box>
                    )}

                    <AnnualPlannerRecordsLayoutRecordDetails
                      record={record}
                      anchorEl={anchorTitles.current?.[records[0].id]}
                      onClose={onClose}
                    />
                  </Fragment>
                ))}
              </Stack>
            }
            open={isOpen}
            onClose={onClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
          >
            <AnnualPlannerCalendarRecordTitleTypography
              ref={(el) => {
                if (!anchorTitles.current) {
                  anchorTitles.current = {};
                }
                anchorTitles.current![records[0].id] = el;
              }}
              color="primary.main"
              sx={{ py: 0.5, px: 0.75, cursor: 'pointer' }}
            >
              {formatMessage({ id: 'annualPlanner-Records-More' }, { count: records.length })}
            </AnnualPlannerCalendarRecordTitleTypography>
          </Tooltip>
        </Stack>
      </ClickAwayListener>
    );
  };
