import { Stack, Typography } from '@mui/material';
import {
  AnnualPlanRecord,
  DATE_FORMAT_WEEK_DAY_FNS,
  DEFAULT_DATE_FORMAT_FNS,
  DEFAULT_FORMATTED_DATE_FORMAT_FNS,
  SchoolYear,
} from '@schooly/api';
import { format, getDay, isSameDay, isWithinInterval } from 'date-fns';
import { FC, useCallback, useMemo, useRef, useState } from 'react';

import useSchoolYears from '../../../../../hooks/useSchoolYears';
import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarDateTooltip,
  AnnualPlannerCalendarGrid,
} from '../../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import {
  ANNUAL_PLANNER_CALENDAR_MONTH_COLS,
  ANNUAL_PLANNER_HEADER_CELL_HEIGHT,
  ANNUAL_PLANNER_MONTH_RECORD_CELL_HEIGHT,
  AnnualPlannerCalendarWithDates,
  AnnualPlannerRecordMeta,
  canBeRecurring,
} from '../../scheme';
import { useAnnualPlannerMonthGrid } from '../../useAnnualPlannerGrid';
import { useAnnualPlannerGridEvents } from '../../useAnnualPlannerGridEvents';
import {
  getAnnualPlannerCell,
  getAnnualPlannerRecordsWithGapsByDate,
  isAnnualPlannerRecordMeta,
} from '../../utils';
import { useAnnualPlannerDnD } from '../../WithAnnualPlannerDnD';
import { AnnualPlannerGridLayoutMonthRecordCell } from './AnnualPlannerGridLayoutMonthRecordCell';
import { AnnualPlannerGridLayoutMoreRecordsButton } from './AnnualPlannerGridLayoutMoreRecordsButton';
import { AnnualPlannerGridLayoutSelectedCell } from './AnnualPlannerGridLayoutSelectedCell';

export interface AnnualPlannerGridLayoutMonthProps extends AnnualPlannerCalendarWithDates {
  records?: AnnualPlanRecord[];
  yearStart: SchoolYear['start'];
  yearEnd: SchoolYear['end'];
}

export const AnnualPlannerGridLayoutMonth: FC<AnnualPlannerGridLayoutMonthProps> = ({
  records = [],
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { getSchoolYearById } = useSchoolYears();
  const { movingItem } = useAnnualPlannerDnD();
  const { dates, firstWeek, rows, yearStart, yearEnd, month, start } =
    useAnnualPlannerMonthGrid(props);
  const {
    isOpen,
    date: selectedDate,
    type: selectedType,
    record: editingRecord,
    open,
    openParamsRef,
    setOpenParams,
  } = useAnnualPlannerCreateRecord();
  const { onCellMouseDown } = useAnnualPlannerGridEvents({
    gridRef: ref,
    open,
    openParamsRef,
    setOpenParams,
    validityStart: yearStart,
    validityEnd: yearEnd,
  });

  const [anchorRecordId, setAnchorRecordId] = useState<string>();
  const anchorTitles = useRef<Record<AnnualPlannerRecordMeta['id'], HTMLElement | null>>();

  const onRecordMouseEnter = useCallback<
    (record: AnnualPlannerRecordMeta) => React.MouseEventHandler
  >(
    (record) => () => {
      const recurrenceId = canBeRecurring(record)
        ? record.details.recurring_state?.recurrence_id
        : '';
      const id = recurrenceId || record.id;
      if (!id) return;

      document
        .querySelectorAll(`.AnnualPlannerCalendarRecordGroup-${record.type}[data-hover-id="${id}"]`)
        .forEach((record) => {
          record.classList.add('AnnualPlannerCalendarRecordGroup-highlight');
        });
    },
    [],
  );

  const onRecordMouseLeave = useCallback<React.MouseEventHandler>(() => {
    document
      .querySelectorAll(
        '.AnnualPlannerCalendarRecordGroup-root.AnnualPlannerCalendarRecordGroup-highlight',
      )
      .forEach((record) => {
        record.classList.remove('AnnualPlannerCalendarRecordGroup-highlight');
      });
  }, []);

  const handleRecordClick = (id: string) => () => {
    setAnchorRecordId(id);
  };

  const handleRecordClose = () => {
    setAnchorRecordId(undefined);
  };

  const data = useMemo(
    () => getAnnualPlannerRecordsWithGapsByDate(records, getSchoolYearById),
    [getSchoolYearById, records],
  );

  const recordCellSx = { flex: `0 0 ${ANNUAL_PLANNER_MONTH_RECORD_CELL_HEIGHT}px`, mb: 0.25 };

  return (
    <AnnualPlannerCalendarGrid
      ref={ref}
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-MainGrid-root',
      ].join(' ')}
      sx={(theme) => ({
        borderTop: theme.mixins.borderValue(),
        borderLeft: theme.mixins.borderValue(),
        gridTemplateColumns: `repeat(${ANNUAL_PLANNER_CALENDAR_MONTH_COLS}, 1fr)`,
        gridTemplateRows: `${ANNUAL_PLANNER_HEADER_CELL_HEIGHT}px repeat(${rows}, 1fr)`,
      })}
    >
      {firstWeek.map((day) => {
        const { isWeekEnd } = getAnnualPlannerCell({
          month,
          start: yearStart,
          end: yearEnd,
          date: day,
        });
        return (
          <AnnualPlannerCalendarCell
            key={`header-col-${getDay(day)}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
              px: theme.spacing(0.5),
              backgroundColor: isWeekEnd ? theme.palette.background.default : undefined,
            })}
          >
            {format(day, DATE_FORMAT_WEEK_DAY_FNS)}
          </AnnualPlannerCalendarCell>
        );
      })}

      {dates.map((date, index) => {
        const { isWeekEnd, day, isThisMonth, isMonday, isSunday, hasDate } = getAnnualPlannerCell({
          month,
          start: yearStart,
          end: yearEnd,
          date,
        });
        const formattedDate = format(date, DEFAULT_DATE_FORMAT_FNS);

        const neighbourDate =
          index < dates.length - 1
            ? getAnnualPlannerCell({
                month,
                date: dates[index + 1],
                start: yearStart,
                end: yearEnd,
              })
            : undefined;
        const belowDate = dates[index + 6]
          ? getAnnualPlannerCell({
              month,
              date: dates[index + 6],
              start: yearStart,
              end: yearEnd,
            })
          : undefined;
        const hasNeighbourDate = neighbourDate?.hasDate && !isSunday;
        const hasBelowDate = belowDate?.hasDate;

        const isSelectedStart = selectedDate?.[0] && isSameDay(date, selectedDate[0]);
        const isSelectedEnd = !selectedDate?.[1] || isSameDay(date, selectedDate[1]);

        const records = data[formattedDate] ?? [];
        const [visibleRecords, restRecords] = [
          records.slice(0, 3),
          records.slice(3).filter(isAnnualPlannerRecordMeta),
        ];

        const hiddenRecords: AnnualPlannerRecordMeta[] = restRecords.filter((r) => r !== null);

        const hasHiddenRecords = hiddenRecords.length > 0;
        const hiddenRecordGroupId =
          hasHiddenRecords && hiddenRecords?.[0] ? `${formattedDate}-${hiddenRecords[0].id}` : '';

        const isCreating =
          isOpen &&
          !editingRecord &&
          selectedDate &&
          isWithinInterval(date, {
            start: selectedDate[0],
            end: selectedDate[1] ?? selectedDate[0],
          });

        const content = (
          <Stack height="100%">
            {hasDate && (
              <>
                <Typography
                  color={isThisMonth ? 'text.primary' : 'text.secondary'}
                  sx={(theme) => ({ textAlign: 'end', px: theme.spacing(0.5) })}
                >
                  {day}
                </Typography>

                {!isOpen && (
                  <AnnualPlannerGridLayoutSelectedCell
                    date={date}
                    highlight
                    sx={(theme) => ({
                      '.AnnualPlannerCalendarRecord-cell': {
                        width: 'calc(100% + 1px)',
                      },
                      '&.AnnualPlannerCalendar-MainGrid-highlight-start': {
                        '.AnnualPlannerCalendarRecord-cell': {
                          borderTopLeftRadius: theme.spacing(0.25),
                          borderBottomLeftRadius: theme.spacing(0.25),
                        },
                      },
                      '&.AnnualPlannerCalendar-MainGrid-highlight-end': {
                        '.AnnualPlannerCalendarRecord-title': {
                          borderTopRightRadius: theme.spacing(0.25),
                          borderBottomRightRadius: theme.spacing(0.25),
                        },
                      },
                      ':not(.AnnualPlannerCalendar-MainGrid-highlight-start, .AnnualPlannerCalendar-MainGrid-highlight-end)':
                        { '.AnnualPlannerCalendarRecord-cell::before': { content: 'none' } },
                      ...recordCellSx,
                    })}
                  />
                )}

                {isCreating && (
                  <AnnualPlannerGridLayoutSelectedCell
                    date={date}
                    type={selectedType}
                    start={isSameDay(date, selectedDate[0])}
                    end={!selectedDate[1] || isSameDay(date, selectedDate[1])}
                    sx={(theme) => ({
                      '.AnnualPlannerCalendarRecord-cell::before': {
                        content: isSelectedStart || isSelectedEnd ? undefined : 'none',
                      },
                      '& .AnnualPlannerCalendarRecord-cell': {
                        width: 'calc(100% + 1px)',
                        borderTopLeftRadius: isSelectedStart ? undefined : 0,
                        borderBottomLeftRadius: isSelectedStart ? undefined : 0,
                      },
                      '& .AnnualPlannerCalendarRecord-title': {
                        borderTopRightRadius: isSelectedEnd ? theme.spacing(0.25) : 0,
                        borderBottomRightRadius: isSelectedEnd ? theme.spacing(0.25) : 0,
                      },
                      ...recordCellSx,
                    })}
                  />
                )}

                {visibleRecords.map((record, index) => {
                  if (!record) return null;

                  return (
                    <AnnualPlannerGridLayoutMonthRecordCell
                      key={`${formattedDate}-${record.id}`}
                      records={visibleRecords}
                      record={record}
                      index={index}
                      isMoving={movingItem?.id === record.id}
                      anchorRecordId={anchorRecordId}
                      anchorTitles={anchorTitles}
                      start={start}
                      visible={!(isCreating && index === 0)}
                      isEdit={Boolean(isOpen && editingRecord && editingRecord.id === record.id)}
                      onClick={handleRecordClick(record.id)}
                      onMouseEnter={onRecordMouseEnter(record)}
                      onMouseLeave={onRecordMouseLeave}
                      onClose={handleRecordClose}
                      date={date}
                      sx={recordCellSx}
                    />
                  );
                })}

                {hasHiddenRecords && (
                  <AnnualPlannerGridLayoutMoreRecordsButton
                    key={hiddenRecordGroupId}
                    onClose={handleRecordClose}
                    isOpen={Boolean(anchorRecordId && anchorRecordId === hiddenRecordGroupId)}
                    onClick={handleRecordClick(hiddenRecordGroupId)}
                    records={hiddenRecords}
                    anchorTitles={anchorTitles}
                  />
                )}
              </>
            )}
          </Stack>
        );

        return (
          <AnnualPlannerCalendarCell
            key={formattedDate}
            data-date={formattedDate}
            className={[
              AnnualPlannerCalendarCell.defaultProps?.className ?? '',
              'AnnualPlannerCalendar-MainGrid-cell',
              hasDate
                ? 'AnnualPlannerCalendar-MainGrid-withDate'
                : 'AnnualPlannerCalendar-MainGrid-empty',
            ].join(' ')}
            sx={(theme) => ({
              borderRight: hasDate || hasNeighbourDate ? theme.mixins.borderValue() : undefined,
              borderBottom: hasDate || hasBelowDate ? theme.mixins.borderValue() : undefined,
              backgroundColor: hasDate && isWeekEnd ? theme.palette.background.default : undefined,

              ...(!hasDate &&
                isMonday && {
                  borderLeft: theme.mixins.borderValue(),
                  ml: '-1px',
                  borderLeftColor: 'background.paper',
                }),

              //hides first record in cell on create
              '&:has(.AnnualPlannerCalendar-MainGrid-highlight-visible) .AnnualPlannerCalendarRecordGroup-root:nth-of-type(2)':
                { display: 'none' },
            })}
            onMouseDown={(event) => {
              hasDate && onCellMouseDown(event, date);
              handleRecordClose();
            }}
          >
            <AnnualPlannerCalendarDateTooltip
              title={
                isOpen || !hasDate ? undefined : format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)
              }
            >
              {content}
            </AnnualPlannerCalendarDateTooltip>
          </AnnualPlannerCalendarCell>
        );
      })}
    </AnnualPlannerCalendarGrid>
  );
};
