import {
  Button,
  Icon,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AnalyticsEventName, trackAmplitudeEvent } from '@schooly/analytics';
import { SchoolProperty } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SchoolPropertyType } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  DeleteIcon,
  DragIcon,
  LeavingReasonIcon,
  PlusIcon,
  RollBackIcon,
  SettingsIcon,
} from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { FieldArrayPath, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import { ControlTextField } from '../../../../../components/uikit-components/FormTextField/ControlTextField';
import { SchoolGeneralRowAction } from '../../../SchoolGeneral/SchoolGeneralRowAction';
import { SchoolGeneralStatusesReplaceModal } from '../../../SchoolGeneral/SchoolGeneralStatuses/SchoolGeneralStatusesReplaceModal';
import {
  SchoolTuneStatus,
  SchoolTuneStatusesArchivable,
  SchoolTuneStatusesForm,
  SchoolTuneStatusType,
} from './scheme';

export const getEmptyStatus = (): SchoolTuneStatus => ({
  id: '',
  originId: '',
  replaceId: '',
  name: '',
  type: SchoolPropertyType.Status,
  conduct_default: false,
  group_default: false,
  student_default: false,
  staff_default: false,
  archived: false,
  order: 0,
  category: {},
});

const FORMER_STATUS_TOOLTIP_TIMEOUT = 5000;

export interface SchoolTuneStatusesModalCategoryProps extends SchoolTuneStatusesArchivable {
  type: keyof SchoolTuneStatusesForm;
  category: keyof SchoolTuneStatusType;
  title: string;
  showReEnrollmentStatuses?: boolean;
  leavingStatusId?: string;
}

export const SchoolTuneStatusesModalCategory: FC<SchoolTuneStatusesModalCategoryProps> = ({
  type,
  category,
  title,
  showArchived,
  setShowArchived,
  autoSwitchedArchivedOn,
  showReEnrollmentStatuses,
  leavingStatusId,
}) => {
  const { $t } = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { permissions } = useAuth();

  const [isFormerStatusTooltipVisible, showFormerStatusTooltip, hideFormerStatusTooltip] =
    useFlag();
  const { getValues, control } = useFormContext<SchoolTuneStatusesForm>();

  const tooltipPopper = useRef<HTMLDivElement | null>(null);

  const name: FieldArrayPath<SchoolTuneStatusesForm> = `${type}.${category}`;

  const { fields, append, remove, replace, move, update } = useFieldArray({ control, name });

  const [archivingFieldIndex, setArchivingFieldIndex] = useState<number>();

  useEffect(() => {
    showFormerStatusTooltip();

    setTimeout(() => {
      hideFormerStatusTooltip();
    }, FORMER_STATUS_TOOLTIP_TIMEOUT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // (TR-4607): Have to spread active & archived fields to two different lists and
  // render/manage them separately.
  const [activeFields, archivedFields] = useMemo(
    () =>
      fields.reduce<Array<Array<{ index: number; field: typeof fields[number] }>>>(
        (prev, field, index) => {
          if (!showReEnrollmentStatuses && field.source?.type === 're_enrollment') {
            return prev;
          }
          prev[field.archived ? 1 : 0].push({ index, field });
          return prev;
        },
        [[], []],
      ),
    [fields, showReEnrollmentStatuses],
  );

  const prevActiveFields = usePrevious(activeFields);

  const firstActiveField = getValues(`${name}.${activeFields[0]?.index}`);

  const shouldFocusLastItem = Boolean(
    activeFields.length === 1
      ? !firstActiveField.name
      : prevActiveFields && activeFields && activeFields.length - prevActiveFields.length === 1,
  );

  const handleCloseReplaceModal = useCallback(() => {
    setArchivingFieldIndex(undefined);
  }, []);

  const addStatus = useCallback(() => {
    append(getEmptyStatus());
  }, [append]);

  const archiveStatus = useCallback(
    (index: number, replaceId: SchoolProperty['id'] = '') => {
      const field = getValues(`${name}.${index}`);

      update(index, { ...field, archived: true, replaceId });

      // TR-4607: should switch on `Show archived` first time if no archived fields were before
      if (!archivedFields.length && autoSwitchedArchivedOn && !autoSwitchedArchivedOn?.current) {
        setShowArchived?.(true);
        autoSwitchedArchivedOn.current = true;
      }
    },
    [archivedFields.length, autoSwitchedArchivedOn, getValues, name, setShowArchived, update],
  );

  const handleDeleteStatus = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([]);
      }
    },
    [fields.length, remove, replace],
  );

  const handleArchiveStatus = useCallback((index: number) => {
    setArchivingFieldIndex(index);
  }, []);

  const handleReplaceStatus = useCallback(
    (index: number) => (replaceId: SchoolProperty['id']) => {
      archiveStatus(index, replaceId);
      setArchivingFieldIndex(undefined);
    },
    [archiveStatus],
  );

  const handleArchiveStatusWithoutReplacing = useCallback(
    (index: number) => () => {
      archiveStatus(index);
      setArchivingFieldIndex(undefined);
    },
    [archiveStatus],
  );

  const handleRestoreStatus = useCallback(
    (index: number) => async () => {
      const field = getValues(`${name}.${index}`);
      // can not just use `update` as need to move to the end
      remove(index);
      append({ ...field, archived: false });
    },
    [append, getValues, name, remove],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  return (
    <Stack gap={1} height="100%">
      <Typography variant="h4">{title}</Typography>
      <Paper
        elevation={0}
        sx={{ flex: '1 1 100%', p: 1, bgcolor: 'background.default', overflowY: 'auto' }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`StatusCategoryDroppable-${name}`}>
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                alignItems="flex-start"
                gap={2}
              >
                {activeFields.map(({ field, index }, arrayIndex) => {
                  return (
                    <Draggable key={field.id} draggableId={field.id} index={index}>
                      {(provided) => (
                        <Stack
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          direction="row"
                          alignItems="center"
                          gap={1}
                          sx={{ width: '100%', bgcolor: 'background.default' }}
                          style={provided.draggableProps.style}
                        >
                          {activeFields.length > 1 && (
                            <IconButton inverse {...provided.dragHandleProps}>
                              <DragIcon />
                            </IconButton>
                          )}
                          <ControlTextField
                            name={`${name}.${index}.name`}
                            control={control}
                            rules={{
                              required: true,
                              validate: (value, formValues) => {
                                // Check for name uniqueness
                                for (const category of Object.keys(
                                  formValues[type],
                                ) as (keyof SchoolTuneStatusType)[]) {
                                  for (let i = 0; i < formValues[type][category].length; i++) {
                                    if (i === index) {
                                      continue;
                                    }

                                    const field = formValues[type][category][i];

                                    if (
                                      field.name?.trim().toLowerCase() ===
                                      `${value ?? ''}`.trim().toLowerCase()
                                    ) {
                                      return $t({
                                        id: field.archived
                                          ? 'school-tabs-Statuses-ArchivedExists'
                                          : 'school-tabs-Statuses-ActiveExists',
                                      });
                                    }
                                  }
                                }

                                return true;
                              },
                            }}
                            customIcon={
                              category === 'former' &&
                              field.originId &&
                              field.originId === leavingStatusId && (
                                <Tooltip
                                  PopperProps={{
                                    ref: tooltipPopper,
                                  }}
                                  title={
                                    <Stack gap={1}>
                                      {$t({ id: 'school-tabs-LeavingReasons-FormerTooltip' })}
                                      {pathname === '/settings/statuses' && (
                                        <Stack
                                          sx={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            gap: 1,
                                            cursor: 'pointer',
                                            pointerEvents: 'auto',
                                          }}
                                          onClick={() => {
                                            trackAmplitudeEvent({
                                              name: AnalyticsEventName.RLOpen,
                                              payload: { user_role: permissions },
                                            });
                                            navigate('/settings/rollover_and_leaving');
                                          }}
                                        >
                                          <Icon>
                                            <SettingsIcon />
                                          </Icon>

                                          <Typography>
                                            <FormattedMessage id="school-tabs-LeavingReasons-ChangeFormerStatus" />
                                          </Typography>
                                        </Stack>
                                      )}
                                    </Stack>
                                  }
                                  open={isFormerStatusTooltipVisible}
                                  onMouseEnter={showFormerStatusTooltip}
                                >
                                  <IconButton>
                                    <LeavingReasonIcon />
                                  </IconButton>
                                </Tooltip>
                              )
                            }
                            label={$t({ id: 'school-tabs-Statuses-StatusName' })}
                            autoFocus={
                              shouldFocusLastItem && arrayIndex === activeFields.length - 1
                            }
                            solid
                            fullWidth
                            hideLabel
                          />

                          {setShowArchived ? (
                            activeFields.length > 0 && (
                              <SchoolGeneralRowAction
                                id={field.originId}
                                index={index}
                                isLocked={
                                  field.conduct_default ||
                                  field.group_default ||
                                  field.student_default ||
                                  field.staff_default ||
                                  field.re_enrollment_default
                                }
                                lockMessage={$t({
                                  id: field.re_enrollment_default
                                    ? 'school-tabs-Statuses-Tooltip-ReEnrollmentDefault'
                                    : 'school-tabs-Statuses-Tooltip-DefaultFilter',
                                })}
                                onArchive={handleArchiveStatus}
                                onDelete={handleDeleteStatus}
                              />
                            )
                          ) : (
                            <>
                              {!field.originId && (
                                <IconButton inverse onClick={() => handleDeleteStatus(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                        </Stack>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}

                <Button variant="text" startIcon={<PlusIcon />} onClick={addStatus}>
                  <FormattedMessage id="school-tabs-Statuses-AddStatus" />
                </Button>

                {showArchived &&
                  archivedFields.map(({ field, index }) => (
                    <Stack
                      key={field.id}
                      direction="row"
                      alignItems="center"
                      gap={1}
                      pl={3.5}
                      width="100%"
                    >
                      <TextField
                        value={field.name}
                        sx={{
                          '&&& .MuiOutlinedInput-notchedOutline': { borderColor: 'common.light3' },
                        }}
                        fullWidth
                        disabled
                        label={$t({ id: 'school-tabs-Statuses-StatusName' })}
                      />

                      <IconButton onClick={handleRestoreStatus(index)}>
                        <RollBackIcon />
                      </IconButton>
                    </Stack>
                  ))}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Paper>

      {archivingFieldIndex != null && (
        <SchoolGeneralStatusesReplaceModal
          group={title}
          field={fields[archivingFieldIndex]}
          fields={fields}
          onClose={handleCloseReplaceModal}
          onReplace={handleReplaceStatus(archivingFieldIndex)}
          onArchiveWithoutReplacing={handleArchiveStatusWithoutReplacing(archivingFieldIndex)}
        />
      )}
    </Stack>
  );
};
