import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import {
  ArrowRightIcon,
  CrossIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLarge,
  Spin,
} from '@schooly/style';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useRouter } from '../../../../../context/router/useRouter';
import { useNotificationSettings } from '../../../../../hooks/useNotificationSettings';
import { useSchool } from '../../../../../hooks/useSchool';
import { SchoolCreateEmailsForm } from '../../../SchoolCreate/useSchoolCreate';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';

export const SchoolTuneNotificationsModal = () => {
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const { currentSchool } = useSchool();
  const { $t, formatMessage } = useIntl();

  const { updateInternalEmailsNotifications, loadingUpdateNotificationSettings } =
    useNotificationSettings();

  const form = useForm<SchoolCreateEmailsForm>({
    defaultValues: {
      admissionsEmail: '',
      invoicingEmail: '',
    },
  });

  const onSubmit = useCallback<SubmitHandler<SchoolCreateEmailsForm>>(
    async (data) => {
      await updateInternalEmailsNotifications({
        admissions_email: data.admissionsEmail,
        invoicing_email: data.invoicingEmail,
      });

      navigate('/');
    },
    [navigate, updateInternalEmailsNotifications],
  );

  return (
    <SchoolSettingsLayout>
      <ModalLarge
        open
        onClose={goBack}
        sx={{
          '& .MuiDialog-paperFullWidth': {
            maxWidth: 700,
            height: 'unset',
          },
        }}
      >
        <ModalHeader active title={currentSchool?.name}>
          <IconButton onClick={goBack}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <ModalContent active>
              <Stack gap={1.5}>
                <Typography variant="h1">
                  {$t({ id: 'school-create-EmailsForNotificationsTitle' })}
                </Typography>

                <Typography variant="h3">
                  {$t({ id: 'school-create-EmailsForNotificationsDescription' })}
                </Typography>
              </Stack>

              <Stack gap={2} mt={2.5}>
                <ControlTextField
                  control={form.control}
                  name="admissionsEmail"
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: formatMessage({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                  label={$t({ id: 'school-sections-Notifications-Internal-AdmissionsEmail' })}
                />

                <ControlTextField
                  control={form.control}
                  name="invoicingEmail"
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: formatMessage({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                  label={$t({ id: 'school-sections-Notifications-Internal-InvoicingEmail' })}
                />
              </Stack>
            </ModalContent>

            <ModalFooter active sx={{ justifyContent: 'space-between' }}>
              <Button variant="outlined" onClick={goBack}>
                <FormattedMessage id="action-Back" />
              </Button>

              <Button
                type="submit"
                endIcon={loadingUpdateNotificationSettings ? <Spin /> : <ArrowRightIcon />}
              >
                <FormattedMessage id="action-LetsStart" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalLarge>
    </SchoolSettingsLayout>
  );
};
