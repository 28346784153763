import { Theme } from '@emotion/react';
import { Box, ClickAwayListener, Icon, Stack, SxProps, Tooltip } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { LockIcon, Spin } from '@schooly/style';
import { differenceInDays, format, getDay, isBefore, isSameDay } from 'date-fns';
import { FC, MouseEventHandler, MutableRefObject, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import {
  AnnualPlannerCalendarRecordCell,
  AnnualPlannerCalendarRecordEnd,
  AnnualPlannerCalendarRecordGroup,
  AnnualPlannerCalendarRecordTitle,
  AnnualPlannerCalendarRecordTitleTypography,
} from '../../AnnualPlannerCalendar.styled';
import {
  ANNUAL_PLANNER_MONTH_RECORD_CELL_HEIGHT,
  AnnualPlannerRecordMeta,
  canBeRecurring,
} from '../../scheme';
import { getRecordIncompleteFields, useGetRecordLockHint } from '../../utils';
import { AnnualPlannerRecordsLayoutCellGroup } from '../records/AnnualPlannerRecordsLayoutCellGroup';
import { AnnualPlannerRecordsLayoutRecordDetails } from '../records/AnnualPlannerRecordsLayoutRecordDetails';

interface AnnualPlannerGridLayoutMonthRecordCellProps {
  records: Array<AnnualPlannerRecordMeta | null>;
  record: AnnualPlannerRecordMeta;
  date: Date;
  index: number;
  isMoving: boolean;
  start: Date;
  anchorRecordId?: string;
  visible: boolean;
  isEdit: boolean;
  onClick: () => void;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onClose: () => void;
  anchorTitles: MutableRefObject<Record<string, HTMLElement | null> | undefined>;
  sx?: SxProps<Theme>;
}

export const AnnualPlannerGridLayoutMonthRecordCell: FC<AnnualPlannerGridLayoutMonthRecordCellProps> =
  ({
    records,
    record,
    date,
    index,
    isMoving,
    start,
    anchorRecordId,
    visible,
    isEdit,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onClose,
    anchorTitles,
    sx,
  }) => {
    const { formatMessage } = useIntl();
    const ref = useRef<HTMLDivElement | null>();
    const width = ref.current?.clientWidth ?? 170;
    const getRecordLockHint = useGetRecordLockHint();

    //counts gaps
    const position = useMemo(
      () => records.slice(0, index).reduce((acc, r) => (r === null ? acc + 1 : 0), 0),
      [index, records],
    );

    const isStart = isSameDay(date, record.start);
    const isEnd = isSameDay(date, record.end);

    const weekDay = getDay(date);
    const isMonday = weekDay === 1;
    const isSunday = weekDay === 0;
    const continuesToPrevWeek = !isStart && isMonday;
    const continuesToNextWeek = !isEnd && isSunday;
    const continuesToPrevPeriod = isBefore(record.start, start);

    const isStartInPrevPeriod = continuesToPrevPeriod && isSameDay(date, start);

    const isOpenGroup = anchorRecordId === record.id;
    const isOpenOrEdit = isOpenGroup || isEdit;

    const isOpenEditInPrevPeriod = isOpenOrEdit && isStartInPrevPeriod;

    const showRightArrow = continuesToNextWeek && !(isOpenOrEdit && isSunday && isStart);
    const showLeftArrow = continuesToPrevWeek && !(isOpenOrEdit && isStartInPrevPeriod);

    const recurrenceId = canBeRecurring(record)
      ? record.details.recurring_state?.recurrence_id
      : '';

    const isIncomplete = getRecordIncompleteFields(record).length > 0;

    const lockHint = getRecordLockHint(record);

    const daysCount = differenceInDays(record.end, record.start) + 1;

    return (
      <AnnualPlannerRecordsLayoutCellGroup
        className={[
          AnnualPlannerCalendarRecordGroup.defaultProps?.className,
          `AnnualPlannerCalendarRecordGroup-${record.type}`,
          (isOpenOrEdit || isMoving) && 'AnnualPlannerCalendarRecordGroup-active',
        ]
          .filter(Boolean)
          .join(' ')}
        draggable={!lockHint}
        arrowLeft={showLeftArrow}
        arrowRight={showRightArrow}
        incomplete={isIncomplete}
        data-hover-id={recurrenceId || record.id}
        type={record.type}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          onClick();
          e.stopPropagation();
        }}
        group={{
          id: record.id,
          position: [],
          type: record.type,
          records: [record],
          start: record.start,
          end: record.end,
        }}
        sx={(theme) => ({
          position: 'relative',
          display: visible ? 'grid' : 'none',
          ml: (isStart || isMonday) && !isOpenEditInPrevPeriod ? 0.25 : 0,
          mr: isEnd || (isSunday && !isOpenOrEdit) ? 0.25 : 0,
          mt: `${(ANNUAL_PLANNER_MONTH_RECORD_CELL_HEIGHT + 2) * position}px`,
          '& .AnnualPlannerCalendarRecord-title': {
            borderTopRightRadius: isEnd ? theme.spacing(0.25) : 0,
            borderBottomRightRadius: isEnd ? theme.spacing(0.25) : 0,
          },
          ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        })}
        dragPreviewProps={{
          sx: (theme) => ({
            width: daysCount * width,
            '& .AnnualPlannerCalendarRecord-title': {
              height: ANNUAL_PLANNER_MONTH_RECORD_CELL_HEIGHT,
              width: width,
              borderBottomRightRadius: isEnd ? theme.spacing(0.25) : 0,
            },
          }),
        }}
      >
        <AnnualPlannerCalendarRecordCell
          ref={ref}
          type={record.type}
          incomplete={isIncomplete}
          sx={{
            borderRight: isEnd ? undefined : 'none',
            '&::before': {
              content: isStart ? undefined : 'none',
            },
            width: isEnd ? undefined : 'calc(100% + 1px)',
            borderTopLeftRadius: isStart ? undefined : 0,
            borderTopRightRadius: isEnd ? undefined : 0,
            borderBottomLeftRadius: isStart ? undefined : 0,
            borderBottomRightRadius: isEnd ? undefined : 0,
          }}
        />

        {isEdit && isEnd && !isStart && (
          <AnnualPlannerCalendarRecordEnd
            type={record.type}
            sx={(theme) => ({
              position: 'absolute',
              height: '100%',
              right: 0,
              bottom: 0,
              left: theme.spacing(-0.25),
              borderTopRightRadius: theme.spacing(0.25),
            })}
          >
            <AnnualPlannerCalendarRecordTitleTypography ml={0.5}>
              {format(record.end, SHORT_FORMATTED_DATE_FORMAT_FNS)}
            </AnnualPlannerCalendarRecordTitleTypography>
          </AnnualPlannerCalendarRecordEnd>
        )}

        {(isStart || isStartInPrevPeriod) && (
          <>
            <ClickAwayListener onClickAway={onClose} mouseEvent={isOpenGroup ? undefined : false}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      p: 0,
                      maxWidth: 'unset',
                      width: 330,
                    },
                  },
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -7],
                        },
                      },
                    ],
                  },
                }}
                title={
                  <Stack sx={{ p: 1 }}>
                    <AnnualPlannerRecordsLayoutRecordDetails
                      record={record}
                      anchorEl={anchorTitles.current?.[record.id]}
                      onClose={onClose}
                    />
                  </Stack>
                }
                open={isOpenGroup}
                onClose={onClose}
                placement="bottom"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
              >
                <AnnualPlannerCalendarRecordTitle
                  ref={(el) => {
                    if (!anchorTitles.current) {
                      anchorTitles.current = {};
                    }

                    anchorTitles.current![record.id] = el;
                  }}
                  type={record.type}
                  sx={{
                    ...(isStartInPrevPeriod && {
                      ml: isOpenOrEdit ? 0 : 1,
                      pl: isOpenOrEdit ? 0.5 : undefined,
                    }),
                  }}
                >
                  {lockHint ? (
                    <Tooltip
                      title={formatMessage({ id: lockHint })}
                      PopperProps={{
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            px: 0.5,
                            py: 0.25,
                            maxWidth: 'unset',
                            whiteSpace: 'nowrap',
                          },
                        },
                      }}
                    >
                      <Icon
                        sx={(theme) => ({
                          mr: theme.spacing(0.25),
                          fontSize: theme.spacing(1.5),
                          pointerEvents: 'all',
                          zIndex: 1,
                        })}
                      >
                        <LockIcon />
                      </Icon>
                    </Tooltip>
                  ) : (
                    <Box sx={{ pl: 0.25 }} />
                  )}

                  <AnnualPlannerCalendarRecordTitleTypography>
                    {isEdit ? format(date, SHORT_FORMATTED_DATE_FORMAT_FNS) : record.title}
                  </AnnualPlannerCalendarRecordTitleTypography>

                  {isMoving && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      sx={{ flex: '1 1 auto', ml: 0.25, zIndex: 1 }}
                    >
                      <Spin />
                    </Stack>
                  )}
                </AnnualPlannerCalendarRecordTitle>
              </Tooltip>
            </ClickAwayListener>
          </>
        )}
      </AnnualPlannerRecordsLayoutCellGroup>
    );
  };
