import { useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';

export const useAnnualPlannerDropLayout = () => {
  const { isDragging } = useDragLayer((monitor) => ({ isDragging: monitor.isDragging() }));

  const [zIndex, setZIndex] = useState<number | undefined>(-1);

  /*
   * Changes `zIndex` on next tick as react-dnd starts really dragging on next tick after
   * the `isDragging` flag is set.
   * If set zIndex synchronously, the real drag is not yet started which will break
   * the entire process as the layout will cover the dragging item from above.
   */
  useEffect(() => {
    setTimeout(() => {
      setZIndex(isDragging ? undefined : -1);
    }, 0);
  }, [isDragging]);

  return { zIndex };
};
