import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  ActivityCycle,
  MAX_PAGE_SIZE,
  PayableFee,
  PayableFeeInvoiceLineItem,
  PayableFeeLineItem,
  PaymentFrequencyType,
  Product,
  RegistrationStatus,
  useGetPayableFeesQuery,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { LockIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import {
  areIntervalsOverlapping,
  isBefore,
  isToday,
  isWithinInterval,
  startOfToday,
} from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DisabledRegistrationStatus } from './StudentRegistrationForm';

// Temporary hook to make restrictions on editing registration based on invoicing info.
// Backend support wasn’t available (TR-7113), so this hook makes extra requests to get the needed data.
// This should be optimized with backend support in the future.

type PayableFeeItem = PayableFeeLineItem | PayableFeeInvoiceLineItem;

type UseInvoicingRegistrationRestrictionsProps = {
  yearId: string;
  schoolId: string;
  studentId: string;
  statuses: RegistrationStatus[];
  products: Product[];
};

export const useInvoicingRegistrationRestrictions = ({
  yearId,
  schoolId,
  studentId,
  statuses,
  products,
}: UseInvoicingRegistrationRestrictionsProps) => {
  const { formatMessage } = useIntl();

  const enabled = !!yearId && !!schoolId && !!studentId;

  const { data: payableFeesData, isFetching: isFetchingPayableFees } = useGetPayableFeesQuery(
    {
      year_id: yearId,
      school_ids: schoolId,
      filters: { relation_ids: [studentId] },
      page_size: MAX_PAGE_SIZE,
    },
    { refetchOnMount: 'always', enabled },
  );

  const { data: frequenciesData, isFetching: isFetchingFrequencies } =
    useGetSchoolPaymentFrequencies({ school_id: schoolId, year_id: yearId }, { enabled });

  const items = useMemo(() => {
    const entries =
      payableFeesData?.pages.reduce<PayableFee[]>((prev, curr) => [...prev, ...curr.results], []) ??
      [];
    const today = startOfToday();
    const invoices = entries.filter((e) => {
      const issueDate = newDateTimezoneOffset(e.issue_date);
      return (isToday(issueDate) || isBefore(issueDate, today)) && e.status !== 'voided';
    });
    return invoices.reduce<Array<PayableFeeItem & { generationDate: string }>>(
      (prev, { items, issue_date }) => [
        ...prev,
        ...items.map((item) => ({
          ...item,
          generationDate: issue_date,
        })),
      ],
      [],
    );
  }, [payableFeesData?.pages]);

  const { oneOffTriggerStatuses, recurringTriggerStatuses } = useMemo(() => {
    //get trigger status for each line item
    const triggerStatusesByProduct = items.reduce<Record<string, string>>((acc, item) => {
      if (!acc[item.product_id]) {
        const product = products.find((p) => p.id === item.product_id);
        const triggerStatusId = product?.triggers.find((t) => t.extra_data)?.extra_data?.status;

        if (product && triggerStatusId) {
          acc[item.product_id] = triggerStatusId;
        }
      }
      return acc;
    }, {});

    //get activityCycles for recurring products and generationDates for one-off products
    const { activityCycles, generationDates } = items.reduce<{
      activityCycles: Record<string, Array<ActivityCycle>>;
      generationDates: Record<string, Array<string>>;
    }>(
      (acc, item) => {
        const frequency = frequenciesData?.frequencies.find((f) => f.id === item.frequency_id);
        if (!frequency) return acc;

        if (frequency.type === PaymentFrequencyType.OneOff) {
          acc.generationDates[item.product_id] = [
            ...(acc.generationDates[item.product_id] ?? []),
            item.generationDate,
          ];
        } else {
          const billingPeriodIndex = frequency.billing_periods.findIndex((period, index) => {
            //activity cycle start was in the future when line item was generated
            const isSameInvoiceDate = period.invoice_date === item.invoice_date;
            if (isSameInvoiceDate) return true;

            //activity cycle start has come when line item was generated
            const billingPeriodStart = newDateTimezoneOffset(period.generation_date);
            const activityCycleEnd = newDateTimezoneOffset(frequency.activity_cycles[index].end);

            return isWithinInterval(newDateTimezoneOffset(item.invoice_date), {
              start: billingPeriodStart,
              end: activityCycleEnd,
            });
          });

          if (billingPeriodIndex >= 0) {
            const productActivityPeriod = frequency.activity_cycles[billingPeriodIndex];
            acc.activityCycles[item.product_id] = [
              ...(acc.activityCycles[item.product_id] ?? []),
              productActivityPeriod,
            ];
          }
        }

        return acc;
      },
      {
        activityCycles: {},
        generationDates: {},
      },
    );

    const productIds = Object.keys(triggerStatusesByProduct);

    const { triggerStatusesForRecurringProducts, triggerStatusesForOneOffProducts } =
      productIds.reduce<{
        triggerStatusesForRecurringProducts: Set<string>;
        triggerStatusesForOneOffProducts: Set<string>;
      }>(
        (acc, productId) => {
          const productActivityCycles = activityCycles[productId];
          const productGenerationDates = generationDates[productId];
          const status = statuses.find(
            (s) => s.school_property.id === triggerStatusesByProduct[productId],
          );

          if (!status) return acc;

          if (productActivityCycles) {
            productActivityCycles.forEach((cycle) => {
              const areOverlapping = areIntervalsOverlapping(
                {
                  start: newDateTimezoneOffset(status.applies_from),
                  end: newDateTimezoneOffset(status.applies_to),
                },
                {
                  start: newDateTimezoneOffset(cycle.start),
                  end: newDateTimezoneOffset(cycle.end),
                },
                { inclusive: true },
              );
              if (areOverlapping) {
                acc.triggerStatusesForRecurringProducts.add(status.school_property.id);
              }
            });
          }

          if (productGenerationDates) {
            productGenerationDates.forEach((date) => {
              const areOverlapping = isWithinInterval(newDateTimezoneOffset(date), {
                start: newDateTimezoneOffset(status.applies_from),
                end: newDateTimezoneOffset(status.applies_to),
              });
              if (areOverlapping) {
                acc.triggerStatusesForOneOffProducts.add(status.school_property.id);
              }
            });
          }

          return acc;
        },
        {
          triggerStatusesForRecurringProducts: new Set(),
          triggerStatusesForOneOffProducts: new Set(),
        },
      );

    return {
      oneOffTriggerStatuses: Array.from(triggerStatusesForOneOffProducts),
      recurringTriggerStatuses: Array.from(triggerStatusesForRecurringProducts),
    };
  }, [frequenciesData?.frequencies, items, products, statuses]);

  const triggerStatusIds = Array.from(
    new Set([...oneOffTriggerStatuses, ...recurringTriggerStatuses]),
  );

  const disabledStatuses = statuses.reduce<DisabledRegistrationStatus[]>((acc, status) => {
    if (status.school_property.id && triggerStatusIds.includes(status.school_property.id)) {
      acc = [
        ...acc,
        {
          formId: status.id,
          disabledFields: ['school_property_id'],
          endAdornment: <></>,
          lockIcon: (
            <Tooltip
              componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
              title={
                <>
                  <Typography mb={2}>
                    {formatMessage({ id: 'students-CannotRemoveStatusWithInvoices-1' })}
                  </Typography>
                  <Typography>
                    {formatMessage({ id: 'students-CannotRemoveStatusWithInvoices-2' })}
                  </Typography>
                </>
              }
            >
              <IconButton inverse>
                <LockIcon />
              </IconButton>
            </Tooltip>
          ),
        },
      ];
    }

    return acc;
  }, []);

  const checkCanEditStatus = useCallback(
    (statusPropertyId: string) => !triggerStatusIds.includes(statusPropertyId),
    [triggerStatusIds],
  );

  return {
    isFetching: isFetchingFrequencies || isFetchingPayableFees,
    disabledStatuses,
    canEditAgeGroup: !recurringTriggerStatuses.length,
    checkCanEditStatus,
    recurringTriggerStatuses,
  };
};
