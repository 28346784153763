import { Typography } from '@mui/material';
import {
  GetPayableFeesStatisticsResponse,
  InvoiceDiscrepancyRecord,
  InvoiceDiscrepancyStatus,
  InvoiceDiscrepancyType,
  PayableFee,
  PayableFeesStatisticsItem,
  PayableFeeStatus,
  PayableFeeWithInvoice,
  WithName,
} from '@schooly/api';
import { Currencies } from '@schooly/constants';
import { theme } from '@schooly/style';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const payableFeeStatusColor: Record<PayableFeeStatus, string> = {
  paid: theme.palette.success.main,
  unpaid: theme.palette.common.orange,
  partially_paid: theme.palette.common.coral,
  overdue: theme.palette.error.main,
  upcoming: theme.palette.primary.main,
  projected: theme.palette.primary.main,
  voided: theme.palette.common.grey,
  cancelled: theme.palette.common.grey,
  due: theme.palette.primary.main,
};

export function getStudentName<T extends WithName>(student: T) {
  return student.known_as || student.given_name || '';
}

export type PayableFeesStatisticsMapItem = {
  items: PayableFeesStatisticsItem[];
  total: number;
};

type PayableFeesStatisticsMap = Partial<Record<Currencies, PayableFeesStatisticsMapItem>>;

export const getChartStatisticsByCurrencyMap = (
  statistics?: GetPayableFeesStatisticsResponse['statistics'],
) => {
  return statistics?.reduce<PayableFeesStatisticsMap>((acc, { currency, items, total }) => {
    acc[currency] = { items, total };
    return acc;
  }, {});
};

const sortDiscrepancyRecords = (records: InvoiceDiscrepancyRecord[]) => {
  const itemsByOldIds = new Map<string, InvoiceDiscrepancyRecord[]>();
  const newIds = new Set<string>();

  records.forEach((record) => {
    if (!itemsByOldIds.has(record.old_invoice_item_id)) {
      itemsByOldIds.set(record.old_invoice_item_id, []);
    }
    itemsByOldIds.get(record.old_invoice_item_id)?.push(record);

    if (record.new_invoice_item_id) {
      newIds.add(record.new_invoice_item_id);
    }
  });

  const uniqueStartItemIds = new Set<string>();
  const startItems: InvoiceDiscrepancyRecord[] = [];

  records.forEach((record) => {
    if (
      !newIds.has(record.old_invoice_item_id) &&
      !uniqueStartItemIds.has(record.old_invoice_item_id)
    ) {
      startItems.push(record);
      uniqueStartItemIds.add(record.old_invoice_item_id);
    }
  });

  const sortedRecords: InvoiceDiscrepancyRecord[] = [];

  const processChain = (startItem: InvoiceDiscrepancyRecord) => {
    let currentItem: InvoiceDiscrepancyRecord | null = startItem;
    while (currentItem) {
      const items: InvoiceDiscrepancyRecord[] =
        itemsByOldIds.get(currentItem.old_invoice_item_id) ?? [];

      sortedRecords.push(...items);

      const nextId = items[0].new_invoice_item_id ?? '';
      const nextItem = nextId ? itemsByOldIds.get(nextId)?.[0] : null;

      currentItem = nextItem ?? null;
    }
  };

  startItems.forEach((item) => processChain(item));

  return sortedRecords;
};

type InvoiceDiscrepancyRecordValue = string | number | null;
type DiscrepanciesByItems = {
  [key: string]: {
    [type in InvoiceDiscrepancyType]?: {
      initValue?: InvoiceDiscrepancyRecordValue;
      discrepancies: Pick<InvoiceDiscrepancyRecord, 'date' | 'type' | 'status'>[];
    };
  };
};

export const useInvoiceDiscrepancyRecords = (discrepancyRecords: InvoiceDiscrepancyRecord[]) => {
  const { formatMessage } = useIntl();

  const discrepancyRecordsByItems: DiscrepanciesByItems = useMemo(() => {
    // Sort by new and old ids in order of changes application
    const sortedDiscrepancyRecords = sortDiscrepancyRecords(discrepancyRecords);

    return sortedDiscrepancyRecords.reduce((acc, record) => {
      const {
        new_invoice_item_id: newItemId,
        old_invoice_item_id: oldItemId,
        old_value: oldValue,
        new_value: newValue,
        type,
        date,
        status,
      } = record;

      const oldItem =
        newItemId && acc[newItemId]
          ? // One action caused multiple changes
            acc[newItemId]
          : // One action caused one change
            acc[oldItemId] ??
            // First change;
            {};

      // Ignore changes that were applied
      if (status === InvoiceDiscrepancyStatus.Reconciled) {
        if (newItemId) {
          acc[newItemId] = {
            ...oldItem,
          };
          delete acc[oldItemId];
        }

        return acc;
      }

      // Item has been deleted
      if (!newItemId) {
        acc[oldItemId] = {
          ...oldItem,
          [type]: { discrepancies: [{ date, type, status }] },
        };
        return acc;
      }

      // First change of this type
      if (!oldItem[type]) {
        acc[newItemId] = {
          ...oldItem,
          [type]: { initValue: oldValue, discrepancies: [{ date, type, status }] },
        };
        // Rewrite previous change of this type
      } else if (oldItem[type]?.initValue !== newValue) {
        const discrepancies = oldItem[type]?.discrepancies ?? [];

        acc[newItemId] = {
          ...oldItem,
          [type]: {
            ...oldItem[type],
            discrepancies: discrepancies.some((d) => d.date === date)
              ? discrepancies
              : [...discrepancies, { date, type, status }],
          },
        };
      } else {
        // Change is reverted to initial
        acc[newItemId] = { ...oldItem };
        delete acc[newItemId][type];
      }

      delete acc[oldItemId];

      return acc;
    }, {} as DiscrepanciesByItems);
  }, [discrepancyRecords]);

  const discrepantItemIds = Object.keys(discrepancyRecordsByItems);

  const allDiscrepancyTypes = Object.values(discrepancyRecordsByItems).flatMap(
    (item) => Object.keys(item) as InvoiceDiscrepancyType[],
  );

  const invoiceDiscrepancyLabelIdsByTypes: {
    [type in InvoiceDiscrepancyType]?: string;
  } = allDiscrepancyTypes.reduce((acc, t) => {
    acc[t] = formatMessage({
      id: acc[t] ? `payableFees-Discrepancy-Type-${t}-plural` : `payableFees-Discrepancy-Type-${t}`,
    });

    return acc;
  }, {} as { [type in InvoiceDiscrepancyType]?: string });

  const invoiceDiscrepancyLabelIds = Object.values(invoiceDiscrepancyLabelIdsByTypes);
  const invoiceDiscrepancyTitleId = discrepancyRecords.some(
    (r) => r.status === InvoiceDiscrepancyStatus.Unreconcilable,
  )
    ? 'payableFees-Discrepancy-InvoiceTooltipTitle-Unreconcilable'
    : 'payableFees-Discrepancy-InvoiceTooltipTitle-Unprocessed';

  const getItemDiscrepancyLabels = useCallback(
    (itemId: string) => {
      const recordDatesByType = discrepancyRecordsByItems[itemId];
      if (!recordDatesByType) return [];

      const entries = Object.entries(recordDatesByType).reduce(
        (acc, [_, { discrepancies }]) => [...acc, ...discrepancies],
        [] as Pick<InvoiceDiscrepancyRecord, 'date' | 'type'>[],
      );

      // Group by date
      const groupedByDate: { [date: string]: { date: string; types: InvoiceDiscrepancyType[] } } =
        {};

      entries.forEach(({ date, type }) => {
        if (!groupedByDate[date]) {
          groupedByDate[date] = { date, types: [type] };
        } else {
          groupedByDate[date].types.push(type);
        }
      });

      // Generate labels by dates
      return Object.values(groupedByDate).map(({ date, types }) => {
        const changes = types.filter((t) => t !== InvoiceDiscrepancyType.InvoiceItemDeleted);
        const changesLabels = changes.reduce((acc, type, i, arr) => {
          const label = formatMessage({ id: `payableFees-Discrepancy-Type-${type}` });
          if (arr.length <= 1 || !acc) return label;
          if (i === arr.length - 1) return `${acc} ${formatMessage({ id: 'and' })} ${label}`;
          return `${acc}, ${label}`;
        }, '');

        const changesLabel = changesLabels ? (
          <Typography>
            {formatMessage(
              { id: 'payableFees-Discrepancy-LineItemChanged' },
              {
                changedItems: changesLabels,
                count: changes.length,
              },
            )}
          </Typography>
        ) : null;

        const deletionLabel = types.includes(InvoiceDiscrepancyType.InvoiceItemDeleted) ? (
          <Typography>
            {formatMessage({ id: 'payableFees-Discrepancy-LineItemRemoved' })}
          </Typography>
        ) : null;

        return {
          date,
          label: (
            <>
              {changesLabel}
              {deletionLabel}
            </>
          ),
        };
      });
    },
    [discrepancyRecordsByItems, formatMessage],
  );

  const getItemDiscrepancyTitleId = useCallback(
    (itemId: string) => {
      const records = discrepancyRecordsByItems[itemId];

      const hasUnreconcilableDiscrepancy = Object.values(records).some((r) =>
        r.discrepancies.some((d) => d.status === InvoiceDiscrepancyStatus.Unreconcilable),
      );

      if (hasUnreconcilableDiscrepancy) {
        return 'payableFees-Discrepancy-InvoiceTooltipTitle-Unreconcilable';
      }
      return 'payableFees-Discrepancy-InvoiceTooltipTitle-Unprocessed';
    },
    [discrepancyRecordsByItems],
  );

  return {
    discrepantItemIds,
    invoiceDiscrepancyLabelIds,
    invoiceDiscrepancyTitleId,
    getItemDiscrepancyLabels,
    getItemDiscrepancyTitleId,
  };
};

export const payableFeeHasInvoice = (payableFee: PayableFee): payableFee is PayableFeeWithInvoice =>
  !!payableFee.invoice_id;
