import { SchoolYear, SchoolYearPeriodGroup } from '@schooly/api';
import { Loading, ModalSmall } from '@schooly/style';
import { FC } from 'react';

import { useRouter } from '../../../../context/router/useRouter';
import {
  SchoolPeriodsUpdateContent,
  SchoolPeriodsUpdateContentProps,
} from './SchoolPeriodUpdateContent';
import { useSchoolPeriodsUpdate } from './useSchoolPeriodsUpdate';

export interface SchoolPeriodsUpdateModalProps {
  schoolYear?: SchoolPeriodsUpdateContentProps['schoolYear'];
  autoFocusGroup?: SchoolYearPeriodGroup['id'];
  onClose?: SchoolPeriodsUpdateContentProps['onClose'];
}

export const SchoolPeriodsUpdateModal: FC<SchoolPeriodsUpdateModalProps> = ({
  schoolYear,
  autoFocusGroup,
  onClose: propsOnClose,
}) => {
  const { isLoading, currentSchoolYear } = useSchoolPeriodsUpdate(schoolYear as SchoolYear);
  const { goBack } = useRouter();

  const onClose = propsOnClose ?? goBack;

  return (
    <ModalSmall
      onClose={onClose}
      sx={{
        '.MuiPaper-root': {
          overflowY: 'visible',
        },
      }}
      open
    >
      {isLoading || !currentSchoolYear ? (
        <Loading />
      ) : (
        <SchoolPeriodsUpdateContent
          schoolYear={currentSchoolYear}
          autoFocusGroup={autoFocusGroup}
          onClose={onClose}
        />
      )}
    </ModalSmall>
  );
};
