import { useMutation, useQuery } from '@tanstack/react-query';

import { UpdateNotificationSettingsProps } from './apiTypes/endpoints/notificationSettings';
import { ApiError } from './apiTypes/misc';
import { NotificationSettings } from './apiTypes/notificationSettings';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import * as api from './requests';

const SCHOOL_URL = '/school/';

function getNotificationAttendanceSettings({
  schoolId,
}: {
  schoolId: string;
}): Promise<NotificationSettings> {
  return api.get(`${SCHOOL_URL}${schoolId}/settings/notifications`);
}

export const GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY = `${SCHOOL_URL}/GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY`;

export const useGetNotificationAttendanceSettingsQuery = (
  params: {
    schoolId: string;
  },
  options?: RQUseQueryOptions<NotificationSettings>,
) => {
  return useQuery<NotificationSettings, ApiError>(
    [GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY, params],
    () => getNotificationAttendanceSettings(params),
    {
      ...options,
    },
  );
};

export const UPDATE_NOTIFICATION_SETTINGS_MUTATION = `${SCHOOL_URL}/UPDATE_NOTIFICATION_SETTINGS_MUTATION`;

function updateNotificationAttendanceSettings({
  schoolId,
  attendance,
  internalEmails,
}: UpdateNotificationSettingsProps): Promise<NotificationSettings> {
  const params = {
    attendance,
    internal_emails: {
      admissions_email: internalEmails?.admissionsEmail,
      invoicing_email: internalEmails?.invoicingEmail,
    },
  };
  return api.patch(`${SCHOOL_URL}${schoolId}/settings/notifications`, params);
}

export const useUpdateNotificationSettingsMutation = (
  options?: RQUseMutationOptions<NotificationSettings, UpdateNotificationSettingsProps>,
): RQUseMutationResult<NotificationSettings, UpdateNotificationSettingsProps> => {
  return useMutation(
    [UPDATE_NOTIFICATION_SETTINGS_MUTATION],
    (props) => updateNotificationAttendanceSettings(props),
    {
      ...options,
    },
  );
};
