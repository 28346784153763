import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Box, Checkbox, Icon, IconButton, Stack, styled, Tooltip, Typography } from '@mui/material';
import {
  GetProductsToBeAssignedResponse,
  LeavingReasonSet,
  RegistrationStatus as RegistrationStatusType,
  SchoolProperty,
  SchoolYear,
  StatusCreatedBy,
  StudentRegistration,
  SyncUser,
  useGetNotificationAttendanceSettingsQuery,
} from '@schooly/api';
import { SameAgeGroupWarning } from '@schooly/components/annual-roll-over';
import { useAuth } from '@schooly/components/authentication';
import {
  SelectOptionsArchivedEmptyStub,
  SelectOptionsArchivedIcon,
} from '@schooly/components/filters';
import { ControlCheckbox } from '@schooly/components/form-checkbox';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  CheckboxIcon,
  InformationIcon,
  LockIcon,
  ModalContent,
  ModalMain,
  RollBackIcon,
  SettingsIcon,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { isAfter } from 'date-fns';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { getRegistrationsByTimePeriods } from '../../../helpers/registrations';
import { useSchool } from '../../../hooks/useSchool';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { convertProductToAssignedProductUpdate } from '../../../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/helpers';
import { AssignedProductUpdate } from '../../../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/StudentProductsModalContent';
import FormSelect2 from '../../ui/Input/FormSelect2';
import { FormCheckboxStyled } from '../../uikit-components/FormCheckbox/FormCheckbox.styled';
import { ModalFooterWithActions } from '../../uikit-components/Modal/ModalFooterWithActions';
import {
  ProductAssignmentsConfirmModal,
  ProductAssignmentsForm,
} from './ProductAssignmentsConfirmModal';
import { RegistrationConflicts } from './RegistrationConflicts';
import { RegistrationStatusComponent, RegistrationStatusProps } from './RegistrationStatus';
import { useRegistrationValidation } from './useRegistrationValidation';
import {
  checkHasChangesToConfirm,
  isCurrentStatusSelected,
  validateStatusDateOrder,
} from './utils';

type RegistrationStep = 'enrollment' | 'product_assignments';

export interface AddRegistrationStatus {
  formId?: string;
  school_property_id?: string;
  applies_from?: string;
  read_only?: boolean;
  created_at?: string;
  created_by?: Partial<StatusCreatedBy>;
  leaving_reason?: Partial<LeavingReasonSet> | null;
}

export type ActiveRegistrations = RegistrationStatusType & {
  year: SchoolYear;
  house?: SchoolProperty;
  ageGroup?: SchoolProperty;
  registrationId: string;
};

export type DisabledRegistrationStatus = {
  formId: string;
  disabledFields: Array<'school_property_id' | 'applies_from'>;
  endAdornment: JSX.Element;
  required?: boolean;
  tag?: ReactNode;
  lockIcon?: ReactNode;
};

export type RegistrationForm = {
  school_year_id?: string;
  campus_property_id?: string;
  age_group_property_id?: string;
  half_day?: boolean;
  house_property_id?: string;
  statuses: AddRegistrationStatus[];
  same_age_group?: boolean;
  product_assignments: AssignedProductUpdate[];
};

interface StudentRegistrationFormProps
  extends PropsWithChildren,
    Pick<RegistrationStatusProps, 'products' | 'initial'> {
  defaultValues?: RegistrationForm;
  schoolId: string;
  isDeleting: boolean;
  onSubmit: (d: RegistrationForm) => void;
  isSaving: boolean;
  registrations?: StudentRegistration[];
  routePathname: string;
  fetchRegistrations: () => Promise<StudentRegistration[]>;
  isLoading: boolean;
  registrationId?: string;
  leavingStatusId: string;
  onManageAgeGroups: () => void;
  disabledStatuses: DisabledRegistrationStatus[];
  canEditYear: boolean;
  canEditAgeGroup: boolean;
  renderDeleteButton: () => ReactNode;
  renderYearEndIcon: () => EmotionJSX.Element | undefined;
  getProductAssignments: (
    formData: RegistrationForm,
  ) => Promise<GetProductsToBeAssignedResponse | undefined>;
  user: SyncUser;
  statusesToConfirm: string[];
}

export const StudentRegistrationForm: FC<StudentRegistrationFormProps> = ({
  defaultValues,
  schoolId,
  leavingStatusId,
  onSubmit,
  isDeleting,
  isSaving,
  registrations,
  children,
  routePathname,
  fetchRegistrations,
  isLoading,
  onManageAgeGroups,
  registrationId,
  disabledStatuses,
  canEditYear,
  canEditAgeGroup,
  renderDeleteButton,
  renderYearEndIcon,
  getProductAssignments,
  user,
  statusesToConfirm,
  ...registrationStatusProps
}) => {
  const { schoolYears, defaultValidity } = useSchoolYears();
  const { activeStudentStatuses } = useSchool();
  const { permissions } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);

  const { ageGroupSelectOptions } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { data: notificationSettings } = useGetNotificationAttendanceSettingsQuery(
    { schoolId: schoolId ?? '' },
    { enabled: !!schoolId },
  );

  const [activeRegistrations, , previousRegistrations] = registrations
    ? getRegistrationsByTimePeriods(registrations, 'student')
    : [];

  const isAdmin = permissions?.includes('school_admin');
  const [showReload, setShowReloadTrue, setShowReloadFalse] = useFlag();
  const [sameAgeGroupShowed, showSameAgeGroup, hideSameAgeGroup] = useFlag();
  const [registrationStep, setRegistrationStep] = useState<RegistrationStep>('enrollment');

  const { activePropertiesMap, selectOptions: studentSelectOptions } = useSchoolProperties({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });

  const statusOptions = useMemo(
    () =>
      activePropertiesMap.status.map((p) => ({
        value: p.id,
        label: p.name,
      })),
    [activePropertiesMap.status],
  );
  const { registrationConflicts, validateRegistrations } = useRegistrationValidation({
    schoolStatuses: activePropertiesMap['status'],
  });

  const hasHouseOptions = Boolean(activePropertiesMap.house.length);
  const { formatMessage } = useIntl();

  const form = useForm<RegistrationForm>({
    defaultValues,
    mode: 'onChange',
  });
  const { isDirty, dirtyFields } = form.formState;
  const selectedYearId = form.watch('school_year_id');
  const selectedHouseId = form.watch('house_property_id');
  const sameAgeGroup = form.watch('same_age_group');
  const selectedAgeGroupId = form.watch('age_group_property_id');
  const productAssignments = form.watch('product_assignments');

  const hasActiveCurrentRegistration = useMemo(
    () =>
      activeRegistrations?.some((r) =>
        r.statuses.some((s) => Boolean(s.school_property.category?.current)),
      ),
    [activeRegistrations],
  );

  const hasActiveSameAgeGroupRegistration = useMemo(
    () =>
      (activeRegistrations as StudentRegistration[])?.some((r) =>
        Boolean(r.id !== registrationId && r.same_age_group),
      ),
    [activeRegistrations, registrationId],
  );

  const selectedYear = useMemo(() => {
    const schoolYear = schoolYears.find((y) => y.id === selectedYearId);
    const defaultSchoolYear = schoolYears.find((y) => y.id === defaultValidity?.id);

    if (!schoolYear || !defaultSchoolYear) return null;
    const idx = defaultValidity ? schoolYears.indexOf(defaultSchoolYear) : -1;

    const isNextYearRegistration = idx >= 0 ? schoolYears[idx + 1]?.id === selectedYearId : false;
    const isCurrentRegistration = defaultValidity?.id === selectedYearId;
    const isPreviousRegistration =
      idx > 0 ? schoolYears.slice(0, idx).some((y) => y.id === selectedYearId) : false;

    return { isNextYearRegistration, isCurrentRegistration, isPreviousRegistration };
  }, [defaultValidity, schoolYears, selectedYearId]);

  const prevSameAgeGroup = useMemo(() => {
    if (!selectedYear) return;
    const { isCurrentRegistration, isNextYearRegistration } = selectedYear;
    if (isNextYearRegistration && activeRegistrations?.length) {
      const registration = activeRegistrations.find(
        (r) => (r as StudentRegistration).same_age_group,
      );

      return registration
        ? registration.school_properties.find((p) => p.type === SchoolPropertyType.AgeGroup)
        : null;
    }

    if (
      isCurrentRegistration &&
      !hasActiveSameAgeGroupRegistration &&
      previousRegistrations?.length
    ) {
      const prevRegistration = previousRegistrations[0] as StudentRegistration;
      if (!prevRegistration.same_age_group) return;

      const property = prevRegistration.school_properties.find(
        (p) => p.type === SchoolPropertyType.AgeGroup,
      );

      return property;
    }
  }, [activeRegistrations, hasActiveSameAgeGroupRegistration, previousRegistrations, selectedYear]);

  useEffect(() => {
    if (!prevSameAgeGroup || prevSameAgeGroup.archived) return;

    if (!selectedAgeGroupId && !dirtyFields['age_group_property_id']) {
      form.setValue('age_group_property_id', prevSameAgeGroup.id);
    }
  }, [dirtyFields, form, prevSameAgeGroup, selectedAgeGroupId]);

  const isFutureYearSelected = useMemo(() => {
    const selectedYear = schoolYears.find((y) => y.id === selectedYearId);
    if (!selectedYear || !defaultValidity) return null;

    return isAfter(
      newDateTimezoneOffset(selectedYear.start),
      newDateTimezoneOffset(defaultValidity.end),
    );
  }, [defaultValidity, schoolYears, selectedYearId]);

  const canShowSameAgeGroup = selectedYear?.isPreviousRegistration
    ? !hasActiveCurrentRegistration
    : true;

  useEffect(() => {
    if (isDirty) return;

    if (defaultValues) {
      const { age_group_property_id, statuses, same_age_group } = defaultValues;
      if (!age_group_property_id || !statuses?.length) return;
      if (!canShowSameAgeGroup && !same_age_group) return;

      const currentStatusSelected = statuses?.some(
        isCurrentStatusSelected(activePropertiesMap['status']),
      );

      if (currentStatusSelected) showSameAgeGroup();
    }
  }, [activePropertiesMap, canShowSameAgeGroup, defaultValues, isDirty, showSameAgeGroup]);

  useEffect(() => {
    const sameAgeGroupSubscription = form.watch((value, { name }) => {
      if (!name) return;
      const currentName = name.split('.').at(0);

      if (currentName === 'age_group_property_id' || currentName === 'statuses') {
        const checkStatus = isCurrentStatusSelected(activePropertiesMap['status']);
        const currentStatusSelected = value.statuses?.some(checkStatus);
        const statusAndAgeGroupSelected = currentStatusSelected && !!value.age_group_property_id;

        if (canShowSameAgeGroup && !sameAgeGroupShowed && statusAndAgeGroupSelected) {
          showSameAgeGroup();
        } else if (sameAgeGroupShowed && (!currentStatusSelected || !value.age_group_property_id)) {
          hideSameAgeGroup();
          form.setValue('same_age_group', false);
        }
      }
    });

    return () => sameAgeGroupSubscription.unsubscribe();
  }, [
    activePropertiesMap,
    showSameAgeGroup,
    form,
    hideSameAgeGroup,
    sameAgeGroupShowed,
    hasActiveCurrentRegistration,
    canShowSameAgeGroup,
  ]);

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (!name) return;
      const currentName = name.split('.').at(0);

      const schoolYear = schoolYears.find(({ id }) => id === value.school_year_id);
      if (!schoolYear) return;

      const nonEmptyStatuses = value.statuses?.length ? value.statuses.filter(isNotEmpty) : null;
      if (!nonEmptyStatuses?.length) return;

      if (currentName === 'statuses' || currentName === 'school_year_id') {
        const yearErrors = validateStatusDateOrder({
          selectedStatuses: nonEmptyStatuses,
          schoolYear,
          studentStatues: activeStudentStatuses,
        });

        yearErrors.forEach((d, i) => {
          const path = `statuses.${i}.applies_from` as const;

          if (d?.applies_from) {
            form.setError(path, {
              type: 'validate',
              message: formatMessage({ id: d.applies_from.id }, d.applies_from.values),
            });
          } else {
            form.clearErrors(path);
          }
        });
      }

      validateRegistrations({
        form: {
          ...value,
          product_assignments: productAssignments,
          statuses: nonEmptyStatuses,
        },
        registrations: registrations?.filter((r) => r.id !== registrationId) || [],
        yearEndDate: schoolYear.end,
      });
    });

    return () => subscription.unsubscribe();
  }, [
    formatMessage,
    activePropertiesMap,
    activeStudentStatuses,
    form,
    hideSameAgeGroup,
    sameAgeGroupShowed,
    schoolYears,
    selectedYearId,
    showSameAgeGroup,
    validateRegistrations,
    registrations,
    registrationId,
    productAssignments,
  ]);

  const selectedHouse = form.watch('house_property_id');

  const activeAgeGroups = Boolean(activePropertiesMap['age_group'].length);
  const noAgeGroupsText = formatMessage({ id: 'school-ageGroups-NoActiveGroups' });

  const schoolYearOptions = useMemo(
    () =>
      schoolYears?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [schoolYears],
  );

  const handleReload = useCallback(async () => {
    const registrations = await fetchRegistrations();

    const formValues = form.getValues();
    const closeDate = schoolYears.find((y) => y.id === formValues.school_year_id)?.end;

    const conflicts = validateRegistrations({
      form: formValues,
      registrations,
      yearEndDate: closeDate,
    });

    if (!conflicts.length) {
      setShowReloadFalse();
    }
  }, [fetchRegistrations, form, schoolYears, setShowReloadFalse, validateRegistrations]);

  const filterDataForSubmit = useCallback(
    (d: RegistrationForm) => {
      let data = d;
      if (isFutureYearSelected) {
        const { same_age_group, ...rest } = d;
        data = rest;
      }
      return data;
    },
    [isFutureYearSelected],
  );

  const handleEnrollmentSubmit = useCallback(
    async (d: RegistrationForm) => {
      const data = filterDataForSubmit(d);

      const res = await getProductAssignments(data);
      if (!res) return;
      const hasProductAssignments = res.products.some((p) => p.length);

      const hasChangesToConfirm = checkHasChangesToConfirm(form, statusesToConfirm);

      if (!hasProductAssignments && !hasChangesToConfirm) {
        onSubmit(data);
        return;
      }

      if (hasProductAssignments) {
        form.setValue(
          'product_assignments',
          res.products[0].map(convertProductToAssignedProductUpdate),
        );
      }

      setRegistrationStep('product_assignments');
    },
    [filterDataForSubmit, form, getProductAssignments, onSubmit, statusesToConfirm],
  );

  const handleAssignedProductsSubmit = useCallback<SubmitHandler<ProductAssignmentsForm>>(
    ({ products }) => {
      form.setValue('product_assignments', products[0]);
      const data = filterDataForSubmit(form.getValues());
      onSubmit(data);
    },
    [filterDataForSubmit, form, onSubmit],
  );

  const handleAssignedProductsClose = useCallback(() => {
    setRegistrationStep('enrollment');
    form.setValue('product_assignments', []);
  }, [form]);

  const renderAgeGroupEndIcon = () => {
    if (!canEditAgeGroup) {
      return (
        <Tooltip
          componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
          title={
            <>
              <Typography mb={2}>
                {formatMessage({
                  id: 'students-CannotChangeAgeGroupWithInvoices-1',
                })}
              </Typography>
              <Typography>
                {formatMessage({
                  id: 'students-CannotChangeAgeGroupWithInvoices-2',
                })}
              </Typography>
            </>
          }
        >
          <Icon sx={{ pointerEvents: 'auto', ':hover': { color: 'primary.main' } }}>
            <LockIcon />
          </Icon>
        </Tooltip>
      );
    }
    if (!activeAgeGroups) {
      return (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: (theme) => ({
                padding: theme.spacing(1.25),
                pointerEvents: 'none',
              }),
            },
          }}
          title={
            <AgeGroupsSettingsContent
              disabled={!activeAgeGroups}
              hasManageAccess={isAdmin}
              warningText={
                isAdmin
                  ? `${noAgeGroupsText}. ${formatMessage({
                      id: 'school-ageGroups-NoActiveGroupsConfigure',
                    })}`
                  : `${noAgeGroupsText}. ${formatMessage({
                      id: 'school-ageGroups-ManagingNoAccess',
                    })}`
              }
              onClick={onManageAgeGroups}
            />
          }
        >
          <Icon
            sx={{
              pointerEvents: 'auto',
            }}
          >
            <InformationIcon />
          </Icon>
        </Tooltip>
      );
    }
  };

  const getConfirmationTitle = () => {
    const hasChangesToConfirm = checkHasChangesToConfirm(form, statusesToConfirm);
    const hasProductAssignments = productAssignments?.length > 0;

    if (hasChangesToConfirm && hasProductAssignments) {
      return (
        <Typography variant="h1">
          {formatMessage(
            { id: 'products-ChangesInRegistration-WillAffect' },
            {
              changeOne: (
                <Typography component="span" variant="h1" sx={{ textDecoration: 'underline' }}>
                  {formatMessage({
                    id: 'products-ExistingProductAssignments',
                  }).toLowerCase()}
                </Typography>
              ),
              changeTwo: (
                <Typography component="span" variant="h1" sx={{ textDecoration: 'underline' }}>
                  {formatMessage({ id: 'products-NewInvoices' }).toLowerCase()}
                </Typography>
              ),
            },
          )}
        </Typography>
      );
    }

    return (
      <Typography variant="h1">
        {formatMessage({
          id: hasProductAssignments
            ? 'products-ChangesInRegistration-NewInvoices'
            : 'products-ChangesInRegistration-FeeAssignments',
        })}
      </Typography>
    );
  };

  const getConfirmationDescription = () => {
    if (productAssignments?.length > 0) {
      return (
        <Typography variant="h3">
          {formatMessage({ id: 'products-AdjustDetailsForInvoices' })}
        </Typography>
      );
    }

    const email = notificationSettings?.internal_emails?.invoicing_email;
    return (
      <Typography variant="h3">
        {email ? formatMessage({ id: 'products-EmailWillBeSent' }, { email }) : ''}
      </Typography>
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleEnrollmentSubmit)}>
          {children}
          <ModalMain>
            <ModalContent active ref={containerRef}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2">
                  <FormattedMessage id="students-RegistrationInformation" />
                </Typography>
                {sameAgeGroupShowed && !isFutureYearSelected && (
                  <Tooltip
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: (theme) => ({
                          padding: theme.spacing(1.25),
                        }),
                      },
                    }}
                    title={formatMessage({ id: 'students-sameAgeGroupActionLabel' })}
                  >
                    <ControlCheckbox
                      name="same_age_group"
                      sx={(theme) => ({
                        whiteSpace: 'nowrap',
                        ':hover': {
                          svg: {
                            circle: {
                              stroke: !sameAgeGroup ? theme.palette.primary.main : 'inherit',
                            },
                          },
                        },
                      })}
                      label={formatMessage({ id: 'students-sameAgeGroupLabel' })}
                    />
                  </Tooltip>
                )}
              </Stack>

              <FormSelectContainer>
                {!!schoolYearOptions && (
                  <FormSelect2
                    name="school_year_id"
                    labelTextId="students-SchoolYear"
                    options={schoolYearOptions}
                    rules={{ required: true }}
                    disabled={!canEditYear}
                    endIcon={renderYearEndIcon()}
                  />
                )}

                {!!studentSelectOptions.campus?.length && (
                  <FormSelect2
                    name="campus_property_id"
                    labelTextId="schoolProperty-Campus"
                    options={studentSelectOptions.campus}
                  />
                )}

                {hasHouseOptions && (
                  <FormSelect2
                    name="house_property_id"
                    labelTextId="schoolProperty-House"
                    options={studentSelectOptions.house}
                    disabled={!hasHouseOptions && !selectedHouse}
                    endIcon={
                      !hasHouseOptions && !selectedHouse ? (
                        <SelectOptionsArchivedIcon
                          isAdmin={isAdmin}
                          type={SchoolPropertyType.House}
                        />
                      ) : undefined
                    }
                    searchEmptyStub={
                      !hasHouseOptions && selectedHouse ? (
                        <Box p={1}>
                          <SelectOptionsArchivedEmptyStub
                            isAdmin={isAdmin}
                            type={SchoolPropertyType.House}
                          />
                        </Box>
                      ) : undefined
                    }
                  />
                )}

                {!!ageGroupSelectOptions?.length && (
                  <Stack flexDirection="row" gap={1}>
                    <Stack
                      sx={{
                        flex: 1,
                        position: 'relative',
                        '.form-group__required-label': {
                          '.required_label': {
                            display: !!prevSameAgeGroup ? 'none' : 'inherit',
                          },
                        },
                      }}
                    >
                      <FormSelect2
                        name="age_group_property_id"
                        labelTextId="schoolProperty-AgeGroup"
                        options={ageGroupSelectOptions}
                        propertyType={SchoolPropertyType.AgeGroup}
                        disabled={!activeAgeGroups || !canEditAgeGroup}
                        endIcon={renderAgeGroupEndIcon()}
                      />

                      {prevSameAgeGroup && (
                        <SameAgeGroupDropdownWarning
                          ageGroupProperty={prevSameAgeGroup}
                          hasManageAccess={isAdmin}
                          onManageAgeGroups={onManageAgeGroups}
                        />
                      )}
                    </Stack>{' '}
                    {selectedAgeGroupId && (
                      <Controller
                        name="half_day"
                        control={form.control}
                        render={({ field }) => (
                          <Tooltip
                            componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
                            title={
                              canEditAgeGroup ? undefined : (
                                <>
                                  <Typography mb={2}>
                                    {formatMessage({
                                      id: 'students-CannotChangeHalfDayWithInvoices-1',
                                    })}
                                  </Typography>
                                  <Typography>
                                    {formatMessage({
                                      id: 'students-CannotChangeHalfDayWithInvoices-2',
                                    })}
                                  </Typography>
                                </>
                              )
                            }
                          >
                            <FormCheckboxStyled
                              sx={{ width: 140, whiteSpace: 'nowrap' }}
                              withBorder
                              control={
                                <Checkbox
                                  name={field.name}
                                  checked={field.value}
                                  onChange={field.onChange}
                                  checkedIcon={<CheckboxIcon className="reset-svg-currentColor" />}
                                  disabled={!canEditAgeGroup}
                                />
                              }
                              label={formatMessage({ id: 'students-HalfDay' })}
                            />
                          </Tooltip>
                        )}
                      />
                    )}
                  </Stack>
                )}
                {!!statusOptions?.length && (
                  <RegistrationStatusComponent
                    leavingStatusId={leavingStatusId}
                    containerRef={containerRef}
                    schoolId={schoolId}
                    disabledStatuses={disabledStatuses}
                    {...registrationStatusProps}
                  />
                )}
              </FormSelectContainer>
              {!!registrationConflicts?.length && (
                <RegistrationConflicts
                  registrationConflicts={registrationConflicts}
                  getRegistrationLink={(id) => `${routePathname}?id=${id}`}
                  onRegistrationClick={setShowReloadTrue}
                  selectedAgeGroupId={selectedAgeGroupId}
                  selectedHouseId={selectedHouseId}
                  endIcon={
                    showReload && (
                      <IconButton disabled={isLoading} onClick={handleReload}>
                        <RollBackIcon />
                      </IconButton>
                    )
                  }
                />
              )}
            </ModalContent>
          </ModalMain>
          <ModalFooterWithActions
            active
            saving={isSaving}
            deleting={isDeleting}
            disabled={Boolean(registrationConflicts?.length)}
            leftButton={renderDeleteButton()}
          />
        </form>
      </FormProvider>

      {registrationStep === 'product_assignments' && (
        <ProductAssignmentsConfirmModal
          onClose={handleAssignedProductsClose}
          onConfirm={handleAssignedProductsSubmit}
          users={[user]}
          products={[productAssignments]}
          saving={isSaving}
          title={getConfirmationTitle()}
          description={getConfirmationDescription()}
        />
      )}
    </>
  );
};

const FormSelectContainer = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2.25),
  paddingBottom: theme.spacing(0.75),

  '.form-select.disabled .form-select-value': {
    color: `${theme.palette.common.grey2} !important`,
  },

  '.form-select-search': {
    maxHeight: 44,
  },

  '.form-select2': {
    '.form-select-value-wrapper': {
      left: theme.spacing(1.5),
    },
    '.form-select-arrow, .form-select-remove': {
      right: theme.spacing(1.5),
    },
    '.form-group__label': {
      left: theme.spacing(1.5),
    },
  },

  '.MuiSelect-outlined': {
    paddingLeft: `${theme.spacing(1.5)} !important`,
  },

  '.MuiOutlinedInput-notchedOutline': {
    paddingLeft: `${theme.spacing(1.5)} !important`,
  },
}));

type SameAgeGroupDropdownWarningProps = {
  ageGroupProperty: SchoolProperty;
  hasManageAccess: boolean;
  onManageAgeGroups: () => void;
};

export const SameAgeGroupDropdownWarning: FC<SameAgeGroupDropdownWarningProps> = ({
  ageGroupProperty,
  hasManageAccess,
  onManageAgeGroups,
}) => {
  const { formatMessage } = useIntl();
  const warning = formatMessage(
    { id: 'students-sameAgeGroupSelectWarningLabel' },
    {
      ageGroupName: ageGroupProperty.name,
    },
  );

  const warningText = ageGroupProperty.archived
    ? `${warning}. ${formatMessage({ id: 'students-sameAgeGroupArchived' })}`
    : warning;

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        alignItems: 'center',
        position: 'absolute',
        right: theme.spacing(5.5),
        top: theme.spacing(1.5),
        gap: 1,
      })}
    >
      <SameAgeGroupWarning
        tooltipLabel={
          <AgeGroupsSettingsContent
            disabled={!!ageGroupProperty.archived}
            onClick={onManageAgeGroups}
            hasManageAccess={hasManageAccess}
            warningText={
              hasManageAccess
                ? warningText
                : `${warningText} ${formatMessage({
                    id: 'school-ageGroups-ManagingNoAccess',
                  })}`
            }
          />
        }
      />
    </Stack>
  );
};

type AgeGroupsSettingsContentProps = {
  warningText: string;
  disabled: boolean;
  hasManageAccess: boolean;
  onClick: () => void;
};

export const AgeGroupsSettingsContent: FC<AgeGroupsSettingsContentProps> = ({
  disabled,
  hasManageAccess,
  onClick,
  warningText,
}) =>
  disabled ? (
    <Stack gap={1}>
      <Typography>{warningText}</Typography>
      {hasManageAccess && (
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
            pointerEvents: 'auto',
          }}
          onClick={onClick}
        >
          <Icon>
            <SettingsIcon />
          </Icon>

          <Typography>
            <FormattedMessage id="section-SchoolSettings" />
          </Typography>
        </Stack>
      )}
    </Stack>
  ) : (
    <>{warningText}</>
  );
