import { Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, DEFAULT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { addMonths, format, isSameDay, isToday, isWithinInterval } from 'date-fns';
import { FC, useRef } from 'react';

import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarDateTooltip,
  AnnualPlannerCalendarGrid,
} from '../../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { ANNUAL_PLANNER_DAY_CELL_WIDTH, AnnualPlannerCalendarWithDates } from '../../scheme';
import { useAnnualPlannerYearGrid } from '../../useAnnualPlannerGrid';
import { useAnnualPlannerGridEvents } from '../../useAnnualPlannerGridEvents';
import { getAnnualPlannerCell } from '../../utils';
import { AnnualPlannerGridLayoutSelectedCell } from './AnnualPlannerGridLayoutSelectedCell';

export interface AnnualPlannerGridLayoutYearProps extends AnnualPlannerCalendarWithDates {}

export const AnnualPlannerGridLayoutYear: FC<AnnualPlannerGridLayoutYearProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { start, end, cols, months, days } = useAnnualPlannerYearGrid(props);
  const {
    isOpen,
    date: selectedDate,
    type: selectedType,
    record,
    open,
    openParamsRef,
    setOpenParams,
  } = useAnnualPlannerCreateRecord();

  const { onCellMouseDown } = useAnnualPlannerGridEvents({
    gridRef: ref,
    open,
    openParamsRef,
    setOpenParams,
    validityStart: start,
    validityEnd: end,
  });

  return (
    <AnnualPlannerCalendarGrid
      ref={ref}
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-MainGrid-root',
      ].join(' ')}
      sx={(theme) => ({
        borderTop: theme.mixins.borderValue(),
        borderLeft: theme.mixins.borderValue(),
        gridTemplateColumns: `${ANNUAL_PLANNER_DAY_CELL_WIDTH}px repeat(${cols}, 1fr)`,
      })}
    >
      <AnnualPlannerCalendarCell
        sx={(theme) => ({
          borderRight: theme.mixins.borderValue(),
          borderBottom: theme.mixins.borderValue(),
        })}
      >
        {/* top-left empty cell */}
      </AnnualPlannerCalendarCell>

      {months.map((month) => {
        return (
          <AnnualPlannerCalendarCell
            key={`header-col-${month.getMonth()}-${month.getFullYear()}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {format(month, 'MMM')}
          </AnnualPlannerCalendarCell>
        );
      })}

      {days.map((day) => (
        <>
          <AnnualPlannerCalendarCell
            key={`col-date-${day}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            <Typography variant="caption">{day}</Typography>
          </AnnualPlannerCalendarCell>

          {months.map((month, index) => {
            const { date, hasDate, isFirstDate, isWeekEnd, isSunday } = getAnnualPlannerCell({
              month,
              day,
              start,
              end,
            });

            // there might be no neighbour date to the right,
            // in this case don't need to render a right border
            const hasNeighbourDate =
              index < months.length - 1
                ? getAnnualPlannerCell({ month: addMonths(month, 1), day, start, end })?.hasDate
                : undefined;

            const content = (
              <Stack flexDirection="row" height="100%">
                {hasDate && (
                  <>
                    <Typography
                      component={Stack}
                      alignItems="center"
                      justifyContent="center"
                      variant="caption"
                      color="text.secondary"
                      sx={(theme) => ({
                        flex: '0 0 auto',
                        width: ANNUAL_PLANNER_DAY_CELL_WIDTH,
                        height: '100%',
                        borderRight: theme.mixins.borderValue(),
                        cursor: 'default',
                        background: isToday(date) ? theme.palette.primary.main : undefined,
                        color: isToday(date) ? theme.palette.primary.contrastText : undefined,
                      })}
                    >
                      {format(date, 'EEEEE')}
                    </Typography>

                    {!isOpen && <AnnualPlannerGridLayoutSelectedCell date={date} highlight />}

                    {isOpen &&
                      !record &&
                      selectedDate?.[0] &&
                      isWithinInterval(date, {
                        start: selectedDate[0],
                        end: selectedDate[1] || selectedDate[0],
                      }) && (
                        <AnnualPlannerGridLayoutSelectedCell
                          date={date}
                          type={selectedType}
                          start={isSameDay(date, selectedDate[0])}
                          end={!selectedDate[1] || isSameDay(date, selectedDate[1])}
                        />
                      )}
                  </>
                )}
              </Stack>
            );

            return (
              <AnnualPlannerCalendarCell
                key={format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)}
                data-date={format(date, DEFAULT_DATE_FORMAT_FNS)}
                className={[
                  AnnualPlannerCalendarCell.defaultProps?.className ?? '',
                  'AnnualPlannerCalendar-MainGrid-cell',
                  hasDate
                    ? 'AnnualPlannerCalendar-MainGrid-withDate'
                    : 'AnnualPlannerCalendar-MainGrid-empty',
                ].join(' ')}
                sx={(theme) => ({
                  borderTop:
                    isFirstDate && start.getDate() > 1 ? theme.mixins.borderValue() : undefined,
                  borderRight: hasDate || hasNeighbourDate ? theme.mixins.borderValue() : undefined,

                  ...(hasDate && {
                    borderBottom: theme.mixins.borderValue(),
                    borderBottomColor: isSunday ? theme.palette.common.light3 : undefined,
                    backgroundColor: isWeekEnd ? theme.palette.background.default : undefined,
                  }),
                })}
                onMouseDown={(event) => hasDate && onCellMouseDown(event, date)}
              >
                {hasDate && !isOpen ? (
                  <AnnualPlannerCalendarDateTooltip
                    title={format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)}
                  >
                    {content}
                  </AnnualPlannerCalendarDateTooltip>
                ) : (
                  content
                )}
              </AnnualPlannerCalendarCell>
            );
          })}
        </>
      ))}
    </AnnualPlannerCalendarGrid>
  );
};
