import { Icon, Stack, Tooltip, Typography } from '@mui/material';
import { AnnualPlannerViewMode, AnnualPlanRecordTypes } from '@schooly/api';
import { PlusIcon, SimpleButton, Spin } from '@schooly/style';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  defaultAnnualPlannerCreateFormValues,
  useAnnualPlannerCreateRecord,
} from './AnnualPlannerCalendar/AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerPageSchoolDays = () => {
  const { formatMessage } = useIntl();
  const { data, isLoading, schoolYear, hasSchoolAdminPermissions, mainPeriodGroup, viewMode } =
    useAnnualPlanner();
  const anchorRef = useRef<HTMLElement | null>(null);
  const { setExpandedData, open } = useAnnualPlannerCreateRecord();

  const schoolDaysTotalCount = data?.school_days.total ?? 0;
  const schoolDaysPeriodCount = data?.school_days.selected ?? 0;
  const hasPeriodGroups = !!schoolYear?.period_groups?.length;
  const showAddMainPeriodTooltip =
    !schoolDaysTotalCount && !hasPeriodGroups && hasSchoolAdminPermissions;

  const getCount = () => {
    if (isLoading) return;
    if (viewMode === AnnualPlannerViewMode.Year) return schoolDaysTotalCount;
    return `${schoolDaysPeriodCount} / ${schoolDaysTotalCount}`;
  };

  return (
    <Tooltip
      placement="left"
      title={
        showAddMainPeriodTooltip ? (
          <Stack alignItems="flex-start">
            {formatMessage({ id: 'annualPlanner-SchoolDays-Hint-AddMainSchoolPeriod' })}
            <SimpleButton
              onClick={() => {
                setExpandedData({
                  ...defaultAnnualPlannerCreateFormValues,
                  type: AnnualPlanRecordTypes.SCHOOL_PERIOD,
                });
                open(anchorRef.current);
              }}
              startIcon={<PlusIcon />}
              sx={(theme) => ({ mt: 1.25, ...theme.typography.body1 })}
            >
              <FormattedMessage id="school-schoolPeriods-AddSchoolPeriods" />
            </SimpleButton>
          </Stack>
        ) : (
          formatMessage(
            { id: 'annualPlanner-SchoolDays-Hint' },
            { periodGroupName: mainPeriodGroup?.name ? `"${mainPeriodGroup.name}"` : '' },
          )
        )
      }
      componentsProps={{ tooltip: { sx: { p: 1.25 } } }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {isLoading && (
          <Icon sx={{ color: 'primary.main' }}>
            <Spin />
          </Icon>
        )}
        <Typography variant="h3" ref={anchorRef}>
          <FormattedMessage id="annualPlanner-SchoolDays" values={{ count: getCount() }} />
        </Typography>
      </Stack>
    </Tooltip>
  );
};
