import { DEFAULT_FORMATTED_DATE_FORMAT_FNS, SchoolYear } from '@schooly/api';
import { format, getDay } from 'date-fns';
import { FC } from 'react';

import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarGrid,
} from '../../AnnualPlannerCalendar.styled';
import {
  ANNUAL_PLANNER_CALENDAR_MONTH_COLS,
  ANNUAL_PLANNER_HEADER_CELL_HEIGHT,
  AnnualPlannerCalendarWithDates,
} from '../../scheme';
import { useAnnualPlannerDropLayout } from '../../useAnnualPlannerDropLayout';
import { useAnnualPlannerMonthGrid } from '../../useAnnualPlannerGrid';
import { AnnualPlannerDropLayoutTarget } from './AnnualPlannerDropLayoutTarget';

export interface AnnualPlannerDropLayoutMonthProps extends AnnualPlannerCalendarWithDates {
  yearStart: SchoolYear['start'];
  yearEnd: SchoolYear['end'];
}

export const AnnualPlannerDropLayoutMonth: FC<AnnualPlannerDropLayoutMonthProps> = (props) => {
  const { zIndex } = useAnnualPlannerDropLayout();
  const { firstWeek, dates, rows } = useAnnualPlannerMonthGrid(props);

  return (
    <AnnualPlannerCalendarGrid
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-DropLayout-root',
      ].join(' ')}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        borderTop: '1px solid transparent',
        borderLeft: '1px solid transparent',
        gridTemplateColumns: `repeat(${ANNUAL_PLANNER_CALENDAR_MONTH_COLS}, 1fr)`,
        gridTemplateRows: `${ANNUAL_PLANNER_HEADER_CELL_HEIGHT}px repeat(${rows}, 1fr)`,
        zIndex,
      }}
    >
      {firstWeek.map((day) => {
        return (
          <AnnualPlannerCalendarCell
            key={`drop-header-col-${getDay(day)}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {/* header weekdays names */}
          </AnnualPlannerCalendarCell>
        );
      })}

      {dates.map((date) => {
        return (
          <AnnualPlannerDropLayoutTarget
            key={`drop-cell-${format(date, DEFAULT_FORMATTED_DATE_FORMAT_FNS)}`}
            className={[
              AnnualPlannerCalendarCell.defaultProps?.className ?? '',
              'AnnualPlannerCalendar-DropLayout-cell',
            ].join(' ')}
            date={date}
          />
        );
      })}
    </AnnualPlannerCalendarGrid>
  );
};
