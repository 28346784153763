import {
  AgeGroup,
  FilterSelectOption,
  GetSchoolPropertiesProps,
  GetSchoolPropertiesResponse,
  RQUseQueryOptions,
  SchoolProperty,
  useGetSchoolPropertiesQuery,
} from '@schooly/api';
import { getSourcePropertyName } from '@schooly/components/filters';
import { SchoolPropertyType } from '@schooly/constants';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

/*
 * For age_groups only use useAgeGroups hook libs/hooks/use-school-properties/src/lib/useAgeGroups.ts
 * Age groups in new format are received in age_groups property in the same endpoint
 */

export type SchoolPropertiesMap = {
  [SchoolPropertyType.AgeGroup]: AgeGroup[];
  [SchoolPropertyType.Campus]: SchoolProperty[];
  [SchoolPropertyType.Department]: SchoolProperty[];
  [SchoolPropertyType.House]: SchoolProperty[];
  [SchoolPropertyType.Status]: SchoolProperty[];
};

export const useSchoolProperties = (
  params: GetSchoolPropertiesProps,
  options?: RQUseQueryOptions<GetSchoolPropertiesResponse>,
) => {
  const res = useGetSchoolPropertiesQuery(params, options);
  const { $t } = useIntl();

  const response = useMemo(() => {
    if (!res.data) return res;
    return {
      ...res,
      data: {
        ...res.data,
        school_properties: res.data.school_properties.map((p) =>
          p.source
            ? {
                ...p,
                name: $t({ id: getSourcePropertyName(p) }),
              }
            : p,
        ),
      },
    };
  }, [$t, res]);

  const schoolProperties = useMemo(
    () => [...(response.data?.school_properties ?? []), ...(response.data?.age_groups ?? [])],
    [response.data?.age_groups, response.data?.school_properties],
  );

  const getPropertyById = useCallback(
    (id: string) => schoolProperties.find((item) => item.id === id),
    [schoolProperties],
  );

  const getPropertiesByType = useCallback(
    (type: string) => {
      if (type === SchoolPropertyType.AgeGroup) {
        return response.data?.age_groups ?? [];
      }
      return response.data?.school_properties.filter((item) => item.type === type) ?? [];
    },
    [response.data?.age_groups, response.data?.school_properties],
  );

  const mapPropertyIdsByType = useCallback(
    <T>(
      props?: string[],
      map?: (schoolProperty: SchoolProperty | AgeGroup) => T,
    ): Record<SchoolPropertyType, T[]> => {
      const defaultMap = {} as Record<SchoolPropertyType, T[]>;

      const propertiesMap =
        props?.reduce((prev, propId) => {
          const schoolProp = response.data?.school_properties.find((item) => item.id === propId);

          if (!schoolProp) {
            return prev;
          }

          if (!prev[schoolProp.type]) {
            prev[schoolProp.type] = [];
          }

          prev[schoolProp.type].push((map ? map(schoolProp) : schoolProp) as T);

          return prev;
        }, defaultMap) ?? defaultMap;

      const ageGroups = props
        ?.map((id) => response.data?.age_groups.find((ageGroup) => ageGroup.id === id))
        .filter((ageGroup): ageGroup is AgeGroup => ageGroup !== undefined);

      if (ageGroups?.length) {
        propertiesMap[SchoolPropertyType.AgeGroup] = map ? ageGroups.map(map) : (ageGroups as T[]);
      }

      return propertiesMap;
    },
    [response.data?.age_groups, response.data?.school_properties],
  );

  const [propertiesMap, activePropertiesMap, archivedPropertiesMap] = useMemo(() => {
    const { showReEnrollmentProperties } = params;
    const getDefaultMap = (): SchoolPropertiesMap => ({
      [SchoolPropertyType.Campus]: [],
      [SchoolPropertyType.House]: [],
      [SchoolPropertyType.AgeGroup]: [],
      [SchoolPropertyType.Status]: [],
      [SchoolPropertyType.Department]: [],
    });

    const defaultMap = getDefaultMap();
    const activePropertiesMap = getDefaultMap();
    const archivedPropertiesMap = getDefaultMap();

    const propertiesMap =
      response.data?.school_properties.reduce((prev, item) => {
        if (!showReEnrollmentProperties && item.source?.type === 're_enrollment') {
          return prev;
        }
        if (!prev[item.type]) {
          prev[item.type] = [];
          activePropertiesMap[item.type] = [];
          archivedPropertiesMap[item.type] = [];
        }

        const property = {
          ...item,
          name: item.source ? $t({ id: getSourcePropertyName(item) }) : item.name,
        };

        //Age groups abd leaving reasons are not sent in school_properties, they are sent separately
        if (property.type === SchoolPropertyType.AgeGroup) return prev;

        // add to a common map
        prev[property.type].push(property);

        // spread by active/archived as well
        const target = property.archived ? archivedPropertiesMap : activePropertiesMap;
        target[property.type].push(property);

        return prev;
      }, defaultMap) ?? defaultMap;

    const ageGroups = response.data?.age_groups ?? [];

    propertiesMap[SchoolPropertyType.AgeGroup] = ageGroups;

    for (const ageGroup of ageGroups) {
      if (ageGroup.archived) {
        archivedPropertiesMap[SchoolPropertyType.AgeGroup].push(ageGroup);
      } else {
        activePropertiesMap[SchoolPropertyType.AgeGroup].push(ageGroup);
      }
    }

    return [propertiesMap, activePropertiesMap, archivedPropertiesMap];
  }, [$t, params, response.data?.age_groups, response.data?.school_properties]);

  const [selectOptions, selectActiveOptions, selectArchivedOptions] = useMemo(() => {
    const defaultMap = { all: {}, active: {}, archived: {} } as {
      all: { [type in SchoolPropertyType]: FilterSelectOption<string>[] };
      active: { [type in SchoolPropertyType]: FilterSelectOption<string>[] };
      archived: { [type in SchoolPropertyType]: FilterSelectOption<string>[] };
    };

    const map =
      (Object.keys(propertiesMap) as SchoolPropertyType[]).reduce<typeof defaultMap>(
        (prev, type) => {
          const all = propertiesMap[type].map((item) => ({
            value: item.id,
            label: item.name,
            archived: item.archived,
          }));

          prev.all[type] = all;
          prev.active[type] = all.filter((item) => !item.archived);
          prev.archived[type] = all.filter((item) => item.archived);

          return prev;
        },
        defaultMap,
      ) ?? defaultMap;

    return [map.all, map.active, map.archived];
  }, [propertiesMap]);

  return {
    ...response,
    schoolProperties,
    getPropertyById,
    getPropertiesByType,
    mapPropertyIdsByType,
    propertiesMap,
    activePropertiesMap,
    archivedPropertiesMap,
    selectOptions,
    selectActiveOptions,
    selectArchivedOptions,
  };
};
