import {
  EnrollmentBase,
  EnrollmentStatus,
  LeavingReasonType,
  RegistrationStatusUpdate,
  SchoolProperty,
  SchoolYear,
  StudentRegistration,
  StudentRegistrationUpdate,
} from '@schooly/api';
import { DATE_FORMAT, SchoolPropertyType } from '@schooly/constants';
import { isNotEmpty, propsAreNotEmpty } from '@schooly/utils/predicates';
import { removeObjectUndefinedOrNullValues } from '@schooly/utils/remove-object-undefined-or-null-values';
import { format, isAfter, isBefore } from 'date-fns';
import { UseFormReturn } from 'react-hook-form-lts';

import { getSortedStatuses } from '../../../helpers/registrations';
import { convertFormDataToAssignedProductSave } from '../../../pages/ProfileModal/tabs/ProfileModalPayers/StudentProductsModal/helpers';
import { SchoolTuneStatusType } from '../../../pages/School/SchoolTune/tabs/statuses/scheme';
import { AddRegistrationStatus, RegistrationForm } from './StudentRegistrationForm';
import { CanceledEnrollmentParams } from './useReEnrollmentActions';

const getLeavingReasonPayload = (
  leavingReason:
    | Partial<{
        type: LeavingReasonType.Other;
        title: string;
      }>
    | Partial<{
        type: LeavingReasonType.Predefined;
        id: string;
      }>
    | null,
) => {
  if (leavingReason === null) {
    return null;
  }
  if ('id' in leavingReason) {
    return {
      id: leavingReason.id,
      type: leavingReason.type,
    };
  } else if ('title' in leavingReason) {
    return {
      title: leavingReason.title,
      type: leavingReason.type,
    };
  }
};

export function convertFormToRegistrationData(data: RegistrationForm): StudentRegistrationUpdate {
  const {
    school_year_id,
    statuses,
    campus_property_id,
    house_property_id,
    age_group_property_id,
    same_age_group,
    product_assignments,
  } = data;
  if (!school_year_id) throw new Error('Empty school_year_id');

  const products = product_assignments?.map(convertFormDataToAssignedProductSave) ?? [];

  const params = {
    school_year_id,
    statuses: statuses
      .filter(propsAreNotEmpty)
      .filter((el) => el !== null)
      .reduce<RegistrationStatusUpdate[]>((acc, { formId, ...rest }) => {
        if (!rest.applies_from && !rest.school_property_id) return acc;

        return [
          ...acc,
          {
            applies_from: rest.applies_from,
            school_property_id: rest.school_property_id,
            id: rest.school_property_id,
            leaving_reason: getLeavingReasonPayload(rest.leaving_reason),
          },
        ];
      }, []),
    half_day: !!data.half_day,
    school_property_ids: [campus_property_id, house_property_id, age_group_property_id].filter(
      isNotEmpty,
    ),
    product_assignments: products,
  };

  return same_age_group ? { ...params, same_age_group } : params;
}

export function convertRegistrationDataToForm(
  registration: StudentRegistration,
  canceledEnrollmentParams?: CanceledEnrollmentParams,
): RegistrationForm {
  const { school_year, school_properties, statuses, same_age_group } = registration;

  const formStatuses = getSortedStatuses(statuses).map(
    ({ school_property, applies_from, id, created_by, leaving_reason, created_at }) => ({
      created_at,
      formId: id,
      school_property_id: school_property.id,
      applies_from,
      created_by,
      leaving_reason,
    }),
  );

  return {
    school_year_id: school_year.id,
    statuses: canceledEnrollmentParams
      ? [
          ...formStatuses,
          {
            formId: canceledEnrollmentParams.id,
            school_property_id: canceledEnrollmentParams.statusId,
            applies_from: '',
          },
        ]
      : formStatuses,
    half_day: registration.half_day,
    campus_property_id: school_properties.find(({ type }) => type === SchoolPropertyType.Campus)
      ?.id,
    house_property_id: school_properties.find(({ type }) => type === SchoolPropertyType.House)?.id,
    age_group_property_id: school_properties.find(
      ({ type }) => type === SchoolPropertyType.AgeGroup,
    )?.id,
    same_age_group,
    product_assignments: [],
  };
}

export function validateStatusDateOrder({
  selectedStatuses,
  schoolYear,
  studentStatues,
}: {
  selectedStatuses: AddRegistrationStatus[];
  schoolYear: SchoolYear;
  studentStatues: SchoolTuneStatusType;
}) {
  return selectedStatuses.map((currentStatus, idx) => {
    if (!currentStatus.applies_from) return null;

    const isCurrentFieldOverlaps = selectedStatuses
      .slice(0, idx)
      .some(
        (prevField) =>
          prevField.applies_from &&
          currentStatus.applies_from &&
          isBefore(new Date(currentStatus.applies_from), new Date(prevField.applies_from)),
      );

    if (isCurrentFieldOverlaps && idx !== 0) {
      return { applies_from: { id: 'error-StatusDateOrder', values: {} } };
    }

    const currentStatusDate = new Date(currentStatus.applies_from);
    const schoolYearStartDate = new Date(schoolYear.start);
    const schoolYearEndDate = new Date(schoolYear.end);

    const isAfterYearEndError = isAfter(currentStatusDate, schoolYearEndDate) && {
      id: 'error-StatusDateBoundsEnd',
      values: {
        date: format(schoolYearEndDate, DATE_FORMAT),
      },
    };
    const isBeforeYearStartError = isBefore(currentStatusDate, schoolYearStartDate) && {
      id: 'error-StatusDateBoundsStart',
      values: {
        date: format(schoolYearStartDate, DATE_FORMAT),
      },
    };

    const isProspective = studentStatues.prospective.some(
      (s) => s.id === currentStatus.school_property_id,
    );
    const isUnsuccessful = studentStatues.unsuccessful.some(
      (s) => s.id === currentStatus.school_property_id,
    );

    /*
      Statuses that belong to prospective category should be allowed to start any date that is before the end date of the selected school
      year (and equal of after the applies from date of the previous status within the registration if one is present).

      Statuses of category unsuccessful can have applies from date that is no later than the end date of the selected school year and equal
      or later than the applies from date of hte precious status within the registration if it is present
    */

    if ((isProspective || isUnsuccessful) && isAfterYearEndError) {
      return {
        applies_from: isAfterYearEndError,
      };
    }

    /*
      Statuses of category current must have applies from date with the selected school year and equal or after the applies from date of
      the previous status within the registration (if present)
    */

    const isCurrent = studentStatues.current.some((s) => s.id === currentStatus.school_property_id);

    if (isCurrent && (isAfterYearEndError || isBeforeYearStartError)) {
      return {
        applies_from: isAfterYearEndError || isBeforeYearStartError,
      };
    }

    /*
      Statuses of category former can only be later than start of the selected school year or the applies from date of the previous status
      within the registration (whichever is later).
    */

    const isFormer = studentStatues.former.some((s) => s.id === currentStatus.school_property_id);

    if (isFormer && isBeforeYearStartError) {
      return {
        applies_from: isBeforeYearStartError,
      };
    }

    return null;
  });
}

export function isCurrentStatusSelected(statusProperties: SchoolProperty[]) {
  return (status?: RegistrationForm['statuses'][0]) =>
    statusProperties.some((s) =>
      Boolean(
        status?.school_property_id &&
          status?.applies_from &&
          s.category?.current &&
          s.id === status.school_property_id,
      ),
    );
}

export function instanceOfCanceledEnrollment(data: any): data is CanceledEnrollmentParams {
  if (!data) return false;

  return Boolean(
    data &&
      data.id !== undefined &&
      data.rejectedReEnrollment !== undefined &&
      data.statusId !== undefined &&
      data.user !== undefined,
  );
}

type GetPreviousStatusIndexProps<T> = {
  schoolStatuses: SchoolProperty[];
  currentStatuses: T[];
  index: number;
};

export const getPreviousStatusIndex = <
  T extends Pick<AddRegistrationStatus, 'school_property_id'>,
>({
  schoolStatuses,
  currentStatuses,
  index,
}: GetPreviousStatusIndexProps<T>) => {
  const beforeStatuses = schoolStatuses.slice(0, index);
  const statusesIds = currentStatuses.map(({ school_property_id }) => school_property_id);

  const prevStatus = beforeStatuses.findLast(({ id }) => statusesIds.includes(id));
  return prevStatus ? statusesIds.findIndex((id) => id === prevStatus.id) : -1;
};

export const convertFormDataToEnrollmentBase = <
  T extends Pick<
    RegistrationForm,
    'age_group_property_id' | 'house_property_id' | 'school_year_id' | 'half_day'
  > & {
    statuses: Array<Partial<EnrollmentStatus>>;
  },
>(
  formData: T,
): EnrollmentBase => {
  const { age_group_property_id, house_property_id, school_year_id, half_day, statuses } = formData;
  if (!school_year_id) throw new Error('Empty school_year_id');

  const schoolPropertiesIds = [age_group_property_id, house_property_id].filter(isNotEmpty) ?? [];
  const enrollmentStatus = statuses
    .filter(propsAreNotEmpty)
    .filter((el) => el !== null)
    .reduce<EnrollmentStatus[]>((acc, { school_property_id, applies_from, applies_to }) => {
      if (!applies_from && !school_property_id) return acc;
      return [...acc, { school_property_id, applies_from, applies_to }];
    }, []);

  return removeObjectUndefinedOrNullValues({
    school_year_id: school_year_id,
    school_property_ids: schoolPropertiesIds.filter(Boolean),
    statuses: enrollmentStatus.length ? enrollmentStatus : undefined,
    half_day: !!half_day,
  });
};

export const checkHasChangesToConfirm = (
  form: UseFormReturn<RegistrationForm>,
  statusesToConfirm: string[],
) => {
  //no trigger statuses
  if (!statusesToConfirm.length) return false;

  const { dirtyFields, defaultValues } = form.formState;
  const statuses = form.getValues().statuses;
  const defaultStatuses = defaultValues?.statuses ?? [];

  //general changes
  if (dirtyFields.half_day || dirtyFields.age_group_property_id || dirtyFields.school_year_id) {
    return true;
  }

  //trigger status deleted
  const statusDeleted = statusesToConfirm.some(
    (id) => statuses.find((status) => status.school_property_id === id) === undefined,
  );
  if (statusDeleted) return true;

  //trigger status' dates changed
  const datesChanged = statusesToConfirm.some((id) => {
    const statusIndex = statuses.findIndex((s) => s.school_property_id === id);
    const defaultStatusIndex = defaultStatuses.findIndex((s) => s?.school_property_id === id);

    const appliedFromChanged =
      statuses[statusIndex]?.applies_from !== defaultStatuses[defaultStatusIndex]?.applies_from;
    const appliedToChanged =
      statuses[statusIndex + 1]?.applies_from !==
      defaultStatuses[defaultStatusIndex + 1]?.applies_from;
    return appliedFromChanged || appliedToChanged;
  });

  return datesChanged;
};
