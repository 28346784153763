import {
  DEFAULT_DATE_FORMAT_FNS,
  SchoolYear,
  SchoolYearPeriod,
  SchoolYearPeriodGroup,
  useGetSchoolYears,
} from '@schooly/api';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfToday, startOfWeek } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

const startMonthDay = format(startOfMonth(new Date()), DEFAULT_DATE_FORMAT_FNS);
const endMonthDay = format(endOfMonth(new Date()), DEFAULT_DATE_FORMAT_FNS);
const startWeekDay = format(startOfWeek(new Date()), DEFAULT_DATE_FORMAT_FNS);
const endWeekDay = format(endOfWeek(new Date()), DEFAULT_DATE_FORMAT_FNS);
const today = format(startOfToday(), DEFAULT_DATE_FORMAT_FNS);

export interface Period {
  yearId: string;
  yearName: string;
  period: SchoolYearPeriod;
}

export const useSchoolPeriods = (
  schoolId: string,
  date: string[],
  defaultSchoolYear?: SchoolYear,
) => {
  const { $t } = useIntl();
  const [currentPeriod, setCurrentPeriod] = useState<Period>();
  const [startDay, endDay] = date;

  const { data, isLoading } = useGetSchoolYears(schoolId, {
    refetchOnMount: 'always',
  });

  const schoolYears: SchoolYear[] = useMemo(
    () =>
      data?.school_years.map((y) => {
        const name = $t({ id: 'school-schoolPeriods-AllYear' });
        const allYear: SchoolYearPeriodGroup = {
          name,
          id: y.id,
          should_publish_events: false,
          frequency: null,
          main: false,
          periods: [
            {
              date_from: y.start,
              date_to: y.end,
              name,
              id: y.id,
            },
          ],
        };
        return {
          ...y,
          period_groups: y.period_groups ? [allYear, ...y.period_groups] : [allYear],
        };
      }) ?? [],
    [$t, data?.school_years],
  );

  const getYearPeriod = useCallback(
    (startDate: string, endDate: string) => {
      return schoolYears.reduce<Period>((acc, y) => {
        if (!y.period_groups) {
          return acc;
        }

        const period = y.period_groups
          .flatMap((g) => {
            return g.periods.filter((p) => p.date_from === startDate && p.date_to === endDate);
          })
          .at(-1);

        return period
          ? {
              yearId: y.id,
              yearName: y.name,
              period,
            }
          : acc;
      }, {} as { yearId: string; yearName: string; period: SchoolYearPeriod });
    },
    [schoolYears],
  );

  const currentSchoolYear = useMemo(() => {
    if (currentPeriod) {
      return schoolYears?.find((s) => s.id === currentPeriod.yearId);
    }

    if (defaultSchoolYear) return defaultSchoolYear;
  }, [currentPeriod, defaultSchoolYear, schoolYears]);

  const isToday = useMemo(() => {
    return today === startDay && today === endDay;
  }, [endDay, startDay]);

  const isThisWeek = useMemo(() => {
    return startWeekDay === startDay && endWeekDay === endDay;
  }, [endDay, startDay]);

  const isThisMonth = useMemo(() => {
    return startMonthDay === startDay && endMonthDay === endDay;
  }, [endDay, startDay]);

  const isCurrentYear = useMemo(() => {
    return defaultSchoolYear?.start === startDay && defaultSchoolYear?.end === endDay;
  }, [defaultSchoolYear?.end, defaultSchoolYear?.start, endDay, startDay]);

  const isFromTodayToThisYearEnd = useMemo(() => {
    return today === startDay && defaultSchoolYear?.end === endDay;
  }, [defaultSchoolYear?.end, endDay, startDay]);

  const isFromThisYearStartToToday = useMemo(() => {
    return defaultSchoolYear?.start === startDay && today === endDay;
  }, [defaultSchoolYear?.start, endDay, startDay]);

  const label = useMemo(() => {
    const [startDate, endDate] = date;

    if (!startDate && !endDate) return '';

    if (
      currentPeriod?.period.date_from === startDate &&
      currentPeriod?.period.date_to === endDate
    ) {
      return `${currentPeriod?.yearName} ${currentPeriod?.period.name}`;
    }

    const data = getYearPeriod(startDate!, endDate!);
    if (data.period) {
      setCurrentPeriod(data);
      return `${data.yearName} ${data.period.name}`;
    } else {
      setCurrentPeriod(undefined);
    }

    if (isToday) {
      return $t({ id: 'datepicker-Today' });
    }

    if (isThisWeek) {
      return $t({ id: 'datepicker-ThisWeek' });
    }

    if (isThisMonth) {
      return $t({ id: 'datepicker-ThisMonth' });
    }

    if (isFromTodayToThisYearEnd) {
      return $t({ id: 'datepicker-ThisYearFromToday' });
    }

    if (isFromThisYearStartToToday) {
      return $t({ id: 'datepicker-StartOfThisYearToToday' });
    }
  }, [
    $t,
    currentPeriod?.period.date_from,
    currentPeriod?.period.date_to,
    currentPeriod?.period.name,
    currentPeriod?.yearName,
    date,
    getYearPeriod,
    isThisMonth,
    isThisWeek,
    isToday,
    isFromTodayToThisYearEnd,
    isFromThisYearStartToToday,
  ]);

  return useMemo(
    () => ({
      currentPeriod,
      schoolYears,
      isLoading,
      label,
      changePeriod: setCurrentPeriod,
      isThisWeek,
      isThisMonth,
      currentSchoolYear,
      isCurrentYear,
      isToday,
      isFromTodayToThisYearEnd,
      isFromThisYearStartToToday,
    }),
    [
      currentPeriod,
      currentSchoolYear,
      isCurrentYear,
      isLoading,
      isThisMonth,
      isThisWeek,
      isToday,
      label,
      schoolYears,
      isFromTodayToThisYearEnd,
      isFromThisYearStartToToday,
    ],
  );
};

type UsePeriodsLabelProps = {
  schoolYears: SchoolYear[];
};

export type PeriodDates = { startDate: string; endDate: string };

export const usePeriodsLabel = ({ schoolYears }: UsePeriodsLabelProps) => {
  const getPeriodKey = ({ startDate, endDate }: PeriodDates) => `${startDate}${endDate}`;
  const { $t } = useIntl();

  const periodsMap = useMemo(() => {
    const map: Map<string, SchoolYearPeriod['name']> = new Map();
    const yearName = $t({ id: 'school-schoolPeriods-AllYear' });

    const years = schoolYears.flatMap((s) => {
      map.set(getPeriodKey({ startDate: s.start, endDate: s.end }), `${s.name} ${yearName}`);
      return s.period_groups;
    });

    for (const periodGroup of years) {
      if (!periodGroup) continue;
      for (const { date_from, date_to, name } of periodGroup.periods) {
        map.set(getPeriodKey({ startDate: date_from, endDate: date_to }), name);
      }
    }

    return map;
  }, [$t, schoolYears]);

  const getPeriodLabel = useCallback(
    (dates: PeriodDates) => periodsMap.get(getPeriodKey(dates)),
    [periodsMap],
  );

  return { getPeriodLabel };
};
