import { List, ListItem, Tooltip, TooltipProps, Typography } from '@mui/material';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { NewTabIcon, SimpleButton } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

export const DiscrepancyTooltip: FC<TooltipProps> = (props) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 300,
            minWidth: 300,
            padding: 1.25,
            maxHeight: 410,
            overflowY: 'scroll',
          },
        },
      }}
      {...props}
    />
  );
};

type InvoiceDiscrepancyTooltipTitleProps = {
  invoiceLink?: string;
  labelIds: string[];
  titleId: string;
};

export const InvoiceDiscrepancyTooltipTitle: FC<InvoiceDiscrepancyTooltipTitleProps> = ({
  invoiceLink,
  labelIds,
  titleId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography mb={2}>{formatMessage({ id: titleId })}</Typography>
      <Typography>{formatMessage({ id: 'payableFees-Discrepancy-ListOfChanges' })}</Typography>

      <List sx={(theme) => ({ listStyleType: 'disc', padding: theme.spacing(0, 2.75) })}>
        {labelIds.map((id) => (
          <ListItem key={id} sx={(theme) => ({ display: 'list-item', padding: theme.spacing(0) })}>
            <Typography>{formatMessage({ id })}</Typography>
          </ListItem>
        ))}
      </List>

      {invoiceLink && (
        <Link to={invoiceLink} target="_blank">
          <SimpleButton
            sx={(theme) => ({
              marginTop: theme.spacing(1),
              '& .MuiButton-startIcon .svg-icon': {
                fontSize: theme.spacing(2),
              },
            })}
            startIcon={<NewTabIcon />}
          >
            <Typography>{formatMessage({ id: 'payableFees-ViewInvoiceInXero' })}</Typography>
          </SimpleButton>
        </Link>
      )}
    </>
  );
};

type LineItemDiscrepancyTooltipTitleProps = {
  invoiceLink?: string;
  labels: { date: string; label: ReactNode }[];
  titleId: string;
};

export const LineItemDiscrepancyTooltipTitle: FC<LineItemDiscrepancyTooltipTitleProps> = ({
  invoiceLink,
  labels,
  titleId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Typography>{formatMessage({ id: titleId })}</Typography>

      {labels.map(({ date, label }) => (
        <React.Fragment key={date}>
          <Typography color="text.primary" mt={2}>
            {format(newDateTimezoneOffset(date), SHORT_FORMATTED_DATE_FORMAT_FNS)}
          </Typography>
          {label}
        </React.Fragment>
      ))}

      {invoiceLink && (
        <Link to={invoiceLink} target="_blank">
          <SimpleButton
            sx={(theme) => ({
              marginTop: theme.spacing(1),
              '& .MuiButton-startIcon .svg-icon': {
                fontSize: theme.spacing(2),
              },
            })}
            startIcon={<NewTabIcon />}
          >
            <Typography>{formatMessage({ id: 'payableFees-ViewInvoiceInXero' })}</Typography>
          </SimpleButton>
        </Link>
      )}
    </>
  );
};
