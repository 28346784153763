import {
  Chip,
  Icon,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DATE_FORMAT_RANGE_FNS,
  GetPayableFeesQuerySort,
  INVOICE_ACTION_MUTATION,
  PayableFee,
  PayerType,
  SORT_DIRECTION,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { CURRENCY_SYMBOLS } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChevronLeftIcon,
  ContextMenu,
  CopyIcon,
  EditIcon,
  GridCell,
  GridHead,
  MoreIcon,
  NewTabIcon,
  Price,
  SortableCell,
  theme,
  TypographyWithOverflowHint,
  UnpublishIcon,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { formatPhoneNumberWithCode } from '@schooly/utils/phone-number';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { useIsMutating } from '@tanstack/react-query';
import { differenceInDays, format } from 'date-fns';
import { FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { VoidInvoiceModal } from '../../components/common/Invoices/VoidInvoiceModalContent';
import {
  payableFeeHasInvoice,
  payableFeeStatusColor,
  useInvoiceDiscrepancyRecords,
} from '../../components/common/PayableFees/helpers';
import useAppLocation from '../../hooks/useAppLocation';
import { EDITABLE_INVOICE_STATUSES } from '../Invoices/InvoiceEditModal';

const SPACING = 8 * 2;

export const PAYABLE_FEES_COL_WIDTH = {
  payer_1: 100 + SPACING,
  invoiceNum_2: 60 + SPACING,
  invoiceDate_3: 70 + SPACING,
  dueDate_4: 70 + SPACING,
  student_5: 50 + SPACING,
  discount_6: 70 + SPACING,
  paid_7: 100 + SPACING,
  due_8: 100 + SPACING,
  total_9: 100 + SPACING,
  status_10: undefined, // status is flexible width
  expand_11: 60 + SPACING,
};

type PayableFeesHeaderProps = {
  sort?: GetPayableFeesQuerySort;
  onChangeSort: (v: GetPayableFeesQuerySort) => void;
  rightIcon?: ReactNode;
} & TableHeadProps;

export const PayableFeesHeader: FC<PayableFeesHeaderProps> = ({
  onChangeSort,
  rightIcon,
  sort,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const handleSort = (by: GetPayableFeesQuerySort['by']) => () => {
    onChangeSort({
      by,
      direction:
        by === sort?.by
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  const cellSort = sort ? { ...sort, columnTextId: sort.by } : undefined;

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.payer_1}>
        {formatMessage({ id: 'payableFees-payer' })}
      </GridCell>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.invoiceNum_2}>
        {formatMessage({ id: 'payableFees-invoice' })} #
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.invoiceDate_3}
        label={formatMessage({ id: 'payableFees-invoice-date' })}
        sort={cellSort}
        columnTextId="issue_date"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.dueDate_4}
        label={formatMessage({ id: 'payableFees-due-date' })}
        sort={cellSort}
        columnTextId="due_date"
        onChangeSort={handleSort}
      />

      <GridCell width={PAYABLE_FEES_COL_WIDTH.student_5}>
        {formatMessage({ id: 'payableFees-student' })}
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.discount_6}
        label={formatMessage({ id: 'payableFees-discount' })}
        sort={cellSort}
        columnTextId="discount"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.paid_7}
        label={formatMessage({ id: 'payableFees-paid' })}
        sort={cellSort}
        columnTextId="paid"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.due_8}
        label={formatMessage({ id: 'payableFees-due' })}
        sort={cellSort}
        columnTextId="due"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.total_9}
        label={formatMessage({ id: 'payableFees-total' })}
        sort={cellSort}
        columnTextId="total"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={formatMessage({ id: 'payableFees-status' })}
        sort={cellSort}
        columnTextId="status"
        onChangeSort={handleSort}
      />
      <GridCell width={PAYABLE_FEES_COL_WIDTH.expand_11} />
    </GridHead>
  );
};

type PayableFeeRowProps = {
  payableFee: PayableFee;
  onVoidInvoice: (invoiceId: string) => Promise<any>;
};

export const PayableFeeRow: FC<PayableFeeRowProps> = ({ payableFee, onVoidInvoice }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [rowHovered, setRowHovered] = useState(false);
  const [invoiceVoidModalOpened, openInvoiceVoidModal, closeInvoiceVoidModal] = useFlag();
  const navigate = useNavigate();
  const location = useAppLocation();
  const isInvoiceMutating = Boolean(useIsMutating({ mutationKey: [INVOICE_ACTION_MUTATION] }));

  const handleExpandClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded((e) => !e);
  };
  const { permissions, schoolId = '' } = useAuth();
  const canEditInvoice = permissions.includes('product_and_invoice_manager');
  const isUpcoming = payableFee.status === 'upcoming';
  const isCancelled = payableFee.status === 'cancelled';
  const isVoided = payableFee.status === 'voided';

  const invoice = payableFeeHasInvoice(payableFee) ? payableFee : null;

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: {
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: 'background.default',
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        },
      }),
      ...(isVoided && {
        '.MuiTypography-root': {
          color: rowHovered ? `${theme.palette.common.grey2} !important` : 'common.grey',
        },
      }),
      '&.item-deleted, .MuiTypography-root.item-deleted': {
        '.MuiTypography-root': {
          color: rowHovered ? `${theme.palette.common.grey2} !important` : 'common.grey',
        },
        color: rowHovered ? `${theme.palette.common.grey2} !important` : 'common.grey',
        textDecoration: 'line-through',
      },
    },
  };
  const { discrepantItemIds } = useInvoiceDiscrepancyRecords(payableFee.discrepancy_records ?? []);

  const itemsToRender =
    !!invoice && payableFee.discrepancy_records.length
      ? payableFee.items.filter((item) => !item.is_deleted || discrepantItemIds.includes(item.id))
      : payableFee.items;

  const discount = useMemo(() => {
    return itemsToRender.reduce<number>((acc, i) => acc + i.discount_amount, 0);
  }, [itemsToRender]);

  const link = payableFee.invoice_link_for_issuer;
  const priceProps = {
    variant: 'body1',
    currency: CURRENCY_SYMBOLS[payableFee.currency],
  } as const;

  const canVoidInvoice =
    invoice && !invoice.total_paid ? EDITABLE_INVOICE_STATUSES.includes(invoice.status) : false;
  const canShowContextMenu =
    !isVoided && !isUpcoming && !isCancelled && !!payableFee.invoice_id && canEditInvoice;

  return (
    <>
      <TableRow {...hoverProps}>
        <GridCell borderBottom={isExpanded ? false : undefined}>
          <PayableFeePayerCellContent fee={payableFee} />
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint
            color="text.primary"
            onClick={
              link
                ? (e) => {
                    e.preventDefault();
                    window.open(link, '_blank');
                  }
                : undefined
            }
            sx={
              link
                ? {
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }
                : undefined
            }
            className={isVoided ? 'item-deleted' : undefined}
          >
            {payableFee.invoice_number || '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint color="text.primary">
            {payableFee.invoice_date
              ? format(newDateTimezoneOffset(payableFee.invoice_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint color="text.primary">
            {payableFee.due_date
              ? format(newDateTimezoneOffset(payableFee.due_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <PayableFeeStudentsCellContent fee={payableFee} />
        </GridCell>
        <GridCell>
          <Typography color="text.primary" className={isVoided ? 'item-deleted' : undefined}>
            {discount ? <Price price={discount} {...priceProps} /> : '–'}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary" className={isVoided ? 'item-deleted' : undefined}>
            {payableFee.total_paid ? <Price price={payableFee.total_paid} {...priceProps} /> : '–'}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary" className={isVoided ? 'item-deleted' : undefined}>
            {payableFee.due ? <Price price={payableFee.due} {...priceProps} /> : '–'}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary" className={isVoided ? 'item-deleted' : undefined}>
            {payableFee.total_payment ? (
              <Price price={payableFee.total_payment} {...priceProps} />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <PayableFeeStatusCellContent fee={payableFee} />
        </GridCell>

        <GridCell>
          <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
            {canShowContextMenu && (
              <ContextMenu
                PopperProps={{ disablePortal: false }}
                placement="bottom"
                arrow={false}
                componentsProps={{
                  tooltip: {
                    sx: {
                      minWidth: 215,
                      '&&.MuiTooltip-tooltip': {
                        mt: 0.25,
                      },
                      '.container': {
                        gap: 1.5,
                        paddingX: 0,
                      },
                    },
                  },
                }}
                actions={[
                  {
                    labelTextId: 'payableFees-EditInvoice',
                    icon: <EditIcon />,
                    onClick: (close) => {
                      close?.();
                      navigate(`/payablefees/${payableFee.invoice_id}`, {
                        state: { backgroundLocation: location },
                      });
                    },
                  },
                  ...(canVoidInvoice
                    ? [
                        {
                          labelTextId: 'payableFees-VoidInvoice',
                          icon: <UnpublishIcon />,
                          onClick: (close?: () => void) => {
                            openInvoiceVoidModal();
                            close?.();
                          },
                        },
                      ]
                    : []),
                ]}
              >
                {(open) => (
                  <IconButton inverse onClick={open}>
                    <MoreIcon />
                  </IconButton>
                )}
              </ContextMenu>
            )}
            <IconButton
              inverse
              onClick={handleExpandClick}
              size="medium"
              sx={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)' }}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Stack>
        </GridCell>
      </TableRow>
      {isExpanded &&
        itemsToRender.map((feeItem, i) => {
          const isDeleted = 'is_deleted' in feeItem && feeItem.is_deleted;
          return (
            <TableRow
              className={isDeleted || isVoided ? 'item-deleted' : undefined}
              key={i}
              {...hoverProps}
            >
              <GridCell borderBottom={i === payableFee.items.length - 1 ? true : false} />
              <GridCell py={1.5} colSpan={4}>
                <TypographyWithOverflowHint color="text.primary">
                  {feeItem.label}
                </TypographyWithOverflowHint>
              </GridCell>
              <GridCell py={1.5}>
                <Typography color="text.primary">
                  {feeItem.discount_amount ? (
                    <Price price={feeItem.discount_amount} {...priceProps} />
                  ) : (
                    '–'
                  )}
                </Typography>
              </GridCell>
              <GridCell py={1.5}>
                {feeItem.paid ? <Price price={feeItem.paid} {...priceProps} /> : '–'}
              </GridCell>
              <GridCell py={1.5}>
                {feeItem.due ? <Price price={feeItem.due} {...priceProps} /> : '–'}
              </GridCell>
              <GridCell py={1.5} colSpan={3}>
                <Typography color="text.primary">
                  <Price price={feeItem.price_full} {...priceProps} />
                </Typography>
              </GridCell>
            </TableRow>
          );
        })}
      <VoidInvoiceModal
        relationType="staff"
        schoolId={schoolId}
        opened={invoiceVoidModalOpened}
        onClose={closeInvoiceVoidModal}
        isLoading={isInvoiceMutating}
        payableFee={payableFee}
        onSubmit={async () => {
          if (!invoice) return;
          await onVoidInvoice?.(invoice.invoice_id);
          closeInvoiceVoidModal();
        }}
      />
    </>
  );
};

const PayableFeePayerCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderContacts = ({ email, telephone }: { email?: string; telephone?: string }) => {
    if (!email && !telephone) return null;

    return (
      <Stack gap={0.5} mt={1} flex={1}>
        {email && (
          <ContactButton
            title={email}
            copyMessage={formatMessage({ id: 'clipboard-EmailCopied' })}
            copyContent={email}
          />
        )}
        {telephone && (
          <ContactButton
            title={formatPhoneNumberWithCode(telephone)}
            copyMessage={formatMessage({ id: 'clipboard-PhoneCopied' })}
            copyContent={telephone}
          />
        )}
      </Stack>
    );
  };

  const getTitle = () => {
    switch (fee.payer.type) {
      case PayerType.Company:
        return fee.payer.data.name;
      case PayerType.Default:
        return getUserFullName(fee.payer.data);
      default:
        const _: never = fee.payer;
        return _;
    }
  };

  const openPayer = () => {
    window.open(
      fee.payer.type === PayerType.Company
        ? `/companies/${fee.payer.data.id}`
        : `/parents/${fee.payer.data.relation_id || fee.payer.data.id}`,
      '_blank',
    );
  };

  const title = getTitle();

  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 'unset',
            px: 1,
          },
        },
      }}
      title={
        <Stack>
          <Typography
            color="text.primary"
            textAlign="center"
            maxWidth={300}
            onClick={openPayer}
            sx={{
              cursor: 'pointer',
              ':hover': { textDecoration: 'underline', color: 'primary.main' },
            }}
          >
            {title}
          </Typography>
          {renderContacts(fee.payer.data)}
        </Stack>
      }
    >
      <div>
        <Typography color="text.primary" noWrap onClick={openPayer}>
          {title}
        </Typography>
      </div>
    </Tooltip>
  );
};

export const ContactButton: FC<{ title: ReactNode; copyContent: string; copyMessage: string }> = ({
  title,
  copyContent,
  copyMessage,
}) => {
  const { showNotification } = useNotifications();

  return (
    <Stack
      alignSelf="stretch"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py={0.25}
      px={1}
      gap={2}
      sx={(theme) => ({
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        '&:hover': { backgroundColor: theme.palette.background.default, '.icon': { opacity: 1 } },
      })}
      onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(copyContent);
        showNotification({ message: copyMessage });
      }}
    >
      <TypographyWithOverflowHint variant="h3">{title}</TypographyWithOverflowHint>
      <Icon className="icon" sx={{ opacity: 0, transition: 'all .2s' }}>
        <CopyIcon />
      </Icon>
    </Stack>
  );
};

const PayableFeeStudentsCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  // const { schoolId = '' } = useAuth();
  if (!fee.students.length) return '–';

  return (
    <Tooltip
      title={
        <Stack m={-1}>
          {fee.students.map((s) => (
            <Stack
              key={s.id}
              py={0.5}
              px={1}
              gap={1}
              sx={(theme) => ({
                cursor: 'pointer',
                borderRadius: theme.spacing(0.5),
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                  '.icon': { opacity: 1 },
                },
              })}
              onClick={(e) => {
                e.preventDefault();
                window.open(`/students/${s.id}#payablefees`, '_blank');
              }}
            >
              <Stack>
                <Stack
                  flexDirection="row"
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <TypographyWithOverflowHint variant="h3">
                    {getUserFullName(s)}
                  </TypographyWithOverflowHint>
                  <Icon className="icon" sx={{ fontSize: 15, opacity: 0, transition: 'all .2s' }}>
                    <NewTabIcon />
                  </Icon>
                </Stack>
                {/* TODO: unblock when age group is available for students in reporting*/}
                {/* <PropertyTypeTagSelect
                  sx={{ alignSelf: 'flex-start' }}
                  size="small"
                  userRole={SchoolUserRole.Student}
                  schoolId={schoolId}
                  id="f77a315c-e01d-434c-87b7-a9085c82f3a1"
                /> */}
              </Stack>
            </Stack>
          ))}
        </Stack>
      }
    >
      <Typography sx={{ cursor: fee.students.length ? 'pointer' : undefined }} color="text.primary">
        {fee.students.length || '–'}
      </Typography>
    </Tooltip>
  );
};

const PayableFeeStatusCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderBadge = () => {
    switch (fee.status) {
      case 'overdue':
      case 'paid':
        const dueDate = newDateTimezoneOffset(fee.due_date);
        const fullyPaidDate = fee.fully_paid_date
          ? newDateTimezoneOffset(fee.fully_paid_date)
          : null;
        const compareDate = fee.status === 'overdue' ? new Date() : fullyPaidDate;

        const formatDistance = compareDate ? differenceInDays(dueDate, compareDate) : null;
        const color = fee.status === 'overdue' ? 'error' : 'success';

        if (!formatDistance) return null;

        const labelDaysLate = formatMessage(
          {
            id:
              fee.status === 'overdue'
                ? 'payableFees-overdue-days'
                : 'payableFees-overdue-days-late',
          },
          { days: Math.abs(formatDistance) },
        );

        const labelOnTime = formatMessage({ id: 'payableFees-on-time' });

        return (
          <Chip
            variant="outlined"
            label={
              fee.status === 'paid' && fullyPaidDate && fullyPaidDate < dueDate
                ? labelOnTime
                : labelDaysLate
            }
            color={color}
            sx={(theme) => ({
              borderColor: `${theme.palette[color].main} !important`,
              height: theme.spacing(2.5),
              '.MuiChip-label': {
                padding: `${theme.spacing(0, 0.75)} !important`,
              },
            })}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5}>
      <Typography sx={{ color: `${payableFeeStatusColor[fee.status]} !important` }}>
        {formatMessage({ id: `payableFees-status-${fee.status}` })}
      </Typography>
      {renderBadge()}
    </Stack>
  );
};
