import {
  AssessmentStatuses,
  Countries,
  EventsInvite,
  Genders,
  InviteStatus,
  Languages,
  Nationalities,
  RelationsToChild,
  SchoolInviteStatus,
  SchoolUserRole,
  Titles,
} from '@schooly/constants';

import { PaymentFrequencyType } from '../frequencies';
import { InvoicesReportingDayPastDue, PayableFeeStatus, ProductTypeId } from '../invoices';
import { AnnualPlannerViewMode, AnnualPlanRecordTypes } from './endpoints/annualPlan';
import { ApplicationCreateCustomField } from './endpoints/applications';
import { ConductVisibility } from './endpoints/conduct';
import { EventsStatuses } from './events';
import { MessageStatus } from './messages';
import { BooleanOrNull, FilterElementType, FilterValue, StringOrNull, UploadFile } from './misc';
import {
  WithAddress,
  WithAssociations,
  WithAvatar,
  WithDateOfBirth,
  WithGender,
  WithGuardian,
  WithLanguage,
  WithName,
  WithNationality,
  WithRole,
  WithTelephone,
  WithTimestamp,
} from './partials';
import { RepetitionType } from './recurring';
import {
  ParentSchoolRelationChild,
  SchoolRelation,
  SchoolUserRelationInBaseUser,
  StudentSchoolRelation,
} from './relations';
import { ReportStatuses } from './reports';
import { School } from './schools';
import { SignUpStatuses, SignUpType } from './signups';

export interface BaseUser extends WithName, WithAvatar {
  user_id: string;
}

export interface User
  extends BaseUser,
    WithDateOfBirth,
    WithAddress,
    WithGender,
    WithTelephone,
    WithNationality,
    WithLanguage {
  email?: StringOrNull;
}

export interface SyncUserSchoolRelation extends SchoolRelation, WithAvatar, WithRole {
  school: School;
}

export interface Guardian extends BaseUser {
  relation_id: string;
  schools: School[];
  invite_status?: SchoolInviteStatus | null;
  email?: StringOrNull;
}

export interface GuardianOfRelation extends WithTimestamp {
  id: string;
  guardian_id: string;
  child_id: string;
  school_id: string;
  guardian: Guardian;
  primary?: BooleanOrNull;
  relation_type?: RelationsToChild | null;
  start?: StringOrNull;
  end?: StringOrNull;
  school?: School | null;
  child: Guardian;
}

export interface Child
  extends BaseUser,
    WithGuardian,
    WithDateOfBirth,
    WithLanguage,
    WithTimestamp {
  email?: StringOrNull;

  student_relations: SchoolUserRelationInBaseUser[];
  staff_relations: SchoolUserRelationInBaseUser[];
  parent_relations: SchoolUserRelationInBaseUser[];

  school_user_relation?: ParentSchoolRelationChild['school_user_relation'];
}

export interface AdultAssociation extends BaseUser, WithDateOfBirth {
  student_relations?: StudentSchoolRelation[];
}

export interface ChildAssociation extends BaseUser, WithAddress, WithTelephone {
  school_relations?: SyncUserSchoolRelation[];
}

export interface PossibleSibling extends BaseUser {
  school_user_relation: SchoolRelation;
}

export interface SyncUser
  extends BaseUser,
    WithAssociations,
    WithGuardian,
    WithDateOfBirth,
    WithGender,
    WithAddress,
    WithLanguage,
    WithNationality,
    WithTelephone,
    WithTimestamp {
  account_email: string;
  email?: StringOrNull;
  last_selected_school_id: StringOrNull;
  use_account_email_as_contact: boolean;
  school_relations: SyncUserSchoolRelation[];
  relation_id?: string;
  can_be_edited?: BooleanOrNull;
  possible_siblings: PossibleSibling[];
  duplicates?: DuplicateUser[];
}

export interface NewUser extends User {
  language: Languages | null;
  nationality: Nationalities | null;
  other_languages_spoken: Languages[] | null;
  other_nationalities: Nationalities[] | null;
}

export interface NewFamilyMember extends Omit<NewUser, 'user_id'> {
  user_id?: string;
  relation_type?: RelationsToChild;
  custom_fields_values?: ApplicationCreateCustomField[];
}

export interface NewAdult extends NewFamilyMember {}

export enum FilterKeys {
  Date = 'date',
  IntersectDate = 'intersect_date',
  SingleDate = 'single_date',
  AgeGroup = 'age_group',
  Department = 'department',
  Status = 'status',
  House = 'house',
  Campus = 'campus',
  Gender = 'gender',
  Nationality = 'nationality',
  OnlyTutorGroups = 'only_tutor_groups',
  Subject = 'subject',
  Staff = 'staff',
  Creator = 'creator',
  Group = 'group',
  Student = 'student',
  RecurrenceId = 'recurrence_id',
  RepetitionType = 'repetition_type',

  // groups
  InviteStatus = 'invite_status',
  // groups
  StudentStatus = 'student_status',
  StaffStatus = 'staff_status',
  StudentAgeGroup = 'student_age_group',
  // TR-3989 Tutor group as type of groups
  TutorGroup = 'tutor_group',
  //StaffAgeGroup = 'staff_age_group',
  StudentHouse = 'student_house',
  StaffHouse = 'staff_house',

  // messages
  MessagesStatus = 'message_status',
  RecipientType = 'recipient_type',
  Month = 'month',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MessagesCreator = 'creator',
  MessagesReadPercent = 'read_percent',

  // assessments
  AssessmentStatus = 'assessment_status',

  // reports
  ReportStatus = 'report_status',
  Report = 'report',

  // conduct
  ConductType = 'conduct_type',
  ConductEntryIds = 'conduct_entry_ids',
  ConductStatus = 'visibility',

  // events & sign ups
  EventStatus = 'event_status',
  EventType = 'event_type',
  SignUpStatus = 'sign_up_status',
  SignUpType = 'sign_up_type',
  InviteType = 'invite_type',

  // parents
  OnlyEmptyEmail = 'empty_email_only',

  // students
  Expired = 'expired',
  // students
  LeavingReason = 'leaving_reason',

  // annual plan
  SchoolYear = 'school_year',
  RecordType = 'record_type',
  PlannerView = 'planner_view',
  SchoolDaysDate = 'school_days_date',

  //invoices
  Name = 'name',
  InvoicesAgeGroup = 'age_group_ids',
  Product = 'product_ids',
  ProductType = 'product_type_ids',
  Payer = 'payer_ids',
  DayPastDue = 'day_past_due',
  Frequency = 'frequency_types',
  FeeStatus = 'fee_status',
  Overdue = 'overdue',
  Due = 'due',
  DueAmount = 'due_amount',
  Discount = 'discount',
}

export type UserFilter = {
  [key in FilterKeys]: FilterValue[];
};

export interface UserName {
  title?: Titles | null;
  given_name: string;
  last_name: string;
  known_as?: StringOrNull;
}

export interface SuggestedChange {
  id: string;
  initiator: BaseUser;

  field: keyof User;
  new_value: string;
}

export interface UserName {
  title?: Titles | null;
  given_name: string;
  last_name: string;
  known_as?: StringOrNull;
}

export interface UserBasicData {
  date_of_birth: StringOrNull;
  gender: Genders | null;
  language: Languages | null;
  other_languages_spoken: Languages[] | null;
  nationality: Nationalities | null;
  other_nationalities: Nationalities[] | null;
  telephone: StringOrNull;
  other_telephones: string[] | null;
}

export interface UserSummary extends UserName, UserBasicData {
  created_at: number;
  email: string;
  id: string;
  avatar?: UploadFile;
  profile_picture?: StringOrNull;
  updated_at: number;
  user_id?: string;
}

export interface UserAddress {
  address_line_1: StringOrNull;
  address_line_2: StringOrNull;
  city: StringOrNull;
  region: StringOrNull;
  zip_code: StringOrNull;
  country: Countries | null;
}

export enum FilterSection {
  Student,
  Parent,
  Staff,
  Group,
  Message,
  Assessment,
  Report,
  Attendance,
  Conduct,
  Events,
  SignUps,
  AnnualPlanner,
  PayableFees,
}
// Deprecated
export type UserFilterListItem = {
  id: string;
  name: string;
  section: FilterSection;
  filter: UserFilter & {
    arrange_by?: FilterKeys;
    group_by?: FilterKeys;
  };
};

export type SavedFilter = {
  id: string;
  name: string;
  section: FilterSection;
  is_default: boolean;
  filter: {
    [FilterKeys.Date]?: string[];
    [FilterKeys.SingleDate]?: string[];
    [FilterKeys.IntersectDate]?: string[];
    [FilterKeys.AgeGroup]?: string[];
    [FilterKeys.Department]?: string[];
    [FilterKeys.Status]?: string[];
    [FilterKeys.House]?: string[];
    [FilterKeys.Campus]?: string[];
    [FilterKeys.Gender]?: Genders[];
    [FilterKeys.Nationality]?: Nationalities[];
    [FilterKeys.Subject]?: string[];
    [FilterKeys.Staff]?: string[];
    [FilterKeys.Creator]?: string[];
    [FilterKeys.Group]?: string[];
    [FilterKeys.Student]?: string[];
    [FilterKeys.OnlyTutorGroups]?: string[];
    [FilterKeys.RecurrenceId]?: string[];
    [FilterKeys.RepetitionType]?: RepetitionType[];

    // groups
    [FilterKeys.InviteStatus]?: InviteStatus[];
    // groups
    [FilterKeys.StudentStatus]?: string[];
    [FilterKeys.StaffStatus]?: string[];
    [FilterKeys.StudentAgeGroup]?: string[];
    // TR-3989 Tutor group as type of groups
    [FilterKeys.TutorGroup]?: string[];

    [FilterKeys.StudentHouse]?: string[];
    [FilterKeys.StaffHouse]?: string[];

    // messages
    [FilterKeys.MessagesStatus]?: MessageStatus[];
    [FilterKeys.RecipientType]?: SchoolUserRole[];
    [FilterKeys.Month]?: string[];
    [FilterKeys.MessagesReadPercent]?: string[];
    [FilterKeys.Creator]?: string[];

    // assessments
    [FilterKeys.AssessmentStatus]?: AssessmentStatuses[];

    // events
    [FilterKeys.EventStatus]?: EventsStatuses[];
    [FilterKeys.EventType]?: Array<'default' | 'period'>;
    [FilterKeys.InviteType]?: EventsInvite[];

    // sign ups
    [FilterKeys.SignUpStatus]?: SignUpStatuses[];
    [FilterKeys.SignUpType]?: SignUpType[];

    // reports
    [FilterKeys.ReportStatus]?: ReportStatuses[];
    [FilterKeys.Report]?: string[];

    // conduct
    [FilterKeys.ConductType]?: string[];
    [FilterKeys.ConductEntryIds]?: string[];
    [FilterKeys.ConductStatus]?: ConductVisibility[];
    arrange_by?: FilterKeys;
    group_by?: FilterKeys;
    past_only?: boolean;
    has_signups?: boolean;
    show_by_present_absent?: boolean;

    //parents
    [FilterKeys.OnlyEmptyEmail]?: number[];

    //students
    [FilterKeys.Expired]?: number[];
    [FilterKeys.LeavingReason]?: string[];

    // annual plan
    [FilterKeys.SchoolYear]?: string[];
    [FilterKeys.RecordType]?: AnnualPlanRecordTypes[];
    [FilterKeys.PlannerView]?: [AnnualPlannerViewMode];
    [FilterKeys.SchoolDaysDate]?: string[];

    // payable fees
    [FilterKeys.Product]?: string[];
    [FilterKeys.ProductType]?: ProductTypeId[];
    [FilterKeys.Payer]?: string[];
    [FilterKeys.DayPastDue]?: InvoicesReportingDayPastDue[];
    [FilterKeys.Frequency]?: PaymentFrequencyType[];
    [FilterKeys.FeeStatus]?: PayableFeeStatus[];
    [FilterKeys.InvoicesAgeGroup]?: string[];

    // not used for filters yet
    [FilterKeys.DueAmount]?: string[];
    [FilterKeys.Name]?: string[];
    [FilterKeys.Overdue]?: string[];
    [FilterKeys.Due]?: string[];
    [FilterKeys.Discount]?: string[];
  };
};

interface UserGroupMembership {
  start: string;
  end: string;
  individual: boolean;
  criteria_type: FilterElementType | null;
  enum_criteria: Array<Nationalities | Genders> | null;
  school_properties: Array<{ property_name: string; property_id: string }> | null;
}
export interface UserWithGroupMembership
  extends Omit<SchoolRelation, 'known_as'>,
    UserGroupMembership,
    WithDateOfBirth,
    WithAvatar {
  role: SchoolUserRole;
  age_group: string;
  attendance_codes: string[];
  gender: Genders;
}

export interface UserWithGroupMembershipNormalized
  extends Omit<UserWithGroupMembership, keyof UserGroupMembership> {
  membership: Array<UserGroupMembership>;
}

export interface DuplicateUser extends Omit<SchoolRelation, 'title'>, WithAvatar {}

export interface UserForDuplicatesCheck extends Omit<WithName, 'title'> {
  id: string;
  role: SchoolUserRole;
  date_of_birth: string;
}

export interface DuplicatesForUser {
  id: string;
  duplicates: DuplicateUser[];
}
