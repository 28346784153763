import React from 'react';

import TextLayout from '../../components/ui/TextLayout';

//TODO Remake with MUI
import './index.scss';

const PrivacyPolicy: React.FC = () => (
  <TextLayout>
    <section className="policy-text">
      <h1 className="mt-4 mb-5">Privacy Policy</h1>

      <p>Last update: 9 December 2021</p>
      <p>
        We at Schooly want to make sure all the information we hold about you (known as
        &ldquo;personal data&rdquo;) is safe and secure. We have written this privacy policy to tell
        you how we make sure we do that and what to do in case you have any questions for us or want
        to see what information we have. Where we use the terms &ldquo;we&rdquo; and
        &ldquo;our&rdquo; in this privacy policy, we are referring to Schooly.
      </p>
      <p>
        This privacy policy is for the users of Schooly (being parents/carers) and their
        children&nbsp; whose information is processed through Schooly, and staff at the institutions
        which use Schooly, as well as those people at other organisations which work with Schooly.
        Where we use the terms &ldquo;you&rdquo; and &ldquo;your&rdquo; in this privacy policy, we
        are referring to those individuals.&nbsp;
      </p>
      <p>
        When we collect or use in any way (known as &ldquo;process&rdquo;) your personal information
        in the course of our provision of the Schooly service we either act as a data controller or
        a data processor.We think that it is helpful to set out the details concerning the
        processing of all personal information through the Schooly service, even where we are not
        acting as the data controller but as the data processor of the relevant institution or
        individual. If you have any questions as to the policies of the relevant institution in
        relation to such information, please contact them directly.
      </p>
      <p>
        If you have any questions about our collection or use of your personal information you can
        email us at{' '}
        <a href="mailto: privacy@schooly-app.com" target="_blank" rel="noreferrer">
          <u>privacy@schooly-app.com</u>
        </a>
        .
      </p>
      <p>This privacy policy covers:</p>
      <ul>
        <li>Who is Schooly?</li>
        <li>Why read Schooly&rsquo;s privacy policy?</li>
        <li>What types of personal information does Schooly collect?</li>
        <li>Where does Schooly get personal information from?</li>
        <li>How does Schooly use your personal information?</li>
        <li>Is your personal information safe?</li>
        <li>How long does Schooly keep your personal information?</li>
        <li>What are your rights?</li>
        <li>What if you contact Schooly via social media?</li>
        <li>What about privacy and other organisations?</li>
        <li>What about cookies?</li>
        <li>What happens if Schooly needs to change this privacy policy?</li>
        <li>How do you get in touch with Schooly?</li>
      </ul>
      <h3>Who is Schooly?</h3>
      <p>
        Schooly (Schooly Solutions Ltd, Company number: 12443158, Registered office: 85 Great
        Portland Street, First Floor, London, England, W1W 7LT) provides a service (available via
        app or browser) which allows parents/carers and their children to share information with
        various different institutions (such as sports clubs, tutors, schools, drama clubs etc.) in
        one place.
      </p>
      <p>For users in the EU, our EU Representative is:</p>
      <ul>
        <li>Name: Delfin Serna Tinao</li>
        <li>NIF: 05205526M</li>
        <li>Address: Calle Mariano Palarea, 18, Murcia, Spain</li>
        <li>
          Email address:{' '}
          <a href="mailto: delfinsernat@gmail.com" target="_blank" rel="noreferrer">
            <u>delfinsernat@gmail.com</u>
          </a>
        </li>
      </ul>
      <h3>Why read Schooly&rsquo;s privacy policy?</h3>
      <p>
        The Schooly app needs to collect and use some personal information in order to set up
        accounts and allow users to share information with each other. This privacy policy applies
        to personal information that Schooly collects and uses when: (a) you interact with the
        Schooly service; (b) you interact with Schooly outside of the Schooly service; and (c) when
        you interact with other users within the Schooly service. This privacy policy explains what
        personal information we collect about you, and how we use this information.
      </p>
      <h3>What types of personal information does Schooly collect?</h3>
      <p>The personal information we process about you includes the following:</p>
      <ol>
        <li>
          the information we need in order to set up and maintain your account with Schooly, such as
          your name, username, password, date of registration, the names of any other individuals
          who are connected to your Schooly account (such as the names of your child, or other
          colleagues at your institution, as relevant) and institutions which you are connected to
          through Schooly &ndash; we will act as the data controller in relation to this
          information;&nbsp;
        </li>
        <li>
          the contents of the information which you share with other users of Schooly - we will act
          as the data processor in relation to this information following the instructions of the
          parent, child or institution (as the case may be) in sharing the data with the relevant
          recipient(s); and
        </li>
        <li>
          if you are a supplier to or other business contact of Schooly, your name, role, company,
          contact details and other details relating to the business relationship between us. We
          will act as the data controller in relation to this information.&nbsp;
        </li>
      </ol>
      <p>
        We do not process any &ldquo;special category data&rdquo; about you as a data controller
        other than where we list with an asterisk (*) in the section &ldquo;How does Schooly use
        your personal information&rdquo; below. Special category data includes information relating
        to your:
      </p>
      <ul>
        <li>nationality&nbsp;</li>
        <li>political opinions</li>
        <li>health</li>
      </ul>
      <p>
        Special category data concerning you may be contained in the information shared between you
        (or your parent/carer) and the relevant institution. As above, in relation to such data we
        will act as the data processorother than where we list with an asterisk (*) below. If you
        have any questions as to the policies of the relevant institution in relation to such
        information, please contact them directly.&nbsp;&nbsp;
      </p>
      <h3>Where does Schooly get personal information from?</h3>
      <p>
        We will collect personal information about you when you create an account on the Schooly
        service, when you use the Schooly service, and when you communicate with us in some other
        way.
      </p>
      <h3>How does Schooly use your personal information?</h3>
      <p>
        We process personal information to enable us to open and manage accounts, and to provide the
        Schooly service which allows users to share information with each other. Here are more
        details:
      </p>
      <div>
        <table>
          <thead>
            <tr>
              <th>
                <strong>What we use your information for</strong>
              </th>
              <th>
                <strong>What type of information we use</strong>
              </th>
              <th>
                <strong>What legal reason we have for using it</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <em>The creation and management of user accounts</em>
              </td>
              <td>
                Your email address, country of residence, Schooly user name, the names of any other
                individuals who are relevant to the account (such as the names of children
                associated with the account)
              </td>
              <td>
                We need all this information to make sure we comply with our obligations to you
                under our Terms of Service to set up and manage accounts.
              </td>
            </tr>
            <tr>
              <td>
                <em>the safeguarding of children&rsquo;s welfare</em>&nbsp;by Schooly&nbsp;
              </td>
              <td>*Any data shared via the Schooly app</td>
              <td>
                It is in everyone&rsquo;s interests to make sure that our online environment is safe
                for children.Sometimes we may have to keep your details because the law tells us we
                have to.We will only use <strong>special category information</strong> for this
                purpose as a data controller if it is in the interests of the public and we have a
                lawful reason to use it, or if we need it to help us with a legal case we are
                involved in, or if certain laws allow or require us to use it, or if you have told
                us you are happy for us to use it.
              </td>
            </tr>
            <tr>
              <td>
                <em>the promotion of Schooly</em>&nbsp;through its own website, other publications
                and communications including through our social media channels
              </td>
              <td>
                Photos or videos of you and your feedback and testimonials about the service - only
                where you have told us you are happy with this
              </td>
              <td>It is in our interests to promote the service that Schooly offers</td>
            </tr>
            <tr>
              <td>
                <em>
                  To help you if you have a question or are not happy with something we have done
                </em>
              </td>
              <td>Contact details and any conversations we have had</td>
              <td>It is in our interests to make sure we deal quickly with any issues you have.</td>
            </tr>
            <tr>
              <td>
                <em>To protect our IT systems</em>
              </td>
              <td>Details about how you use the Schooly service</td>
              <td>
                It is in our interests to make sure that our IT systems are safe and secure for all
                of our staff and you to use.
              </td>
            </tr>
            <tr>
              <td>
                <em>Improving our service&nbsp;</em>
              </td>
              <td>
                IP address, browser type, operating system, location, mobile carrier, device
                information, the sections of our services that you use, how you interact with the
                service, any issues that you have had with our service, and the time and date that
                you access it.
              </td>
              <td>It is in our interests to support and improve our service</td>
            </tr>
            <tr>
              <td>
                <em>
                  If we sell, transfer or merge parts of our business or our assets, or acquire
                  other businesses or merge with them.{' '}
                </em>
                If a change happens to our business, then the new owners may use your personal data
                in the same way as set out in this privacy policy.
              </td>
              <td>*All of your personal data</td>
              <td>It is in our interests to be able to buy and sell companies or parts of them.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        If you have given us information and told us we can use it in a certain way, you can tell us
        to stop using it at any time by contacting{' '}
        <a href="mailto: privacy@schooly-app.com" target="_blank" rel="noreferrer">
          <u>privacy@schooly-app.com</u>
        </a>
        . Please note that there may be reasons why we might continue to use your information after
        such a request; we will let you know if this applies at the relevant time.&nbsp;
      </p>
      <h3>Is your personal information safe?</h3>
      <p>
        We have put in place appropriate technical measures, and security processes, to make sure
        that your personal information is handled safely within the Schooly service.
      </p>
      <h3>We work with our suppliers as below:</h3>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Company</strong>
              </td>
              <td>
                <strong>Address</strong>
              </td>
              <td>
                <strong>Data Storage Location(s)</strong>
              </td>
            </tr>
            <tr>
              <td>Amazon Web Services EMEA SARL</td>
              <td>38 Avenue John F. Kennedy, L-1855, Luxembourg</td>
              <td>Ireland</td>
            </tr>
            <tr>
              <td>Google Ireland Limited</td>
              <td>Gordon House Barrow Street Dublin 4 Ireland</td>
              <td>Globally as shown here: https://www.google.com/about/datacenters/locations/</td>
            </tr>
            <tr>
              <td>Atlassian Pty Ltd</td>
              <td>Level 6, 341 George St, Sydney NSW 2000, Australia</td>
              <td>Ireland, United States, Singapore, Australia</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        Some of our suppliers&nbsp; are outside of the UK and the European Union, and so may not be
        subject to the laws that make sure your information is safe. Where this happens, we will do
        everything we can to make sure that your information is only used in the way we say it will
        be and are kept secure. At the moment we do this by having agreements with such suppliers
        called data processing agreements. The data processing agreements require our suppliers to
        respect the security of your personal information, to treat it in accordance with the law,
        to use your personal information only in accordance with our instructions and, if your
        information does need to be transferred outside of the UK or EU to a country which has a
        lower level of protection for your personal information (and so has not been assessed as
        being &ldquo;adequate&rdquo; by the EU Commission or the UK&rsquo;s data protection
        regulator, the Information Commissioner&rsquo;s Office), we will make sure that we also put
        in place any additional contractual safeguards required by the Information
        Commissioner&rsquo;s Office. If someone else is using your personal information because we
        have told them to, we will also make sure that we put measures in place with them to protect
        it.
      </p>
      <h3>How long does Schooly keep your personal information?</h3>
      <p>
        We collect personal information from you for different reasons and so we might keep it for
        different lengths of time. We usually retain registration information for 12 months from the
        date of closure of the Account, although we may need to retain some information for longer
        for legal reasons, for example information relating to safeguarding incidents. This will be
        retained in a secure environment and access will be restricted.&nbsp;
      </p>
      <p>
        It is important to make sure that the details we hold about you are accurate and up-to-date,
        so make sure you let us know if anything like your email address changes. You can do this
        through your account on the Schooly service.
      </p>
      <h3>What are your rights?</h3>
      <p>You can ask us:</p>
      <ul>
        <li>to tell you how your personal information are being used</li>
        <li>to provide you with a copy of all personal information we hold on you</li>
        <li>
          to correct some of the personal information we hold if they are not correct or out of
          date&nbsp;
        </li>
        <li>to delete all of the personal information we hold on you&nbsp;</li>
        <li>to stop using your personal information in a certain way</li>
        <li>
          to send your personal information to you or another organisation like your school for
          example
        </li>
      </ul>
      <p>
        Some of the rights may not always apply to the personal information we hold for you as there
        are sometimes requirements and exemptions attached which means we need to hold on to certain
        information, and at other times the rights may not apply at all.
      </p>
      <p>
        You can always tell us to stop sending you marketing messages. More information about these
        rights can be found online here:https://ico.org.uk/your-data-matters/&nbsp;&nbsp;
      </p>
      <p>
        If you are unhappy about something, you can make a complaint at any time to the Information
        Commissioner's Office (ICO), the UK supervisory authority for data protection issues
        (www.ico.org.uk). We would like the chance to deal with your concerns before you approach
        the ICO so please contact us first at{' '}
        <a href="mailto: privacy@schooly-app.com" target="_blank" rel="noreferrer">
          <u>privacy@schooly-app.com</u>
        </a>
        .
      </p>
      <h3>What if you contact Schooly via social media?</h3>
      <p>
        If you contact us via social media, the message may be stored by a third party provider. It
        will not be shared with any other organisations.
      </p>
      <h3>What about privacy and other organisations?</h3>
      <p>
        As described above, the institutions with which you connect via the Schooly service will
        have their own policy to explain how they keep your information safe. Please contact them if
        you have any questions about how they process your personal information, including
        information which you have sent to them via the Schooly service.
      </p>
      <h3>What about cookies?</h3>
      <p>
        Please see our{' '}
        <a href={import.meta.env.PUBLIC_URL + '/cookies-policy'} target="_blank" rel="noreferrer">
          <u>cookies policy</u>
        </a>
        .
      </p>
      <h3>
        <strong>What happens if Schooly needs to change this privacy policy?</strong>
      </h3>
      <p>
        We will update this privacy policy from time to time and make the updated version available
        via the app. Where we have made an important change, we will make this clear to you. Of
        course where we are required to do so by law, we will ask for your permission before we
        change the reason for using your details.
      </p>
      <h3>How do you get in touch with Schooly?</h3>
      <p>
        Please contact us if you have any questions about our privacy policy by email at{' '}
        <a href="mailto: privacy@schooly-app.com" target="_blank" rel="noreferrer">
          <u>privacy@schooly-app.com</u>
        </a>
        .
      </p>
    </section>
  </TextLayout>
);

export default PrivacyPolicy;
