import { Icon, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import { NotificationIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ManageGroupModalTooltip } from '../../ManageGroupModal/ManageGroupModalTooltip';
import { TakeAttendanceHeaderCell } from './TakeAttendanceModalContent.styled';

interface TakeAttendanceGridCodeHeaderCellProps {
  code: AttendanceCode;
  notificationsEnabled: boolean;
  onMouseOver: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave: () => void;
}

export const TakeAttendanceGridCodeHeaderCell: FC<TakeAttendanceGridCodeHeaderCellProps> = ({
  code,
  onMouseOver,
  onMouseLeave,
  notificationsEnabled,
}) => {
  return (
    <TakeAttendanceHeaderCell
      key={`th-${code.code}`}
      sx={{
        width: '100%',
        whiteSpace: 'normal',

        '&.MuiTableCell-root.MuiTableCell-root': {
          backgroundColor: 'transparent',
          color: 'inherit',
        },
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {code.name}
      {notificationsEnabled && code.has_push_notifications && (
        <Icon
          sx={{
            fontSize: 'inherit',
            ml: 1,
            overflow: 'visible',
            '.svg-icon': { verticalAlign: 'top' },
          }}
        >
          <ManageGroupModalTooltip
            sx={{ p: 1 }}
            text={
              <Typography color="primary.main">
                <FormattedMessage id="attendance-ParentNotification-AfterSaving" />
              </Typography>
            }
            arrow
          >
            <NotificationIcon />
          </ManageGroupModalTooltip>
        </Icon>
      )}
    </TakeAttendanceHeaderCell>
  );
};
