import { BoxProps } from '@mui/material';
import { AnnualPlanRecordTypes, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { addDays, differenceInDays, format, isAfter, isBefore, isPast } from 'date-fns';
import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';

import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { AnnualPlannerCalendarCell } from '../../AnnualPlannerCalendar.styled';
import { useAnnualPlannerCreateRecord } from '../../AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { AnnualPlannerCreateForm } from '../../scheme';
import { UseAnnualPlannerCellItem } from '../../useAnnualPlannerCell';
import { ANNUAL_PLANNER_DND_TYPE, useAnnualPlannerDnD } from '../../WithAnnualPlannerDnD';

export interface AnnualPlannerMainGridLayoutDropTargetProps extends BoxProps {
  date: Date;
  disabled?: boolean;
}

export const AnnualPlannerDropLayoutTarget: FC<AnnualPlannerMainGridLayoutDropTargetProps> = ({
  date,
  disabled,
  ...props
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { yearStart, yearEnd, schoolYear } = useAnnualPlanner();
  const { setMovingItem } = useAnnualPlannerDnD();
  const { submit } = useAnnualPlannerCreateRecord();

  const [, drop] = useDrop<UseAnnualPlannerCellItem>({
    accept: ANNUAL_PLANNER_DND_TYPE,
    drop: async (item) => {
      const record = item.records[0];

      if (!record) {
        return;
      }

      const newStartDate = date;
      const newEndDate = addDays(newStartDate, differenceInDays(item.end, item.start));

      if (yearStart && isBefore(newStartDate, yearStart)) {
        showError({
          message: $t(
            { id: 'annualPlanner-DnD-Error-OutOfSchoolYear' },
            { title: record.title, type: record.type },
          ),
        });
        return;
      }

      if (yearEnd && isAfter(newStartDate, yearEnd)) {
        showError({
          message: $t(
            { id: 'annualPlanner-DnD-Error-OutOfSchoolYear' },
            { title: record.title, type: record.type },
          ),
        });
        return;
      }

      if (yearEnd && newEndDate && isAfter(newEndDate, yearEnd)) {
        showError({
          message: $t(
            { id: 'annualPlanner-DnD-Error-OutOfSchoolYear' },
            { title: record.title, type: record.type },
          ),
        });
        return;
      }

      switch (record.type) {
        case AnnualPlanRecordTypes.SCHOOL_PERIOD:
        case AnnualPlanRecordTypes.HOLIDAY:
        case AnnualPlanRecordTypes.EVENT:
          if (isPast(newStartDate)) {
            showError({
              message: $t(
                { id: 'annualPlanner-DnD-Error-PastDate' },
                { title: record.title, type: record.type },
              ),
            });
            return;
          }
      }

      //disabled check is after errors in case is disabled and need to show error
      if (disabled) return;

      const payload: AnnualPlannerCreateForm = {
        originId: record.id,
        type: item.type,
        date: [
          format(newStartDate, DEFAULT_DATE_FORMAT_FNS),
          format(newEndDate, DEFAULT_DATE_FORMAT_FNS),
        ],
        schoolPeriod: {},
        event: {},
        assessment: {},
        report: {},
      };

      switch (record.type) {
        case AnnualPlanRecordTypes.SCHOOL_PERIOD: {
          const group = schoolYear?.period_groups?.find(
            (group) => group.id === record.details.period_group_id,
          );

          if (group) {
            payload.schoolPeriod = {
              groupId: group.id,
              groupName: group.name,
            };
          }

          break;
        }
        case AnnualPlanRecordTypes.EVENT:
        case AnnualPlanRecordTypes.HOLIDAY:
          payload.event = {
            name: record.title,
            isHoliday: record.type === AnnualPlanRecordTypes.HOLIDAY,
          };

          break;

        case AnnualPlanRecordTypes.ASSESSMENT:
          if (record.details.group_ids.length) {
            showError({
              message: $t({ id: 'annualPlanner-DnD-Error-AssessmentWithGroups' }),
            });
            return;
          }

          payload.assessment = {
            name: record.details.name,
            groups: [],
          };

          break;

        case AnnualPlanRecordTypes.REPORT:
          payload.report = {
            name: record.details.name,
          };

          break;
      }

      setMovingItem(item);

      await submit({
        ...payload,
        date: [
          format(newStartDate, DEFAULT_DATE_FORMAT_FNS),
          newEndDate ? format(newEndDate, DEFAULT_DATE_FORMAT_FNS) : '',
        ],
        withNotifications: false,
      });

      setMovingItem(undefined);
    },
  });

  return <AnnualPlannerCalendarCell ref={drop} {...props} />;
};
