import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useGetMembership } from '@schooly/api';
import { DuplicateProfileIcon, Spin } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { DuplicatesRow } from '../../../components/common/PersonDuplicates/DuplicatesRow';
import { useProfile } from '../../../context/profile/useProfile';
import { useSchool } from '../../../hooks/useSchool';

const DuplicatesButton: React.FC = () => {
  const { $t } = useIntl();
  const { userType, user } = useProfile();
  const navigate = useNavigate();
  const [openTooltip, setOpenTooltip] = useState(false);
  const { schoolId } = useSchool();

  const { data, isRefetching, isLoading } = useGetMembership(
    'student',
    { id: user?.relation_id || '', schoolId: schoolId || '' },
    {
      enabled: Boolean(schoolId && user?.relation_id),
      refetchOnWindowFocus: 'always',
      refetchOnMount: 'always',
    },
  );

  const duplicates = data?.duplicates || [];
  const fetching = Boolean(isRefetching || isLoading);

  const titleId = useMemo(() => {
    switch (userType) {
      case 'student':
        if (duplicates.length < 2) {
          return 'profile-StudentDuplicate';
        }
        return 'profile-StudentDuplicate-plural';
      default:
        return '';
    }
  }, [duplicates.length, userType]);

  const onClickAway = useCallback(() => {
    if (openTooltip) {
      setOpenTooltip(false);
    }
  }, [openTooltip]);

  if (!userType || !titleId) return null;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <Tooltip
          open={openTooltip && !fetching}
          componentsProps={{
            tooltip: {
              sx: () => ({
                width: 400,
                maxWidth: 400,
                overflow: 'hidden',
                py: 2,
              }),
            },
          }}
          title={
            <Stack gap={1.5}>
              <Typography variant="h3">
                {$t({
                  id: titleId,
                })}
              </Typography>
              {duplicates.map((duplicate) => (
                <DuplicatesRow
                  duplicate={duplicate}
                  endAction={
                    <Button
                      onClick={() => {
                        navigate(`compare/${duplicate.relation_id}`);
                      }}
                    >
                      {$t({ id: 'action-Compare' })}
                    </Button>
                  }
                  userType={userType}
                  sxProps={{
                    '&:hover': {
                      backgroundColor: 'background.default',
                      ' .MuiTypography-root, .MuiIconButton-root': {
                        color: 'primary.main',
                      },
                    },
                  }}
                />
              ))}
            </Stack>
          }
          arrow
        >
          <IconButton
            sx={{
              '&.MuiIconButton-root, &.MuiIconButton-root:hover': { color: 'warning.main' },
              display: !duplicates.length && !isLoading ? 'none' : 'visible',
            }}
            onClick={() => {
              setOpenTooltip((prev) => !prev);
            }}
            disabled={fetching}
          >
            {fetching ? <Spin /> : <DuplicateProfileIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default DuplicatesButton;
