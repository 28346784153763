import {
  Genders,
  Languages,
  Nationalities,
  RelationsToChild,
  SchoolUserRole,
  Titles,
} from '@schooly/constants';

import { CustomField, CustomFieldStringValue } from './endpoints/customFields';
import { StringOrNull } from './misc';
import { School } from './schools';

export type ApplicationStatus = 'open' | 'rejected' | 'converted';

export type RelationId = string;

export enum LanguageLevel {
  Beginner,
  Intermediate,
  Advanced,
  Fluent,
}

export enum LanguageAreas {
  Speaking = 'speaking',
  Writing = 'writing',
}

/** T[Language, SpeakingLevel, WritingLevel] */
export type Language = [Languages, LanguageLevel, LanguageLevel];

interface ApplicationCommon {
  id: string;
  created_at: string;
  status: ApplicationStatus;
  adults: Array<Parent>;
  creator_type: SchoolUserRole;
}

export enum ApplicationDefaultCustomFields {
  MedicalNeeds = 'Medical needs',
  SpecialNeeds = 'Special needs',
  BehaviourConcerns = 'Behaviour concerns',
  LearningDifficulties = 'Learning difficulties',
}

export type ApplicationCustomFieldLabel = string | keyof typeof ApplicationDefaultCustomFields;

export interface ApplicationCustomField
  extends Pick<CustomField, 'data_type' | 'is_severe' | 'select_type_options'>,
    Omit<CustomFieldStringValue, 'label'> {
  label: ApplicationCustomFieldLabel;
}

export interface ApplicationRelation {
  child_rid: RelationId;
  adult_rid: RelationId;
  relationship_type: RelationsToChild | null;
}

export interface ApplicationShort extends ApplicationCommon {
  children: Array<ApplicationChildShort>;
}

export interface Application extends ApplicationCommon {
  children: Array<ApplicationChild>;
  adults: Array<Parent>;
  general_info_custom_fields_values: Array<ApplicationCustomField>;
  relations?: ApplicationRelation[];
}

export interface ApplicationsByChildrenIdsRequest {
  children_ids: string;
  school_user_relation_id: string;
}

export interface ApplicationsByChildrenIds {
  application_id: Application['id'];
  date: string;
  school: School;
  status: ApplicationStatus;
  student: ApplicationChild[];
}

export interface Parent {
  id: string;
  profile_id: StringOrNull;
  title?: Titles;
  given_name?: string;
  last_name: string;
  gender?: Genders;
  nationalities?: Nationalities[];
  language?: Languages;
  profession?: string;
  telephone?: string;
  phone?: string;
  email?: string;
  can_be_edited?: boolean;
  primary: boolean;
  children?: Array<ParentChild>;
  custom_fields?: Array<ApplicationCustomField>;
}

interface ParentChild {
  given_name: string;
  last_name: string;
}

export type ParentCreate = Omit<Parent, 'profile_id'>;

export interface ApplicationChildShort {
  id: string;
  preferred_start_date: string;
  given_name: string;
  last_name: string;
  date_of_birth: string;
  nationalities: Array<Nationalities>;
  language: Language;
  known_as?: string;
  notes?: string;
  profile_id: StringOrNull;
}

export interface ApplicationChild extends ApplicationChildShort {
  gender: Genders;
  other_languages_spoken?: Language[];
  school_history?: Array<SchoolHistory>;
  custom_fields_values?: Array<ApplicationCustomField>;
  relation_id?: string;
}

export type ApplicationChildCreate = Omit<ApplicationChild, 'profile_id'>;

export interface SchoolHistory {
  school_name: string;
  start_date: string;
  end_date: string;
  country: number;
  language: keyof typeof Languages;
}

export interface EnrollmentStatus {
  school_property_id: string;
  applies_from: string;
  applies_to?: string;
}

export interface EnrollmentBase {
  school_year_id: string;
  half_day?: boolean;
  /** For age_group, house */
  school_property_ids: string[];
  /** For applying, enrolled, cancelled, etc */
  statuses: EnrollmentStatus[];
}
export interface ApplicationEnrollment extends EnrollmentBase {
  application_child_id: ApplicationChild['id'];
  relation_id: StringOrNull;
}
