import { SelectProps, useThemeProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { FC, useCallback, useRef, useState } from 'react';

import { SingleSelectStyled } from './SingleSelect.style';

export const SingleSelect: FC<SelectProps> = (props) => {
  const defaultProps = useThemeProps({ name: 'MuiSelect', props });
  const [isTop, setIsTop] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onMenuDidMount = useCallback((element: HTMLDivElement | null) => {
    setTimeout(() => {
      if (!ref.current || !element) {
        setIsTop(false);
        return;
      }

      const menu = element.querySelector('.MuiMenu-paper') as HTMLDivElement;

      if (!menu) {
        setIsTop(false);
        return;
      }

      const inputBottom = ref.current.getBoundingClientRect().bottom;
      const menuTop = parseInt(menu.style.top);

      setIsTop(inputBottom > menuTop);
    }, 0);
  }, []);

  return (
    <SingleSelectStyled
      ref={ref}
      {...props}
      isTop={isTop}
      MenuProps={{
        ...defaultProps?.MenuProps,
        sx: (theme) => ({
          ...((typeof defaultProps?.MenuProps?.sx === 'function'
            ? defaultProps?.MenuProps?.sx(theme)
            : defaultProps?.MenuProps?.sx) as SystemStyleObject),

          '& .MuiMenu-paper': { maxWidth: ref.current?.offsetWidth },

          ...(isTop && {
            '& .MuiMenu-paper': {
              borderTop: theme.mixins.borderControlValue(),
              borderBottom: 0,

              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              maxWidth: ref.current?.offsetWidth,

              '&.MuiPaper-elevation': {
                boxShadow: 'none',
              },
            },
          }),
        }),
        ref: onMenuDidMount,
        anchorOrigin: {
          vertical: isTop ? 'top' : 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: isTop ? 'bottom' : 'top',
          horizontal: 'left',
        },
      }}
    />
  );
};
