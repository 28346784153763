import { Icon, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

import { Attention2Icon } from '../assets/assets';

export const AttentionTooltipIcon: FC<{ title: TooltipProps['title'] }> = ({ title }) => {
  return (
    <Tooltip componentsProps={{ tooltip: { sx: { padding: 1.25 } } }} title={title}>
      <Icon
        sx={(theme) => ({
          color: theme.palette.background.paper,
          path: { color: theme.palette.common.orange },
        })}
      >
        <Attention2Icon />
      </Icon>
    </Tooltip>
  );
};
