import { Box, Icon, Stack, Typography } from '@mui/material';
import { AnnualPlannerViewMode, AnnualPlanRecordTypes } from '@schooly/api';
import { DropdownSelect, DropdownSelectProps } from '@schooly/components/filters';
import { getLabelIdForOption } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { ArrowDownV2Icon, DoneIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import {
  defaultAnnualPlannerCreateFormValues,
  useAnnualPlannerCreateRecord,
} from './AnnualPlannerCalendar/AnnualPlannerCreatePopover/WithAnnualPlannerCreate';
import { trackPlannerViewModeEvents } from './utils';
import { useAnnualPlanner } from './WithAnnualPlanner';

export const ANNUAL_PLANNER_VIEW_MODE_SELECT_WIDTH = 220;

export const AnnualPlannerViewModeSelect: FC<
  Omit<DropdownSelectProps, 'children' | 'renderContent'>
> = (dropdownProps) => {
  const { formatMessage } = useIntl();
  const [opened, open, close] = useFlag();
  const dropdownRef = useRef<DropdownSelect | null>(null);
  const anchorRef = useRef<HTMLElement | null>(null);

  const { setExpandedData, open: openCreate } = useAnnualPlannerCreateRecord();
  const { viewMode, handleViewModeSelect, mainPeriodGroup, hasSchoolAdminPermissions } =
    useAnnualPlanner();

  const showAddPeriodsButton = !mainPeriodGroup && hasSchoolAdminPermissions;

  const options = useMemo(() => {
    const allOptions = $enum(AnnualPlannerViewMode).map((value, key) => ({
      value,
      label:
        value === AnnualPlannerViewMode.Term
          ? mainPeriodGroup?.name
          : formatMessage({
              id: getLabelIdForOption({
                optionsKey: 'annual-planner-view-mode',
                key,
              }),
            }),
    }));

    if (!mainPeriodGroup) {
      return allOptions.filter((o) => o.value !== AnnualPlannerViewMode.Term);
    }

    return allOptions;
  }, [formatMessage, mainPeriodGroup]);

  const selectedOption = options.find((o) => o.value === viewMode);

  const renderContent = useCallback(() => {
    return (
      <Box sx={(theme) => ({ padding: theme.spacing(1, 1, 0.75) })}>
        {options.map((option) => {
          const isSelected = viewMode === option.value;

          return (
            <Stack
              key={option.value}
              onClick={() => {
                handleViewModeSelect(option.value);
                trackPlannerViewModeEvents(option.value, 'select');
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              px={0.5}
              mb={0.5}
              sx={(theme) => ({
                borderRadius: theme.spacing(0.5),
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                  color: theme.palette.primary.main,
                },
              })}
            >
              <Typography variant="h3" color={isSelected ? 'primary.main' : 'inherit'}>
                {option.label}
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Icon sx={{ visibility: isSelected ? 'visible' : 'hidden', color: 'primary.main' }}>
                  <DoneIcon />
                </Icon>
              </Stack>
            </Stack>
          );
        })}

        {showAddPeriodsButton && (
          <SimpleButton
            startIcon={<PlusIcon />}
            onClick={() => {
              dropdownRef.current?.close();
              setExpandedData({
                ...defaultAnnualPlannerCreateFormValues,
                type: AnnualPlanRecordTypes.SCHOOL_PERIOD,
              });
              openCreate(anchorRef.current);
            }}
          >
            {formatMessage({ id: 'school-schoolPeriods-AddSchoolPeriods' })}
          </SimpleButton>
        )}
      </Box>
    );
  }, [
    formatMessage,
    handleViewModeSelect,
    openCreate,
    options,
    setExpandedData,
    showAddPeriodsButton,
    viewMode,
  ]);

  return (
    <Box ref={anchorRef}>
      <DropdownSelect
        {...dropdownProps}
        ref={dropdownRef}
        renderContent={renderContent}
        sx={{
          borderColor: opened ? undefined : 'transparent',
          outlineColor: 'transparent !important',
          minWidth: opened ? ANNUAL_PLANNER_VIEW_MODE_SELECT_WIDTH : undefined,
          ' .right-icon': { right: opened ? undefined : 0 },
          transition: 'all .2s, border-color 0s',
        }}
        layoutStyleProps={{ pr: opened ? undefined : 2.5 }}
        hasValues
        onOpen={open}
        onClose={close}
        renderRightIcon={() => (
          <Icon
            sx={(theme) => ({
              width: theme.spacing(1),
              transform: 'rotate(180deg)',
              path: { stroke: theme.palette.common.grey },
            })}
          >
            <ArrowDownV2Icon />
          </Icon>
        )}
      >
        {() => <Typography variant="h3">{selectedOption?.label}</Typography>}
      </DropdownSelect>
    </Box>
  );
};
