import { Stack, Typography } from '@mui/material';
import { FormTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolCreateForm } from './useSchoolCreate';

interface SchoolCreateEmailsProps {
  form: UseFormReturn<SchoolCreateForm, any>;
}

export const SchoolCreateEmails: FC<SchoolCreateEmailsProps> = ({ form }) => {
  const { formatMessage } = useIntl();
  const rules = {
    required: true,
    pattern: {
      value: VALID_EMAIL_REGEXP,
      message: formatMessage({
        id: 'input-ErrorInvalidEmail',
      }),
    },
  };
  return (
    <Stack height={'80vh'}>
      <Stack my="auto">
        <Stack gap={1.5}>
          <Typography variant="h1">
            {formatMessage({ id: 'school-create-EmailsForNotificationsTitle' })}
          </Typography>
          <Typography variant="h3" maxWidth={640}>
            {formatMessage({ id: 'school-create-EmailsForNotificationsDescription' })}
          </Typography>
        </Stack>

        <Stack gap={2} mt={2.5}>
          <Controller
            control={form.control}
            rules={rules}
            name="notificationEmails.admissionsEmail"
            render={({ field, fieldState }) => {
              return (
                <FormTextField
                  label={formatMessage({
                    id: 'school-sections-Notifications-Internal-AdmissionsEmail',
                  })}
                  fullWidth
                  canClear
                  inputRef={field.ref}
                  required
                  error={!!fieldState.error}
                  helperText={getControllerErrorText(
                    fieldState.error,
                    { required: true },
                    formatMessage,
                  )}
                  {...field}
                  onChange={(e) => {
                    form.clearErrors('notificationEmails.admissionsEmail');
                    field.onChange(e);
                  }}
                />
              );
            }}
          />

          <Controller
            control={form.control}
            rules={rules}
            name="notificationEmails.invoicingEmail"
            render={({ field, fieldState }) => {
              return (
                <FormTextField
                  label={formatMessage({
                    id: 'school-sections-Notifications-Internal-InvoicingEmail',
                  })}
                  fullWidth
                  canClear
                  inputRef={field.ref}
                  required
                  error={!!fieldState.error}
                  helperText={getControllerErrorText(
                    fieldState.error,
                    { required: true },
                    formatMessage,
                  )}
                  {...field}
                  onChange={(e) => {
                    form.clearErrors('notificationEmails.invoicingEmail');
                    field.onChange(e);
                  }}
                />
              );
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
