import { IconButton } from '@mui/material';
import {
  CheckIcon,
  ContextMenu,
  ContextMenuProps,
  CrossIcon,
  DownloadIcon,
  Spin,
} from '@schooly/style';
import { FC } from 'react';

export type ExportStatus = 'success' | 'error';

type PageExport = {
  isLoading: boolean;
  exportStatus: ExportStatus | null;
} & Omit<ContextMenuProps, 'children'>;

export const PageExport: FC<PageExport> = ({ isLoading, exportStatus, ...menuProps }) => {
  const renderIcon = () => {
    if (isLoading) {
      return <Spin />;
    }

    if (exportStatus === 'success') {
      return <CheckIcon />;
    }

    if (exportStatus === 'error') {
      return <CrossIcon />;
    }

    return <DownloadIcon />;
  };

  return (
    <ContextMenu
      placement="bottom"
      componentsProps={{ tooltip: { sx: { p: 2.5, minWidth: 300, '.container': { p: 0 } } } }}
      {...menuProps}
    >
      {(open) => (
        <IconButton
          sx={{ '&&': { color: isLoading || exportStatus ? 'primary.main' : undefined } }}
          onClick={isLoading ? undefined : open}
        >
          {renderIcon()}
        </IconButton>
      )}
    </ContextMenu>
  );
};
