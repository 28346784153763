import { FC } from 'react';

import {
  AnnualPlannerCalendarCell,
  AnnualPlannerCalendarGrid,
} from '../../AnnualPlannerCalendar.styled';
import { ANNUAL_PLANNER_DAY_CELL_WIDTH, AnnualPlannerCalendarWithDates } from '../../scheme';
import { useAnnualPlannerDropLayout } from '../../useAnnualPlannerDropLayout';
import { useAnnualPlannerYearGrid } from '../../useAnnualPlannerGrid';
import { getAnnualPlannerCell } from '../../utils';
import { AnnualPlannerDropLayoutTarget } from './AnnualPlannerDropLayoutTarget';

export interface AnnualPlannerDropLayoutYearProps extends AnnualPlannerCalendarWithDates {}

export const AnnualPlannerDropLayoutYear: FC<AnnualPlannerDropLayoutYearProps> = (props) => {
  const { zIndex } = useAnnualPlannerDropLayout();
  const { start, end, cols, months, days } = useAnnualPlannerYearGrid(props);

  return (
    <AnnualPlannerCalendarGrid
      className={[
        AnnualPlannerCalendarGrid.defaultProps?.className ?? '',
        'AnnualPlannerCalendar-DropLayout-root',
      ].join(' ')}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        borderTop: '1px solid transparent',
        borderLeft: '1px solid transparent',
        gridTemplateColumns: `${ANNUAL_PLANNER_DAY_CELL_WIDTH}px repeat(${cols}, 1fr)`,

        zIndex,
      }}
    >
      <AnnualPlannerCalendarCell
        sx={(theme) => ({
          borderRight: theme.mixins.borderValue(),
          borderBottom: theme.mixins.borderValue(),
        })}
      >
        {/* top-left empty cell */}
      </AnnualPlannerCalendarCell>

      {months.map((month) => {
        return (
          <AnnualPlannerCalendarCell
            key={`header-col-${month.getMonth()}-${month.getFullYear()}`}
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
          >
            {/* header months names */}
          </AnnualPlannerCalendarCell>
        );
      })}

      {days.map((day) => {
        return (
          <>
            <AnnualPlannerCalendarCell
              key={`col-date-${day}`}
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: theme.mixins.borderValue(),
                borderBottom: theme.mixins.borderValue(),
              })}
            >
              {/* left-side day nums */}
            </AnnualPlannerCalendarCell>
            {months.map((month) => {
              const { date, hasDate } = getAnnualPlannerCell({
                month,
                day,
                start,
                end,
              });

              return (
                <AnnualPlannerDropLayoutTarget
                  key={`col-${day}-${month.getMonth()}-${month.getFullYear()}`}
                  className={[
                    AnnualPlannerCalendarCell.defaultProps?.className ?? '',
                    'AnnualPlannerCalendar-DropLayout-cell',
                  ].join(' ')}
                  date={date}
                  disabled={!hasDate}
                />
              );
            })}
          </>
        );
      })}
    </AnnualPlannerCalendarGrid>
  );
};
