import { IconButton, Stack, Typography } from '@mui/material';
import { AnnualPlannerViewMode } from '@schooly/api';
import { ChevronLeftIcon, ChevronRightIcon } from '@schooly/style';
import { FC } from 'react';

import { useAnnualPlanner } from './WithAnnualPlanner';

export const AnnualPlannerHeader: FC = () => {
  const {
    handleViewPeriodChange,
    viewPeriods,
    currentViewPeriodIndex = 0,
    viewMode,
  } = useAnnualPlanner();

  const isFirstPeriod = currentViewPeriodIndex === 0;
  const isLastPeriod = currentViewPeriodIndex === viewPeriods.length - 1;
  const currentPeriod = viewPeriods[currentViewPeriodIndex];

  if (viewMode === AnnualPlannerViewMode.Year) return null;

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: theme.mixins.borderValue(),
        borderBottom: 'none',
        px: 0.5,
        py: 0.75,
      })}
    >
      <IconButton
        onClick={() => handleViewPeriodChange(currentViewPeriodIndex - 1)}
        sx={{ visibility: isFirstPeriod ? 'hidden' : 'visible' }}
      >
        <ChevronLeftIcon />
      </IconButton>

      <Typography variant="h3" color="text.primary">
        {currentPeriod.name}
      </Typography>

      <IconButton
        onClick={() => handleViewPeriodChange(currentViewPeriodIndex + 1)}
        sx={{ visibility: isLastPeriod ? 'hidden' : 'visible' }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
